import {defineMessages} from "react-intl";
import {FileMetadata} from "../common/model";
import {SpatialModel} from "../model";

export enum StyleType {
  RASTER = "Raster",
  VECTOR = "Vector",
  MIXED = "Mixed",
  UNKNOWN = "Unknown",
}

export interface Style extends SpatialModel {
  filePath?: string;
  createdBy?: {
    username: string;
  };
  creationTime?: string;
  updateTime?: string;
  content?: string;
  type: StyleType;
  fileMetadata?: FileMetadata;
}

export const MODEL_MESSAGES = defineMessages({
  defaultMetaTitle: {
    id: "studio.styles.default-metadata-style",
    defaultMessage: "DEFAULT STYLE",
  },
  noMetaTitle: {
    id: "studio.styles.no-metadata-style",
    defaultMessage: "NO NAME",
  },
});

export const DEFAULT_STYLE: Style = {
  id: null,
  name: MODEL_MESSAGES.defaultMetaTitle.defaultMessage,
  title: MODEL_MESSAGES.defaultMetaTitle.defaultMessage,
  abstractText: "",
  wgs84Bounds: null,
  type: null,
};
