import * as React from "react";

export class ActionBar extends React.Component<{}, {}> {
  render() {
    const {children} = this.props;
    return (
        <div className="actionBar">
          {React.Children.map(children, (el) => el)}
        </div>
    );
  }
}
