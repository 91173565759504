/**
 * Logging utilities inspired by log4j
 */
import moment = require("moment");

export interface Appender {
  name: string;
  layout: Layout;

  doAppend(event: LoggingEvent): void;
}

export class ConsoleAppender implements Appender {
  constructor(public layout: Layout = new SimpleLayout(), public name = "Console", public level: Level = Level.INFO) {
  }

  doAppend(event: LoggingEvent) {
    if (event.level < this.level) {
      return;
    }

    let logFunction = console.log;

    switch (event.level) {
    case Level.TRACE:
    case Level.DEBUG:
    case Level.INFO:
      logFunction = console.log;
      break;
    case Level.WARN:
      logFunction = console.warn;
      break;
    case Level.ERROR:
    case Level.FATAL:
      logFunction = console.error;
      break;
    default:
      logFunction = console.log;
    }

    const formattedMessage: string = this.layout.format(event);
    logFunction(formattedMessage);
  }
}

export interface Layout {
  format(event: LoggingEvent): string;
}

export const formatLevel = (level: Level) => {
  switch (level) {
  case Level.ALL:
    return "ALL";
  case Level.TRACE:
    return "TRACE";
  case Level.DEBUG:
    return "DEBUG";
  case Level.INFO:
    return "INFO";
  case Level.WARN:
    return "WARN";
  case Level.ERROR:
    return "ERROR";
  case Level.FATAL:
    return "FATAL";
  case Level.OFF:
    return "OFF";
  }
  return "UNKNOWN_LEVEL";
};

export class SimpleLayout implements Layout {

  format(event: LoggingEvent) {
    const {timestamp, level, logger, message} = event;
    const date = moment(timestamp);
    const dateStr = date.format("YYYY-MM-DD hh:mm:ss.mmm");
    return `${dateStr} ${formatLevel(level)} - ${logger.name}: ${message} ${event.error && event.error.stack ? "\n" + event.error.stack : ""}`;
  }
}

export class Logger {

  static getLogger(name: string) {
    return LogManager.instance().getLogger(name);
  }

  constructor(public name: string, public appenders: Appender[]) {
  }

  addAppender(appender: Appender) {
    this.appenders.push(appender);
  }

  removeAppender(appender: Appender) {
    this.appenders.splice(this.appenders.indexOf(appender), 1);
  }

  trace(message: string, error?: Error) {
    this.handleMessage(Level.TRACE, message, error);
  }

  debug(message: string, error?: Error) {
    this.handleMessage(Level.DEBUG, message, error);
  }

  info(message: string, error?: Error) {
    this.handleMessage(Level.INFO, message, error);
  }

  warn(message: string, error?: Error) {
    this.handleMessage(Level.WARN, message, error);
  }

  error(message: string, error?: Error) {
    this.handleMessage(Level.ERROR, message, error);
  }

  fatal(message: string, error?: Error) {
    this.handleMessage(Level.FATAL, message, error);
  }

  private notifyAppenders(event: LoggingEvent) {
    this.appenders.forEach((appender) => {
      appender.doAppend(event);
    });
  }

  private handleMessage(level: Level, message: string, error?: Error) {
    const event = new LoggingEvent(this, Date.now(), level, message, error || null);
    this.notifyAppenders(event);
  }

}

export class LogManager {

  static ROOT_LOGGER_NAME = "ROOT";
  static sInstance: LogManager;

  static instance(): LogManager {
    if (!LogManager.sInstance) {
      LogManager.sInstance = new LogManager();
      const rootLogger = LogManager.sInstance.getLogger("ROOT");
      rootLogger.addAppender(new ConsoleAppender());
    }
    return LogManager.sInstance;
  }

  private loggers: { [name: string]: Logger };

  public constructor() {
    this.loggers = {};
    this.loggers[LogManager.ROOT_LOGGER_NAME] = new Logger(LogManager.ROOT_LOGGER_NAME, []);
  }

  getLogger(name: string) {
    if (!this.loggers[name]) {
      const parentLogger = this.findParentLogger(name);
      this.loggers[name] = new Logger(name, parentLogger.appenders);
    }
    return this.loggers[name];
  }

  private findParentLogger(name: string) {
    if (name === LogManager.ROOT_LOGGER_NAME) {
      return this.loggers[LogManager.ROOT_LOGGER_NAME];
    }
    const lastPointIdx = name.lastIndexOf(".");
    const parentPath = lastPointIdx === -1 ? LogManager.ROOT_LOGGER_NAME : name.substr(0, lastPointIdx);
    return this.loggers[parentPath] || this.findParentLogger(parentPath);
  }

}

export enum Level {
  ALL,
  TRACE,
  DEBUG,
  INFO,
  WARN,
  ERROR,
  FATAL,
  OFF,
}

export class LoggingEvent {

  constructor(public logger: Logger,
              public timestamp: number,
              public level: Level,
              public message: string,
              public error: Error) {

  }

}
