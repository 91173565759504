//selectors are separate here, in order to break cyclic dependency cycle between actions and reducer

import {DefaultMetadata} from "./model";

export const moduleName = "defaultMetadata";

const getDefaultMetadataState = (state) => state[moduleName] || {};

const getDefaultMetadata = (state): DefaultMetadata => {
  return getDefaultMetadataState(state).defaultMetadata || {loadedSuccessfully: false};
};

export const selectors = {
  getDefaultMetadata
};
