import * as React from "react";
import {Button, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {ImportedData} from "../data/model";
import {AddToProductForm, CloseAddToProductFormButton, ExecuteButton} from "./AddToProductForm";

interface ButtonProps {
  disabled?: boolean;
  initialData?: ImportedData[];
}
interface ButtonState {
  showModal: boolean;
}
export class AddToProductButton extends React.Component<ButtonProps, ButtonState> {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  closeCreationModal = () => {
    this.setState({showModal: false});
  }

  openCreationModal = () => {
    this.setState({showModal: true});
  }

  render() {
    const {disabled, initialData} = this.props;
    const {showModal} = this.state;
    const noData = !initialData || initialData.length === 0;

    const buttonIsDisabled = disabled || showModal || noData;

    let addToProductButton = null;

    if (buttonIsDisabled) {

      const reason = <FormattedMessage id="studio.products.add-to-product.reason"
                                       defaultMessage="Select some data from the list below"/>;
      //adding an overlay (tooltip) to a disabled element requires some witchcraft, as disabled elements
      //do not respond to mouse events
      //see remark in bootstrap docs: http://getbootstrap.com/javascript/#tooltips
      //and https://github.com/react-bootstrap/react-bootstrap/issues/1588
      addToProductButton = (
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{reason}</Tooltip>}>
            <div style={{cursor: "not-allowed"}}>
              <Button className="actionBarItem" style={{pointerEvents : "none"}} onClick={this.openCreationModal} disabled>
                <LcdIcon icon="plus"/> <FormattedMessage
                  id="studio.products.add-to-product.button-disabled"
                  defaultMessage="Add to Product"/>
              </Button>
            </div>
          </OverlayTrigger>
      );
    } else {
      addToProductButton = (
          <Button className="actionBarItem" onClick={this.openCreationModal}>
            <LcdIcon icon="plus"/> <FormattedMessage
              id="studio.products.add-to-product.button"
              defaultMessage="Add to Product"/>
          </Button>
      );
    }

    return (
        <div>
          {addToProductButton}
          <Modal show={showModal} onHide={this.closeCreationModal} bsSize="large" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title><FormattedMessage id="studio.products.add-to-product.title" defaultMessage="Add to Product"/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddToProductForm initialData={initialData}/>
            </Modal.Body>
            <Modal.Footer>
              <ExecuteButton />
              <CloseAddToProductFormButton onClick={this.closeCreationModal} />
            </Modal.Footer>
          </Modal>
        </div>
    );
  }
}
