import * as React from "react";
import {Grid} from "react-bootstrap";
import {ActionBar} from "../common/ui/actionbar/ActionBar";
import {MasterDetailLayout} from "../common/ui/layouts/MasterDetailLayout";
import {PublishProductInServiceButton} from "./CreateServiceButton";
import {ServiceFilter} from "./filter/ServiceFilter";
import {Service} from "./model";
import {ServiceList} from "./ServiceList";
import {ServiceListDetailSection} from "./ServiceListDetailSection";

interface ServicePageState {
  selection: Service[];
}

export class ServicesPage extends React.Component<any, ServicePageState> {

  constructor(props) {
    super(props);
    this.state = {
      selection: [],
    };
  }

  handleRowSelect = (selection: Service[]) => {
    this.setState(Object.assign({}, this.state, {selection}));
  }

  clearSelection = () => this.handleRowSelect([]);

  render() {
    const {selection} = this.state;
    const noSelection = selection.length === 0;

    const masterPane = (
        <Grid fluid={true}>
          <ActionBar>
            <PublishProductInServiceButton/>
          </ActionBar>

          <ServiceFilter notifyFilterChanged={this.clearSelection}/>

          <div> {/* This empty div is necessary! it's used by ControlRoomTable to calculate its dimensions.*/}
            <ServiceList onRowSelect={this.handleRowSelect} clearSelection={noSelection}/>
          </div>

        </Grid>
    );

    const detailPane = <ServiceListDetailSection selection={selection}/>;

    return (
        <div>
          <MasterDetailLayout masterPane={masterPane} detailPane={detailPane}/>
        </div>
    );
  }
}
