import {Api} from "../../api/InternalApi";
import {createAsyncActionTypes, createLoadDataActionCreator} from "../../common/util/asyncdata/actionUtil";
import {createPagedDataActions, selectors as pagedDataSelectors} from "../../common/util/pagination/PaginationUtil";
import {Service} from "../../services/model";
import {FileInfoSearchFilter, filtersEqual, ImportJob, JobType} from "../model";
import {importJobSubmoduleName, selectors} from "../selectors";

export const IMPORT_JOB_FILES_ACTION_TYPE_NAME = "IMPORT_JOB_FILES";

export const LOAD_JOBS_PREFIX = "controlRoom/LOAD_JOBS";
const loadJobActionTypes = createAsyncActionTypes(LOAD_JOBS_PREFIX);

export const LOAD_JOB_DETAIL_PREFIX = "controlRoom/LOAD_JOB_DETAIL";
const loadJobDetailActionTypes = createAsyncActionTypes(LOAD_JOB_DETAIL_PREFIX);

export const actionTypes = {
  ADD_JOB: "controlRoom/ADD_JOB",
  REMOVE_JOB: "controlRoom/REMOVE_JOB",
  QUEUE_JOB: "controlRoom/QUEUE_JOB",
  RUN_JOB: "controlRoom/RUN_JOB",
  PROGRESS_JOB: "controlRoom/PROGRESS_JOB",
  STOP_JOB: "controlRoom/STOP_JOB",
  FINISH_JOB: "controlRoom/FINISH_JOB",
  FAIL_JOB: "controlRoom/FAIL_JOB",
  UPDATE_JOB: "controlRoom/UPDATE_JOB",
  UPDATE_SERVICE: "controlRoom/UPDATE_SERVICE",
  LOAD_JOBS_STARTED: loadJobActionTypes.STARTED,
  LOAD_JOBS_SUCCESS: loadJobActionTypes.SUCCESS,
  LOAD_JOBS_ERROR: loadJobActionTypes.ERROR,
  LOAD_JOBS_RESET: loadJobActionTypes.RESET,
  LOAD_JOB_DETAIL_STARTED: loadJobDetailActionTypes.STARTED,
  LOAD_JOB_DETAIL_SUCCESS: loadJobDetailActionTypes.SUCCESS,
  LOAD_JOB_DETAIL_ERROR: loadJobDetailActionTypes.ERROR,
  LOAD_JOB_DETAIL_RESET: loadJobDetailActionTypes.RESET,
  SET_IMPORT_JOB_FILTER: "controlRoom/SET_IMPORT_JOB_FILTER",
};

const addJob = (job: ImportJob) => {
  return {
    type: actionTypes.ADD_JOB,
    payload: {
      job,
    },
  };
};

const removeJob = (jobId: string) => {
  return {
    type: actionTypes.REMOVE_JOB,
    payload: {
      id: jobId,
    },
  };
};

const queueJob = (jobId: string) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    // V170-1705: Do not update job state client-side! This can result in race conditions between HTTP responses
    // and websocket updates (in this particular case, the websocket job update to RUNNING state could come in
    // _before_ the HTTP response. When the HTTP response comes in, the job will incorrectly be put back in queued
    // state.
    return api.queueJob(jobId);
  };
};

const runJob = (jobId: string) => {
  return {
    type: actionTypes.RUN_JOB,
    payload: {
      id: jobId,
    },
  };
};

const progressJob = (jobId: string, progress: number) => {
  return {
    type: actionTypes.PROGRESS_JOB,
    payload: {
      id: jobId,
      progress,
    },
  };
};

const finishJob = (jobId: string) => {
  return {
    type: actionTypes.FINISH_JOB,
    payload: {
      id: jobId,
    },
  };
};

const failJob = (jobId: string) => {
  return {
    type: actionTypes.FAIL_JOB,
    payload: {
      id: jobId,
    },
  };
};

const stopJob = (jobId: string) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    // V170-1705: all job updates come in over web sockets. Do not dispatch actions on HTTP responses.
    // cfr. comment above.
    return api.stopJob(jobId);
  };
};

const updateJobOnClient = (job: ImportJob) => {
  return {
    type: actionTypes.UPDATE_JOB,
    payload: {
      job,
    },
  };
};

const updateJobOnServer = (job: ImportJob) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    // V170-1705: all job updates come in over web sockets. Do not dispatch actions on HTTP responses.
    // cfr. comment above.
    return api.updateJob(job);
  };
};

const updateServiceOnClient = (service: Service) => {
  return {
    type: actionTypes.UPDATE_SERVICE,
    payload: {
      service,
    },
  };
};

const validateJob = (job: ImportJob) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.validateJob(job);
  };
};

const jobRequestState = selectors.getJobRequestState.bind(null, importJobSubmoduleName);
const jobDetailRequestState = selectors.getJobDetailRequestState.bind(null, importJobSubmoduleName);

const loadJobs = createLoadDataActionCreator(LOAD_JOBS_PREFIX, (api) => api.loadJobs, jobRequestState);
const loadJobById = createLoadDataActionCreator(LOAD_JOB_DETAIL_PREFIX, (api) => api.getJobById, jobDetailRequestState);

const setJobFilesFilter = (filter: FileInfoSearchFilter) => {
  return {
    type: actionTypes.SET_IMPORT_JOB_FILTER,
    payload: filter,
  };
};

const fetchPage = (page: number, state, api: Api) => {
  const filter = selectors.getJobFilesFilter(JobType.IMPORT, state);
  filter.crawlJob = selectors.getCurrentJobId(JobType.IMPORT, state);
  const pagedDataState = selectors.getImportJobFilesPagedState(state);
  const maxResults = pagedDataSelectors.getPageSize(pagedDataState);
  const offset = page * maxResults;
  const filterWithPagination: FileInfoSearchFilter = Object.assign({}, filter, {offset, maxResults});
  return api.loadFileInfo(filterWithPagination).then((datas) => ({
    datas,
    pageValidationInfo: {
      filter,
    },
  }));
};

const isPageStillValid = (pageValidationInfo, state) => {
  return filtersEqual(pageValidationInfo.filter, selectors.getJobFilesFilter(JobType.IMPORT, state));
};

const fetchById = (id: string, state, api) => null;

const pagedDataActions = createPagedDataActions(
    IMPORT_JOB_FILES_ACTION_TYPE_NAME,
    fetchPage,
    isPageStillValid,
    fetchById,
    selectors.getImportJobFilesPagedState,
);

export const actions = Object.assign({}, {
  addJob,
  removeJob,
  queueJob,
  runJob,
  progressJob,
  finishJob,
  failJob,
  stopJob,
  updateJobOnClient,
  updateJobOnServer,
  updateServiceOnClient,
  loadJobs,
  loadJobById,
  validateJob,
  setJobFilesFilter,
}, pagedDataActions);
