import * as React from "react";
import {SectionHeader} from "../../../common/ui/detailsection/SectionHeader";
import {FormattedMessage} from "react-intl";
import {Alert, FormGroup, Radio} from "react-bootstrap";

interface IgnoreDeletedFilesConfiguratorProps {
  ignoreDeletedFiles: boolean;
  handleIgnoreDeletedFilesChange: (ignoreDeletedFiles: boolean) => void;
}

export class IgnoreDeletedFilesConfigurator extends React.Component<IgnoreDeletedFilesConfiguratorProps> {
  render() {
    const {ignoreDeletedFiles, handleIgnoreDeletedFilesChange} = this.props;
    return (
        <>
          <SectionHeader>
            <FormattedMessage id="studio.jobs.job-detail-page.deleted-files" defaultMessage="Deleted files"/>
          </SectionHeader>
          <FormGroup>
            <Radio inline
                   checked={ignoreDeletedFiles === false}
                   onChange={(event) => {
                     const detect = (event.target as any).checked;
                     if (detect) {
                       handleIgnoreDeletedFilesChange(false);
                     }
                   }}>
              <FormattedMessage id="studio.jobs.job-detail-page.detect"
                                defaultMessage="Detect"/>
            </Radio>
            <Radio inline
                   checked={ignoreDeletedFiles !== false}
                   onChange={(event) => {
                     const ignore = (event.target as any).checked;
                     if (ignore) {
                       handleIgnoreDeletedFilesChange(true);
                     }
                   }}>
              <FormattedMessage id="studio.jobs.job-detail-page.ignore"
                                defaultMessage="Ignore"/>
            </Radio>
          </FormGroup>
          {ignoreDeletedFiles ? null :
           <Alert bsStyle="warning">
             <b><FormattedMessage id="studio.jobs.job-detail-page.deleted-files-warning" defaultMessage="WARNING:"/></b>
             &nbsp;
             <FormattedMessage id="studio.jobs.job-detail-page.deleted-files-warning-description"
                               defaultMessage="Data and Styles will be deleted from LuciadFusion if the corresponding files are not available when this job runs"/>
           </Alert>
          }
        </>
    );
  }
}