import {ServiceStatus, ServiceType} from "../model";

export type ServiceTypeFilter = ServiceType | "ANY";
export type ServiceStatusFilter = ServiceStatus | "ANY";

export interface ServiceFilter {
  anyText?: string;
  type?: ServiceTypeFilter;
  status?: ServiceStatusFilter;
  startDate?: string;
  endDate?: string;
}

export const filtersEqual = (filterA: ServiceFilter, filterB: ServiceFilter) => {
  return filterA.anyText === filterB.anyText
         && filterA.type === filterB.type
         && filterA.status === filterB.status
         && filterA.startDate === filterB.startDate
         && filterA.endDate === filterB.endDate;
};
