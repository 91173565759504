function requestFullscreen(elem, onActivate: () => void, onExit: () => void) {
  const onFullScreenChangeListener = () => {
    const fullScreenElement = document.fullscreenElement
                            || (document as any).webkitFullscreenElement
                            || (document as any).mozFullScreenElement
                            || (document as any).msFullscreenElement;
    if (fullScreenElement) {
      onActivate();
    } else {
      onExit();
      document.removeEventListener("fullscreenchange", onFullScreenChangeListener);
      document.removeEventListener("webkitfullscreenchange", onFullScreenChangeListener);
      document.removeEventListener("mozfullscreenchange", onFullScreenChangeListener);
      document.removeEventListener("msfullscreenchange", onFullScreenChangeListener);
    }
  };
  document.addEventListener("fullscreenchange", onFullScreenChangeListener);
  document.addEventListener("webkitfullscreenchange", onFullScreenChangeListener);
  document.addEventListener("mozfullscreenchange", onFullScreenChangeListener);
  document.addEventListener("msfullscreenchange", onFullScreenChangeListener);
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  }
}

function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document as any).cancelFullScreen) {
    (document as any).cancelFullScreen();
  } else if ((document as any).mozCancelFullScreen) {
    (document as any).mozCancelFullScreen();
  } else if ((document as any).webkitCancelFullScreen) {
    (document as any).webkitCancelFullScreen();
  } else if ((document as any).msExitFullscreen) {
    (document as any).msExitFullscreen();
  }
}

export const FullscreenUtil = {
  requestFullscreen,
  exitFullscreen,
};
