import * as React from "react";
import {defineMessages, FormattedMessage, InjectedIntl} from "react-intl";
import {Link} from "react-router-dom";
import {ColumnsDescription, ColumnWidth} from "../../common/ui/table/ControlRoomColumns";
import {PreprocessJob} from "../model";
import {PreprocessingJobStartStopButton} from "./PreprocessingJobStartStopButton";

const MESSAGES = defineMessages({
  serviceTooltip: {
    id: "studio.jobs.preprocessing-job-columns.service.tooltip",
    defaultMessage: "Preprocessed service",
  },
  productTooltip: {
    id: "studio.jobs.preprocessing-job-columns.product.tooltip",
    defaultMessage: "Preprocessed product",
  },
  startStopTooltip: {
    id: "studio.jobs.preprocessing-job-columns.start-stop.tooltip",
    defaultMessage: "Start or stop preprocessing job",
  },
});

export const PreprocessJobColumns: ColumnsDescription<PreprocessJob> = {
  service: {
    header: <FormattedMessage id="studio.jobs.preprocessing-job-columns.service" defaultMessage="Service"/>,
    cellContent: (job) => {
      return <Link to={`/jobs/preprocessing/${job.id}`}>{job.serviceTitle}</Link>;
    },
    cellTooltip: (job, intl) => {
      return intl.formatMessage(MESSAGES.serviceTooltip);
    },
    columnWidth: ColumnWidth.flex(0.5),
  },
  product: {
    header: <FormattedMessage id="studio.jobs.preprocessing-job-columns.product" defaultMessage="Product"/>,
    cellContent: (job) => {
      return <Link to={`/jobs/preprocessing/${job.id}`}>{job.productTitle}</Link>;
    },
    cellTooltip: (job, intl) => {
      return intl.formatMessage(MESSAGES.productTooltip);
    },
    columnWidth: ColumnWidth.flex(0.5),
  },
  startStop: {
    header: <FormattedMessage id="studio.jobs.preprocessing-job-columns.start-stop" defaultMessage="Start/Stop"/>,
    cellContent: (job) => {
      return <PreprocessingJobStartStopButton job={job} compact/>;
    },
    cellTooltip: (job, intl) => {
      return intl.formatMessage(MESSAGES.startStopTooltip);
    },
    columnWidth: ColumnWidth.STARTSTOP_BUTTON_COLUMN_WIDTH,
  },
  status: {
    header: <FormattedMessage id="studio.jobs.preprocessing-job-columns.status" defaultMessage="Status"/>,
    cellContent: (job) => job.jobState.toString(),
    cellTooltip: (job) => job.jobState.toString(),
    columnWidth: ColumnWidth.pixels(90),
  },
  progress: {
    header: "%",
    cellContent: (job) => (100 * job.jobProgress).toFixed(2) + "%",
    cellTooltip: (job) => (100 * job.jobProgress).toFixed(2) + "%",
    columnWidth: ColumnWidth.pixels(70),
  },
};
