import * as React from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {ColumnsDescription, ColumnWidth} from "../../common/ui/table/ControlRoomColumns";
import {JobStartStopButton} from "../JobStartStopButton";
import {ImportJob} from "../model";

const MESSAGES = defineMessages({
  startStopTooltip: {
    id: "studio.jobs.import-job-columns.start-stop.tooltip",
    defaultMessage: "Start or stop crawling {path}",
  },
});

export const ImportJobColumns: ColumnsDescription<ImportJob> = {
  path: {
    header: <FormattedMessage id="studio.jobs.import-job-columns.path" defaultMessage="Path"/>,
    cellContent: (job) => {
      return <Link to={`/jobs/import/${job.id}`}>{job.dataRootPath}</Link>;
    },
    cellTooltip: (job) => job.dataRootPath,
    columnWidth: ColumnWidth.flex(1),
  },
  startStop: {
    header: <FormattedMessage id="studio.jobs.import-job-columns.start-stop" defaultMessage="Start/Stop"/>,
    cellContent: (job) => {
      return <JobStartStopButton job={job} compact/>;
    },
    cellTooltip: (job, intl) => {
      return intl.formatMessage(MESSAGES.startStopTooltip, {path: job.id});
    },
    columnWidth: ColumnWidth.STARTSTOP_BUTTON_COLUMN_WIDTH,
  },
  status: {
    header: <FormattedMessage id="studio.jobs.import-job-columns.status" defaultMessage="Status"/>,
    cellContent: (job) => <div className={"import-job-status-column"}>{job.jobState.toString()}</div>,
    cellTooltip: (job) => job.jobState.toString().toUpperCase(),
    columnWidth: ColumnWidth.pixels(90),
  },
  progress: {
    header: <FormattedMessage id="studio.jobs.import-job-columns.%" defaultMessage="%"/>,
    cellContent: (job) => (100 * job.jobProgress).toFixed(2) + "%",
    cellTooltip: (job) => (100 * job.jobProgress).toFixed(2) + "%",
    columnWidth: ColumnWidth.pixels(70),
  },
};
