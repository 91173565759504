export enum ValidationMessageSeverity {
  WARNING = "Warning",
  ERROR = "Error",
}

export interface ValidationMessage {
  product: string;
  message: string;
  severity: ValidationMessageSeverity;
}

export interface ValidationResult {
  canCreate: boolean;
  name: string;
  validationMessages: ValidationMessage[];
}

export interface ServiceTypeValidation {
  serviceTypes: ValidationResult[];
}