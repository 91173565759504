import {actionTypes} from "./import/actions";
import {AllIdState, ByIdState} from "./import/reducer";
import {CompositeJob, JobExecutionResult, JobStatus} from "./model";
import {preprocessActionTypes} from "./preprocessing/actions";

const loadJobsSuccessForJobByIdReducer = (state: ByIdState = {}, action): ByIdState => {
  const loadedJobs = action.payload;
  const nextState: ByIdState = Object.assign({}, state);
  loadedJobs.forEach((aJob) => {
    nextState[aJob.id] = aJob;
  });
  return nextState;
};

const loadJobsSuccessForAllIdReducer = (state: AllIdState = [], action): AllIdState => {
  const loadedJobs = action.payload;
  return loadedJobs.map((service) => service.id);
};

const loadJobDetailForJobByIdReducer = (state: ByIdState = {}, action): ByIdState => {
  const loadedJob = action.payload;
  return Object.assign({}, state, {[loadedJob.id]: loadedJob});
};

const updateJobActionForJobByIdReducer = (state: ByIdState = {}, action): ByIdState => {
  let updatedJob = action.payload.job;
  if (state[action.payload.job.id]) {
    //the redux state might already contain a more detailed view on the job, merge it with the less-detailed job coming from the server
    // note that an unset jobschedule is undefined. Set it to null so its properly merged (when undefined in response from server)
    const newSchedule = action.payload.job.jobSchedule || null;
    updatedJob = Object.assign({}, state[action.payload.job.id], action.payload.job, {jobSchedule: newSchedule});
  }
  return Object.assign({}, state, {[action.payload.job.id]: updatedJob});
};

const deleteJobForJobByIdReducer = (state: ByIdState = {}, action): ByIdState => {
  const deletedState: ByIdState = Object.assign({}, state);
  delete deletedState[action.payload.id];
  return deletedState;
};

const updateJobForJobByIdReducer = (state: ByIdState = {}, action): ByIdState => {
  const job = state[action.payload.id];
  if (job) { //only update a job if it's already present
    return Object.assign({}, state, {
      [job.id]: jobReducer(job, action),
    });
  }
  return state;
};

const addJobForAllIdReducer = (state: AllIdState = [], action): AllIdState => {
  return [...state, action.payload.job.id];
};

const removeJobForAllIdReducer = (state: AllIdState = [], action): AllIdState => {
  const removedId = action.payload.id;
  return state.filter((id) => id !== removedId);
};

const jobReducer = (state: CompositeJob, action) => {
  switch (action.type) {
  case actionTypes.QUEUE_JOB:
  case preprocessActionTypes.QUEUE_PREPROCESS_JOB:
    return Object.assign({}, state, {jobState: JobStatus.QUEUED});
  case actionTypes.RUN_JOB:
  case preprocessActionTypes.RUN_PREPROCESS_JOB:
    return Object.assign({}, state, {jobState: JobStatus.RUNNING});
  case actionTypes.PROGRESS_JOB:
  case preprocessActionTypes.PROGRESS_PREPROCESS_JOB:
    return Object.assign({}, state, {jobProgress: action.payload.progress});
  case actionTypes.FINISH_JOB:
  case preprocessActionTypes.FINISH_PREPROCESS_JOB:
    return Object.assign({}, state, {jobState: JobStatus.IDLE, lastExecutionResult: JobExecutionResult.SUCCESS});
  case actionTypes.FAIL_JOB:
  case preprocessActionTypes.FAIL_PREPROCESS_JOB:
    return Object.assign({}, state, {jobState: JobStatus.IDLE, lastExecutionResult: JobExecutionResult.FAILED});
  case actionTypes.STOP_JOB:
  case preprocessActionTypes.STOP_PREPROCESS_JOB:
    return Object.assign({}, state, {jobState: JobStatus.IDLE, lastExecutionResult: JobExecutionResult.STOPPED});
  default:
    return state;
  }
};

export const jobReducerUtil = {
  loadJobsSuccessForJobByIdReducer,
  loadJobsSuccessForAllIdReducer,
  loadJobDetailForJobByIdReducer,
  updateJobActionForJobByIdReducer,
  deleteJobForJobByIdReducer,
  updateJobForJobByIdReducer,
  addJobForAllIdReducer,
  removeJobForAllIdReducer,
};
