import {actionTypes} from "./actions";
import {moduleName} from "./selectors";

const {RAISE_WARNINGS, CLEAR_WARNINGS} = actionTypes;

const reducer = (state: any = {}, action) => {
  switch (action.type) {
  case RAISE_WARNINGS:
    return Object.assign({}, action.payload);
  case CLEAR_WARNINGS:
    return {};
  default:
    return state;
  }
};

export {
  moduleName,
  reducer,
};
