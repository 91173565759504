import * as React from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {BasicColumnFactory, ColumnWidth} from "../../common/ui/table/ControlRoomColumns";
import {DateUtil} from "../../common/util/DateUtil";
import {DataRootDeleteButton} from "./DataRootDeleteButton";
import {DataRoot} from "./model";

const MESSAGES = defineMessages({
  createdByUnknown: {
    id: "studio.settings.data-roots-list.created-by.unknown",
    defaultMessage: "Unknown",
  },
  removeTooltip: {
    id: "studio.settings.data-roots-list.remove.tooltip",
    defaultMessage: "Remove {path}",
  },
  cannotRemoveTooltip: {
    id: "studio.settings.data-roots-list.cannot-remove.tooltip",
    defaultMessage: "Cannot remove {path}",
  },
});

export class DataRootsColumnFactory extends BasicColumnFactory<DataRoot> {

  constructor() {
    super({
      rootPath: {
        header: <FormattedMessage id="studio.settings.data-roots-column-headers.root-path" defaultMessage="Root path"/>,
        cellContent: (dataRoot) => dataRoot.rootPath,
        cellTooltip: (dataRoot) => dataRoot.rootPath,
        columnWidth: ColumnWidth.flex(1),
      },
      createdBy: {
        header: <FormattedMessage id="studio.settings.data-roots-column-headers.created-by"
                                  defaultMessage="Created by"/>,
        cellContent: (dataRoot) => dataRoot.createdBy ? dataRoot.createdBy.username : <FormattedMessage id="studio.settings.data-roots-list.created-by.unknown" defaultMessage="Unknown"/>,
        cellTooltip: (dataRoot, intl) => dataRoot.createdBy ? dataRoot.createdBy.username : intl.formatMessage(MESSAGES.createdByUnknown),
        columnWidth: ColumnWidth.USERNAME_COLUMN_WIDTH,
      },
      creationTime: {
        header: <FormattedMessage id="studio.settings.data-roots-column-headers.creation-time"
                                  defaultMessage="Creation Time"/>,
        cellContent: (dataRoot) => DateUtil.formatDateTime(dataRoot.creationTime),
        cellTooltip: (dataRoot) => DateUtil.formatDateTime(dataRoot.creationTime),
        columnWidth: ColumnWidth.DATETIME_COLUMN_WIDTH,
      },
      updatedBy: {
        header: <FormattedMessage id="studio.settings.data-roots-column-headers.updated-by"
                                  defaultMessage="Updated by"/>,
        cellContent: (dataRoot) => dataRoot.updatedBy ? dataRoot.updatedBy.username : <FormattedMessage id="studio.settings.data-roots-list.created-by.unknown" defaultMessage="Unknown"/>,
        cellTooltip: (dataRoot, intl) => dataRoot.updatedBy ? dataRoot.updatedBy.username : intl.formatMessage(MESSAGES.createdByUnknown),
        columnWidth: ColumnWidth.USERNAME_COLUMN_WIDTH,
      },
      updateTime: {
        header: <FormattedMessage id="studio.settings.data-roots-column-headers.update-time"
                                  defaultMessage="Update Time"/>,
        cellContent: (dataRoot) => DateUtil.formatDateTime(dataRoot.updateTime),
        cellTooltip: (dataRoot) => DateUtil.formatDateTime(dataRoot.updateTime),
        columnWidth: ColumnWidth.DATETIME_COLUMN_WIDTH,
      },
      remove: {
        header: <FormattedMessage id="studio.settings.data-roots-column-headers.remove" defaultMessage="Remove"/>,
        cellContent: (dataRoot) => <DataRootDeleteButton dataRoot={dataRoot}/>,
        cellTooltip: (dataRoot, intl) => intl.formatMessage(dataRoot.canDelete ? MESSAGES.removeTooltip : MESSAGES.cannotRemoveTooltip, {path: dataRoot.rootPath}),
        columnWidth: ColumnWidth.pixels(65),
      },
    });
  }
}
