import * as React from "react";
import {debounce as perfDebounce} from "../../util/PerformanceUtil";

function defaultGetDimensions(element) {
  return {width: element.clientWidth, height: element.clientHeight};
}

export interface DimensionProps {
  containerWidth: number;
  containerHeight: number;
  updateDimensions?: () => void;
}

type DimensionsHOCType<WrappedComponentProps> = React.ComponentClass<WrappedComponentProps>;
interface DimensionsHOCState {containerWidth: number; containerHeight: number; }

/**
 * Create a HOC that will inject the 'containerWidth' and 'containerHeight' properties into the wrapped component.
 * @param getDimensions a function that determines the how the 'containerWidth' and 'containerHeight' properties are
 *        calculated. The parent HTML element is passed into the function.
 *        By default, this is a function that returns the width and height of the parent element.
 * @param debounce The amount of milliseconds to debounce the resizing of the component.
 *        See {@link perfDebounce debounce} for more information on debouncing.
 * @returns {(ComponentToWrap:any)=>DimensionsHOC}
 * @example Dimensions({debounce: 500})(MyResizeableTable);
 */
export function Dimensions(getDimensions: (parent: HTMLElement) => {width: number, height: number} = defaultGetDimensions,
                           debounce: number = 0) {
  function wrapWithDimensions<WrappedProps>(ComponentToWrap: React.ComponentClass<WrappedProps & DimensionProps>): DimensionsHOCType<WrappedProps> {
    return class DimensionsHOC extends React.Component<WrappedProps, DimensionsHOCState> {

      _parent: HTMLElement;
      _raf: number;
      _wrapper: HTMLDivElement;

      constructor(props) {
        super(props);
        this.state = {containerWidth: 0, containerHeight: 0};
      }

      updateDimensionsImmediate = () => {
        const dimensions = getDimensions(this._parent);
        if (dimensions.width !== this.state.containerWidth ||
            dimensions.height !== this.state.containerHeight) {
          this.setState({
            containerWidth: dimensions.width,
            containerHeight: dimensions.height,
          });
        }
      }

      updateDimensions = (): void => {
        if (debounce === 0) {
          this.updateDimensionsImmediate();
        } else {
          perfDebounce(this.updateDimensionsImmediate, debounce);
        }
      }

      onResize = () => {
        if (this._raf) {
          return;
        }
        this._raf = window.requestAnimationFrame(() => {
          this._raf = null;
          this.updateDimensions();
        });
      }

      componentDidMount() {
        this._parent = this._wrapper.parentElement;
        this.updateDimensionsImmediate();
        window.addEventListener("resize", this.onResize, false);
      }

      componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
      }

      render() {
        const {containerWidth, containerHeight} = this.state;
        //TODO: find a way to properly type this. Currently, the TS struggles with this (TS 2.3.4)
        const passedDownProps: any = Object.assign({}, {
          containerWidth,
          containerHeight,
          updateDimensions: this.updateDimensions,
        }, this.props);
        return (
            <div style={{overflow: "visible"}} ref={(ref) => { this._wrapper = ref; }}>
              {(containerWidth || containerHeight) && <ComponentToWrap {...passedDownProps} />}
            </div>
        );
      }
    };
  }

  return wrapWithDimensions;
}
