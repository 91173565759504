import * as React from "react";
import {Button, Sizes} from "react-bootstrap";
import {LcdIcon} from "./icon/LcdIcon";

interface CompactStartStopButtonProps {
  showStartButton: boolean;
  onClick?: (startButtonClicked: boolean) => void;
  bsSize?: Sizes;
}

export class CompactStartStopButton extends React.Component<CompactStartStopButtonProps, {}> {

  render() {
    const {showStartButton, bsSize, onClick} = this.props;
    const icon = showStartButton ? "play" : "pause";
    return (
        <Button
            className="btn-icon"
            bsSize={bsSize || null}
            style={{backgroundColor: "transparent", color: "currentColor"}}
            onClick={(event) => {
              event.stopPropagation();
              onClick(showStartButton);
            }}>
          <LcdIcon icon={icon}/>
        </Button>
    );
  }
}
