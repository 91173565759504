import * as React from "react";
import {Button, Sizes} from "react-bootstrap";
import {LcdIcon} from "../../common/ui/icon/LcdIcon";
import {FileInfo} from "../../data/model";
import {Product} from "../../products/model";
import {Service} from "../model";
import {getFileDownloadURLByImportedData} from "./Util";

interface FileDownloadButtonProps {
  importedDataFile: FileInfo;
  product: Product;
  service: Service;
  bsSize?: Sizes;
}

export class FileDownloadButton extends React.Component<FileDownloadButtonProps, {}> {

  render() {
    const {bsSize, importedDataFile, product, service} = this.props;

    const downloadURL = getFileDownloadURLByImportedData(service, product, importedDataFile);

    if (downloadURL) {
      return (
          <Button className="download-button" href={downloadURL} bsSize={bsSize}>
            <LcdIcon icon="download-alt"/>
          </Button>
      );
    }

  }
}
