import {Feature} from "@luciad/ria/model/feature/Feature";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {MemoryStore} from "@luciad/ria/model/store/MemoryStore";
import {CoordinateReference} from "@luciad/ria/reference/CoordinateReference";
import {Polyline} from "@luciad/ria/shape/Polyline";
import {createPolyline, createBounds, createPoint} from "@luciad/ria/shape/ShapeFactory";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {Map} from "@luciad/ria/view/Map";
import * as React from "react";
import {Logger} from "../../util/Logger";
import {DataSeriePainter} from "./DataSeriePainter";
import {DefaultController} from "./DefaultController";
import {RIAChartOptions} from "./RIAChartOptions";

export interface DataEntry {
  time: number;
  value: number;
}

export interface DataSerie {
  label: string;
  color: string;
  serie: DataEntry[];
  reference: CoordinateReference;
}

interface ChartProperties {
  style: React.CSSProperties;
  dataSeries: {
    [name: string]: DataSerie;
  };
  reference: CoordinateReference;
}

export class Chart extends React.Component<ChartProperties, {}> {

  _logger: Logger = Logger.getLogger("common.ui.Chart");

  riaMapNode: HTMLDivElement;

  map: Map;
  layers: {
    [dataSerieName: string]: {
      store: MemoryStore;
      model: FeatureModel;
      painter: DataSeriePainter;
      featureLayer: FeatureLayer;
    };
  } = {};

  minTime: number;
  maxTime: number;
  minValue: number;
  maxValue: number;

  constructor(props) {
    super(props);
    this.minTime = Infinity;
    this.maxTime = 0;
    this.minValue = Infinity;
    this.maxValue = 0;
    // this.state = {legend : null};
  }

  componentDidMount() {
    this.map = new Map(this.riaMapNode, RIAChartOptions.createChartOptions(this.props.reference));
    this.map.controller = new DefaultController();
    this.updateModels({}, this.props.dataSeries);
    // this.setState({legend : painter.assignedColors});
  }

  componentWillUnmount() {
    if (this.map) {
      this.map.destroy();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateModels(this.props.dataSeries, nextProps.dataSeries);
  }

  createPolyline(values: DataEntry[], reference: CoordinateReference) {
    return createPolyline(reference, values.map((data) => {
      return this.createPointFromEntry(data, reference);
    }));
  }

  updateFeature(feature: Feature, newValues: DataEntry[], reference: CoordinateReference) {
    const pointList = feature.shape as Polyline;
    const initialPointCount = pointList.pointCount;
    for (let i = initialPointCount; i < newValues.length; i++) {
      const point = this.createPointFromEntry(newValues[i], reference);
      pointList.insertPoint(i, point);
    }
  }

  createPointFromEntry(entry: DataEntry, reference: CoordinateReference) {
    const timeInMapX = reference.getAxis(0).unitOfMeasure.convertToUnit(entry.time, this.map.reference.getAxis(0).unitOfMeasure);
    const valueInMapY = reference.getAxis(1).unitOfMeasure.convertToUnit(entry.value, this.map.reference.getAxis(1).unitOfMeasure);
    this.maxTime = Math.max(this.maxTime, timeInMapX);
    this.minTime = Math.min(this.minTime, timeInMapX);
    this.maxValue = Math.max(this.maxValue, valueInMapY);
    this.minValue = Math.min(this.minValue, valueInMapY);
    return createPoint(reference, [entry.time, entry.value]);
  }

  ensureModelsCreated(dataSeries: { [dataSerieName: string]: DataSerie }) {
    for (const dataSerieName in dataSeries) {
      if (dataSeries.hasOwnProperty(dataSerieName)) {
        const dataSerie: DataSerie = dataSeries[dataSerieName];
        if (!this.layers[dataSerieName]) {
          const store = new MemoryStore();
          const model = new FeatureModel(store, {reference: dataSerie.reference});
          const painter = new DataSeriePainter();
          const featureLayer = new FeatureLayer(model, {painter});
          const layer = {
            store,
            model,
            painter,
            featureLayer,
          };
          this.map.layerTree.addChild(featureLayer);
          this.layers[dataSerieName] = layer;
        }
      }
    }
  }

  updateModels(prevDataSeries: { [dataSerieName: string]: DataSerie }, newDataSeries: { [dataSerieName: string]: DataSerie }) {
    this.ensureModelsCreated(newDataSeries);
    for (const name in newDataSeries) {
      if (newDataSeries.hasOwnProperty(name)) {
        const newDataSerie: DataSerie = newDataSeries[name];
        const layer = this.layers[name];
        const feature = layer.model.get(name) as Feature;
        if (!feature) {
          const newFeature = new Feature(
              this.createPolyline(newDataSerie.serie, newDataSerie.reference),
              {label: newDataSerie.label},
              name,
          );
          layer.model.add(newFeature);
          layer.painter.assignColor(name, newDataSerie.color);
        } else {
          this.updateFeature(feature, newDataSerie.serie, newDataSerie.reference);
          layer.model.put(feature);
        }
      }
    }
    for (const prevName in prevDataSeries) {
      if (prevDataSeries.hasOwnProperty(prevName) && !newDataSeries.hasOwnProperty(prevName)) {
        this.layers[prevName].model.remove(prevName);
      }
    }
    this.map.mapNavigator.fit({bounds: createBounds(this.map.reference, [
        this.minTime,
        this.maxTime - this.minTime,
        this.minValue,
        this.maxValue - this.minValue,
      ]),
      allowWarpXYAxis : true,
      fitMargin : "5px",
          animate: {duration: 400, ease: undefined},
        },
    ).catch((error) => {
      this._logger.error("Error occurred while fitting", error);
    });
  }

  renderLegend = () => {
    const {dataSeries} = this.props;
    if (dataSeries !== null && Object.keys(dataSeries).length > 0) {
      return <div className="legend">
        <ul>
          {Object.keys(dataSeries).map((dataSerieName) => {
            const dataSerie: DataSerie = dataSeries[dataSerieName];
            return (
                <li key={dataSerieName}>
                  <span className="color" style={{background: dataSerie.color}}/>{dataSerie.label}
                </li>
            );
          })}
        </ul>
      </div>;
    } else {
      return <div/>;
    }
  }

  render() {
    return (
        <div className="chart" ref={(ref) => {this.riaMapNode = ref; }} style={this.props.style}>
          {this.renderLegend()}
        </div>
    );
  }
}
