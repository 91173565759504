import * as React from "react";
import {Alert, Button, Modal} from "react-bootstrap/lib";
import {FormattedMessage, InjectedIntlProps} from "react-intl";
import {ErrorDisplay} from "./errordisplay/ErrorDisplay";
import {LcdIcon} from "./icon/LcdIcon";
import {Spinner} from "./spinner/Spinner";
import {Warning} from "../model";
import {mapWarningToMessage} from "../util/WarningMapper";
import {mapErrorToMessage} from "../util/ErrorMapper";

interface ConfirmationDialogProps {
  show: boolean;
  title: string;
  renderConfirmation: () => JSX.Element;
  confirmButtonIcon: string;
  confirmButtonText: string;
  onConfirm: () => void;
  executing: boolean;
  executingMessage: string;
  error: Error;
  warnings: Warning[];
  success: boolean;
  successMessage: string;
  onHide: () => void;
  bsStyleConfirm?: string;
}

export class ConfirmationDialog extends React.Component<ConfirmationDialogProps & InjectedIntlProps, {}> {

  renderExecuting() {
    const {executing, executingMessage} = this.props;
    if (executing) {
      return (
          <div style={{textAlign: "center"}}>
            <h2>{executingMessage}</h2>
            <Spinner bigStyle/>
          </div>
      );
    }
    return null;
  }

  renderSuccess() {
    const {success, successMessage} = this.props;
    if (success) {
      return (
          <div style={{textAlign: "center"}}>
            <h2>{successMessage}</h2>
          </div>
      );
    }
    return null;
  }

  renderError() {
    const {error} = this.props
    const errorMessage = mapErrorToMessage(error);
    if (errorMessage) {
      return <Alert bsStyle="danger">{errorMessage}</Alert>;
    }
    return <ErrorDisplay error={error}/>;
  }

  renderWarnings() {
    const {warnings, successMessage} = this.props;
    if (warnings) {
      return (
          <div>
            <div style={{textAlign: "center"}}>
              <h2>{successMessage}</h2>
            </div>
            <div>
              <FormattedMessage id="studio.ui.confirmation-dialog.warnings-reported" defaultMessage="The following warnings were reported:"/>
              <ul>
                {warnings.map((warning, idx) =>
                    <li key={"warning-response-" + idx}>
                      {mapWarningToMessage(warning)}
                    </li>)}
              </ul>
            </div>
          </div>
      );
    }
    return null;
  }

  renderConfirmation() {
    const {renderConfirmation} = this.props;
    return renderConfirmation();
  }

  renderTitle() {
    const {executing, error, warnings, success, title} = this.props;

    if (executing || error || success || warnings) {
      return null;
    }

    return <Modal.Title>{title}</Modal.Title>;
  }

  renderBody() {
    const {executing, error, warnings, success} = this.props;

    if (error) {
      return this.renderError();
    }

    if (warnings) {
      return this.renderWarnings();
    }

    if (success) {
      return this.renderSuccess();
    }

    if (executing) {
      return this.renderExecuting();
    }

    return this.renderConfirmation();
  }

  renderButtons() {
    const {executing, onConfirm, confirmButtonIcon, confirmButtonText, onHide, success, error, warnings, bsStyleConfirm} = this.props;

    if (error || warnings || success) {
      return <Button onClick={onHide}><FormattedMessage id="studio.ui.confirmation-dialog.close"
                                                        defaultMessage="Close"/></Button>;
    }

    if (executing) {
      return null;
    }

    return (
        <div>
          <Button onClick={onConfirm} bsStyle={bsStyleConfirm ? bsStyleConfirm : "danger"}> <LcdIcon icon={confirmButtonIcon}/> {confirmButtonText}
          </Button>
          <Button onClick={onHide}><FormattedMessage id="studio.ui.confirmation-dialog.cancel"
                                                     defaultMessage="Cancel"/></Button>
        </div>
    );
  }

  render() {
    const {show, onHide, executing} = this.props;

    return (
        <Modal show={show} onHide={onHide} backdrop="static">
          <Modal.Header closeButton={!executing}>
            {this.renderTitle()}
          </Modal.Header>
          <Modal.Body>
            {this.renderBody()}
          </Modal.Body>
          <Modal.Footer>
            {this.renderButtons()}
          </Modal.Footer>
        </Modal>
    );
  }
}
