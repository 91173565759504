import {AxiosError} from "axios";
import * as React from "react";
import {Button, Col, Grid, Panel} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {Link} from "react-router-dom";
import {ErrorDisplay} from "../common/ui/errordisplay/ErrorDisplay";
import {selectors as startupSelectors} from "../startup/selectors";
import {actions} from "./actions";

interface LogoutPageProperties {
  performLogout?: () => Promise<void>;
  isMappEnterprise?: boolean;
}

interface LogoutState {
  loggingOut: boolean;
  logoutError: Error;
  errorOpen: boolean;
}

export class LogoutPagePresentation extends React.Component<LogoutPageProperties, LogoutState> {

  constructor(props) {
    super(props);
    this.state = {
      loggingOut: false,
      logoutError: null,
      errorOpen: false,
    };
  }

  UNSAFE_componentWillMount() {
    this._logout();
  }

  UNSAFE_componentWillReceiveProps() {
    this._logout();
  }

  _logout() {
    if (!(this.props.isMappEnterprise || this.state.loggingOut)) {
      this.setState(Object.assign({}, this.state, {loggingOut: true, logoutError: null}));
      this.props.performLogout().then(() => {
        this.setState(Object.assign({}, this.state, {loggingOut: false, logoutError: null}));
      }).catch((error) => {
        if (error) {
          this.setState(Object.assign({}, this.state, {loggingOut: false, logoutError: error}));
        }
      });
    }
  }

  renderBody() {
    if (this.state.loggingOut) {
      return (
          <div>
            <h1><FormattedMessage
                id="studio.logout-page.logging-out"
                defaultMessage="Logging Out..."
            /></h1>
          </div>
      );
    }

    if (this.state.logoutError) {
      const error = this.state.logoutError as AxiosError;
      const is404Error = !!(error.response && error.response.status === 404);
      return (
          <div>
            <h1><FormattedMessage
                id="studio.logout-page.logging-out-error.header"
                defaultMessage="Could not perform logout"
            /></h1>
            {is404Error ? (<p><FormattedMessage
                            id="studio.logout-page.logging-out-error.404"
                            defaultMessage={"The backend's logout endpoint could not be found. One of the causes of this issue is that authentication is disabled on the backend."}
                        /></p>)
                : null}
            <Button onClick={() => this.setState(Object.assign({}, this.state, { errorOpen: !this.state.errorOpen }))}>
              <FormattedMessage
                  id="studio.logout-page.logging-out-error.details-button"
                  defaultMessage="Show error details"
              />
            </Button>
            <Panel collapsible expanded={this.state.errorOpen}>
              <ErrorDisplay error={this.state.logoutError}/>
            </Panel>
            <Link to="/"><FormattedMessage
                id="studio.logout-page.logging-out-error.back-button"
                defaultMessage="Go Back"
            /></Link>
          </div>
      );
    }

    return (
        <div>
          <h1>
            <FormattedMessage
              id="studio.logout-page.message"
              defaultMessage="Logout Successful"/>
          </h1>
          <Link to="/login">
            <FormattedMessage
              id="studio.logout-page.login-link"
              defaultMessage="Back to Login"
            />
          </Link>
        </div>
    );
  }

  render() {
    return this.props.isMappEnterprise ? <Redirect to={{pathname: "/"}}/> : (
        <Grid>
          <Col xs={8} xsOffset={2} md={6} mdOffset={3}>
            {this.renderBody()}
          </Col>
        </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isMappEnterprise: startupSelectors.isMappEnterprise(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    performLogout: () => {
      return dispatch(actions.logout());
    },
  };
};

export const LogoutPage = connect(mapStateToProps, mapDispatchToProps)(LogoutPagePresentation);
