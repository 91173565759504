import {Product} from "../../../products/model";
import {ServiceType} from "../../../services/model";

export type ValidationState = "success" | "warning" | "error";

type SeverityType = "OK" | "ERROR" | "WARNING";
export const Severity: {[key: string]: SeverityType} = {
  OK: "OK",
  ERROR: "ERROR",
  WARNING: "WARNING",
};

export interface ValidationResult {
  severity: SeverityType;
  errorMessages: string[];
  warningMessages: string[];
}

const SEVERITY_TO_STATE: {[key: string]: ValidationState} = {
  OK: "success",
  ERROR: "error",
  WARNING: "warning",
};
const VOID_VALIDATION_RESULT: ValidationResult = {
  severity: Severity.WARNING,
  errorMessages: [],
  // this un-localized text could appear in the UI, but chances are low and the i18n components are not accessible from
  // this simple component. Also, the validation messages coming from the server are not localized anyway.
  warningMessages: ["Validation Information is missing"],
};

const fetchServiceValidation = (product: Product, serviceType: ServiceType): ValidationResult => {
  const {validationByServiceType} = product;

  let validationResult = VOID_VALIDATION_RESULT;
  if (validationByServiceType && validationByServiceType[serviceType]) {
    validationResult = validationByServiceType[serviceType];
  }
  return validationResult;
};

const getState = (validationResult: ValidationResult): ValidationState => {
  return SEVERITY_TO_STATE[validationResult.severity];
};

const getReason = (validationResult: ValidationResult): string => {
  const severity = validationResult.severity;
  return severity === Severity.ERROR ? validationResult.errorMessages[0] :
         (severity === Severity.WARNING ? validationResult.warningMessages[0] : "");
};

const isAccepted = (validationResult: ValidationResult): boolean => {
  const severity = validationResult.severity;
  return severity === Severity.OK || severity === Severity.WARNING;
};

const isCritical = (validationResult: ValidationResult): boolean => {
  return validationResult.severity === Severity.ERROR;
};

const isCriticalPartial = (service: ServiceType) => (product: Product) => {
  const validationResult = fetchServiceValidation(product, service);
  return validationResult && isCritical(validationResult);
};

const areProductsOK = (products: Product[], service: ServiceType): boolean => {
  return !products.some(isCriticalPartial(service));
};

export const productValidation = {
  fetchServiceValidation,
  getState,
  getReason,
  isAccepted,
  areProductsOK,
};
