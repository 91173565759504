import * as React from "react";
import {Button, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {ErrorDisplay} from "../common/ui/errordisplay/ErrorDisplay";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {Spinner} from "../common/ui/spinner/Spinner";
import {Severity} from "../common/ui/validation/ProductValidation";
import {
  ServiceTypeValidation,
  ValidationMessage,
  ValidationMessageSeverity
} from "../common/ui/validation/ServiceTypeValidation";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {ImportedData} from "../data/model";
import {actions as productActions} from "../products/actions";
import {Product, ProductType, StyledData} from "../products/model";
import {CloseCreateServiceFormButton, CreateServiceForm, CreateServiceFormSubmitButton} from "./CreateServiceForm";
import {addValidationToProducts} from "../common/util/ValidationUtil";

interface ButtonProps {
  disabled?: boolean;
  inputProducts?: Product[];
}

export interface ButtonState {
  showModal: boolean;
  serviceTypeValidation: ServiceTypeValidation;
}

const CREATE_SERVICE_MESSAGES = defineMessages({
  serviceProductError: {
    id: "studio.create-service.service-product-error",
    defaultMessage: "Service product has not been created",
  },
  productNameMore: {
    id: "studio.create-service.service-product-name-more",
    defaultMessage: "{name} (+{count} more)",
  },
});

export class CreateServiceButton extends React.Component<ButtonProps & WithApiProperties & WithDispatch, ButtonState> {

  constructor(props) {
    super(props);
    this.state = {showModal: false, serviceTypeValidation: null};
  }

  updateState = (partial) => this.setState(Object.assign({}, this.state, partial));

  closeCreationModal = () => this.updateState({showModal: false});

  openCreationModal = () => {
    const {inputProducts} = this.props;
    if (inputProducts) {
      this.props.api.serviceTypeValidation(inputProducts).then((serviceTypeValidation) => {
        addValidationToProducts(serviceTypeValidation, this.props.inputProducts);
        this.updateState({showModal: true, serviceTypeValidation});
      });
    } else {
      this.updateState({showModal: true, serviceTypeValidation: null});
    }
  }

  render() {
    const {disabled, inputProducts} = this.props;
    const {showModal, serviceTypeValidation} = this.state;

    const buttonIsDisabled = disabled || showModal;

    let createServiceButton = null;

    if (buttonIsDisabled) {
      const reason = <FormattedMessage id="studio.create-service.button.select-products"
                                       defaultMessage="Select some products from the list below"/>;
      //adding an overlay (tooltip) to a disabled element requires some witchcraft, as disabled elements
      //do not respond to mouse events
      //see remark in bootstrap docs: http://getbootstrap.com/javascript/#tooltips
      //and https://github.com/react-bootstrap/react-bootstrap/issues/1588
      createServiceButton = (
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{reason}</Tooltip>}>
            <div style={{cursor: "not-allowed"}}>
              <Button style={{pointerEvents: "none"}} onClick={this.openCreationModal} disabled>
                <LcdIcon icon="plus"/><FormattedMessage
                  id="studio.create-service.button-disabled"
                  defaultMessage="Create Service"
              />
              </Button>
            </div>
          </OverlayTrigger>
      );
    } else {
      createServiceButton = (
          <Button onClick={this.openCreationModal}>
            <LcdIcon icon="plus"/><FormattedMessage
              id="studio.create-service.button"
              defaultMessage="Create Service"
          />
          </Button>
      );
    }

    return (
        <div>
          {createServiceButton}

          <Modal show={this.state.showModal} onHide={this.closeCreationModal} bsSize="large" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title><FormattedMessage id="studio.create-service.modal-title"
                                             defaultMessage="Create Service"/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateServiceForm inputProducts={inputProducts} initServiceTypeValidation={serviceTypeValidation}/>
            </Modal.Body>
            <Modal.Footer>
              <CreateServiceFormSubmitButton/>
              <CloseCreateServiceFormButton onClick={this.closeCreationModal}/>
            </Modal.Footer>
          </Modal>

        </div>
    );
  }
}

export const PublishProductInServiceButton = connect()(WithApi(injectIntl(CreateServiceButton)));

// Publish Service based on Data

interface DataButtonProps {
  disabled?: boolean;
  inputData: ImportedData[];
}

interface WithDispatch {
  dispatch: (action) => any;
}

export interface DataButtonState {
  inputProducts: Product[];
  busy: boolean;
  error: Error;
}

export class DataButton extends React.Component<DataButtonProps & InjectedIntlProps & WithDispatch, ButtonState & DataButtonState> {

  constructor(props) {
    super(props);
    this.state = {showModal: false, serviceTypeValidation: null, inputProducts: [], busy: false, error: null};
  }

  getProductBaseName = (dataElement) => dataElement.title;

  updateState = (partial) => this.setState(Object.assign({}, this.state, partial));

  removeQuickProductsOnCancel = () => {
    const products = this.state.inputProducts;
    products.forEach((product) => {
      this.props.dispatch(productActions.deleteProduct(product.id));
    });
  }

  closeModal = (canceled: boolean) => {
    if (canceled) {
      this.removeQuickProductsOnCancel();
    }
    this.updateState({showModal: false, inputProducts: [], busy: false, error: null});
  }

  openModal = () => {
    const {inputData, intl} = this.props;

    if (!inputData || !inputData.length) {
      return;
    }
    this.updateState({showModal: true, busy: true, error: null});

    // create product based on selected data objects
    const contents: StyledData[] = inputData.map((aData) => ({
      id: aData.id,
      productId: null,
      data: aData.id,
      dataTitle: aData.title,
      style: aData.defaultStyleId,
      visible: true,
    }));

    const len = contents.length;
    let productName = this.getProductBaseName(inputData[0]);
    if (len > 1) {
      productName = intl.formatMessage(CREATE_SERVICE_MESSAGES.productNameMore, {name: productName, count: len - 1});
    }
    const product: Product = {
      id: null,
      title: productName,
      abstractText: "QuickPublish",
      contents,
      type: ProductType.EMPTY,
    };

    return this.props.dispatch(productActions.createProduct(product))
        .then((createdProduct: Product) => {
          if (!createdProduct) {
            throw new Error(intl.formatMessage(CREATE_SERVICE_MESSAGES.serviceProductError));
          }

          // Map internal validation structure to public validation structure
          const serviceTypeValidation: ServiceTypeValidation = createdProduct.validationByServiceType ? {
            serviceTypes: Object.entries(createdProduct.validationByServiceType).map(serviceValidation => {
              const errorMessages: ValidationMessage[] = serviceValidation[1].errorMessages.map(
                  message => ({product: createdProduct.id, message, severity: ValidationMessageSeverity.ERROR}));
              const warningMessages: ValidationMessage[] = serviceValidation[1].warningMessages.map(
                  message => ({product: createdProduct.id, message, severity: ValidationMessageSeverity.WARNING}));
              return ({
                name: serviceValidation[0],
                canCreate: !serviceValidation[1].errorMessages || serviceValidation[1].errorMessages.length === 0,
                validationMessages: errorMessages.concat(warningMessages)
              })
            })
          } : null;

          this.updateState({inputProducts: [createdProduct], busy: false, serviceTypeValidation});
        })
        .catch((error) => this.updateState({busy: false, error}));
  }

  render() {
    const {disabled} = this.props;
    const {showModal, inputProducts, busy, error, serviceTypeValidation} = this.state;
    const buttonIsDisabled = disabled || showModal;

    let publishInServiceButton = null;

    if (buttonIsDisabled) {
      const reason = <FormattedMessage id="studio.create-service-button.select-some-data"
                                       defaultMessage="Select some data from the list below"/>;
      //adding an overlay (tooltip) to a disabled element requires some witchcraft, as disabled elements
      //do not respond to mouse events
      //see remark in bootstrap docs: http://getbootstrap.com/javascript/#tooltips
      //and https://github.com/react-bootstrap/react-bootstrap/issues/1588
      publishInServiceButton = (
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{reason}</Tooltip>}>
            <div style={{cursor: "not-allowed"}}>
              <Button className="user-tour-create-service-button" style={{pointerEvents: "none"}}
                      onClick={this.openModal} disabled>
                <LcdIcon icon="plus"/><FormattedMessage
                  id="studio.create-service.data-service-button-disabled"
                  defaultMessage="Create Service"
              />
              </Button>
            </div>
          </OverlayTrigger>
      );
    } else {
      publishInServiceButton = (
          <Button className="user-tour-create-service-button" onClick={this.openModal}>
            <LcdIcon icon="plus"/><FormattedMessage
              id="studio.create-service.data-service-button"
              defaultMessage="Create Service"
          />
          </Button>
      );
    }

    const busyUI = busy && (
        <div>
          <h4><FormattedMessage
              id="studio.create-service.busy-header"
              defaultMessage="Preparing data in progress..."
          /></h4>
          <div className="spinnerContainer">
            <Spinner bigStyle={true}/>
          </div>
        </div>
    );

    const errorUI = error && (<ErrorDisplay error={error}/>);

    const canProceed = !busy && !error;

    return (
        <div>
          {publishInServiceButton}

          <Modal show={showModal} onHide={this.closeModal} bsSize="large" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title><FormattedMessage
                  id="studio.create-service.title"
                  defaultMessage="Create Service"
              /></Modal.Title>
              {busyUI}
              {errorUI}
            </Modal.Header>
            <Modal.Body>
              {canProceed &&
               (<CreateServiceForm inputProducts={inputProducts} initServiceTypeValidation={serviceTypeValidation}/>)}
            </Modal.Body>
            <Modal.Footer>
              {canProceed && <CreateServiceFormSubmitButton/>}
              <CloseCreateServiceFormButton onClick={this.closeModal}/>
            </Modal.Footer>
          </Modal>

        </div>
    );
  }
}

// inject dispatch only
export const PublishDataInServiceButton = connect()(injectIntl(DataButton));
