import * as React from "react";
import {connect} from "react-redux";
import {OutdatedWarningPresentation} from "../common/ui/outdatedwarning/OutdatedWarning";
import {actions} from "./actions";
import {selectors} from "./selectors";

const mapStateToProps = (state) => {
  return {
    numberOfNewItems: selectors.getNumberOfNewDataItemsOnServer(state),
    messageType: "data",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refreshLinkClicked: () => {
      dispatch(actions.clearData());
      dispatch(actions.loadDataTypes());
    },
  };
};

export const DataOutdatedWarning = connect(mapStateToProps, mapDispatchToProps)(OutdatedWarningPresentation);
