import * as React from "react";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {LinkContainer} from "react-router-bootstrap";
import {NavLink} from "react-router-dom";
import {HelpNavDropdown} from "../../help/HelpNavDropdown";
import {UserDisplay} from "../../user/UserDisplay";

/**
 * ControlRoom's navigation bar. Contains links to the different pages, as well as a
 * UserDisplay and a LanguageSelect
 */
export interface NavigationBarProperties {
  isAuthenticated: boolean;
}

export class NavigationBar extends React.Component<NavigationBarProperties> {

  render() {
    const {isAuthenticated} = this.props;
    return (
        <Navbar fluid inverse>
          <Navbar.Header>
            <Navbar.Brand>
              <NavLink to="/">
                <img src="theme/assets/images/logo.png" />
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle/>
          </Navbar.Header>
          {isAuthenticated &&
           <Navbar.Collapse>
             <Nav className="nav-pagelinks">
               <LinkContainer className="user-tour-data-page-nav" to="/data">
                 <NavItem>
                   <FormattedMessage id="studio.navbar.data" defaultMessage="DATA"/>
                 </NavItem>
               </LinkContainer>
               <LinkContainer className="user-tour-styles-page-nav" to="/styles">
                 <NavItem>
                   <FormattedMessage id="studio.navbar.styles" defaultMessage="STYLES"/>
                 </NavItem>
               </LinkContainer>
               <LinkContainer className="user-tour-products-page-nav" to="/products">
                 <NavItem>
                   <FormattedMessage id="studio.navbar.prducts" defaultMessage="PRODUCTS"/>
                 </NavItem>
               </LinkContainer>
               <LinkContainer className="user-tour-services-page-nav" to="/services">
                 <NavItem>
                   <FormattedMessage id="studio.navbar.services" defaultMessage="SERVICES"/>
                 </NavItem>
               </LinkContainer>
               <LinkContainer className="user-tour-jobs-page-nav" to="/jobs">
                 <NavItem>
                   <FormattedMessage id="studio.navbar.jobs" defaultMessage="JOBS"/>
                 </NavItem>
               </LinkContainer>
               <LinkContainer className="user-tour-settings-page-nav" to="/settings">
                 <NavItem>
                   <FormattedMessage id="studio.navbar.settings" defaultMessage="SETTINGS"/>
                 </NavItem>
               </LinkContainer>
             </Nav>
             <Nav pullRight={true}>
               <UserDisplay/>
               <HelpNavDropdown/>
             </Nav>
           </Navbar.Collapse>
          }
        </Navbar>
    );
  }

}
