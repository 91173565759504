import {Action} from "redux";
import {Api} from "../api/InternalApi";
import {
  createPagedDataActions,
  createPagedDataActionTypes,
  selectors as pagedDataSelectors,
} from "../common/util/pagination/PaginationUtil";
import {filtersEqual, ImportedData, ImportedDataSearchFilter} from "./model";
import {selectors} from "./selectors";
import {DeleteResponse} from "../common/model";

export const IMPORTED_DATA_ACTION_TYPE_NAME = "IMPORTED_DATA";

const dataActionTypes = {
  DATA_ADDED_ON_SERVER: "controlRoom/DATA_ADDED_ON_SERVER",
  LOAD_DATA_TYPES: "controlRoom/LOAD_DATA_TYPES",
  SET_DATA_FILTER: "controlRoom/SET_DATA_FILTER",
  UPDATE_DATA: "controlRoom/UPDATE_DATA",
};

const paginationActionTypes = createPagedDataActionTypes(IMPORTED_DATA_ACTION_TYPE_NAME);
export const actionTypes = Object.assign({}, dataActionTypes, paginationActionTypes);

const dataAddedOnServer = (): (Action & any) => {
  return {
    type: actionTypes.DATA_ADDED_ON_SERVER,
  };
};

const loadDataTypes = () => {
  return (dispatch, getState, {getApi}) => {
    getApi(getState()).getDataTypes().then(
        (dataTypes) => dispatch({
          type: actionTypes.LOAD_DATA_TYPES,
          payload: {dataTypes},
        }));
  };
};

const setDataFilter = (filter: ImportedDataSearchFilter) => {
  return {
    type: actionTypes.SET_DATA_FILTER,
    payload: filter,
  };
};

const fetchPage = (page: number, state, api: Api) => {
  const filter = selectors.getFilter(state);
  const pagedDataState = selectors.getPagedState(state);
  const pageSize = pagedDataSelectors.getPageSize(pagedDataState);
  const offset = page * pageSize;
  const filterWithPagination: ImportedDataSearchFilter = Object.assign({}, filter, {offset, maxResults: pageSize});
  return api.getImportedData(filterWithPagination).then((datas) => ({
    datas,
    pageValidationInfo: {
      filter,
    },
  }));
};

const isPageStillValid = (pageValidationInfo, state) => {
  return filtersEqual(pageValidationInfo.filter, selectors.getFilter(state));
};

const fetchById = (id: string, state, api) => api.getImportedDataById(id);

const pagedDataActions = createPagedDataActions(
    IMPORTED_DATA_ACTION_TYPE_NAME,
    fetchPage,
    isPageStillValid,
    fetchById,
    selectors.getPagedState,
);

const deleteData = (ids: string[]) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).deleteImportedData(ids).then((warnings: DeleteResponse) => {
      dispatch(pagedDataActions.clearData());
      dispatch(loadDataTypes());
      return warnings;
    });
  };
};

const refreshData = (id: string) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).refreshImportedData(id).then((data) => {
      dispatch(pagedDataActions.clearData());
      return data;
    });
  };
};

const addData = (filePath: string) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).addImportedData(filePath).then((data) => {
      dispatch(pagedDataActions.clearData());
      return data;
    });
  };
};

const updateData = (data: ImportedData) => {
  return (dispatch, getState, {getApi}): Promise<ImportedData> => {
    return getApi(getState()).updateImportedData(data).then((updatedData) => {
      const updateDataAction = {
        type: actionTypes.UPDATE_DATA,
        payload: {
          data: updatedData,
        },
      };
      dispatch(updateDataAction);
      return updatedData;
    })
  };
};


export const actions = Object.assign({}, {
  dataAddedOnServer,
  loadDataTypes,
  setDataFilter,
  deleteData,
  refreshData,
  addData,
  updateData,
}, pagedDataActions);
