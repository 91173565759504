import {createPoint} from "@luciad/ria/shape/ShapeFactory";
import {Controller} from "@luciad/ria/view/controller/Controller";
import {HandleEventResult} from "@luciad/ria/view/controller/HandleEventResult";
import {GestureEventType} from "@luciad/ria/view/input/GestureEventType";

export class DefaultController extends Controller {

  _tooltipNode: HTMLDivElement;

  constructor() {
    super();
    this._tooltipNode = null;
  }

  createTooltipDOMNode() {
    const tooltipNode = document.createElement("div");
    tooltipNode.classList.add("info", "hidden");
    return tooltipNode;
  }

  onActivate(map) {
    super.onActivate(map);
    this._tooltipNode = this.createTooltipDOMNode();
    map.domNode.appendChild(this._tooltipNode);
  }

  onDeactivate(map) {
    map.domNode.removeChild(this._tooltipNode);
    this._tooltipNode = null;
    super.onDeactivate(map);
  }

  onGestureEvent(event) {
    if (event.type === GestureEventType.MOVE) {
      const [x, y] = event.viewPosition;
      const pickInfo = this.map.pickClosestObject(x, y, 0);
      if (pickInfo) {
        this.showTooltip(x, y, pickInfo);
      } else {
        this.hideTooltip(x, y);
      }
      return HandleEventResult.EVENT_HANDLED;
    }
    if (event.type === GestureEventType.DRAG) { //hide the tooltip when a drag comes in
      this.hideTooltip(event.viewPosition[0], event.viewPosition[1]);
      return HandleEventResult.EVENT_HANDLED;
    }
    return HandleEventResult.EVENT_HANDLED;
  }

  showTooltip(x, y, pickInfo) {
    const feature = pickInfo.objects[0];
    this._tooltipNode.classList.remove("hidden");
    this._tooltipNode.style.left = x + 10 + "px";
    this._tooltipNode.style.top = y + 10 + "px";
    this._tooltipNode.innerHTML = this.tooltipContents(feature, this.map.viewToMapTransformation.transform(createPoint(null, [x, y])));
  }

  tooltipContents(feature, mouseLocation) {
    const unit = this.map.reference.getAxis(1).unitOfMeasure.symbol;
    return "<span class='featureID'>" + feature.properties.label + ":</span> " + mouseLocation.y.toFixed(2) + unit;
  }

  hideTooltip(x, y) {
    this._tooltipNode.classList.add("hidden");
  }

  onKeyEvent(event) {
    return HandleEventResult.EVENT_IGNORED;
  }
}
