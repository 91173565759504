import * as moment from "moment";

const formatDate = (date: Date|string|moment.Moment): string => {
  const momentDate: moment.Moment = moment(date);
  return momentDate.format("l");
};

const formatDateTime = (date: Date|string|moment.Moment): string => {
  const momentDate: moment.Moment = moment(date);
  return momentDate.format("l") + " " + momentDate.format("LT");
};

export const DateUtil = {
  formatDate,
  formatDateTime,
};
