import * as React from "react";
import {Grid} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {JobsList} from "./import/JobsList";
import {PreprocessJobList} from "./preprocessing/PreprocessJobsList";

export class JobsPage extends React.Component<{}, {}> {

  render() {
    return (
        <Grid fluid={true}>
          <div> {/* This empty div is necessary! it's used by ControlRoomTable to calculate its dimensions.*/}
            <h3><FormattedMessage id="studio.jobs.preprocessing.jobs-page.crawl-jobs" defaultMessage="Crawl Jobs"/></h3>
            <hr/>
            <JobsList />

            <hr/>

            <h3><FormattedMessage id="studio.jobs.preprocessing.jobs-page.preprocessing-jobs"
                                  defaultMessage="Preprocessing Jobs"/></h3>
            <hr/>
            <PreprocessJobList />
          </div>
        </Grid>
    );
  }

}
