import * as React from "react";
import {Button, Form, Label} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Action, Dispatch} from "redux";
import {
  Field,
  Fields,
  FormErrors,
  getFormSyncErrors,
  hasSubmitFailed,
  hasSubmitSucceeded,
  isSubmitting,
  reduxForm,
  submit,
  InjectedFormProps,
} from "redux-form";
import {DetailView, DetailViewRow} from "../common/ui/detailsection/DetailView";
import {ErrorDisplay} from "../common/ui/errordisplay/ErrorDisplay";
import {createCloseButton} from "../common/ui/FormButtons";
import {productValidation} from "../common/ui/validation/ProductValidation";
import {moveItem, removeItem} from "../common/util/ArrayUtil";
import {Logger} from "../common/util/Logger";
import {calculateDimensionBasedOnRowsBeforeScroll, isEmpty} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {Identifiable} from "../model";
import {PendingProductForService, Product} from "../products/model";
import {actions} from "./actions";
import {Service, toServiceLink} from "./model";
import {SERVICE_PRODUCT_LIST_ROW_HEIGHT, ServiceProductList} from "./ServiceProductList";
import {ServiceTypeahead} from "./ServiceTypeahead";

const FIELD_SERVICE = "selectedService";
const FIELD_PRODUCT_TO_ADD = "toAddProducts";
const FIELD_SERVICE_PRODUCTS = "selectedServiceProducts";
const FIELD_USED_PRODUCTS = "blackList";

interface AddToServiceFormData {
  selectedService: Service;
  selectedServiceProducts: Product[];
  toAddProducts: Product[];
  blackList: Product[];
}

interface AddToServiceFormProps {
  inputProducts: PendingProductForService[];
}

const SERVICE_FORM_MESSAGES = defineMessages({
  detailViewRowService: {
    id: "studio.services.add-to-service-form.detail-view.service",
    defaultMessage: "Service",
  },
  detailViewRowProducts: {
    id: "studio.services.add-to-service-form.detail-view.products",
    defaultMessage: "Products to add",
  },
  detailViewRowServiceProducts: {
    id: "studio.services.add-to-service-form.detail-view.service-products",
    defaultMessage: "Service Products",
  },
});

class AddToServiceFormComponent extends React.Component<AddToServiceFormProps & InjectedIntlProps & WithApiProperties & InjectedFormProps<AddToServiceFormData, AddToServiceFormProps>, {}> {

  _logger: Logger = Logger.getLogger("services.AddToServiceForm");

  componentDidMount() {
    this.props.initialize({
      selectedService: null,
      selectedServiceProducts: [],
      toAddProducts: [],
      blackList: [],
    });
  }

  fetchSelectedServiceProducts = (service: Service) => {

    if (service) {
      this.props.api.getProductsForService(service.id)    // returns promise with service products
          .then((serviceProductsWithValidation) => {        // handles validated products + returns promise for products to add
            const productIds = serviceProductsWithValidation.map((obj) => obj.id);

            const whiteList: Product[] = [];
            const blackList: Product[] = [];
            this.props.inputProducts.forEach((inputProduct) => {
              inputProduct.pendingServiceType = service.type;
              if (productIds.indexOf(inputProduct.id) === -1) {
                whiteList.push(inputProduct);
              } else {
                blackList.push(inputProduct);
              }
            });

            this.props.change(FIELD_USED_PRODUCTS, [...blackList]);
            this.props.change(FIELD_SERVICE_PRODUCTS, [...serviceProductsWithValidation]);

            // Adding validation to the whitelist gets form validation using validateAddToServiceForm
            // working as it should.  It will not enable the AddToService button until all of the
            // products to be added get validated. This will also be used to render warnings
            // in the list of products to be added so the user will know why.
            this.props.api.validateProducts(service.id, whiteList.map((product) => product.id))
                .then((validationForProductsToAdd) => {
                  for (const i in validationForProductsToAdd) {
                    if (Number(i) >= 0 && Number(i) < validationForProductsToAdd.length) {
                      whiteList[i].validationByServiceType = { [service.type] : validationForProductsToAdd[i]};
                    }
                  }
                  this.props.change(FIELD_PRODUCT_TO_ADD, [...whiteList]);
                });
          })
          .catch((error) => {
            this._logger.error("Error occurred while loading service's products", error);
          });

      this.props.change(FIELD_SERVICE, service);
    }
  }

  validateItemToAdd = (currentServiceProducts: Product[]) => (item: Identifiable) =>
      (!currentServiceProducts.some((aProduct) => aProduct.id === item.id))

  renderFormFields = (fields) => {

    const selectedService: Service = fields.selectedService.input.value;
    const toAddProductsInput: any = fields.toAddProducts.input;
    const blackListValue = fields.blackList.input.value;
    const selectedServiceProductsValue = fields.selectedServiceProducts.input.value;

    const serviceEl =
        selectedService ?
        <DetailView fields={[
          {
            key: "Service",
            name: <FormattedMessage id="studio.services.add-to-service-form.selected-service"
                                    defaultMessage="Service"/>,
            value: `${selectedService.id} : ${selectedService.title}`,
          },
          {
            key: "Service type",
            name: <FormattedMessage id="studio.services.add-to-service-form.selected-service-type"
                                    defaultMessage="Service type"/>, value: selectedService.type.toUpperCase(),
          }]}/>
                        : <DetailViewRow
            name={this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.detailViewRowService)}>
          <ServiceTypeahead value={selectedService} onChange={this.fetchSelectedServiceProducts}/>
        </DetailViewRow>;

    const ProductsElement =
        selectedService &&
        (
            <div>
              <DetailViewRow name={this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.detailViewRowProducts)}>
                <ServiceProductList items={toAddProductsInput.value}
                                    onAdd={(newItem) => toAddProductsInput.onChange(
                                        [newItem].concat(toAddProductsInput.value))}
                                    onRemove={(item, index) => toAddProductsInput.onChange(
                                        removeItem(toAddProductsInput.value, index))}
                                    onReorder={(oldIndex, newIndex, items) => toAddProductsInput.onChange(moveItem<Product>(items, oldIndex, newIndex))}
                                    validateItemToAdd={this.validateItemToAdd(selectedServiceProductsValue)}
                                    serviceType={selectedService.type}
                                    noLinks
                                    readOnly={false}
                                    showValidationBadge={true}
                                    calculateDimensions={calculateDimensionBasedOnRowsBeforeScroll(3, SERVICE_PRODUCT_LIST_ROW_HEIGHT)}
                                    service={selectedService}
                />
              </DetailViewRow>
              <DetailViewRow name={this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.detailViewRowServiceProducts)}>
                <ServiceProductList items={selectedServiceProductsValue}
                                    noLinks
                                    readOnly
                                    showValidationBadge={false}
                                    serviceType={selectedService.type}
                                    calculateDimensions={calculateDimensionBasedOnRowsBeforeScroll(3, SERVICE_PRODUCT_LIST_ROW_HEIGHT)}
                />
              </DetailViewRow>
            </div>
        );

    const blackListElement = blackListValue.length > 0 && (
        <DetailViewRow name="">
          {blackListValue.map((item) => item.id)
              .sort()
              .map(
                  (itemId) => <Label bsSize="xs" key={itemId} style={{margin: "0px 3px 0px 3px"}}> <FormattedMessage
                      id="studio.services.add-to-service-form.blacklist-product"
                      defaultMessage="Product: {itemID} is already used in the service"
                      values={{itemID: itemId}}/></Label>)
          }
        </DetailViewRow>
    );

    return (
        <div>
          {serviceEl}
          {ProductsElement}
          {blackListElement}
          {selectedService.type === "lts" ?
           <small><strong><FormattedMessage id="studio.services.add-to-service-form.note"
                                            defaultMessage="Note"/>&#58;&nbsp;</strong>
             <FormattedMessage id="studio.services.add-to-service-form.lts-note"
                               defaultMessage="LTS services will only start if all products contain either a Fusion coverage or elevation data"/>
           </small> : null}
        </div>
    );
  }

  confirmationComponent = (field) => {
    const service = field.input.value;
    return (
        <div>
          <h2><FormattedMessage id="studio.services.add-to-service-form.update-success"
                                defaultMessage="Service successfully updated!"/></h2>
          <Link to={toServiceLink(service)}>
            <Button bsStyle="info"><FormattedMessage id="studio.services.add-to-service-form.go-to-service"
                                                     defaultMessage="Go To Service {name}"
                                                     values={{name: service.name}}/></Button>
          </Link>
        </div>
    );
  }

  render() {
    if (this.props.submitting) {
      return (
          <div className="form-message">
            <h1><FormattedMessage id="studio.services.add-to-service-form.updating"
                                  defaultMessage="Updating Service..."/></h1>
          </div>
      );
    }
    if (this.props.submitFailed) {
      return (
          <div className="form-message">
            <h1><FormattedMessage id="studio.services.add-to-service-form.update-fail"
                                  defaultMessage="Service update failed"/></h1>
            {_error ? <ErrorDisplay error={_error}/> :
             <p><FormattedMessage id="studio.services.add-to-service-form.error-unknown"
                                  defaultMessage="Reason: unknown"/></p>}
          </div>
      );
    }
    if (this.props.submitSucceeded) {
      return <Field name={FIELD_SERVICE} component={this.confirmationComponent}/>;
    }
    return (
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <Fields names={[FIELD_SERVICE, FIELD_PRODUCT_TO_ADD, FIELD_SERVICE_PRODUCTS, FIELD_USED_PRODUCTS]}
                  component={this.renderFormFields}/>
        </Form>
    );
  }
}

const submitAddToService = (values: AddToServiceFormData, dispatch) => {
  const {selectedService, toAddProducts} = values;
  _error = null;
  let validationResolveFunc, validationRejectFunc;
  const resultPromise = new Promise((resolve, reject) => {
    validationResolveFunc = resolve;
    validationRejectFunc = reject;
  });
  dispatch(actions.addProductsToService(selectedService.id, toAddProducts.map((product) => product.id)))
      .then(validationResolveFunc)
      .catch((error) => {
        _error = error;
        validationRejectFunc(error);
      });
};

const validateAddToServiceForm = (values) => {
  const errors: any = {};
  if (!values.toAddProducts || values.toAddProducts.length === 0) {
    errors.toAddProducts = <FormattedMessage id="studio.services.add-to-service-form.no-products-to-add-error"
                                             defaultMessage="No products to add"/>;
  }
  if (values.toAddProducts && values.selectedService &&
      !productValidation.areProductsOK(values.toAddProducts, values.selectedService.type)) {
    errors.toAddProducts = <FormattedMessage id="studio.services.add-to-service-form.product-usage-error"
                                             defaultMessage="Some products cannot be used with this service"/>;
  }
  return errors;
};

let _error: Error = null;

export const FORM_NAME = "addToServiceForm";
const formConfig = {
  form: FORM_NAME,
  onSubmit: submitAddToService,
  validate: validateAddToServiceForm,
};

interface ExecuteButtonProps {
  dispatch: Dispatch<Action>;
  submitting: boolean;
  submitSucceeded: boolean;
  submitFailed: boolean;
  syncErrors: FormErrors<any>;
}

/**
 * The button that submits the form changes.
 * Any changes that user performs are not persisted to the backend before the submit button is pushed.
 */
class ExecuteButtonComponent extends React.Component<ExecuteButtonProps, {}> {
  render() {
    const {dispatch, submitting, submitSucceeded, submitFailed, syncErrors} = this.props;
    if (submitting || submitSucceeded || submitFailed) {
      return null;
    }

    return (
        <Button disabled={!isEmpty(syncErrors)} onClick={() => dispatch(submit(FORM_NAME))}>
          <FormattedMessage id="studio.services.add-to-service-form.add-to-service" defaultMessage="Add to service"/>
        </Button>
    );
  }
}

export const ExecuteButton = connect((state) => {
  return {
    submitting: isSubmitting(FORM_NAME)(state),
    submitSucceeded: hasSubmitSucceeded(FORM_NAME)(state),
    submitFailed: hasSubmitFailed(FORM_NAME)(state),
    syncErrors: getFormSyncErrors(FORM_NAME)(state),
  };
})(ExecuteButtonComponent);

export const CloseAddToServiceFormButton = createCloseButton(FORM_NAME);

export const AddToServiceForm = reduxForm<AddToServiceFormData, AddToServiceFormProps>(formConfig)(injectIntl(WithApi(AddToServiceFormComponent)));
