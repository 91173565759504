type SET_STYLE_DATA_TOUR_COMPLETED = "user/SET_STYLE_DATA_TOUR_COMPLETED";
const SET_STYLE_DATA_TOUR_COMPLETED: SET_STYLE_DATA_TOUR_COMPLETED = "user/SET_STYLE_DATA_TOUR_COMPLETED";

const actionTypes = {
  SET_STYLE_DATA_TOUR_COMPLETED,
};

export interface SetStyleDataTourCompletedAction {
  type: SET_STYLE_DATA_TOUR_COMPLETED;
  payload: boolean;
}

const setStyleDataTourCompleted = (isStyleDataTourCompleted: boolean) => {
  return {
    type: SET_STYLE_DATA_TOUR_COMPLETED,
    payload: isStyleDataTourCompleted,
  };
};

const actions = {
  setStyleDataTourCompleted,
};

export {actions, actionTypes};
