import * as React from "react";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {Button} from "react-bootstrap";
import {Alert, Modal} from "react-bootstrap/lib";
import {Spinner} from "../../common/ui/spinner/Spinner";
import {mapErrorToMessage} from "../../common/util/ErrorMapper";
import {ErrorDisplay} from "../../common/ui/errordisplay/ErrorDisplay";

interface RevertButtonProps {
  defaultBackgroundData: string;
  onSuccess: () => void;
}

interface RevertButtonState {
  modalIsShowing: boolean;
  isExecuting: boolean;
  error: Error;
}

class RevertBackgroundDataButtonComponent extends React.Component<WithApiProperties & InjectedIntlProps & RevertButtonProps, RevertButtonState> {

  constructor(props) {
    super(props);
    this.state = {
      modalIsShowing: false,
      isExecuting: false,
      error: null,
    }
  }

  openDialog = () => {
    this.setState({...this.state, modalIsShowing: true, error: null, isExecuting: false});
  }

  closeDialog = () => {
    this.setState({...this.state, modalIsShowing: false});
  }

  performRevert = () => {
    this.setState({...this.state, isExecuting: true, error: null});
    this.props.api.setBackgroundDataFilePath(this.props.defaultBackgroundData).then(() => {
      this.setState({...this.state, modalIsShowing: false, error: null}, () => this.props.onSuccess());
    }).catch((error) => {
      this.setState({...this.state, isExecuting: false, error});
    });
  }

  renderTitle() {
    const {isExecuting} = this.state;

    if (isExecuting) {
      return null;
    }
    return <Modal.Title><FormattedMessage id="studio.settings.previewer.background-data.revert.title"
                                          defaultMessage="Revert background data"/></Modal.Title>;
  }

  renderButtons() {
    const {isExecuting, error} = this.state;
    if (isExecuting) {
      return null;
    }
    return (
        <div>
          {error ? null :
           <Button bsStyle={"danger"} onClick={this.performRevert}>
             <FormattedMessage id="studio.settings.previewer.background-data.revert.button"
                               defaultMessage="Revert to default"/>
           </Button>}
          <Button onClick={this.closeDialog}><FormattedMessage
              id="studio.settings.previewer.background-data.revert.cancel"
              defaultMessage="Cancel"/></Button>
        </div>
    );
  }

  renderExecuting() {
    const {isExecuting} = this.state;
    if (isExecuting) {
      return (
          <div style={{textAlign: "center"}}>
            <Spinner bigStyle/>
          </div>
      );
    }
    return null;
  }

  renderError() {
    const {error} = this.state;

    if (error) {
      const errorMessage = mapErrorToMessage(error);
      if (errorMessage) {
        return <Alert bsStyle="danger">{errorMessage}</Alert>;
      }
      return <ErrorDisplay error={error}/>;
    }
    return null;
  }

  renderBody() {
    const {isExecuting, error} = this.state;
    if (isExecuting) {
      return this.renderExecuting();
    }
    if (error) {
      return this.renderError();
    }
    return (
        <div>
          <p>
            <FormattedMessage id="studio.settings.previewer.background-data.revert.confirmation"
                              defaultMessage="Are you sure you want to revert the background data to the default? This action cannot be undone."/>
          </p>
          <p>
            <FormattedMessage id="studio.settings.previewer.background-data.revert.body"
                               defaultMessage="The background data will be set to"/><br/>
                               {this.props.defaultBackgroundData}
          </p>
        </div>
    );
  }

  renderButton() {
    return (
        <Button bsSize={"small"} onClick={this.openDialog}>
          <FormattedMessage
              id="studio.settings.previewer.background-data.revert"
              defaultMessage="Revert to default"/>
        </Button>
    );
  }

  renderModal() {
    const {modalIsShowing, isExecuting} = this.state;
    return (
        <Modal show={modalIsShowing} onHide={this.closeDialog} backdrop="static">
          <Modal.Header closeButton={!isExecuting}>
            {this.renderTitle()}
          </Modal.Header>
          <Modal.Body id={"revert-modal-body"}>
            {this.renderBody()}
          </Modal.Body>
          <Modal.Footer>
            {this.renderButtons()}
          </Modal.Footer>
        </Modal>
    );
  }

  render() {
    return (
        <div className="backgroundButton">
          {this.renderButton()}
          {this.renderModal()}
        </div>
    );
  }
}

export const RevertBackgroundDataButton = injectIntl(WithApi(RevertBackgroundDataButtonComponent));