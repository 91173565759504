import {actionTypes, HideNotificationAction, ShowNotificationAction} from "./actions";
import {moduleName} from "./selectors";

const reducer = (prevState = [], action) => {
  if (action.type === actionTypes.SHOW_NOTIFICATION) {
    const showNotificationAction: ShowNotificationAction = action as ShowNotificationAction;
    return [
      ...prevState,
      showNotificationAction.payload,
    ];
  } else if (action.type === actionTypes.HIDE_NOTIFICATION) {
    const hideNotificationAction: HideNotificationAction = action as HideNotificationAction;
    const index = prevState.findIndex((notification) => notification.id === hideNotificationAction.payload.id);
    return [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ];
  }
  return prevState;
};

export {
  moduleName,
  reducer,
};
