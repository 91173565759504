import {User} from "./model";
import {UserState} from "./reducer";

export const moduleName = "user";

const getUserState = (state) => state[moduleName] as UserState;

const getUser = (state): User => {
  return getUserState(state);
};

const getUserTourCompleted = (state) => {
  const userState = getUserState(state);
  // By default not show the user tour
  return userState ? userState.userTourCompleted : true;
};

export const selectors = {
  getUser,
  getUserTourCompleted,
};
