import * as React from "react";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {DetailHeader} from "../common/ui/detailsection/DetailHeader";
import {DetailSection, DetailSectionProps} from "../common/ui/detailsection/DetailSection";
import {DetailView, Formats} from "../common/ui/detailsection/DetailView";
import {PreviewMetadata} from "../common/ui/riamap/model";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import * as paths from "../paths";
import {Style} from "./model";

const STYLES_LIST_DETAIL_SECTION = defineMessages({
  path: {
    id: "studio.styles-list-detail-section.path",
    defaultMessage: "Path",
  },
  type: {
    id: "studio.styles-list-detail-section.type",
    defaultMessage: "type",
  },
  fileSize: {
    id: "studio.styles-list-detail-section.file-size",
    defaultMessage: "File Size",
  },
});

class StyleListDetailSectionComponent extends DetailSection<DetailSectionProps<Style> & InjectedIntlProps & WithApiProperties, Style> {

  loadData(nextProps: DetailSectionProps<Style>): Promise<Style> {
    if (nextProps.selection.length === 1) {
      const styleId = nextProps.selection[0].id;
      return this.props.api.getStyleById(styleId).then((style) => {
        const styleWithoutBounds = style;
        return this.props.api.getStyleBoundsByType(style.type).then((bounds) => {
          return Object.assign({}, styleWithoutBounds, {wgs84Bounds: bounds});
        });
      }).then((style) => style);
    }
    return Promise.resolve(null);
  }

  getPreviewBaseUrl(): string {
    return paths.PREVIEW_STYLE_PATH;
  }

  // Styles can be previewed since they use small data set, we don't need to make an API call to check whether a style can be previewed.
  getPreviewMetadataFunction(): (item: Style) => Promise<PreviewMetadata> {
    return (item) => this.props.api.canPreviewStyleType(item.type).then((canPreview) => {
      return {
        previewable: canPreview,
        shouldForceToPreview: false,
        georeferenced: true,
        empty: false,
      };
    });
  }

  renderContent() {
    const {isLoading, error} = this.state;
    const {selection} = this.props;

    if (selection.length === 0) {
      return (
          <div className="emptyDetailPlaceholder">
            <FormattedMessage id="studio.styles-list-detail-section.empty-placeholder"
                              defaultMessage="Select some data in the table."/>
          </div>
      );
    }
    if (selection.length > 1) {
      return <p><FormattedMessage
          id="studio.styles-list-detail-section.selection-length"
          defaultMessage="Selected {length} items."
          values={{length: selection.length}}/></p>;
    }
    if (selection.length === 1 && this.state.data && !isLoading && !error) {
      const style = this.state.data;
      return (
          <div className="details">
            <DetailHeader title={style.title}/>
            <DetailView fields={[
              {
                key: "Path",
                name: this.props.intl.formatMessage(STYLES_LIST_DETAIL_SECTION.path),
                value: style.filePath,
              },
              {
                key: "Type",
                name: this.props.intl.formatMessage(STYLES_LIST_DETAIL_SECTION.type),
                value: style.type,
              },
              {
                key: "File Size",
                name: this.props.intl.formatMessage(STYLES_LIST_DETAIL_SECTION.fileSize),
                value: style.fileMetadata.size,
                format: Formats.FILESIZE,
              },
            ]}/>
          </div>
      );
    }
    return super.renderContent();
  }
}

export const StylesListDetailSection = injectIntl(WithApi(StyleListDetailSectionComponent));
