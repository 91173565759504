import * as React from "react";
import {SectionHeader} from "../../../common/ui/detailsection/SectionHeader";
import {FormattedMessage} from "react-intl";
import {FormGroup, Radio} from "react-bootstrap";
import {DecodeFailureResolution} from "../../model";

interface DecodeFailureResolutionConfiguratorProps {
  decodeFailureResolution: DecodeFailureResolution;
  handleDecodeFailureResolutionChange: (decodeFailureResolution: DecodeFailureResolution) => void;
}

export class DecodeFailureResolutionConfigurator extends React.Component<DecodeFailureResolutionConfiguratorProps> {
  render() {
    const {decodeFailureResolution, handleDecodeFailureResolutionChange} = this.props;
    return (
        <>
          <SectionHeader>
            <FormattedMessage id="studio.jobs.job-detail-page.on-decode-failure"
                              defaultMessage="Decode failure resolution"/>
          </SectionHeader>
          <FormGroup>
            <Radio inline
                   checked={decodeFailureResolution === DecodeFailureResolution.DELETE}
                   onChange={(event) => {
                     const doDelete = (event.target as any).checked;
                     if (doDelete) {
                       handleDecodeFailureResolutionChange(DecodeFailureResolution.DELETE);
                     }
                   }}>
              <FormattedMessage id="studio.jobs.job-detail-page.decode-failure-resolution.delete"
                                defaultMessage="Delete associated Data and Styles"/>
            </Radio>
            <Radio inline
                   checked={decodeFailureResolution === DecodeFailureResolution.KEEP}
                   onChange={(event) => {
                     const keep = (event.target as any).checked;
                     if (keep) {
                       handleDecodeFailureResolutionChange(DecodeFailureResolution.KEEP);
                     }
                   }}>
              <FormattedMessage id="studio.jobs.job-detail-page.decode-failure-resolution.keep"
                                defaultMessage="Keep associated Data and Styles"/>
            </Radio>
          </FormGroup>
        </>
    );
  }
}