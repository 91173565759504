import * as React from "react";
import {Identifiable} from "../../../model";
import {Service} from "../../../services/model";

interface SpecialProps {
  service?: Service;
  serviceType?: string;
}

/**
 * Props are for controlling the way presentational components are rendered.
 */
interface PresentationalProps extends SpecialProps {
  noLinks?: boolean;
  readOnly?: boolean;
  showValidationBadge?: boolean;
}

/**
 * Props that are exposed by the wrapped ReorderableList.
 */
export interface ReorderableListProps<T extends Identifiable> extends PresentationalProps {
  items: T[];
  onReorder?: (oldIndex: number, newIndex: number, items: T[]) => void;
  onAdd?: (addedItem: T) => void;
  onRemove?: (item: T, index: number) => void;
  onChange?: (changedItem: T, index: number) => void;
  validateItemToAdd?: (item: Identifiable) => boolean;
}

/**
 * Props that are injected into the wrapped component (list's presentational component).
 */
export interface InjectedReorderableListProps<T extends Identifiable> extends PresentationalProps {
  items: T[];
  moveSelection: (toIdx: number) => void;
  selectedIndex: number;
  validateItemToAdd?: (newItem: Identifiable) => boolean;
  addItem?: (newItem: T) => void;
  selectItem?: (itemId: string, deselectIfSelected: boolean) => void;
  reorderItem?: (fromIndex: number, toIdx: number) => void;
  removeItem?: (item: T, index: number) => void;
  changeItem?: (changedItem: T, index: number) => void;
}

interface SelectableState {
  selectedId: string;
}

/**
 * Props that the ReorderableItem should implement.
 */
export interface ReorderableItemProps<T> extends PresentationalProps {
  item: T;
  index: number;
  selected: boolean;
  selectItem?: () => void;
  removeItem?: () => void;
  changeItem?: (changedItem: T) => void;
  reorderItem?: (fromIndex: number, toIndex: number) => any;
}

type ReorderableItemClass<T extends Identifiable> = React.ComponentClass<ReorderableItemProps<T>>;
export type ReorderableListType<T extends Identifiable, OriginalProps> = React.ComponentClass<ReorderableListProps<T> & OriginalProps>;

export function ReorderableList<T extends Identifiable, OriginalProps>(
    WrappedContainerComponent: React.ComponentClass<InjectedReorderableListProps<T>>,
    MyReorderableItemClas: ReorderableItemClass<T>): ReorderableListType<T, OriginalProps> {

  return class ThisClass extends React.Component<ReorderableListProps<T> & OriginalProps, SelectableState> {

    static NO_SELECTION = null;

    constructor(props) {
      super(props);
      this.state = {selectedId: ThisClass.NO_SELECTION};
    }

    isSelected = (id: string) => {
      return this.state.selectedId != null && this.state.selectedId === id;
    }

    getSelectedIndex = () => {
      const {selectedId} = this.state;
      return (selectedId !== ThisClass.NO_SELECTION) ?
             this.props.items.findIndex((item) => selectedId === item.id) : null;
    }

    handleSelect = (id: string, deselectIfSelected: boolean) => {
      // deselectIfSelected = Selecting an already selected index makes it deselected
      const newSelectedId = deselectIfSelected && this.isSelected(id) ? ThisClass.NO_SELECTION : id;
      this.setState({selectedId: newSelectedId});
    }

    handleAdd = (newItem: T) => this.props.onAdd(newItem);

    handleRemove = (item: T, index: number) => {
      if (this.isSelected(item.id)) {
        // remove selections from the item to remove
        this.setState({selectedId: ThisClass.NO_SELECTION});
      }
      this.props.onRemove(item, index);
    }

    handleChange = (changedItem: T, index: number) => {
      this.props.onChange(changedItem, index);
    }

    moveSelection = (toIdx: number) => {
      const selectedIndex = this.getSelectedIndex();
      if (selectedIndex != null) {
        this.reorderItem(selectedIndex, toIdx);
      }
    }

    reorderItem = (fromIndex: number, toIdx: number) => {
      const {onReorder} = this.props;
      if (fromIndex >= 0 && toIdx >= 0 && fromIndex !== toIdx && onReorder) {
        onReorder(fromIndex, toIdx, this.props.items);
      }
    }

    render() {
      const {selectedId} = this.state;
      const {items, noLinks, showValidationBadge, readOnly, service, serviceType} = this.props;

      if (!items) {
        return null;
      }

      const selectedIndex = this.getSelectedIndex();

      return (
          <WrappedContainerComponent {...this.props}
                                     moveSelection={this.moveSelection}
                                     selectedIndex={selectedIndex}
                                     addItem={this.handleAdd}
                                     selectItem={this.handleSelect}
                                     removeItem={this.handleRemove}
                                     reorderItem={this.reorderItem}
                                     changeItem={this.handleChange}
          >

            {MyReorderableItemClas ? items.map((item, idx) => (
                    <MyReorderableItemClas key={idx}
                                           item={item}
                                           index={idx}
                                           selected={selectedId === item.id}
                                           selectItem={(deselectIfSelected = true) => this.handleSelect(item.id,
                                               deselectIfSelected)}
                                           removeItem={() => this.handleRemove(item, idx)}
                                           changeItem={(changedItem) => this.handleChange(changedItem, idx)}
                                           reorderItem={this.reorderItem}
                                           noLinks={noLinks}
                                           showValidationBadge={showValidationBadge}
                                           readOnly={readOnly}
                                           service={service}
                                           serviceType={serviceType}/>
                ),
            ) : null}
          </WrappedContainerComponent>
      );
    }
  };

}
