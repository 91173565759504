import {Product} from "../../products/model";
import {
  ServiceTypeValidation,
  ValidationMessageSeverity
} from "../ui/validation/ServiceTypeValidation";
import {Severity} from "../ui/validation/ProductValidation";

export const addValidationToProducts = (serviceTypeValidation: ServiceTypeValidation, products: Product[]) => {
  products.forEach(product => {
    product.validationByServiceType = serviceTypeValidation.serviceTypes.reduce((acc, validationResult) => {
      const {canCreate, name, validationMessages} = validationResult;
      const productMessages = validationMessages.filter(message => message.product && message.product === product.id);
      const errorMessages = productMessages.filter(message => message.severity === ValidationMessageSeverity.ERROR).map(message => message.message);
      const warningMessages = productMessages.filter(message => message.severity === ValidationMessageSeverity.WARNING).map(message => message.message);
      let severity = warningMessages.length > 0 ? Severity.WARNING : Severity.OK;
      severity = errorMessages.length > 0 ? Severity.ERROR : severity;
      severity = !canCreate ? Severity.ERROR : severity;
      return {...acc, [name]: {severity, errorMessages, warningMessages}}
    }, {})});
}