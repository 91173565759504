/**
 * Returns a new array contain all elements of arr, but where the element originally at previousIndex in arr
 * is now at newIndex.
 * @param arr the array with the element to move at arr[previousIndex]
 * @param previousIndex the index of the element to move in arr.
 * @param newIndex the index of the moved element in the returned array
 * @returns {T[]} an array where the element originally at arr[previousIndex] is now at newIndex
 */
export function moveItem<T>(arr: T[], previousIndex: number, newIndex: number): T[] {
  const array = arr.slice(0);
  if (newIndex >= array.length) {
    let k = newIndex - array.length;
    while ((k--) + 1) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
  return array;
}

/**
 * Returns a new array where the element at index has been removed.
 * If an invalid index is passed, the original array is returned.
 * @param arr the array to remove an element from
 * @param index the index of the element to remove
 * @returns {T[]} an array containing all elements of arr, except for the removed element.
 */
export function removeItem<T>(arr: T[], index: number) {
  const array = arr.slice(0);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
}

/**
 * Returns the difference of arr1 - arr2.
 * So all elements that are present in arr1, but not in arr2.
 */
export function diffArray(arr1, arr2) {
  return arr1.concat(arr2).filter((val) => {
    return arr1.includes(val) && !arr2.includes(val);
  });
}

/**
 * Returns whether or not the two passed arrays are equal.
 */
export function equals(arr1, arr2) {
  if (arr1.length === 0 && arr2.length === 0) {
    return true;
  } else {
    return diffArray(arr1, arr2).length === 0;
  }
}
