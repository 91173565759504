import * as React from "react";
import {Col, FormControl} from "react-bootstrap";
import {defineMessages, InjectedIntl} from "react-intl";
import {DateUtil} from "../../util/DateUtil";
import {TextEdit} from "../edit/TextEdit";

const LABEL_COL_WIDTH = 3;
const VALUE_COL_WIDTH = 12 - LABEL_COL_WIDTH;

const labelCol = (text) => <Col sm={LABEL_COL_WIDTH} className="detail-label">{text}</Col>;
const valueCol = (val, cssClass = "detail-value") => <Col sm={VALUE_COL_WIDTH} className={cssClass}>{val}</Col>;

const DETAIL_VIEW_MESSAGES = defineMessages({
  createdBy: {
    id: "studio.detail-view.common-metadata.created-by",
    defaultMessage: "Created By",
  },
  createdTime: {
    id: "studio.detail-view.common-metadata.created-date",
    defaultMessage: "Date Created",
  },
});

export enum Formats {
  TEXT,
  TEXT_EDIT,
  BOUNDS,
  DATETIME,
  FILESIZE,
  TEXT_AREA
}

export interface FieldInfo {
  key: any;
  name: any;
  value: any;
  format?: Formats;
  onChange?: (changedItem: any) => any;
}

interface DetailViewProps {
  fields: FieldInfo[];
}

export const humanReadableFileSize = (fileSizeInBytes: number) => {
  let i = -1;
  const byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export class DetailView extends React.Component<DetailViewProps, {}> {

  createRowUI = ({key, name, value, format = Formats.TEXT, onChange}: FieldInfo) => {

    switch (format) {
    case Formats.TEXT_EDIT:
      return (
          <div className="form-group detail-row" key={key}>
            {labelCol(name)}
            {valueCol(<TextEdit value={value} onChange={onChange}/>, "detail-row-textedit")}
          </div>
      );
    case Formats.FILESIZE:
      const formattedSize = value ? `${humanReadableFileSize(value)}` : "-";
      return (
          <div className="form-group detail-row" key={key}>
            {labelCol(name)}
            {valueCol(formattedSize)}
          </div>
      );
    case Formats.DATETIME:
      const formattedVal = value ? `${DateUtil.formatDateTime(value)}` : "-";
      return (
          <div className="form-group detail-row" key={key}>
            {labelCol(name)}
            {valueCol(formattedVal)}
          </div>
      );
    case Formats.BOUNDS:
      return (
          <div key={key}>
            <div className="form-group detail-row">
              {labelCol(name)}
              {valueCol(`${value.x}, ${value.y} (x, y)`)}
            </div>
            <div className="form-group detail-row">
              {labelCol("")}
              {valueCol(`${value.width}, ${value.height} (width, height)`)}
            </div>
          </div>

      );
    case Formats.TEXT_AREA:
      return (
          <div className="form-group detail-row detail-value-text-area" key={key}>
            {labelCol(name)}
            {valueCol(<FormControl componentClass="textarea" className="detail-text-area" disabled value={value}/>)}
          </div>
      );
    default:
      return (
          <div className="form-group detail-row" key={key}>
            {labelCol(name)}
            {valueCol(value || "-")}
          </div>
      );
    }
  }

  render() {
    return (
        <div className="detail-view">
          {this.props.fields.map((field) => this.createRowUI(field))}
        </div>
    );
  }
}

interface DetailViewRowProps {
  name: string;
}

export class DetailViewRow extends React.Component<DetailViewRowProps, {}> {
  render() {
    const {name} = this.props;
    return (
        <div className="form-group" key={name}>
          {labelCol(name)}
          <Col sm={VALUE_COL_WIDTH}>{this.props.children}</Col>
        </div>
    );
  }
}

interface CommonMetadata {
  createdBy?: { username: string };
  creationTime?: string;
}

export const createCommonMetadataFields = (object: CommonMetadata, intl: InjectedIntl,
                                           creationTimeLabel?: string): FieldInfo[] => {
  const createdBy = object.createdBy ? object.createdBy.username : "Unknown";
  const creationTime = object.creationTime ? object.creationTime : "Unknown";
  return [
    {
      key: "Created By",
      name: intl.formatMessage(DETAIL_VIEW_MESSAGES.createdBy),
      value: createdBy,
    },
    {
      key: "Date Created",
      name: intl.formatMessage(DETAIL_VIEW_MESSAGES.createdTime),
      value: creationTime,
      format: Formats.DATETIME,
    },
  ];
};
