import * as React from "react";
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {defineMessages, injectIntl} from "react-intl";
import {LcdIcon} from "../icon/LcdIcon";
import InjectedIntlProps = ReactIntl.InjectedIntlProps;

interface DefaultReorderControlProps {
  moveSelection: (toIdx: number) => void;
  selectedIndex: number;
  maxIndex: number;
}

const MESSAGES = defineMessages({
  moveSelectionUp: {
    id: "studio.ui.order.move-selection-up",
    defaultMessage: "Move selection up",
  },
  moveSelectionTop: {
    id: "studio.ui.order.move-selection-top",
    defaultMessage: "Move selection to the top",
  },
  moveSelectionDown: {
    id: "studio.ui.order.move-selection-down",
    defaultMessage: "Move selection down",
  },
  moveSelectionBottom: {
    id: "studio.ui.order.move-selection-bottom",
    defaultMessage: "Move selection to the bottom",
  },
});

class DefaultReorderControlComponent extends React.Component<DefaultReorderControlProps & InjectedIntlProps, {}> {
  render() {
    const {moveSelection, selectedIndex, maxIndex, intl} = this.props;

    function canMove(toId) {
      return (selectedIndex !== null && toId >= 0 && toId <= maxIndex && selectedIndex !== toId);
    }

    return (
        <div>
          <ButtonToolbar>
            <ButtonGroup>
              <Button onClick={(event) => {moveSelection(selectedIndex - 1); event.stopPropagation(); }}
                      disabled={!canMove(selectedIndex - 1)}
                      className="reorder-control-button"
                      title={intl.formatMessage(MESSAGES.moveSelectionUp)}
                      bsSize="xsmall">
                <LcdIcon icon="move-up"/>
              </Button>
              <Button onClick={(event) => {moveSelection(0); event.stopPropagation(); }}
                      disabled={!canMove(0)}
                      className="reorder-control-button"
                      title={intl.formatMessage(MESSAGES.moveSelectionTop)}
                      bsSize="xsmall">
                <LcdIcon icon="step-up"/>
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button onClick={(event) => {moveSelection(selectedIndex + 1); event.stopPropagation(); }}
                      disabled={!canMove(selectedIndex + 1)}
                      className="reorder-control-button"
                      title={intl.formatMessage(MESSAGES.moveSelectionDown)}
                      bsSize="xsmall">
                <LcdIcon icon="move-down"/>
              </Button>
              <Button onClick={(event) => {moveSelection(maxIndex); event.stopPropagation(); }}
                      disabled={!canMove(maxIndex)}
                      className="reorder-control-button"
                      title={intl.formatMessage(MESSAGES.moveSelectionBottom)}
                      bsSize="xsmall">
                <LcdIcon icon="step-down"/>
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
    );
  }
}

export const DefaultReorderControl = injectIntl(DefaultReorderControlComponent);
