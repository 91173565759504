//creates some commonly used fields
import {defineMessages} from "react-intl";
import {DateField, SelectField, TextField, TypeaheadTextField} from "./SearchForm";

export const ANY_TEXT_SEARCH_FIELD_NAME = "anyText";
export const START_DATE_FIELD_NAME = "startDate";
export const END_DATE_FIELD_NAME = "endDate";
export const TYPE_FIELD_NAME = "type";
export const TYPEAHEAD_TEXT_FIELD_NAME = "typeaheadText";
export const FIELD_NAME_MESSAGES = defineMessages({
  startDatePlaceholder: {
    id: "studio.field-factory.start-date-placeholder",
    defaultMessage: "Enter start date",
  },
  endDatePlaceholder: {
    id: "studio.field-factory.end-date-placeholder",
    defaultMessage: "Enter end date",
  },
  anyTextPlaceholder: {
    id: "studio.field-factory.anyText-search-placeholder",
    defaultMessage: "Search by name, keywords,...",
  },
  typeaheadTextPlaceholder: {
    id: "studio.field-factory.typahead-text-placeholder",
    defaultMessage: "Search by keywords,...",
  },
  typeLabelAny: {
    id: "studio.field-factory.type-label-any",
    defaultMessage: "Any",
  },
  typeLabelRaster: {
    id: "studio.field-factory.type-label-raster",
    defaultMessage: "Raster",
  },
  typeLabelVector: {
    id: "studio.field-factory.type-label-vector",
    defaultMessage: "Vector",
  },
  typeLabelMixed: {
    id: "studio.field-factory.type-label-mixed",
    defaultMessage: "Mixed",
  },
  typeLabelEmpty: {
    id: "studio.field-factory.type-label-empty",
    defaultMessage: "Empty",
  },
  typeLabelUnknown: {
    id: "studio.field-factory.type-label-unknown",
    defaultMessage: "Unknown",
  },
  typeLabelMesh: {
    id: "studio.field-factory.type-label-mesh",
    defaultMessage: "Mesh",
  },
  typeLabelPointCloud: {
    id: "studio.field-factory.type-label-pointcloud",
    defaultMessage: "Point Cloud",
  },
});

export const FieldFactory = {
  createStartDateField: (intl): DateField => {
    return {
      type: "date",
      name: START_DATE_FIELD_NAME,
      placeholder: intl.formatMessage(FIELD_NAME_MESSAGES.startDatePlaceholder),
      flexGrow: 1,
    };
  },
  createEndDateField: (intl): DateField => {
    return {
      type: "date",
      name: END_DATE_FIELD_NAME,
      placeholder: intl.formatMessage(FIELD_NAME_MESSAGES.endDatePlaceholder),
      flexGrow: 1,
    };
  },
  createAnyTextSearchField: (intl): TextField => {
    return {
      type: "text",
      name: ANY_TEXT_SEARCH_FIELD_NAME,
      placeholder: intl.formatMessage(FIELD_NAME_MESSAGES.anyTextPlaceholder),
      flexGrow: 5,
    };
  },
  createTypeaheadTextField: (intl): TypeaheadTextField => ({
    type: "typeahead-text",
    name: TYPEAHEAD_TEXT_FIELD_NAME,
    placeholder: intl.formatMessage(FIELD_NAME_MESSAGES.typeaheadTextPlaceholder),
    flexGrow: 5,
  }),
  createTypeField: (options): SelectField => {
    return {
      type: "select",
      name: TYPE_FIELD_NAME,
      defaultValue: "",
      options,
      flexGrow: 1,
    };
  },
};
