//selectors are separate here, in order to break cyclic dependency cycle between actions and reducer
import {selectors as pagedDataSelectors} from "../common/util/pagination/PaginationUtil";

export const moduleName = "product";
const getProductState = (state) => state[moduleName];

const getProductById = (state, id: string) => {
  const pagedProductState = getPagedState(state);
  return pagedDataSelectors.getDataById(pagedProductState, id);
};

const getProductContentsRequestState = (state) => {
  const productState = getProductState(state);
  return productState.loadProductContentsRequest;
};

const getProductContentsOfProduct = (state, productId: string) => {
  const allProducts = getProductState(state).allProductContents;
  return allProducts.filter((styledData) => styledData.productId === productId);
};

const getPagedState = (state) => {
  const productState = getProductState(state);
  return productState.pages;
};

const getFilter = (state) => {
  const productState = getProductState(state);
  return productState.filter;
};

export const selectors = {
  getProductById,
  getProductContentsRequestState,
  getProductContentsOfProduct,
  getPagedState,
  getFilter,
};
