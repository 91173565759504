import * as React from "react";
import {Button, Modal, Sizes} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntl, InjectedIntlProps, injectIntl} from "react-intl";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {ChooseStyleForm, ChooseStyleFormSubmitButton} from "./ChooseStyleForm";
import {Style} from "./model";

interface SetStyleButtonState {
  showModal: boolean;
}

interface SetStyleButtonProps extends InjectedIntl, InjectedIntlProps {
  disabled: boolean;
  bsSize: Sizes;
  onStyleSet: (styleToSet: Style) => Promise<void>;
}

const SET_STYLE_BUTTON_MESSAGES = defineMessages({
  setStyleButtonDescription: {
    id: "studio.set-style-button.set-style-button-description",
    defaultMessage: "Set style for all selected items",
  },
  clearSuccess: {
    id: "studio.set-style-button.cleared-success",
    defaultMessage: "Style successfully cleared",
  },
  setSuccess: {
    id: "studio.set-style-button.set-success",
    defaultMessage: "Style successfully set",
  },
  clearFail: {
    id: "studio.set-style-button.clear-failed",
    defaultMessage: "Failed to clear result",
  },
  setFail: {
    id: "studio.set-style-button.set-failed",
    defaultMessage: "Failed to set result",
  },
  clearing: {
    id: "studio.set-style-button.clearing",
    defaultMessage: "Clearing style. Please wait...",
  },
  setting: {
    id: "studio.set-style-button.setting",
    defaultMessage: "Setting style. Please wait...",
  },
  clearButton: {
    id: "studio.set-style-button.clear-button-label",
    defaultMessage: "Clear Style",
  },
  setButton: {
    id: "studio.set-style-button.set-button-label",
    defaultMessage: "Set Style",
  },
});

export class SetStyleButtonPresentation extends React.Component<SetStyleButtonProps, SetStyleButtonState> {

  constructor(props) {
    super(props);
    this.state = {showModal: false};
  }

  closeModal = () => this.setState({showModal: false});

  openModal = () => this.setState({showModal: true});

  render() {

    const {bsSize, disabled, intl} = this.props;
    return (
        <div>
          <Button bsSize={bsSize} disabled={disabled}
                  alt={intl.formatMessage(SET_STYLE_BUTTON_MESSAGES.setStyleButtonDescription)}
                  onClick={this.openModal}>
            <LcdIcon icon="pencil"/>
            <FormattedMessage id="studio.set-style-button.set-style-button-text" defaultMessage="Set Style" />
          </Button>

          <Modal show={this.state.showModal} onHide={this.closeModal} bsSize="large" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="studio.set-style-button.modal-header" defaultMessage="Set Style"
              /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ChooseStyleForm onStyleChosen={this.props.onStyleSet}
                               submitSucceededMessage={(chosenStyle) => {
                                 return chosenStyle === null ? intl.formatMessage(
                                     SET_STYLE_BUTTON_MESSAGES.clearSuccess) : intl.formatMessage(
                                     SET_STYLE_BUTTON_MESSAGES.setSuccess);
                               }}
                               submitFailedMessage={(chosenStyle) => {
                                 return chosenStyle === null ? intl.formatMessage(
                                     SET_STYLE_BUTTON_MESSAGES.clearFail) : intl.formatMessage(
                                     SET_STYLE_BUTTON_MESSAGES.setFail);
                               }}
                               whileSubmittingMessage={(chosenStyle) => {
                                 return chosenStyle === null ? intl.formatMessage(
                                     SET_STYLE_BUTTON_MESSAGES.clearing) : intl.formatMessage(
                                     SET_STYLE_BUTTON_MESSAGES.setting);
                               }}
              />
            </Modal.Body>
            <Modal.Footer>
              <ChooseStyleFormSubmitButton buttonText={(chosenStyle: Style) => {
                return chosenStyle === null ? intl.formatMessage(SET_STYLE_BUTTON_MESSAGES.clearButton)
                    : intl.formatMessage(SET_STYLE_BUTTON_MESSAGES.setButton);
              }}/>
              <Button onClick={this.closeModal}><FormattedMessage
                  id="studio.set-style-button.close"
                  defaultMessage="close"
              /></Button>
            </Modal.Footer>
          </Modal>
        </div>
    );
  }

}

export const SetStyleButton = injectIntl(SetStyleButtonPresentation);
