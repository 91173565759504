import * as React from "react";
import {FormattedMessage} from "react-intl";

export const MAIN_TOUR_METADATA = {
  description: <FormattedMessage id="studio.tour.description"
                                 defaultMessage="This is the main tour of the application. You will learn the basics to get started: from indicating LuciadFusion Studio where your data is to the publication of your first service."/>,
};

export const MAIN_TOUR_STEPS = [
  {
    title: <FormattedMessage id="studio.tour.description.welcome-title"
                             defaultMessage="Welcome to LuciadFusion Studio"/>,
    text: <FormattedMessage id="studio.tour.description.welcome"
                            defaultMessage="Welcome to LuciadFusion Studio! We see that this is the first time you have logged in, and we would like to guide you through the basic concepts of the application to help you get started. If you already know about LuciadFusion Studio, feel free to skip this tour using the button below."/>,
    selector: ".welcomePage",
    position: "bottom",
    isFixed: true,
  }, {
    title: <FormattedMessage id="studio.tour.description.add-data-title" defaultMessage="Add your data"/>,
    text: <FormattedMessage id="studio.tour.description.add-data"
                            defaultMessage="Get started by telling LuciadFusion Studio {where} your data is. You can do so by clicking here."
                            values={{
                              where: (
                                  <strong>
                                    <FormattedMessage
                                        id="studio.tour.description.add-data.where"
                                        defaultMessage="where"
                                    />
                                  </strong>
                              ),
                            }}/>,
    selector: ".user-tour-data-roots-link",
    position: "bottom-left",
    isFixed: true,
  }, {
    title: <FormattedMessage id="studio.tour.description.settings-title" defaultMessage="Go to settings"/>,
    text: <FormattedMessage id="studio.tour.description.settings"
                            defaultMessage="Or alternatively, you can go to the settings page, which is what we'll be doing in our tour."/>,
    selector: ".user-tour-settings-page-nav",
    position: "bottom-left",
    isFixed: true,
    navigateToOnNext: "/settings/dataroots",
  }, {
    title: <FormattedMessage id="studio.tour.description.settings-2-title" defaultMessage="Go to settings"/>,
    text: <FormattedMessage id="studio.tour.description.settings-2"
                            defaultMessage="This is the settings page, where you can configure various settings of LuciadFusion Studio."/>,
    selector: ".user-tour-settings-page-nav",
    position: "bottom-left",
    isFixed: true,
  }, {
    title: <FormattedMessage id="studio.tour.description.available-data-title"
                             defaultMessage="Data available in LuciadFusion Studio"/>,
    text: <FormattedMessage id="studio.tour.description.available-data"
                            defaultMessage="The {dataroots} page shows the data locations LuciadFusion Studio already knows about and lets you add new ones."
                            values={{
                              dataroots: (
                                  <strong>
                                    <FormattedMessage id="studio.tour.description.data-roots" defaultMessage="Data roots"/>
                                  </strong>
                              ),
                            }}/>,
    selector: ".user-tour-data-roots-list",
    position: "bottom-left",
    isFixed: true,
  }, {
    title: <FormattedMessage id="studio.tour.description.add-data-location-title"
                             defaultMessage="Add the location of your data"/>,
    text: <FormattedMessage id="studio.tour.description.add-data-location"
                            defaultMessage="Click here to tell LuciadFusion Studio where your data is. LuciadFusion Studio adds the location to the data roots list. The paths in this list should be relative to the server."/>,
    selector: ".user-tour-add-data-root-button",
    position: "bottom-left",
    isFixed: true,
  }, {
    title: <FormattedMessage id="studio.tour.description.view-data-title"
                             defaultMessage="Check out your data in LuciadFusion Studio"/>,
    text: <FormattedMessage id="studio.tour.description.view-data"
                            defaultMessage="The data in your data root is automatically added to LuciadFusion Studio. Go to {data} to see it in the list."
                            values={{
                              data: (
                                  <strong>
                                    <FormattedMessage id="studio.tour.description.view-data.data" defaultMessage="Data"/>
                                  </strong>
                              ),
                            }}/>,
    selector: ".user-tour-data-page-nav",
    position: "bottom",
    isFixed: true,
    navigateToOnNext: "/data",
  }, {
    title: <FormattedMessage id="studio.tour.description.view-data-2-title"
                             defaultMessage="Check out your data in LuciadFusion Studio"/>,
    text: <FormattedMessage id="studio.tour.description.view-data-2"
                            defaultMessage="This is the data page, where all your imported data items will appear, ready for you to create new products for your services."/>,
    selector: ".user-tour-data-page-nav",
    position: "bottom-left",
    isFixed: true,
  }, {
    title: <FormattedMessage id="studio.tour.description.data-overview-title" defaultMessage="Data overview"/>,
    text: <FormattedMessage id="studio.tour.description.data-overview"
                            defaultMessage="LuciadFusion Studio analyzes the data in your data root, and lists all data sets it discovered here. Select one data set from the list to publish it."/>,
    selector: ".user-tour-imported-data-list",
    position: "bottom-left",
    isFixed: true,
  }, {
    title: <FormattedMessage id="studio.tour.description.publish-data-title" defaultMessage="Publish your data"/>,
    text: <FormattedMessage id="studio.tour.description.publish-data"
                            defaultMessage="Click here to launch a WMS service with the selected data. You can always remove the service later."/>,
    selector: ".user-tour-create-service-button",
    position: "bottom-right",
    isFixed: true,
  }, {
    title: <FormattedMessage id="studio.tour.description.styles-title"
                             defaultMessage="Want to control how your data looks?"/>,
    text: <FormattedMessage id="studio.tour.description.styles"
                            defaultMessage="Go to the {styles} page to set up a style file for your data"
                            values={{
                              styles: (
                                  <strong>
                                    <FormattedMessage id="studio.tour.description.styles.styles" defaultMessage="Styles"/>
                                  </strong>
                              ),
                            }}/>,
    selector: ".user-tour-styles-page-nav",
    position: "bottom-right",
    isFixed: true,
  },
  {
    title: <FormattedMessage id="studio.tour.description.product-title"
                             defaultMessage="Want to combine the data with other data?"/>,
    text: <FormattedMessage id="studio.tour.description.products"
                            defaultMessage="Go to the {products} page to set up a publication of multiple datasets"
                            values={{
                              products: (
                                  <strong>
                                    <FormattedMessage id="studio.tour.description.products.products" defaultMessage="Products"/>
                                  </strong>
                              ),
                            }}/>,
    selector: ".user-tour-products-page-nav",
    position: "bottom-right",
    isFixed: true,
  },
  {
    title: <FormattedMessage id="studio.tour.description.remove-service-title"
                             defaultMessage="Want to remove the service again?"/>,
    text: <FormattedMessage id="studio.tour.description.remove-service"
                            defaultMessage="Go to the {services} page to manage your services"
                            values={{
                              services: (
                                  <strong>
                                    <FormattedMessage id="studio.tour.description.services" defaultMessage="Services"/>
                                  </strong>
                              ),
                            }}/>,
    selector: ".user-tour-services-page-nav",
    position: "bottom-right",
    isFixed: true,
  },
  {
    title: <FormattedMessage id="studio.tour.description.more-help-title" defaultMessage="Need more help?"/>,
    text: <FormattedMessage id="studio.tour.description.more-help"
                            defaultMessage="If you are still in doubt, feel free to consult our built-in user guide by clicking here."/>,
    selector: ".user-tour-help-page-nav",
    position: "bottom-right",
    isFixed: true,
    navigateToOnNext: "/",
  }, {
    title: <FormattedMessage id="studio.tour.description.thank-you-title" defaultMessage="Thank you"/>,
    text: <FormattedMessage id="studio.tour.description.thank-you"
                            defaultMessage="This concludes our quick tour of LuciadFusion Studio Platform. To run this tour again at any point, feel free to press the {startUserTour} button on this page."
                            values={{
                              startUserTour: (
                                  <strong>
                                    <FormattedMessage
                                        id="studio.tour.description.thank-you.start-user-tour"
                                        defaultMessage="Start User Tour"
                                    />
                                  </strong>
                              ),
                            }}/>,
    selector: ".welcomePage",
    position: "bottom",
    isFixed: true,
  },
];
