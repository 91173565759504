import * as React from "react";
import {Button} from "react-bootstrap";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {LcdIcon} from "../../common/ui/icon/LcdIcon";
import {actions} from "./actions";
import {DataRoot} from "./model";

interface ButtonProps {
  dataRoot: DataRoot;
  bsSize?: string;
}

interface ButtonDispatchProps {
  deleteButtonClicked(): void;
}

export class DataRootDeleteButtonComponent extends React.Component<ButtonProps & ButtonDispatchProps & InjectedIntlProps, {}> {

  render() {
    return (
        <Button className="btn-icon"
                style={{backgroundColor: "transparent", color: "currentColor"}}
                onClick={this.props.deleteButtonClicked}
                disabled={!this.props.dataRoot.canDelete}>
          <LcdIcon icon="delete"/>
        </Button>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteButtonClicked: () => dispatch(actions.deleteDataRootWithWarning(ownProps.dataRoot, ownProps.intl)),
});

export const DataRootDeleteButton = injectIntl(connect(null, mapDispatchToProps)(DataRootDeleteButtonComponent));
