import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {ColumnsDescription, ColumnWidth} from "../common/ui/table/ControlRoomColumns";
import {createBasicTableComponent} from "../common/ui/table/ControlRoomTable";
import {connectPagedTable, PagedTableConfig} from "../common/ui/table/PagedTable";
import {DateUtil} from "../common/util/DateUtil";
import {actions} from "./actions";
import {ImportedData} from "./model";
import {selectors} from "./selectors";

const config: PagedTableConfig = {
  canMultiSelect: true,
  noItemsFoundMessage: <FormattedMessage id="studio.data.data-list-page.no-items" defaultMessage="No data found."/>,
};

const columns: ColumnsDescription<ImportedData> = {
  name: {
    header: <FormattedMessage id="studio.data.data-list-page.title" defaultMessage="Title"/>,
    cellContent: (importedData) => <Link to={`/data/${encodeURIComponent(importedData.id)}`}>{importedData.title}</Link>,
    cellTooltip: (importedData) => importedData.title,
    columnWidth: ColumnWidth.flex(2),
  },
  path: {
    header: <FormattedMessage id="studio.data.data-list-page.path" defaultMessage="Path"/>,
    cellContent: (importedData) => importedData.filePath,
    cellTooltip: (importedData) => importedData.filePath,
    columnWidth: ColumnWidth.flex(5),
  },
  dataFormat: {
    header: <FormattedMessage id="studio.data.data-list-page.format" defaultMessage="Format"/>,
    cellContent: (importedData) => importedData.type,
    cellTooltip: (importedData) => importedData.type,
    columnWidth: ColumnWidth.pixels(120),
  },
  creationTime: {
    header: <FormattedMessage id="studio.data.data-list-page.date-created" defaultMessage="Date Created"/>,
    cellContent: (importedData) => DateUtil.formatDateTime(importedData.creationTime),
    cellTooltip: (importedData) => DateUtil.formatDateTime(importedData.creationTime),
    columnWidth: ColumnWidth.DATETIME_COLUMN_WIDTH,
  },
};

export const ImportedDataListComponent = createBasicTableComponent(columns);
export const ImportedDataList = connectPagedTable(ImportedDataListComponent, selectors.getPagedState,
    actions.requestPage, config);
