import {selectors} from "./selectors";
import {Address, DefaultMetadata, ResponsibleParty} from "./model";

export const actionTypes = {
  DEFAULT_METADATA_LOADED: "controlRoom/DEFAULT_METADATA_LOADED",
  DEFAULT_METADATA_FAILED: "controlRoom/DEFAULT_METADATA_FAILED",
};

/**
 * Loads the default metadata from the Fusion Studio REST APU. The default metadata is used to fill in default values in forms.
 */
export const loadDefaultMetadata = () => {
  return (dispatch, getState, {getApi}) => {
    const state = getState();
    const defaultMetadataFromState: DefaultMetadata = {... selectors.getDefaultMetadata(state)};
    const api = getApi(state);

    if(defaultMetadataFromState && defaultMetadataFromState.loadedSuccessfully === true) {
      return Promise.resolve(defaultMetadataFromState);
    } else {
      return api.getDefaultMetadata("defaultMetadata").then((defaultMetadata) => {
        if (!defaultMetadata) {
          defaultMetadata = {} as DefaultMetadata;
        }

        if (!defaultMetadata.responsibleParty) {
          defaultMetadata.responsibleParty = {} as ResponsibleParty;
        }

        if (!defaultMetadata.responsibleParty.address) {
          defaultMetadata.responsibleParty.address = {} as Address;
        }

        if (!defaultMetadata.responsibleParty.email) {
          defaultMetadata.responsibleParty.email = [];
        }

        defaultMetadata.loadedSuccessfully = true;

        dispatch({
          type: actionTypes.DEFAULT_METADATA_LOADED,
          payload: defaultMetadata,
        });
        return defaultMetadata;
      }).catch((e) => {
        dispatch({
          type: actionTypes.DEFAULT_METADATA_FAILED,
          payload: defaultMetadataFromState,
        });
        return defaultMetadataFromState;
      });
    }
  };
};

export const actions = {
  loadDefaultMetadata,
};
