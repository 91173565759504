import {getLoadedData} from "../../common/util/asyncdata/reducerUtil";

export const moduleName = "contentRoots";

const getContentRootState = (state) => state[moduleName];

const getRequestState = (state) => {
  return getContentRootState(state);
  /* state == request state */
};

const getContentRoots = (state) => {
  const requestState = getRequestState(state);
  return getLoadedData(requestState);
};

export const selectors = {
  getContentRoots,
  getRequestState,
};
