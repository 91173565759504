import * as React from "react";
import {Sizes} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {CompactStartStopButton} from "../common/ui/CompactStartStopButton";
import {StartStopButton} from "../common/ui/StartStopButton";
import {actions} from "./import/actions";
import {CompositeJob, JobStatus} from "./model";

interface JobStartButtonStatProperties {
  job: CompositeJob;
}

interface JobStartStopButtonDispatchProperties {
  startJob: (id: string) => void;
  stopJob: (id: string) => void;
}

interface JobStartStopButtonOtherProps {
  bsSize?: Sizes;
  compact?: boolean;
}

type JobStartStopButtonProperties = JobStartButtonStatProperties
    & JobStartStopButtonDispatchProperties
    & JobStartStopButtonOtherProps;

export class JobStartStopButtonComponent extends React.Component<JobStartStopButtonProperties, {}> {

  _handleButtonClick = (startButtonClicked: boolean) => {
    const {job, startJob, stopJob} = this.props;
    if (startButtonClicked) {
      startJob(job.id);
    } else {
      stopJob(job.id);
    }
  }

  render() {
    const {job, compact, bsSize} = this.props;
    const showStartButton = job.jobState !== JobStatus.RUNNING && job.jobState !== JobStatus.QUEUED;
    const label = showStartButton ?
                  <FormattedMessage id="studio.jobs.job-start-stop-button.start" defaultMessage=" Start job"/> :
                  <FormattedMessage id="studio.jobs.job-start-stop-button.stop" defaultMessage=" Stop job"/>;

    if (compact) {
      return (
          <CompactStartStopButton
              showStartButton={showStartButton}
              onClick={this._handleButtonClick}
              bsSize={bsSize}
          />
      );
    }

    return (
        <StartStopButton
            showStartButton={showStartButton}
            label={label}
            onClick={this._handleButtonClick}
            bsSize={bsSize}
        />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    job: ownProps.job,
  };
};

const mapDispatchToProps = (dispatch): JobStartStopButtonDispatchProperties => {
  return {
    startJob: (id) => {
      dispatch(actions.queueJob(id));
    },
    stopJob: (id) => {
      dispatch(actions.stopJob(id));
    },
  };
};

export const JobStartStopButton = connect(mapStateToProps, mapDispatchToProps)(JobStartStopButtonComponent);
