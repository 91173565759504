import * as React from "react";
import {Col, Grid} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

export class BackendOfflinePage extends React.Component<{}, {}> {

  render() {
    return (
        <Grid>
          <Col xs={8} xsOffset={2} md={6} mdOffset={3}>
            <h1><FormattedMessage
                id="studio.backend-offline.header"
                defaultMessage="Backend Offline"
            />
            </h1>
            <p><FormattedMessage
                id="studio.backend-offline.message"
                defaultMessage="The Backend could not be reached."
            />
            </p>
          </Col>
        </Grid>
    );
  }
}
