import {ServiceFilter} from "./model";

export const actionTypes = {
  SET_SERVICE_FILTER: "controlRoom/SET_SERVICE_FILTER",
};

const setFilter = (newFilter: ServiceFilter) => {
  return {
    type: actionTypes.SET_SERVICE_FILTER,
    payload: newFilter,
  };
};

export const actions = {
  setFilter,
};
