import * as React from "react";
import {Sizes} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {CompactStartStopButton} from "../common/ui/CompactStartStopButton";
import {StartStopButton} from "../common/ui/StartStopButton";
import {actions} from "./actions";
import {Service, ServiceStatus} from "./model";

const START_STOP_MESSAGES = defineMessages({
  start: {
    id: "studio.services.service-start-stop.start", defaultMessage: "Start Service",
  },
  stop: {
    id: "studio.services.service-start-stop.stop", defaultMessage: "Stop Service",
  },
});

interface ServiceStartStopButtonStateProperties {
  service: Service;
}

interface ServiceStartStopButtonDispatchProperties {
  startService: (id: string) => void;
  stopService: (id: string) => void;
}

interface ServiceStartStopButtonOtherProps {
  bsSize?: Sizes;
  compact?: boolean;
}

type ServiceStartStopButtonProperties = ServiceStartStopButtonStateProperties
    & ServiceStartStopButtonDispatchProperties
    & ServiceStartStopButtonOtherProps;

export class ServiceStartStopButtonReactComponent extends React.Component<ServiceStartStopButtonProperties & InjectedIntlProps, {}> {

  _handleButtonClick = (startButtonClicked: boolean) => {
    const {service, startService, stopService} = this.props;
    if (startButtonClicked) {
      startService(service.id);
    } else {
      stopService(service.id);
    }
  }

  render() {
    const {service, compact, bsSize, intl} = this.props;
    if (service.status !== ServiceStatus.STOPPED && service.status !== ServiceStatus.PENDING && service.status !==
        ServiceStatus.RUNNING) {
      return <span>
        <FormattedMessage id="studio.services.service-start-stop.unknown-service-status"
                          defaultMessage="Unknown service status: {status}"
                          values={{status: service.status}}/>
        </span>;
    }

    const startMessage = intl.formatMessage(START_STOP_MESSAGES.start);
    const stopMessage = intl.formatMessage(START_STOP_MESSAGES.stop);

    const showStartButton = service.status === ServiceStatus.STOPPED;
    const label = showStartButton ? startMessage : stopMessage;

    if (compact) {
      return (
          <CompactStartStopButton
              showStartButton={showStartButton}
              onClick={this._handleButtonClick}
              bsSize={bsSize}
          />
      );
    }

    return (
        <StartStopButton
            showStartButton={showStartButton}
            label={label}
            onClick={this._handleButtonClick}
            bsSize={bsSize}
        />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    service: ownProps.service,
  };
};

const mapDispatchToProps = (dispatch): ServiceStartStopButtonDispatchProperties => {
  return {
    startService: (id) => {
      dispatch(actions.startService(id));
    },
    stopService: (id) => {
      dispatch(actions.stopService(id));
    },
  };
};

export const ServiceStartStopButtonComponent = injectIntl(ServiceStartStopButtonReactComponent);
export const ServiceStartStopButton = connect(mapStateToProps, mapDispatchToProps)(ServiceStartStopButtonComponent);
