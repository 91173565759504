import {combineReducers} from "redux";
import {createPagedDataReducer, PagedDataState} from "../common/util/pagination/PaginationUtil";
import {actionTypes, IMPORTED_DATA_ACTION_TYPE_NAME} from "./actions";
import {ImportedData, ImportedDataSearchFilter} from "./model";
import {moduleName} from "./selectors";

type SearchDataTypes = {value: string, label: string}[];
type FilterState = ImportedDataSearchFilter;
interface ReactorUpdateState {numberOfNewDataItems: number; }

export interface ImportedDataState {
  filter: FilterState;
  allDataTypes: SearchDataTypes;
  reactorDataUpdates: ReactorUpdateState;
  pages: PagedDataState<ImportedData>;
}

const initialReactorUpdateState: ReactorUpdateState = {
  numberOfNewDataItems: 0,
};

const reactorUpdateReducer = (state: ReactorUpdateState = initialReactorUpdateState, action) => {
  switch (action.type) {
  case actionTypes.DATA_ADDED_ON_SERVER:
    return Object.assign({}, state, {numberOfNewDataItems: state.numberOfNewDataItems + 1});
  case actionTypes.CLEAR_DATA: //reset counter when we do a new request
    return Object.assign({}, state, initialReactorUpdateState);
  default:
    return state;
  }
};

const allDataTypeReducer = (state: SearchDataTypes = [], action) => {
  switch (action.type) {
  case actionTypes.LOAD_DATA_TYPES:
    return [...action.payload.dataTypes];
  default:
    return state;
  }
};

const pagedDataReducer = createPagedDataReducer<ImportedData>(IMPORTED_DATA_ACTION_TYPE_NAME);

const basePagedDataReducer = createPagedDataReducer<ImportedData>(IMPORTED_DATA_ACTION_TYPE_NAME);
const defaultState = basePagedDataReducer(undefined, {type: "init"}) as PagedDataState<ImportedData>;
//extend the existing pagedDataReducer so that it also handles UPDATE_DATA actions
const extendedPagedProductReducer = (state: PagedDataState<ImportedData> = defaultState, action) => {
  switch (action.type) {
  case actionTypes.UPDATE_DATA:
    const oldData = state.data;
    const newData = Object.assign({}, oldData, {[action.payload.data.id]: action.payload.data});
    return Object.assign({}, state, {data: newData});
  }
  return basePagedDataReducer(state, action);
};

function filterReducer(state: FilterState = {}, action) {
  switch (action.type) {
  case actionTypes.SET_DATA_FILTER:
    return action.payload;
  }
  return state;
}

const reducer = combineReducers<ImportedDataState>({
  filter: filterReducer,
  reactorDataUpdates: reactorUpdateReducer,
  allDataTypes: allDataTypeReducer,
  pages: extendedPagedProductReducer,
});

const createTestState = () => {
  return {
    [moduleName]: reducer(undefined, {type: "init"}),
  };
};

export {
  moduleName,
  reducer,
  createTestState,
};
