export interface FileUploadStatus {
  success: boolean;
  message: string;
  fileName: string;
}

export interface DirectoryItem {
  fileName: string;
  absolutePath: string;
  directory: boolean;
  regularFile: boolean;
  symlink: boolean;
  other: boolean;
  readable: boolean;
  fileSize: number;
  humanReadableFileSize: string;
  creationDate: string;
  modifiedDate: string;
}

export interface Identifiable {
  id: string;
}

export enum EntityType {
  TYPE_DATA,
  TYPE_STYLE,
  TYPE_PRODUCT,
  TYPE_SERVICE,
  TYPE_FILE_INFO,
}

export interface MetadataModel {
  title: string;
  abstractText?: string;
  keywords?: string[];
}

export interface EntityModel extends Identifiable, MetadataModel {
  name?: string;
  sequenceNumber?: number;
}

export interface SpatialModel extends EntityModel {
  wgs84Bounds?: Bounds;
}

export interface Bounds {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ConfigurationInfo {
  securityInfo: {
    isMappEnterprise: boolean;
    mappEnterpriseSecurityInfo: {
      authServiceUrl: string;
      clientId: string;
    };
  };
  missingMappEnterpriseInfo: boolean;
  invalidMappEnterpriseInfo: boolean;
}