import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {
  ControlRoomTableDispatchProperties,
  ControlRoomTableStateProperties,
  createBasicTableComponent,
} from "../../common/ui/table/ControlRoomTable";
import {isFinished} from "../../common/util/asyncdata/reducerUtil";
import {jobContentListDimensions} from "../import/JobsList";
import {CompositeJob} from "../model";
import {preprocessJobSubmoduleName, selectors} from "../selectors";
import {actions} from "./actions";
import {PreprocessJobColumns} from "./PreprocessJobColumns";

export const PreprocessJobsListComponent = createBasicTableComponent(PreprocessJobColumns, jobContentListDimensions);

const mapStateToProps = (state, ownProps): ControlRoomTableStateProperties<CompositeJob> => {
  return {
    data: selectors.getAllJobs(preprocessJobSubmoduleName, state),
    isPaged: false,
    isAllDataLoaded: isFinished(selectors.getJobRequestState(preprocessJobSubmoduleName, state)),
    noItemsFoundMessage: <FormattedMessage id="studio.jobs.preprocess-jobs-list.none-found"
                                           defaultMessage="No preprocess jobs found."/>,
  };
};

const mapDispatchToProps = (dispatch): ControlRoomTableDispatchProperties<CompositeJob> => {
  return {
    loadData: () => {
      dispatch(actions.loadJobs());
    },
  };
};

export const PreprocessJobList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(PreprocessJobsListComponent));
