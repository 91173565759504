import {defineMessages} from "react-intl";
import {executeIfConfirmed, registerWarningAction} from "../../common/ui/warnings/Warnings";
import {createAsyncActionTypes, createLoadDataActionCreator} from "../../common/util/asyncdata/actionUtil";
import {DataRoot} from "./model";
import {selectors} from "./selectors";

export type GET_DATA_ROOTS_PREFIX = "controlRoom/GET_DATA_ROOTS";
export const GET_DATA_ROOTS_PREFIX: GET_DATA_ROOTS_PREFIX = "controlRoom/GET_DATA_ROOTS";

const asyncActionTypes = createAsyncActionTypes(GET_DATA_ROOTS_PREFIX);

export const actionTypes = {
  LOAD_DATA_ROOT_STARTED: asyncActionTypes.STARTED,
  LOAD_DATA_ROOT_SUCCESS: asyncActionTypes.SUCCESS,
  LOAD_DATA_ROOT_ERROR: asyncActionTypes.ERROR,
  LOAD_DATA_ROOT_RESET: asyncActionTypes.RESET,
};

const loadDataRoots = createLoadDataActionCreator<DataRoot[]>(
    GET_DATA_ROOTS_PREFIX,
    (api) => api.getDataRoots,
    selectors.getContentRoots,
);

// Effectively removing data root will result in reloading the whole list of roots
const deleteDataRoot = (dataRootId: number) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).deleteContentRoot(dataRootId).then(() => dispatch(actions.loadDataRoots()));
  };
};

const REMOVE_DATA_ROOT_WARNING_ID = "deleteDataRoot";
registerWarningAction(REMOVE_DATA_ROOT_WARNING_ID, deleteDataRoot);

const DATA_ROOT_MESSAGES = defineMessages({
  removeRootHeader: {
    id: "studio.settings.dataroots.remove-root-header",
    defaultMessage: "Removing data root: {path}",
  },
  removeRootBody: {
    id: "studio.settings.dataroots.remove-root-body",
    defaultMessage: "Are you sure you want to continue?",
  },
  whileRemoveRoot: {
    id: "studio.settings.dataroots.while-remove-root",
    defaultMessage: "Deleting data root...",
  },
  forceButton: {
    id: "studio.settings.dataroots.forceButton",
    defaultMessage: "Remove Data Root",
  },
  removeWarning1: {
    id: "studio.settings.dataroots.remove-warning-1",
    defaultMessage: "All imported data and style files will be removed",
  },
  removeWarning2: {
    id: "studio.settings.dataroots.remove-warning-2",
    defaultMessage: "This action may affect existing products, and services",
  },
});

export const deleteDataRootWithWarning =
    (dataRoot: DataRoot, intl: any) =>
        (dispatch) => dispatch(executeIfConfirmed(
            Promise.resolve([intl.formatMessage(DATA_ROOT_MESSAGES.removeWarning1),
                             intl.formatMessage(DATA_ROOT_MESSAGES.removeWarning2)]),
            {
              headerText: intl.formatMessage(DATA_ROOT_MESSAGES.removeRootHeader, {path: dataRoot.rootPath}),
              bodyText: intl.formatMessage(DATA_ROOT_MESSAGES.removeRootBody),
              whileExecutingText: intl.formatMessage(DATA_ROOT_MESSAGES.whileRemoveRoot),
              forceActionCreatorId: REMOVE_DATA_ROOT_WARNING_ID,
              forceActionParams: [dataRoot.id],
              forceButtonText: intl.formatMessage(DATA_ROOT_MESSAGES.forceButton),
              forceButtonIcon: "delete",
            },
        ));

export const actions = {
  loadDataRoots,
  deleteDataRoot,
  deleteDataRootWithWarning,
};
