import {warningCreator} from "./actions";
import {WarningsModalMeta} from "./ModalWarnings";

// We need store references to action creators (functions) in the REDUX store, in order to be able to execute them
// in when user resumes the action in through the Warning UI.
// This definition identifies action functions by their names, as they are defined in the 'actions' files.
let registeredActions: {[actionID: string]: (...args: any[]) => any};

export function registerWarningAction(actionId: string, action: (...args: any[]) => any) {
  if (!registeredActions) {
    registeredActions = {};
  }
  if (!registeredActions[actionId]) {
    registeredActions[actionId] = action;
  }
}

const fetchAction = (actionId) => registeredActions[actionId];

/**
 * Reconstruct action function
 * @param byId
 * @param params
 * @return {any}
 */
export const executeById = (byId, params) => {
  const actionCreator = fetchAction(byId);
  return actionCreator(...params);
};

type WarningResultsPromise = Promise<string[]>;

/**
 * Conditional Action generator.
 * When the ConditionResults yields a promise without warning messages then the mainAction gets executed.
 * Otherwise the ModalWarnings UI is opened where the user can decide whether to force the action or not.
 *
 * @param warningResults a promise with warning messages
 * @param mainAction an action generator that is executed when the condition is met
 * @param modalMeta an object containing properties for Modal Warning component
 * @return {Function} thunk action that encapsulates the conditional chain logic.
 */
export function executeIfNoWarnings(warningResults: WarningResultsPromise,
                                    mainAction,
                                    modalMeta: WarningsModalMeta) {
  return (dispatch) => warningResults.then(
      (warningMessages = []) => {
        // If there are warnings then show the WarningModal, otherwise execute the main action
        return warningMessages.length ?
               dispatch(warningCreator(warningMessages, modalMeta)) :
               dispatch(mainAction);
      },
  );
}

/**
 * Execute action only when user confirms it.
 *
 * @param warningResults a promise with warning messages
 * @param modalMeta an object containing properties for Modal Warning component
 * @return {Function} thunk action that opens confirmation modal with static warnings.
 */
export function executeIfConfirmed(warningResults: WarningResultsPromise,
                                   modalMeta: WarningsModalMeta) {
  return (dispatch) => warningResults.then(
      (warningMessages = []) => dispatch(warningCreator(warningMessages, modalMeta)),
  );
}
