//selectors are separate here, in order to break cyclic dependency cycle between actions and reducer
import {PagedDataState, selectors as paginationSelectors} from "../common/util/pagination/PaginationUtil";
import {Style} from "./model";

export const moduleName = "styles";
const getStylesState = (state) => state[moduleName];

const getFilter = (state) => {
  const stylesState = getStylesState(state);
  return stylesState.filter;
};

const getPagedState = (state): PagedDataState<Style> => {
  return getStylesState(state).pages;
};

const getStyleById = (state, id: string): Style => {
  const pagedStyleState = getPagedState(state);
  return paginationSelectors.getDataById(pagedStyleState, id);
};

const getNumberOfNewStylesOnServer = (state): number => {
  const stylesState = getStylesState(state);
  return stylesState.styleUpdates.numberOfStylesAddedOnServer;
};

export const selectors = {
  getFilter,
  getPagedState,
  getStyleById,
  getNumberOfNewStylesOnServer,
};
