import * as React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {ReactorUpdateClient} from "../../../liveupdate/ReactorUpdateClient";
import {NavigationBar} from "../Navbar";
import {NotificationBar} from "../notification/NotificationBar";
import {MainUserTour} from "../tour/MainUserTour";
import {MAIN_TOUR_STEPS} from "../tour/MainUserTourSteps";
import {StyleDataTour} from "../tour/StyleDataTour";
import {STYLE_DATA_TOUR_STEPS} from "../tour/StyleDataTourSteps";
import {ModalWarnings} from "../warnings/ModalWarnings";

export class AppLayout extends React.Component {

  render() {
    return (
        <div className="app">
          <MainUserTour appLayoutRef={this} allTourSteps={MAIN_TOUR_STEPS} />
          <StyleDataTour appLayoutRef={this} allTourSteps={STYLE_DATA_TOUR_STEPS} />
          <ReactorUpdateClient />
          <NavigationBar isAuthenticated={true}/>
          <NotificationBar />
          <ModalWarnings />
          <Grid fluid={true} className="mainContent">
            <Row className="mainContentRow">
              <Col xs={12} className="mainContentCol">
                {this.props.children}
              </Col>
            </Row>
          </Grid>
        </div>
    );
  }
}
