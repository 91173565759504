import {createCartesianReference} from "@luciad/ria/reference/ReferenceProvider";
import {registerConversionUnit, registerStandardUnit, getUnitOfMeasure} from "@luciad/ria/uom/UnitOfMeasureRegistry";

const milliSecondUom = registerConversionUnit("milliseconds", "ms", "Second", 1e-3, 0);

registerStandardUnit("Bytes", "B", "AmountOfSubstance");
const kiloBytesUom = registerConversionUnit("KiloBytes", "kB", "Bytes", 1e3, 0);
const megaBytesUom = registerConversionUnit("MegaBytes", "MB", "Bytes", 1e6, 0);

const MILLISECONDS_KILOBYTES_REFERENCE = createCartesianReference({
  xUnitOfMeasure: milliSecondUom,
  yUnitOfMeasure: kiloBytesUom,
});

const SECONDS_MEGABYTES_REFERENCE = createCartesianReference({
  xUnitOfMeasure: getUnitOfMeasure("Second"),
  yUnitOfMeasure: megaBytesUom,
});

export const ChartReferences = {
  MILLISECONDS_KILOBYTES_REFERENCE,
  SECONDS_MEGABYTES_REFERENCE,
};
