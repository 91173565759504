import * as React from "react";
import {Typeahead} from "../common/ui/typeahead/Typeahead";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {Product, ProductFilter} from "./model";
import {InjectedIntlProps, injectIntl} from "react-intl";

interface ProductTypeaheadProps {
  value: Product;
  onChange: (newValue: Product) => void;
  acceptSuggestion?: (suggestion: Product) => boolean;
  placeholder?: string;
  productFilter: ProductFilter;
}

export const MAX_RESULTS = 10;
export const AbstractProductTypeahead = Typeahead<Product>();

export class ProductTypeaheadComponent extends React.Component<ProductTypeaheadProps & WithApiProperties & InjectedIntlProps, {}> {

  fetchSuggestions = (value: string) => {
    const productFilter = Object.assign({}, this.props.productFilter, {anyText: value + "*"});

    return this.props.api.loadProducts(productFilter)
        .then((data) => {
              const {acceptSuggestion} = this.props;
              const acceptedData = acceptSuggestion ? data.filter(acceptSuggestion) : data;
              return acceptedData.slice(0, MAX_RESULTS);
            },
        );
  }

  renderSuggestion = (entityData, shortForm = false) => {
    return entityData.title;
  }

  render() {
    return (
        <AbstractProductTypeahead
            value={this.props.value}
            fetchSuggestions={this.fetchSuggestions}
            renderSuggestion={this.renderSuggestion}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            intl={this.props.intl}
        />
    );
  }
}

export const ProductTypeahead = injectIntl(WithApi(ProductTypeaheadComponent));
