import * as React from "react";
import {ReactNode} from "react";

interface DetailHeaderProps {
  title?: ReactNode;
  buttons?: ReactNode[];
}

export class DetailHeader extends React.Component<DetailHeaderProps, {}> {
  render() {
    const {title, buttons} = this.props;
    return (
        <div className="detail-header">
          <h3>{title}</h3>
          {buttons}
        </div>
    );
  }
}
