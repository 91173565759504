import * as React from "react";
import {CSSProperties} from "react";

interface LcdIconProps {
  icon: string;
}

const ICONS_PATH = "theme/assets/icons/";
const ICONS_EXTENSION = ".svg";

interface LcdIconProps {
  icon: string;
  className?: string;
  style?: CSSProperties;
}

export class LcdIcon extends React.Component<LcdIconProps, {}> {

  render() {
    const {icon, className, style} = this.props;

    const iconURL = ICONS_PATH + icon + ICONS_EXTENSION;
    const iconStyle = {
      ...style,
      maskImage: "url('" + iconURL + "')",
      WebkitMaskImage: "url('" + iconURL + "')",
    };

    let iconClasses = "lcdIcon";

    if (className) {
      iconClasses += " " + className;
    }

    return (
        <span className={iconClasses} style={iconStyle} />
    );
  }
}
