import {DefaultMetadata} from "./model";
import {actionTypes} from "./actions";
import {combineReducers} from "redux";
import {moduleName} from "./selectors";

export interface DefaultMetadataState {
  defaultMetadata: DefaultMetadata;
}

const defaultMetadataReducer = (state: DefaultMetadata = null, action) => {
  switch (action.type) {
  case actionTypes.DEFAULT_METADATA_LOADED:
    return Object.assign({}, state, {...action.payload, loadedSuccessfully: true});
  case actionTypes.DEFAULT_METADATA_FAILED:
    return Object.assign({}, state, {...action.payload, loadedSuccessfully: false});
  case "init":
    return state ? state : {responsibleParty: {address: {}}, loadedSuccessfully: false};
  }
  return state;
}

const reducer = combineReducers<DefaultMetadataState>({
  defaultMetadata: defaultMetadataReducer,
});

export {
  moduleName,
  reducer,
};
