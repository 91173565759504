import * as React from "react";
import {ControlLabel, Form, FormControl, FormGroup, HelpBlock, Radio} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {Logger} from "../../util/Logger";
import {debounce} from "../../util/PerformanceUtil";
import {CronValidationResult} from "./CronUtil";
import {SimpleCronPicker, SimpleCronPickerComponent} from "./SimpleCronPicker";

/**
 * Combines a SimpleCronPicker together with an "Advanced" one, which is just a text field with some validation.
 */

interface CombinedCronPickerProperties {
  mode?: Mode;
  value: string;
  onChange: (newValue: string) => void;
  validate: (valueToValidate: string) => Promise<CronValidationResult>;
  onModeSwitch?: (newMode: Mode) => void;
}

export type Mode = "simple" | "advanced" | "notset";

interface CombinedCronPickerState {
  advancedValue: string;
  advancedIsValidating: boolean;
  advancedValidationResult: CronValidationResult;
}

let idCounter = 0;

const VALIDATION_DEBOUNCE_TIME = 1000; //time to wait before firing a cron string validation request to the API

export class CombinedCronPicker extends React.Component<CombinedCronPickerProperties, CombinedCronPickerState> {

  static canUseSimpleMode(cronString: string) {
    return SimpleCronPickerComponent.canHandleCronString(cronString || "0 * * * * *").supported;
  }

  _logger: Logger = Logger.getLogger("common.ui.cron.CombinedCronPicker");

  _modeToggleControlId: string;
  _simpleModeControlId: string;
  _advancedModeControlId: string;
  debouncedFireChangeEvent: (mode: Mode, newValue: string) => void;

  constructor(props) {
    super(props);
    const cronString = props.value;
    this.debouncedFireChangeEvent = debounce(this.fireChangeEvent, VALIDATION_DEBOUNCE_TIME);
    this.state = {
      advancedValue: cronString || "",
      advancedIsValidating: false,
      advancedValidationResult: {valid: true},
    };
  }

  UNSAFE_componentWillMount() {
    this._modeToggleControlId = "combined-cron-picker-toggle-" + idCounter;
    this._simpleModeControlId = "combined-cron-picker-simple-mode-" + idCounter;
    this._advancedModeControlId = "combined-cron-picker-advanced-mode-" + idCounter;
    idCounter++;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({...this.state, advancedValue: nextProps.value});
    }
  }

  renderSimpleMode() {
    return (
        <FormGroup controlId={this._simpleModeControlId}>
          <FormControl componentClass={SimpleCronPicker} value={this.props.value || "0 * * * * *"} onChange={(newValue) => {
              this.fireChangeEvent("simple", newValue as any as string);
        }}/>
        </FormGroup>
    );
  }

  fireChangeEvent = (mode: Mode, newValue: string) => {
    if (mode === "notset") {
      this.props.onChange(null);
    }
    if (mode === "simple") {
      this.props.onChange(newValue);
    } else if (mode === "advanced") {
      this.performAdvancedValidation().then(() => {
        if (this.state.advancedValidationResult.valid) {
          this.props.onChange(newValue);
        }
      }).catch((error) => {
        this._logger.error("Error occured while validating", error);
      });
    }
  }

  performAdvancedValidation = () => {
    this.startAdvancedValidation();
    return this.props.validate(this.state.advancedValue)
        .then((validationResult) => {
          return new Promise<void>((resolve) => {
            this.setState(Object.assign({}, this.state, {advancedValidationResult: validationResult}), resolve);
          });
        })
        .then(this.endAdvancedValidation)
        .catch(this.endAdvancedValidation);
  }

  startAdvancedValidation = () => {
    this.setState(Object.assign({}, this.state, {advancedIsValidating: true}));
  }

  endAdvancedValidation = () => {
    this.setState(Object.assign({}, this.state, {advancedIsValidating: false}));
  }

  renderAdvancedMode() {
    const {advancedValue, advancedValidationResult, advancedIsValidating} = this.state;
    let validationState, help;
    if (advancedIsValidating) {
      validationState = null;
      help = <FormattedMessage id="studio.UI.cron.combined-cron-picker.validating"
                               defaultMessage="Validating Cron string..."/>;
    } else {
      validationState = advancedValidationResult.valid ? "success" : "error";
      help = advancedValidationResult.warning ||
             <FormattedMessage id="studio.UI.cron.combined-cron-picker.valid" defaultMessage="Valid Cron string"/>;
    }
    return (
        <FormGroup controlId={this._simpleModeControlId} validationState={validationState}>
          <ControlLabel><FormattedMessage id="studio.UI.cron.combined-cron-picker.enter-cron-string"
                                          defaultMessage="Enter a Cron string:"/></ControlLabel>
          <FormControl type="text" value={advancedValue || ""} onChange={(event) => {
            const newValue = (event.target as any).value;
            const newState = {advancedValue: newValue};
            this.setState(Object.assign({}, this.state, newState), () => {
              this.debouncedFireChangeEvent("advanced", this.state.advancedValue);
            });
          }}/>
          <FormControl.Feedback />
          <HelpBlock>{help}</HelpBlock>
        </FormGroup>
    );
  }

  switchMode = (newMode: Mode) => {
    if (this.props.onModeSwitch) {
      this.props.onModeSwitch(newMode);
    }
  }

  render() {
    const {mode} = this.props;
    const canUseSimpleMode = CombinedCronPicker.canUseSimpleMode(this.props.value);
    return (
        <Form onSubmit={() => {event.preventDefault(); }}>
          <FormGroup>
            <Radio inline checked={mode === "notset"} className="notsetModeRadio" onChange={(event) => {
              const notset = (event.target as any).checked;
              if (notset) { this.switchMode("notset"); }
            }}>
              <FormattedMessage id="studio.UI.cron.combined-cron-picker.not-set" defaultMessage="Not set"/>
            </Radio>
            <Radio inline
                   disabled={!canUseSimpleMode}
                   title={!canUseSimpleMode ?
                          <FormattedMessage id="studio.UI.cron.combined-cron-picker.simple-picker-error"
                                            defaultMessage="Cannot use simple picker for cron string "/> +
                          this.props.value : null}
                   checked={mode === "simple"}
                   className="simpleModeRadio"
                   onChange={(event) => {
              const simpleMode = (event.target as any).checked;
              if (simpleMode) { this.switchMode("simple"); }
            }}>
              <FormattedMessage id="studio.UI.cron.combined-cron-picker.simple" defaultMessage="Simple"/>
            </Radio>
            <Radio inline checked={mode === "advanced"} className="advancedModeRadio" onChange={(event) => {
              const advancedMode = (event.target as any).checked;
              if (advancedMode) { this.switchMode("advanced"); }
            }}>
              <FormattedMessage id="studio.UI.cron.combined-cron-picker.advanced" defaultMessage="Advanced"/>
            </Radio>
          </FormGroup>
          {mode === "simple" ? this.renderSimpleMode() : null}
          {mode === "advanced" ? this.renderAdvancedMode() : null}
        </Form>
    );
  }
}
