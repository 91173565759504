import * as React from "react";

interface SpinnerProps {
  bigStyle?: boolean;
}
export class Spinner extends React.Component<SpinnerProps, {}> {

  render() {
    const className = this.props.bigStyle ? "spinner-big" : "spinner";
    return <div className={className}></div>;
  }

}
