import * as React from "react";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {createAddButtonComponent} from "../common/ui/AddButton";
import {actions} from "./actions";
import {Style} from "./model";

const AddButtonComponent = createAddButtonComponent<Style>();

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAdd: (filePath: string) => {
    return dispatch(actions.addStyle(filePath));
  },
});

interface AddStyleButtonDispatchProps {
  onAdd: (filePath: string) => Promise<Style>;
}

interface AddStyleButtonProps {
  onAddSuccess: () => void;
}

const STYLE_MESSAGES = defineMessages({
  buttonTitle: {
    id: "studio.styles.add-style-button.button-title",
    defaultMessage: "Add Style",
  },
  chooseFile: {
    id: "studio.styles.add-style-button.choose-file",
    defaultMessage: "Choose File",
  },
  selectStyle: {
    id: "studio.styles.add-style-button.select-data",
    defaultMessage: "Select Style",
  },
  helperText: {
    id: "studio.styles.add-style-button.helper-text",
    defaultMessage: "Enter the file path for the style you want to add, e.g Data/SLD/world.xml",
  },
  executingMessage: {
    id: "studio.styles.add-style-button.executing-message",
    defaultMessage: "Adding style...",
  },
});

class AddStyleButtonComponent extends React.Component<AddStyleButtonProps & InjectedIntlProps & AddStyleButtonDispatchProps> {
  render() {
    const {intl, onAdd, onAddSuccess} = this.props;
    return <AddButtonComponent
        buttonTitle={intl.formatMessage(STYLE_MESSAGES.buttonTitle)}
        confirmButtonTitle={intl.formatMessage(STYLE_MESSAGES.buttonTitle)}
        modalTitle={intl.formatMessage(STYLE_MESSAGES.buttonTitle)}
        chooseFileTitle={intl.formatMessage(STYLE_MESSAGES.selectStyle)}
        chooseFileButtonTitle={intl.formatMessage(STYLE_MESSAGES.chooseFile)}
        addHelperText={intl.formatMessage(STYLE_MESSAGES.helperText)}
        executingMessage={intl.formatMessage(STYLE_MESSAGES.executingMessage)}
        onAdd={onAdd}
        onAddSuccess={onAddSuccess}
        intl={intl}/>;
  }
}

export const AddStyleButton = injectIntl(connect(null, mapDispatchToProps)(AddStyleButtonComponent));
