import {AxiosError} from "axios";
import * as React from "react";
import ProgressBar = require("react-bootstrap/lib/ProgressBar");
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {ActionBar} from "../../common/ui/actionbar/ActionBar";
import {DetailHeader} from "../../common/ui/detailsection/DetailHeader";
import {createCommonMetadataFields, DetailView, FieldInfo, Formats} from "../../common/ui/detailsection/DetailView";
import {SectionHeader} from "../../common/ui/detailsection/SectionHeader";
import {ErrorDisplay} from "../../common/ui/errordisplay/ErrorDisplay";
import {MasterDetailLayout} from "../../common/ui/layouts/MasterDetailLayout";
import {Spinner} from "../../common/ui/spinner/Spinner";
import {asyncDataSelectors} from "../../common/util/asyncdata/reducerUtil";
import {getParameterFromOwnProps} from "../../common/util/Util";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {JobStatus, PreprocessJob} from "../model";
import {preprocessJobSubmoduleName, selectors} from "../selectors";
import {actions} from "./actions";
import {PreprocessingJobStartStopButton} from "./PreprocessingJobStartStopButton";

interface JobDetailPageStateProps {
  job: PreprocessJob;
  isLoading: boolean;
  isFinished: boolean;
  isError: boolean;
  error: Error;
}

interface JobDetailPageDispatchProps {
  initialLoad: () => Promise<PreprocessJob>;
}

type PreprocessJobDetailPageProps = JobDetailPageStateProps & JobDetailPageDispatchProps & WithApiProperties;

export class PreprocessJobDetailPageComponent extends React.Component<PreprocessJobDetailPageProps & InjectedIntlProps, {}> {

  _previousJobProgress: number;

  UNSAFE_componentWillMount() {
    this.props.initialLoad();
  }

  /**
   * Once a preprocess job has been completed, we delete the job from state not to list it in the Jobs page.
   * However, this causes that the job detail page for the deleted job gets re-rendered and the detail page
   * shows a blank result because of the deleted job. To overcome this issue, we check whether some variables
   * are undefined (e.g. nextProps.job). If so (which means that the job is completed and deleted from the
   * state), we do nothing to keep the detail page remaining same.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {job: currentJob} = this.props;
    const {job: updatedJob} = nextProps;
    if (updatedJob) {
      const nextProgress = updatedJob.jobProgress;
      // If there is no known progress value, then we initiliaze the variable with the first value.
      if (!this._previousJobProgress) {
        this._previousJobProgress = nextProgress;
      } else {
        if (currentJob && currentJob.jobProgress) {
          // The job is still in progress, we keep the previous progress value.
          this._previousJobProgress = currentJob.jobProgress;
        } else {
          // We keep job's final progress value once the job is finished to show that it's finished.
          this._previousJobProgress = nextProgress;
        }
      }
    }
  }

  renderActionBarButtons() {
    const {job} = this.props;
    return (<PreprocessingJobStartStopButton job={job} compact={false}/>);
  }

  render() {
    const {job, isLoading, isFinished, isError, error} = this.props;
    const id = getParameterFromOwnProps(this.props, "id"); //maybe injected by withRouter

    if (isError) {
      if ((error as any).config) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 404) {
          return (
              <div>
                <h2><FormattedMessage id="studio.jobs.preprocessing-job-detail-page.no-job-found"
                                      defaultMessage="Could not find job with id: {id}" values={{id}}/></h2>
                <p><Link to="/jobs"><FormattedMessage id="studio.jobs.preprocessing-job-detail-page.back-to-overview"
                                                      defaultMessage="Go back to jobs overview"/></Link></p>
              </div>
          );
        }
      }
      return <ErrorDisplay error={error}/>;
    }

    if (isLoading) {
      return (
          <div>
            <DetailHeader title={<FormattedMessage id="studio.jobs.preprocessing-job-detail-page.loading-job"
                                            defaultMessage="Loading job {id}" values={{id}}/>}/>
            <Spinner/>
          </div>
      );
    }

    if (isFinished && job) {
      // V180-908: Animation of the progress bar has disabled if the progress goes backwards,
      // because this causes that the progress bar can not show the progress properly.
      // If the user starts an already completed job again, the progress first goes to
      // zero and then increase again, and this causes progress visualization problem.
      const isProgressGoingBackwards = job.jobProgress < this._previousJobProgress;

      const fields: FieldInfo[] = [{
        key: "Service",
        name: <FormattedMessage id="studio.jobs.preprocessing-job-detail-page.service" defaultMessage="Service"/>,
        value: job.serviceTitle,
      }, {
        key: "Product",
        name: <FormattedMessage id="studio.jobs.preprocessing-job-detail-page.product" defaultMessage="Product"/>,
        value: job.productTitle,
      }, {
        key: "Status",
        name: <FormattedMessage id="studio.jobs.preprocessing-job-detail-page.status" defaultMessage="Status"/>,
        value: job.jobState.toString(),
      }, {
        key: "Last execution time",
        name: <FormattedMessage id="studio.jobs.preprocessing-job-detail-page.last-execution-time"
                                defaultMessage="Last execution time"/>,
        value: job.lastExecutionTime,
        format: Formats.DATETIME,
      }, {
        key: "Last execution status",
        name: <FormattedMessage id="studio.jobs.preprocessing-job-detail-page.last-execution-status"
                                defaultMessage="Last execution status"/>,
        value: job.lastExecutionResult,
      }];

      const masterPane = (
          <div>
            <SectionHeader><FormattedMessage id="studio.jobs.preprocessing-job-detail-page.details-header"
                                             defaultMessage="Details"/></SectionHeader>
            <DetailView fields={fields.concat(createCommonMetadataFields(job, this.props.intl))}/>
          </div>
      );

      const detailPane = (
          <div>
            <DetailHeader title={<FormattedMessage id="studio.jobs.preprocessing-job-detail-page.header-message"
                                            defaultMessage="Preprocessing Job - Service: {title}"
                                            values={{title: job.serviceTitle}}/>}/>
            <ActionBar>
              {this.renderActionBarButtons()}
            </ActionBar>
            <SectionHeader><FormattedMessage id="studio.jobs.preprocessing-job-detail-page.progress"
                                             defaultMessage="Progress"/></SectionHeader>
            <ProgressBar bsStyle="info"
                         className={isProgressGoingBackwards ? "disableProgressBarAnimation" : ""}
                         active={job.jobState === JobStatus.RUNNING}
                         now={job.jobProgress ? job.jobProgress * 100.0 : 0}
                         label={(job.jobProgress ? (100.0 * job.jobProgress).toFixed(2) : 0) + "%"}/>
          </div>
      );

      return (
          <MasterDetailLayout masterPane={masterPane} detailPane={detailPane} evenSplit/>
      );
    }

    return null;
  }

}

const mapStateToProps = (state, ownProps) => {
  const id = getParameterFromOwnProps(ownProps, "id"); //maybe injected by withRouter
  return {
    job: selectors.getJobById(preprocessJobSubmoduleName, state, id),
    isLoading: asyncDataSelectors.isLoading(selectors.getJobDetailRequestState(preprocessJobSubmoduleName, state)),
    isFinished: asyncDataSelectors.isFinished(selectors.getJobDetailRequestState(preprocessJobSubmoduleName, state)),
    isError: asyncDataSelectors.isError(selectors.getJobDetailRequestState(preprocessJobSubmoduleName, state)),
    error: asyncDataSelectors.getError(selectors.getJobDetailRequestState(preprocessJobSubmoduleName, state)),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    initialLoad: () => {
      const id = getParameterFromOwnProps(ownProps, "id"); //maybe injected by withRouter
      return dispatch(actions.loadJobById(id));
    },
  };
};

export const PreprocessingJobDetailPage = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WithApi(injectIntl(PreprocessJobDetailPageComponent))));
