import {Cell, CellProps, Column, Table} from "fixed-data-table-2";
import * as React from "react";
import {Panel} from "react-bootstrap";
import {FormattedMessage, InjectedIntlProps} from "react-intl";
import {Page} from "../../util/pagination/PaginationUtil";
import {PlatformUtil} from "../../util/PlatformUtil";
import {getFirstParentWithWidth} from "../../util/Util";
import {DimensionProps, Dimensions} from "../dimensions/Dimensions";
import {
  BasicColumnFactory,
  ColumnFactory,
  ColumnsDescription,
  ColumnWidth,
  ColumnWidthType,
} from "./ControlRoomColumns";

const CONTROLROOM_TABLE_SCROLL_BAR_WIDTH = 18; //pixels
export const CONTROLROOM_TABLE_ROW_HEIGHT = 50; //pixels, make sure bootstrap xs buttons can fit nicely!
export const CONTROLROOM_TABLE_HEADER_HEIGHT = 35; //pixels

export const NOTIFICATION_BAR_HEIGHT = 75; //pixels, room to spare below between bottom of table and bottom of viewport
export const CONTROLROOM_TABLE_MINIMUM_HEIGHT = 100; //pixels, the minimum height of the table

export interface ControlRoomTableStateProperties<T> {
  data: T[];
  isPaged: boolean;
  pages?: {[pageNumber: number]: Page<T>};
  pageSize?: number;
  isAllDataLoaded: boolean;
  selectedRowIndices?: number[];
  rowHeight?: number;
  canMultiSelect?: boolean;
  noItemsFoundMessage?: string | JSX.Element;
  loadingMessage?: string;
  clearSelection?: boolean; // flag to state that selection should be reset, e.g. after search launched.
}

export interface ControlRoomTableDispatchProperties<T> {
  onRowSelect?: (selectedEntry: T[], selectedIndices: number[]) => void;
  onRowClick?: (event: React.MouseEvent<any>, index: number) => void;
  onRowDoubleClick?: (event: React.MouseEvent<any>, index: number) => void;
  onRowMouseEnter?: (event: React.MouseEvent<any>, index: number) => void;
  onRowMouseLeave?: (event: React.MouseEvent<any>, index: number) => void;
  className?: string;
  loadPage?: (pageNumber: number) => void;
  loadData?: () => void;
}

export type ControlRoomTableProperties<T> = ControlRoomTableStateProperties<T> & ControlRoomTableDispatchProperties<T> & InjectedIntlProps;

interface ControlRoomTableState {
  columnWidths: {[columnKey: string]: number};
  shownColumnIds: string[];
  selectedRowIndices: number[];
  lastSelectedIndex: number;
}

export type CalculateDimensionsFunction = (parent: HTMLElement) => {width: number, height: number};

export function ControlRoomTable<T>(columnFactory: ColumnFactory<T>,
                                    getCustomDimensions?: CalculateDimensionsFunction)
: React.ComponentClass<ControlRoomTableProperties<T>> {

  interface PagedEntryStateProps<DataType> {
    entry: DataType;
    pageSize: number;
    page: Page<DataType>;
    pageNumber: number;
  }

  interface PagedEntryDispatchProps {
    loadPage: (pageNumber: number) => void;
  }

  type PagedEntryProps<DataType> = PagedEntryStateProps<DataType> & PagedEntryDispatchProps & CellProps;

  class PagedEntry extends React.Component<PagedEntryProps<T>, {}> {

    _ensureDataLoaded = (props) => {
      const pageNotPresent = !props.page || (props.page.status !== "LOADING" && props.page.status !== "SUCCESS");
      if (!props.entry && pageNotPresent) {
        props.loadPage(props.pageNumber);
      }
    }

    componentDidMount() {
      this._ensureDataLoaded(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.entry !== nextProps.entry) {
        this._ensureDataLoaded(nextProps);
      }
    }

    render() {
      return <div>{this.props.children}</div>;
    }
  }

  class ControlRoomTableComponent extends React.Component<ControlRoomTableProperties<T> & DimensionProps, ControlRoomTableState> {

    constructor(props) {
      super(props);
      const columnWidths = {};
      const shownColumnIds = [];
      columnFactory.getInitialColumnIDs().forEach((columnId) => {
        shownColumnIds.push(columnId);
        const columnWidth = columnFactory.getInitialColumnWidth(columnId);
        //at this point, the total width of the component is still unknown, so just default to the min width
        columnWidths[columnId] = columnWidth.type === ColumnWidthType.PIXELS ? columnWidth.value : ColumnWidth.MIN_FLEX_COL_WIDTH;
      });
      this.state = {
        columnWidths,
        shownColumnIds,
        selectedRowIndices: [],
        lastSelectedIndex: 0,
      };
    }

    isSelected = (index: number) => this.state.selectedRowIndices.indexOf(index) > -1;

    _onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
      const prevState = this.state;
      const newState = Object.assign({}, prevState, {
        columnWidths: Object.assign({}, prevState.columnWidths, {[columnKey]: newColumnWidth}),
      });
      this.setState(newState);
    }

    needsVerticalScrollbar = (numberOfEntries: number, containerHeight: number): boolean => {
      const tableHeight = CONTROLROOM_TABLE_HEADER_HEIGHT + (numberOfEntries * CONTROLROOM_TABLE_ROW_HEIGHT);
      return tableHeight > containerHeight;
    }

    updateFlexColumnWidths = (containerWidth: number, needsScrollbar: boolean) => {
      let totalFixedColumnWidth = 0;
      let totalFlex = 0;
      const flexColumnWidths = {};
      const updatedColumnWidths = Object.assign({}, this.state.columnWidths);
      this.state.shownColumnIds.forEach((columnId) => {
        const columnWidth = columnFactory.getInitialColumnWidth(columnId);
        if (columnWidth.type === ColumnWidthType.FLEX) {
          flexColumnWidths[columnId] = columnWidth.value;
          totalFlex += columnWidth.value;
        } else {
          totalFixedColumnWidth += this.state.columnWidths[columnId];
        }
      });
      const remainingWidth = containerWidth - totalFixedColumnWidth - CONTROLROOM_TABLE_SCROLL_BAR_WIDTH;
      for (const columnId in flexColumnWidths) {
        if (flexColumnWidths.hasOwnProperty(columnId)) {
          const flexValue = flexColumnWidths[columnId];
          const flexFraction = flexValue / totalFlex;
          updatedColumnWidths[columnId] = Math.max(ColumnWidth.MIN_FLEX_COL_WIDTH, flexFraction * remainingWidth);
        }
      }
      this.setState(Object.assign({}, this.state, {columnWidths: updatedColumnWidths}));
    }

    componentDidMount() {
      this._ensureDataLoading(this.props);
      if (!this.props.isPaged && this.props.loadData) {
        this.props.loadData();
      }
      this.updateFlexColumnWidths(this.props.containerWidth, this.needsVerticalScrollbar(this.props.data.length, this.props.containerHeight));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if ((this.props.containerWidth !== nextProps.containerWidth)
          || (this.props.containerHeight !== nextProps.containerHeight)
          || this.props.data.length !== nextProps.data.length) {
        this.updateFlexColumnWidths(nextProps.containerWidth, this.needsVerticalScrollbar(nextProps.data.length, nextProps.containerHeight));
      }
      this._ensureDataLoading(nextProps);

      if (nextProps.clearSelection && this.state.selectedRowIndices.length) {
        this.setState((prevState) => Object.assign({}, prevState, {
          selectedRowIndices: [],
          lastSelectedIndex: 0,
        }));
      } else if (nextProps.selectedRowIndices) {
        const size = nextProps.selectedRowIndices.length;
        this.setState((prevState) => Object.assign({}, prevState, {
          selectedRowIndices: nextProps.selectedRowIndices,
          lastSelectedIndex: size ? nextProps.selectedRowIndices[size - 1] : 0,
        }));
      }
    }

    _ensureDataLoading = (props) => {
      if (props.isPaged && !props.pages[0]) {
        props.loadPage(0); //make sure there is always at least the first page (being) loaded
      }
    }

    renderLoading() {
      return (
          <div style={{
            width: this.props.containerWidth,
            height: this.props.containerHeight,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            <Panel>
              <b>{this.props.loadingMessage || (<FormattedMessage id="studio.UI.list.loading" defaultMessage="Loading..."/>)}</b>
            </Panel>
          </div>
      );
    }

    renderNoData() {
      return (
          <div style={{
            width: this.props.containerWidth,
            height: this.props.containerHeight,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            <Panel>
              <b>{this.props.noItemsFoundMessage || (<FormattedMessage id="studio.UI.list.no-items" defaultMessage="No items found."/>)}</b>
            </Panel>
          </div>
      );
    }

    createCellRenderer = (columnId) => {
      const {columnWidths} = this.state;
      const {data, isPaged, pageSize, pages, loadPage, intl} = this.props;
      const column = columnFactory.createColumn(columnId);
      const width = columnWidths[columnId];
      return (cellProps) => {
        const index = cellProps.rowIndex;
        const pageNumber = Math.floor(index / pageSize);
        const entry: T = data[index];
        const selected = this.isSelected(index);
        const selectedIndices = this.state.selectedRowIndices;
        const cellContent = (
            <Cell {...cellProps} className={selected ? " selectedRowCell" : ""}>
              <div className="cell" title={(entry && column.cellTooltip) ? column.cellTooltip(entry, intl) : ""}>
                <div className="cell-overflow" style={{width}}>
                  {entry ? column.cellContent(entry, cellProps.rowIndex, selected, data, selectedIndices) : (
                      <FormattedMessage id="studio.UI.list.loading" defaultMessage="Loading..."/>
                  )}
                </div>
              </div>
            </Cell>
        );
        if (isPaged) {
          return <PagedEntry entry={entry}
                             loadPage={loadPage}
                             page={pages[pageNumber]}
                             pageNumber={pageNumber}
                             pageSize={pageSize}
          >
            {cellContent}
          </PagedEntry>;
        }
        return cellContent;
      };
    }

    renderColumns = () => {
      const {shownColumnIds, columnWidths} = this.state;
      return shownColumnIds.map((columnId) => {
        const column = columnFactory.createColumn(columnId);
        return (
            <Column key={columnId}
                    header={<Cell>{column.header}</Cell>}
                    columnKey={columnId}
                    cell={this.createCellRenderer(columnId)}
                    width={columnWidths[columnId]}
                    isResizable={true}
            />
        );
      });
    }

    handleRowClick = (event, index) => {
      const {onRowClick, onRowSelect, data, canMultiSelect} = this.props;
      if (onRowClick) {
        onRowClick(event, index);
      }

      // Selecting an already selected index makes it deselected
      const {selectedRowIndices, lastSelectedIndex} = this.state;
      const idxInSelection = selectedRowIndices.indexOf(index);
      const itemAlreadySelected = (idxInSelection > -1);
      const selectionSize = selectedRowIndices.length;
      const multiSelection = selectionSize > 1;

      let newSelectedRowIndices;
      if (canMultiSelect && this.multiSelectButtonPressed(event)) {
        newSelectedRowIndices = itemAlreadySelected ?
                                selectedRowIndices.filter((key, val) => val !== idxInSelection) :
            [...selectedRowIndices, index];
      } else if (canMultiSelect && event.shiftKey) {
        newSelectedRowIndices = _selectAll(lastSelectedIndex, index);
      } else {
        newSelectedRowIndices = (itemAlreadySelected && !multiSelection) ? [] : [index];
      }
      // remove text selection (side-effect of SHIFT-click)
      window.getSelection().removeAllRanges();

      this.setState(Object.assign({}, this.state, {
        selectedRowIndices: newSelectedRowIndices,
        lastSelectedIndex: newSelectedRowIndices.length ? index : 0,
      }));

      if (onRowSelect) {
        const selectedItems = newSelectedRowIndices.map((aIndex) => data[aIndex]);
        onRowSelect(selectedItems, newSelectedRowIndices);
      }

      function _selectAll(fromIndex, toIndex) {
        if (fromIndex > toIndex) {
          [fromIndex, toIndex] = [toIndex, fromIndex];
        }
        const result = new Set([...selectedRowIndices]);
        for (let idx = fromIndex; idx <= toIndex; idx++) {
          if (!result.has(idx)) {
            result.add(idx);
          }
        }
        return [...result.values()];
      }
    }

    multiSelectButtonPressed(event) {
      if (PlatformUtil.isMac()) {
        return event.metaKey;
      } else {
        return event.ctrlKey;
      }
    }

    getRowCount() {
      if (this.props.isPaged) {
        return this.props.isAllDataLoaded ? this.props.data.length : this.props.data.length + 1;
      }
      return this.props.data.length;
    }

    _getRowClassName = (index: number): string => {
      if (this && this.state) {
        const {selectedRowIndices} = this.state;
        return (selectedRowIndices && selectedRowIndices.length > 0 && selectedRowIndices.indexOf(index) > -1)
               ? "selectedRow" : null;
      }
    }

    render() {
      const {data, containerWidth, containerHeight, className, canMultiSelect, rowHeight, isPaged, isAllDataLoaded, pages} = this.props;

      const firstPageLoading = isPaged && (pages[0] && (pages[0].status === "LOADING" ));
      const shouldShowLoadingMessage = (isPaged && firstPageLoading) || (!isPaged && !isAllDataLoaded);

      if (shouldShowLoadingMessage) {
        return this.renderLoading();
      } else if (data.length === 0) {
        return this.renderNoData();
      } else if (data.length > 0) {
        return (
            <div>
            <Table
                className={className}
                width={containerWidth}
                height={containerHeight}
                rowsCount={this.getRowCount()}
                rowHeight={rowHeight || CONTROLROOM_TABLE_ROW_HEIGHT}
                headerHeight={CONTROLROOM_TABLE_HEADER_HEIGHT}
                isColumnResizing={false}
                onRowClick={this.handleRowClick}
                onRowDoubleClick={this.props.onRowDoubleClick}
                onRowMouseEnter={this.props.onRowMouseEnter}
                onRowMouseLeave={this.props.onRowMouseLeave}
                onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                rowClassNameGetter={this._getRowClassName}>
              {this.renderColumns()}
            </Table>
        {canMultiSelect &&
         <div className="TableNote">
           <FormattedMessage
               id="studio.UI.list.multi-select-buttons"
               defaultMessage={"Multi-select with Shift-click or {button}-click"}
               values={{button: PlatformUtil.isMac() ? "⌘" : "Ctrl"}}
           />
          </div>}
      </div>
        );
      }
    }
  }

  const fullViewPortDimensions = (parent: HTMLElement) => {

    const newParent: HTMLElement = getFirstParentWithWidth(parent);
    const paddingLeft = parseFloat(getComputedStyle(newParent).paddingLeft) || 0;
    const paddingRight = parseFloat(getComputedStyle(newParent).paddingRight) || 0;
    const viewPortHeight = document.documentElement.clientHeight;
    const parentTop = newParent.getBoundingClientRect().top;
    const tableHeight = viewPortHeight - parentTop - NOTIFICATION_BAR_HEIGHT;
    return {
      width: newParent.clientWidth - paddingLeft - paddingRight,
      height: Math.max(CONTROLROOM_TABLE_MINIMUM_HEIGHT, tableHeight),
    };
  };

  const calculateDimensions = getCustomDimensions || fullViewPortDimensions;
  return Dimensions(calculateDimensions)(ControlRoomTableComponent);
}

/**
 * Creates a basic ControlRoomTable, using a BasicColumnFactory
 * @param columnsDescription a description on how columns should be rendered
 * @param {CalculateDimensionsFunction} function which determines dimensions of the table
 * @returns {React.ComponentClass<ControlRoomTableProperties<T>>}
 */
export function createBasicTableComponent<T>(columnsDescription: ColumnsDescription<T>,
                                             getCustomDimensions?: CalculateDimensionsFunction) {
  const columnFactory = new BasicColumnFactory<T>(columnsDescription);
  return ControlRoomTable(columnFactory, getCustomDimensions);
}
