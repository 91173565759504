import * as React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Api} from "../../api/InternalApi";
import {ControlRoomApi} from "../../api/StudioInternalApi";
import {UserTourApi} from "../../api/UserTourInternalApi";
import {selectors as toursSelectors} from "../../common/ui/tour/selectors";
import {selectors as userSelectors} from "../../user/selectors";

export interface WithApiProperties {
  api: Api;
}

interface WithApiStateProperties {
  userTourCompleted: boolean;
  styleDataTourCompleted: boolean;
}

interface ExportedHOCProps extends PropsFromRedux {
  api?: Api;
}

type WithApiHOCType<WrappedComponentProps> = React.ComponentClass<WrappedComponentProps & ExportedHOCProps>;

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, {
    userTourCompleted: userSelectors.getUserTourCompleted(state),
    styleDataTourCompleted: toursSelectors.getStyleDataTourCompleted(state),
  }, ownProps);
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export function WithApi<WrappedProps>(
    WrappedComponent: React.ComponentClass<WrappedProps & WithApiProperties>,
): WithApiHOCType<WrappedProps & ExportedHOCProps> {

  class WithApiHOC extends React.Component<WithApiStateProperties & WrappedProps & ExportedHOCProps> {

    render() {
      //TODO: find a way to properly type this. Currently, the TS struggles with this (TS 2.3.4) as you cannot do a
      //spread in a destructuring assignment on a generic type
      //const {userTourCompleted, styleDataTourCompleted, api, ...otherProps} = this.props;
      const {userTourCompleted, styleDataTourCompleted, api} = this.props;
      const userTourRunning = !userTourCompleted || !styleDataTourCompleted;
      const apiToPassDown: Api = api || (userTourRunning ? UserTourApi : ControlRoomApi);
      const propsToPassDown: any = Object.assign({}, this.props, {api: apiToPassDown});
      delete propsToPassDown.userTourCompleted;
      delete propsToPassDown.styleDataTourCompleted;
      return <WrappedComponent {...propsToPassDown} />;
    }
  }


  return connector(WithApiHOC) as any as React.ComponentClass<WrappedProps>;
}
