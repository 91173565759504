import * as React from "react";
import {Route, Switch} from "react-router-dom";
import {AppLayout} from "./common/ui/layouts/AppLayout";
import {NotFoundPage} from "./common/ui/NotFoundPage";
import {DataDetailPage} from "./data/DataDetailPage";
import {DataListPage} from "./data/DataListPage";
import {HelpPage} from "./help/HelpPage";
import {FileDetailPage} from "./jobs/import/FileDetailPage";
import {JobDetailPage} from "./jobs/import/JobDetailPage";
import {JobsPage} from "./jobs/JobsPage";
import {PreprocessingJobDetailPage} from "./jobs/preprocessing/PreprocessingJobDetailPage";
import {ProductDetailPage} from "./products/ProductDetailPage";
import {ProductsPage} from "./products/ProductsPage";
import {DataSourceFilesPage} from "./services/fileserver/DataSourceFilesPage";
import {ServiceDetail} from "./services/ServiceDetail";
import {ServicesPage} from "./services/ServicesPage";
import {SettingsPage} from "./settings/SettingsPage";
import {StyleDetailPage} from "./styles/StyleDetailPage";
import {StylesPage} from "./styles/StylesPage";
import {BackendOfflinePage} from "./user/BackendOfflinePage";
import {LoginPage} from "./user/LoginPage";
import {LogoutPage} from "./user/LogoutPage";
import {WithAuthenticationCheck} from "./user/WithAuthenticationCheck";
import {WelcomePage} from "./welcome/WelcomePage";

const AuthenticatedAppContent: React.FunctionComponent = (props) => {
  return (
      <AppLayout>
        <Switch>
          <Route exact path="/" component={WelcomePage}/>
          <Route exact path="/data" component={DataListPage}/>
          <Route path="/data/:dataId" component={DataDetailPage}/>
          <Route exact path="/styles" component={StylesPage}/>
          <Route path="/styles/:id" component={StyleDetailPage}/>
          <Route exact path="/services" component={ServicesPage}/>
          <Route path="/services/fileserver/:serviceName/product/:productId/browse-data/:dataId"
                 component={DataSourceFilesPage}/>
          <Route path="/services/:id" component={ServiceDetail}/>
          <Route exact path="/products" component={ProductsPage}/>
          <Route path="/products/:id" component={ProductDetailPage}/>
          <Route exact path="/jobs" component={JobsPage}/>
          <Route path="/jobs/import/:id" component={JobDetailPage}/>
          <Route path="/jobs/preprocessing/:id" component={PreprocessingJobDetailPage}/>
          <Route path="/jobs/file/:id" component={FileDetailPage}/>
          <Route path="/settings" component={SettingsPage}/>
          <Route path="/help/:section?" component={HelpPage}/>
          <Route path="*" component={NotFoundPage}/>
        </Switch>
      </AppLayout>
  );
};

const AuthenticatedContent = WithAuthenticationCheck(AuthenticatedAppContent);

export const routes = (
    <Switch>
      <Route exact path="/login" component={LoginPage}/>
      <Route exact path="/logout" component={LogoutPage}/>
      <Route exact path="/backendoffline" component={BackendOfflinePage}/>
      <Route path="*" component={AuthenticatedContent}/>
    </Switch>
);
