import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {
  ControlRoomTable,
  ControlRoomTableProperties,
  ControlRoomTableStateProperties,
} from "../../common/ui/table/ControlRoomTable";
import {FileInfo} from "../../data/model";
import {Product} from "../../products/model";
import {Service} from "../model";
import {DataSourceFilesColumnFactory} from "./DataSourceFilesColumnFactory";

interface DataFileListProps {
  product: Product;
  service: Service;
}

export class DataSourceFileListComponent extends React.Component<ControlRoomTableStateProperties<FileInfo> & DataFileListProps & InjectedIntlProps, {}> {

  ImportedDataFileListTable: React.ComponentClass<ControlRoomTableProperties<FileInfo>>;

  constructor(props) {
    super(props);
    const {product, service} = this.props;
    this.ImportedDataFileListTable = ControlRoomTable(
        new DataSourceFilesColumnFactory(product, service),
    );
  }

  render() {
    const {data, isPaged, canMultiSelect, isAllDataLoaded, noItemsFoundMessage, intl} = this.props;
    return (
        <this.ImportedDataFileListTable data={data}
                                        intl={intl}
                                        isPaged={isPaged}
                                        canMultiSelect={canMultiSelect}
                                        isAllDataLoaded={isAllDataLoaded}
                                        noItemsFoundMessage={noItemsFoundMessage}
        />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isPaged: false,
    canMultiSelect: false,
    isAllDataLoaded: true,
  } as ControlRoomTableStateProperties<FileInfo>;
};

export const DataSourceFileList = connect(null, mapStateToProps)(injectIntl(DataSourceFileListComponent));
