import {AxiosError} from "axios";
import * as React from "react";

export const mapErrorToMessage = (error: Error) => {
  if((error as any).response) {
    const axiosError = error as AxiosError;
    if (axiosError.response.data) {
      if (axiosError.response.data.message) { // Internal server error
        return axiosError.response.data.message
      } else if (axiosError.response.data.details) { // Unsupported operation error
        return axiosError.response.data.details;
      }
    }
  }
  return null;
}