import * as React from "react";
import {SectionHeader} from "../../../common/ui/detailsection/SectionHeader";
import {FormattedMessage} from "react-intl";
import {Alert, FormGroup, Radio} from "react-bootstrap";

interface ForceCrawlFilesConfiguratorProps {
  forceRecrawlFiles: boolean;
  handleForceRecrawlFilesChange: (forceRecrawlFiles: boolean) => void;
}

export class ForceRecrawlFilesConfigurator extends React.Component<ForceCrawlFilesConfiguratorProps> {
  render() {
    const {forceRecrawlFiles, handleForceRecrawlFilesChange} = this.props;
    return (
        <>
          <SectionHeader>
            <FormattedMessage id="studio.jobs.job-detail-page.force-recrawl-files"
                              defaultMessage="Force recrawl files"/>
          </SectionHeader>
          <FormGroup>
            <Radio inline
                   checked={forceRecrawlFiles === false}
                   onChange={(event) => {
                     const detect = (event.target as any).checked;
                     if (detect) {
                       handleForceRecrawlFilesChange(false);
                     }
                   }}>
              <FormattedMessage id="studio.jobs.job-detail-page.force-recrawl-files-disable"
                                defaultMessage="Disable"/>
            </Radio>
            <Radio inline
                   checked={forceRecrawlFiles === true}
                   onChange={(event) => {
                     const ignore = (event.target as any).checked;
                     if (ignore) {
                       handleForceRecrawlFilesChange(true);
                     }
                   }}>
              <FormattedMessage id="studio.jobs.job-detail-page.force-recrawl-files-enable"
                                defaultMessage="Enable"/>
            </Radio>
          </FormGroup>
          {!forceRecrawlFiles ? null :
           <Alert bsStyle="warning">
             <b><FormattedMessage id="studio.jobs.job-detail-page.force-recrawl-files-warning"
                                  defaultMessage="WARNING:"/></b>
             &nbsp;
             <FormattedMessage id="studio.jobs.job-detail-page.force-recrawl-files-warning-description"
                               defaultMessage="All files will be recrawled even if they were recognized before and the files did not change. Reprocessing all files will impact time to completion."/>
           </Alert>
          }
        </>
    );
  }
}