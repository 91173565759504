import * as React from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {humanReadableFileSize} from "../../common/ui/detailsection/DetailView";
import {BasicColumnFactory, ColumnWidth} from "../../common/ui/table/ControlRoomColumns";
import {DateUtil} from "../../common/util/DateUtil";
import {FileInfo} from "../../data/model";
import {Product} from "../../products/model";
import {Service} from "../model";
import {FileDownloadButton} from "./FileDownloadButton";

const MESSAGES = defineMessages({
  downloadTooltip: {
    id: "studio.services.fileserver.files-factory.download.tooltip",
    defaultMessage: "Download {fileName}",
  },
});

export class DataSourceFilesColumnFactory extends BasicColumnFactory<FileInfo> {

  constructor(product?: Product, service?: Service) {
    const columns = {
      createdBy: {
        header: <FormattedMessage id="studio.services.fileserver.files-factory.file-name" defaultMessage="File Name"/>,
        cellContent: (importedDataFile) => importedDataFile.fileName,
        cellTooltip: (importedDataFile) => importedDataFile.fileName,
        columnWidth: ColumnWidth.pixels(200),
      },
      filePath: {
        header: <FormattedMessage id="studio.services.fileserver.files-factory.file-path" defaultMessage="File Path"/>,
        cellContent: (importedDataFile) => importedDataFile.filePath,
        cellTooltip: (importedDataFile) => importedDataFile.filePath,
        columnWidth: ColumnWidth.flex(1),
      },
      extension: {
        header: <FormattedMessage id="studio.services.fileserver.files-factory.extension" defaultMessage="Extension"/>,
        cellContent: (importedDataFile) => importedDataFile.fileExtension.toUpperCase(),
        cellTooltip: (importedDataFile) => importedDataFile.fileExtension.toUpperCase(),
        columnWidth: ColumnWidth.pixels(90),
      },
      firstScanTime: {
        header: <FormattedMessage id="studio.services.fileserver.files-factory.first-scan-time"
                                  defaultMessage="First Scan Time"/>,
        cellContent: (importedDataFile) => DateUtil.formatDateTime(importedDataFile.firstScanTime),
        cellTooltip: (importedDataFile) => DateUtil.formatDateTime(importedDataFile.firstScanTime),
        columnWidth: ColumnWidth.DATETIME_COLUMN_WIDTH,
      },
      lastScanTime: {
        header: <FormattedMessage id="studio.services.fileserver.files-factory.last-scan-time"
                                  defaultMessage="Last Scan Time"/>,
        cellContent: (importedDataFile) => DateUtil.formatDateTime(importedDataFile.lastScanTime),
        cellTooltip: (importedDataFile) => DateUtil.formatDateTime(importedDataFile.lastScanTime),
        columnWidth: ColumnWidth.DATETIME_COLUMN_WIDTH,
      },
      fileSize: {
        header: <FormattedMessage id="studio.services.fileserver.files-factory.size" defaultMessage="Size"/>,
        cellContent: (importedDataFile) => humanReadableFileSize(importedDataFile.fileSize),
        cellTooltip: (importedDataFile) => humanReadableFileSize(importedDataFile.fileSize),
        columnWidth: ColumnWidth.pixels(90),
      },
    };

    if (product != null && service != null) {
      columns["download"] = {
        header: <FormattedMessage id="studio.services.fileserver.files-factory.download" defaultMessage="Download"/>,
        cellContent: (importedDataFile) => <FileDownloadButton importedDataFile={importedDataFile}
                                                               product={product} service={service}/>,
        cellTooltip: (importedDataFile, intl) => intl.formatMessage(MESSAGES.downloadTooltip, {fileName: importedDataFile.fileName}),
        columnWidth: ColumnWidth.pixels(100),
      };
    }
    super(columns);
  }
}
