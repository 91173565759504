import {AxisFormat} from "./AxisFormat";

const axisColor = "rgb(200, 200, 200)";

function createXAxisOptions(unitOfMeasure) {
  return {
    labelFormatter: AxisFormat.createAxisFormatPostfixLabel(unitOfMeasure),
    labelStyle: {
      fill: axisColor,
      strokeWidth: 1,
      font: "10px sans-serif",
      offsetX: 0,
      offsetY: 10,
      textAnchor: "end",
      alignmentBaseline: "middle",
    },
    // labelRotation: -45,
    axisLineStyle: {
      color: axisColor,
    },
    tickLength: 10,
    //unlabeled ticks
    subTicks: 4,
    subTickLength: 4,
    tickLineStyle: { color: axisColor },
    gridLine: true,
  };
}

function createYAxisOptions(unitOfMeasure) {
  return Object.assign({}, createXAxisOptions(unitOfMeasure), {
    labelFormatter: AxisFormat.createAxisFormatPrefixLabel(unitOfMeasure),
    labelRotation: 0,
    labelStyle: {
      fill: axisColor,
      strokeWidth: 1,
      font: "10px sans-serif",
      offsetX: -10,
      offsetY: 0,
      textAnchor: "end",
      alignmentBaseline: "middle",
    },
    axisLineStyle: {
      color: axisColor,
    },
    gridLine: true,
    subTicks: 9,
  });
}

const createChartOptions = (reference) => {
  return {
    reference,
    border: {
      left: 76,
      bottom: 58,
    },
    axes: {
      xAxis: createXAxisOptions(reference.getAxis(0).unitOfMeasure),
      yAxis: createYAxisOptions(reference.getAxis(1).unitOfMeasure),
    },
  };
};

export const RIAChartOptions = {
  createChartOptions,
};
