import * as React from "react";
import {Alert, Button, Col, ControlLabel, FormGroup, HelpBlock} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {connect, DispatchProp} from "react-redux";
import {
  Field,
  Fields,
  Form,
  formValueSelector,
  hasSubmitSucceeded,
  InjectedFormProps,
  isSubmitting,
  reduxForm,
  submit,
} from "redux-form";
import {Style} from "./model";
import {StyleTypeahead} from "./StyleTypeahead";

export interface ChooseStyleFormData {
  chosenStyle: Style;
}

export interface ChooseStyleFormProps {
  submitSucceededMessage?: (styleToSet: Style) => string;
  submitFailedMessage?: (styleToSet: Style) => string;
  whileSubmittingMessage?: (styleToSet: Style) => string;

  onStyleChosen(chosenStyle: Style): Promise<void>;
}

export const FORM_NAME = "chooseStyleForm";
export const FIELD_CHOSEN_STYLE = "chosenStyle";
const LABEL_COL_WIDTH = 2;

class ChooseStyleFormComponent extends React.Component<ChooseStyleFormProps & InjectedFormProps<ChooseStyleFormData, ChooseStyleFormProps>, {}> {

  _chosenStyle: Style = null;

  componentDidMount() {
    this.props.initialize({
      chosenStyle: null,
    });
    this._chosenStyle = null;
  }

  renderFields(fields: Field[]) {
    const bulkStyleToSetField = fields[FIELD_CHOSEN_STYLE];
    const shouldShowStyleWarning = (bulkStyleToSetField.meta.error);
    return (<div>
          <FormGroup controlId={bulkStyleToSetField.input.name}
                     validationState={shouldShowStyleWarning ? "error" : null}>
            <Col sm={LABEL_COL_WIDTH}>
              <ControlLabel><FormattedMessage
                  id="studio.choose-style-form.clear-label"
                  defaultMessage="Style to set"
              /></ControlLabel>
            </Col>
            <Col sm={10 - LABEL_COL_WIDTH}>
              <StyleTypeahead {...bulkStyleToSetField.input} />
              <HelpBlock><FormattedMessage
                  id="studio.choose-style-form.clear-help"
                  defaultMessage="Leave empty to clear the style"
              /></HelpBlock>
            </Col>
            <Col sm={2}>
              <Button onClick={() => {
                bulkStyleToSetField.input.onChange(null);
              }}><FormattedMessage
                  id="studio.choose-style-form.clear-button"
                  defaultMessage="clear"
              /></Button>
            </Col>
          </FormGroup>
        </div>
    );
  }

  triggerOnStyleChosen = (values) => {
    this._chosenStyle = values.chosenStyle ? values.chosenStyle : null;
    return this.props.onStyleChosen(this._chosenStyle);
  }

  render() {
    const error = this.props.submitFailed ? (
        <Alert bsStyle="danger">
          <strong>{this.props.submitFailedMessage(this._chosenStyle) ||
                   <FormattedMessage
                       id="studio.choose-style-form.submit-failed"
                       defaultMessage="Operation failed:"
                   />} </strong> {this.props.error}
        </Alert>
    ) : null;

    if (this.props.submitting) {
      return (
          <div className="form-message">
            <h2>{this.props.whileSubmittingMessage(this._chosenStyle) || <FormattedMessage
                id="studio.choose-style-form.submitting"
                defaultMessage="Please Wait..."
            />}</h2>
          </div>
      );
    }

    if (this.props.submitSucceeded) {
      return (
          <div className="form-message">
            <h2>{this.props.submitSucceededMessage(this._chosenStyle) || <FormattedMessage
                id="studio.choose-style-form.submit-succeeded"
                defaultMessage="Operation Successful"
            />}</h2>
          </div>
      );
    }

    return (
        <div>
          {error}
          <Form className="form-horizontal" onSubmit={this.props.handleSubmit(this.triggerOnStyleChosen)}>
            <Fields
                names={[FIELD_CHOSEN_STYLE]}
                component={this.renderFields}/>
          </Form>
        </div>
    );
  }
}

const formConfig = {
  form: FORM_NAME,
};

const chooseStyleFormValues = formValueSelector(FORM_NAME);

export const ChooseStyleForm = reduxForm<ChooseStyleFormData, ChooseStyleFormProps>(formConfig)(
    ChooseStyleFormComponent);

interface ChooseStyleFormSubmitButtonStateProps extends DispatchProp {
  chosenStyle: Style;
  submitting: boolean;
  submitSucceeded: boolean;
}

interface ChooseStyleFormSubmitButtonOwnProps {
  buttonText: (chosenStyle: Style) => string;
  valueToSubmit?: Style;
}

type ChooseStyleFormSubmitButtonProps = ChooseStyleFormSubmitButtonStateProps & ChooseStyleFormSubmitButtonOwnProps;

class ChooseStyleFormSubmitButtonComponent extends React.Component<ChooseStyleFormSubmitButtonProps, {}> {
  render() {
    const {dispatch, submitting, submitSucceeded, buttonText, chosenStyle} = this.props;
    if (submitting || submitSucceeded) {
      return null;
    }
    return (
        <Button onClick={() => {
          dispatch(submit(FORM_NAME));
        }}>
          {buttonText(chosenStyle)}
        </Button>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const chosenStyle = chooseStyleFormValues(state, FIELD_CHOSEN_STYLE);
  return {
    submitting: isSubmitting(FORM_NAME)(state),
    submitSucceeded: hasSubmitSucceeded(FORM_NAME)(state),
    chosenStyle,
  };
};

export const ChooseStyleFormSubmitButton = connect(mapStateToProps)(ChooseStyleFormSubmitButtonComponent);
