import axios, {CancelTokenSource} from "axios";
import * as React from "react";
import {Form, FormGroup, HelpBlock} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {FileUploadStatus} from "../../../model";
import {WithApi, WithApiProperties} from "../../util/WithApi";
import {FileDrop} from "../filedrop/FileDrop";
import {FileUploadControls} from "./FileUploadControls";
import {FileUploadFormState} from "./FileUploadFormState";
import {FileUploadStartCancelButton} from "./FileUploadStartCancelButton";
import {getTotalUploadSize, humanFileSize} from "./FileUploadUtil";
import {UploadFiles} from "./UploadFiles";
import {UploadOptions} from "./UploadOptions";

interface FileUploadProps {
  initialFiles?: File[];
  shouldCancel: boolean;
  uploadOptions: UploadOptions;
  createFormData: (uploadFiles: File[]) => FormData;
}

const FILE_UPLOAD_MESSAGES = defineMessages({
  fileExceedsMaxUploadSize: {
    id: "studio.file-upload-form.file-exceeds-max-upload-size",
    defaultMessage: "The selected file exceeds the max upload size",
  },
  uploadCanceledByUser: {
    id: "studio.file-upload-form.upload-canceled-by-user",
    defaultMessage: "Upload cancelled by user",
  },
  uploadingDirectoriesNotSupported: {
    id: "studio.file-upload-form.uploading-directories-not-supported",
    defaultMessage: "Uploading directories is not supported. Add the directory as a data root, or upload it as a ZIP file.",
  },
});

export class FileUploadFormComponent extends React.Component<FileUploadProps & WithApiProperties & InjectedIntlProps, FileUploadFormState> {

  cancelSource: CancelTokenSource;

  constructor(props: any) {
    super(props);

    const initialState = new FileUploadFormState();
    if (this.props.initialFiles && this.props.initialFiles.length > 0) {
      initialState.uploadFiles = this.filterFileSelection(this.props.initialFiles);
    }
    this.state = initialState;
    this.cancelSource = null;
  }

  componentDidMount() {
    if (this.state.maxUploadSize == null) {
      this.props.api.getMaxUploadSize()
          .then((max) => {
            this.setState((prevState, props) => {
              const newState = new FileUploadFormState(prevState);
              newState.maxUploadSize = max;
              return newState;
            });
          })
          .catch((error) => {
            this.setState((prevState, props) => {
              const newState = new FileUploadFormState(prevState);
              newState.maxUploadSize = Number.NaN;
              return newState;
            });
          });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.shouldCancel && nextProps.shouldCancel) {
      this.cancelUpload();
    }
  }

  startUpload = () => {
    const {intl} = this.props;
    const {uploadFiles, isUploading, finished, maxUploadSize} = this.state;
    if (uploadFiles.length < 1 || isUploading || finished) {
      return;
    }

    if (maxUploadSize) {
      if (getTotalUploadSize(uploadFiles) > maxUploadSize) {
        this.fileUploadError(new Error(intl.formatMessage(FILE_UPLOAD_MESSAGES.fileExceedsMaxUploadSize)));
        return;
      }
    }

    const data = this.props.createFormData(uploadFiles);

    this.cancelSource = axios.CancelToken.source();

    this.props.api.uploadFile(data, this.fileUploadProgress, this.cancelSource.token,
        this.props.uploadOptions.uploadUri)
        .then(this.fileUploadEnded)
        .catch((error) => {
          this.fileUploadError(error);
        });

    this.setState((prevState, props) => {
      const newState = new FileUploadFormState(prevState);
      newState.isUploading = true;
      return newState;
    });
  }

  cancelUpload = () => {
    const {intl} = this.props;
    if (this.cancelSource && !this.state.finished) {
      this.cancelSource.cancel(intl.formatMessage(FILE_UPLOAD_MESSAGES.uploadCanceledByUser));
    } else {
      this.resetUpload();
    }
  }

  resetUpload = (newFiles = []) => {
    const newState = new FileUploadFormState();
    newState.uploadFiles = newFiles;
    this.setState((prevState, props) => {
      newState.maxUploadSize = prevState.maxUploadSize;
      return newState;
    });
    this.cancelSource = null;
  }

  fileUploadEnded = (uploadStatus: FileUploadStatus[]) => {
    this.setState((prevState, props) => {
      const newState = new FileUploadFormState(prevState);
      newState.progress = 100;
      newState.isUploading = false;
      newState.finished = true;
      newState.status = uploadStatus;
      return newState;
    });
  }

  fileUploadError = (error) => {
    const {intl} = this.props;
    let status;
    if (error.response && error.response.data) {
      status = error.response.data;
    } else {
      let errorMessage = error.message;
      const directoryUploads = this.state.uploadFiles.filter((uploadFile) => {
        return uploadFile.size % 4096 === 0;
      });
      if (directoryUploads.length >= 0 && error.message.toLowerCase().indexOf("network error") >= 0) {
        errorMessage = intl.formatMessage(FILE_UPLOAD_MESSAGES.uploadingDirectoriesNotSupported);
      }
      status = {
        success: false,
        message: errorMessage,
      };
    }

    this.setState((prevState, props) => {
      const newState = new FileUploadFormState(prevState);
      newState.progress = 100;
      newState.isUploading = false;
      newState.finished = true;
      newState.status = [status];
      return newState;
    });
  }

  fileUploadProgress = (progressPercentage: number) => {
    this.setState((prevState, props) => {
      const newState = new FileUploadFormState(prevState);
      newState.progress = progressPercentage;
      return newState;
    });
  }

  removeFile = (file) => {
    const newFiles = [];
    this.setState((prevState, props) => {
      prevState.uploadFiles.forEach((uploadFile) => {
        if (uploadFile.name !== file.name) {
          newFiles.push(uploadFile);
        }
      });

      const newState = new FileUploadFormState(prevState);
      newState.uploadFiles = newFiles;

      return newState;
    });
  }

  filterFileSelection = (files: File[]) => {
    const {allowedFileExtension, allowMultipleFiles} = this.props.uploadOptions;
    const filteredByExtension = allowedFileExtension ? files.filter((file) => file.name.endsWith(allowedFileExtension))
        : files;
    const qty = filteredByExtension.length;
    return !allowMultipleFiles && qty > 0 ? [filteredByExtension[qty - 1]] : filteredByExtension;
  }

  updateFileSelection = (files: File[]) => {
    const acceptedFiles = this.filterFileSelection(files);
    if (this.state.finished) {
      this.resetUpload(acceptedFiles);
    } else {
      this.setState((prevState, props) => {
        const newFiles = prevState.finished ? acceptedFiles : prevState.uploadFiles.concat(acceptedFiles);
        const newState = new FileUploadFormState(prevState);
        newState.uploadFiles = newFiles;
        return newState;
      });
    }
  }

  render() {
    const maximumExceeded = (this.state.maxUploadSize < getTotalUploadSize(this.state.uploadFiles));
    const maxSize = humanFileSize(this.state.maxUploadSize);
    const totalSize = humanFileSize(getTotalUploadSize(this.state.uploadFiles));

    return (
        <Form>
          <FileUploadControls fileUploadFormState={this.state}
                              updateFileSelection={this.updateFileSelection}
                              allowedFileExtension={this.props.uploadOptions.allowedFileExtension}
                              allowMultipleFiles={this.props.uploadOptions.allowMultipleFiles}/>
          <FormGroup controlId="fileSelectionGroup">
            <FileDrop onDrop={this.updateFileSelection} baseClassName="controlroom-dnd"
                      disabled={this.state.isUploading}>
              <div className="controlroom-dnd-hint text-center" style={{minHeight: "200px"}}>
                <h3>
                  <FormattedMessage id="studio.file-upload-form.drop-files-here"
                                    defaultMessage="Drop files here"
                  />
                </h3>
              </div>
            </FileDrop>
          </FormGroup>

          <FormGroup>
            <UploadFiles fileUploadFormState={this.state} removeFile={this.removeFile}/>
          </FormGroup>

          <FormGroup>
            <FileUploadStartCancelButton fileUploadFormState={this.state}
                                         startUpload={this.startUpload}
                                         cancelUpload={this.cancelUpload}
                                         resetUpload={this.resetUpload}/>
          </FormGroup>

          <FormGroup validationState={maximumExceeded ? "error" : null} className="form-upload-filesize-help">
            <HelpBlock>
              <FormattedMessage id="studio.file-upload-form.form-upload-filesize-help-description"
                                defaultMessage="The maximum upload size {maxSize}. The total size of the selected files is {totalSize}."
                                values={{maxSize, totalSize}}
              />
            </HelpBlock>
          </FormGroup>
        </Form>
    );
  }

}

export const FileUploadForm = WithApi(injectIntl(FileUploadFormComponent));
