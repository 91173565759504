import {WarningsModalMeta} from "./ModalWarnings";

export const actionTypes = {
  RAISE_WARNINGS: "controlRoom/RAISE_WARNINGS",
  CLEAR_WARNINGS: "controlRoom/CLEAR_WARNINGS",
};

export function warningCreator(warningMessages, modalMeta: WarningsModalMeta) {
  return {
    type: actionTypes.RAISE_WARNINGS,
    payload: Object.assign({}, modalMeta, {messages: warningMessages}),
  };
}

export function clearWarnings() {
  return {
    type: actionTypes.CLEAR_WARNINGS,
  };
}
