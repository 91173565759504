import * as React from "react";
import {Panel} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {Layer} from "./Layer";

interface LayerTreeProps {
  map: any;
}
interface LayerTreeState {
  riaLayers: any;
}
export class LayerTree extends React.Component<LayerTreeProps, LayerTreeState> {

  registryHandlers: any[] = [];

  constructor(props) {
    super(props);

    this.state = {riaLayers: null};
    this.resetLayers = this.resetLayers.bind(this);
    this.fit = this.fit.bind(this);
  }

  componentDidUpdate() {
    if (!this.state.riaLayers && this.props.map) {
      const map = this.props.map;
      this.registryHandlers.push(map.layerTree.on("NodeAdded", this.resetLayers));
      this.registryHandlers.push(map.layerTree.on("NodeRemoved", this.resetLayers));
      this.registryHandlers.push(map.layerTree.on("NodeMoved", this.resetLayers));

      this.resetLayers();
    }
  }

  componentWillUnmount() {
    this.registryHandlers.forEach((handler) => handler.remove());
    this.registryHandlers.length = 0;
  }

  resetLayers() {
    const {map} = this.props;
    if (map && map.layerTree) {
      const riaLayers = map.layerTree.children;
      this.setState({riaLayers});
    }
  }

  fit(layer) {
    if (layer) {
      const fitBounds = layer._internalBounds || layer.bounds;
      this.props.map.mapNavigator.fit(fitBounds);
    }
  }

  render() {
    const {riaLayers} = this.state;
    const tableRows = riaLayers ?
                      riaLayers.map(
                          (layer) => <Layer layer={layer} key={layer.id} onFit={() => this.fit(layer)}/>).reverse() :
                      (<tr>
                        <td>
                          <FormattedMessage id="studio.ria-map.layer-tree.no-layers"
                                            defaultMessage="no layers"/>
                        </td>
                      </tr>);

    return (
        <Panel header="Layers" className="layer-control" collapsible defaultExpanded={true}>
          <table style={{width: "100%"}}>
            <tbody>
            {tableRows}
            </tbody>
          </table>
        </Panel>
    );
  }
}
