import {createLoadDataReducer} from "../../common/util/asyncdata/reducerUtil";
import {GET_DATA_ROOTS_PREFIX} from "./actions";
import {DataRoot} from "./model";
import {moduleName} from "./selectors";

const reducer = createLoadDataReducer<DataRoot[]>(GET_DATA_ROOTS_PREFIX, []);

export {
  moduleName,
  reducer,
};
