import * as React from "react";
import {MenuItem, NavDropdown} from "react-bootstrap";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {LinkContainer} from "react-router-bootstrap";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {Logger} from "../common/util/Logger";
import {Modal} from "react-bootstrap/lib";

const HELP_MESSAGES = defineMessages({
  help: {
    id: "studio.navbar.help",
    defaultMessage: "Help",
  },
  userGuide: {
    id: "studio.navbar.help.user-guide",
    defaultMessage: "User Guide",
  },
  productVersion: {
    id: "studio.navbar.help.product-version",
    defaultMessage: "Version",
  },
  productUse: {
    id: "studio.navbar.help.product-use",
    defaultMessage: "You are using",
  },
});

interface HelpNavDropdownState {
  productName: string;
  productVersion: string;
  showProductVersionModel: boolean;
}


class HelpNavDropdownComponent extends React.Component<InjectedIntlProps & WithApiProperties, HelpNavDropdownState> {

  _logger: Logger = Logger.getLogger("help.HelpNavDropdown");

  constructor(props, context) {
    super(props, context);
    this.state = {
      productName: null,
      productVersion: null,
      showProductVersionModel: false,
    };
  }

  showVersionModal = (eventKey) => {
    this.props.api.getVersion().then((productInfo) => {
      this.setState(Object.assign({}, this.state, {productVersion: productInfo.version, productName: productInfo.name, showProductVersionModel: true}));
    }).catch((error) => {
      this._logger.error("Error occurred while requesting version", error);
    });
  }

  hideVersionModal = () => {
    this.setState(Object.assign({}, this.state, {showProductVersionModel: false}));
  }

  render() {
    const {intl} = this.props;
    const {productName, productVersion, showProductVersionModel} = this.state;
    return (
        <>
          <NavDropdown className="user-tour-help-page-nav" eventKey={"help"} id="helpDropDown" title={intl.formatMessage(HELP_MESSAGES.help)}>
            <LinkContainer to="/help">
              <MenuItem eventKey={"userGuide"} key={"userGuide"}>
                {intl.formatMessage(HELP_MESSAGES.userGuide)}
              </MenuItem>
            </LinkContainer>
            <MenuItem divider></MenuItem>
            <MenuItem eventKey={"productVersion"} key={"productVersion"} onSelect={this.showVersionModal}>
              {intl.formatMessage(HELP_MESSAGES.productVersion)}
            </MenuItem>
          </NavDropdown>
          <Modal show={showProductVersionModel} onHide={this.hideVersionModal} backdrop="static">
            <Modal.Header closeButton={true}>
              {intl.formatMessage(HELP_MESSAGES.productVersion)}
            </Modal.Header>
            <Modal.Body>
              <div className={"product-version-message"}>
                {intl.formatMessage(HELP_MESSAGES.productUse)}&nbsp;{productName}&nbsp;{productVersion}
              </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </Modal>
        </>
    );
  }
}

export const HelpNavDropdown = injectIntl<{}>(connect()(WithApi(HelpNavDropdownComponent)));
