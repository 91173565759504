import * as React from "react";
import {defineMessages, FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {createRefreshButtonComponent} from "../common/ui/RefreshButton";
import {actions} from "./actions";
import {Style} from "./model";
import InjectedIntlProps = ReactIntl.InjectedIntlProps;

const RefreshButtonComponent = createRefreshButtonComponent<Style>();

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRefresh: () => {
    return dispatch(actions.refreshStyle(ownProps.style.id));
  },
});

interface RefreshStyleButtonDispatchProps {
  onRefresh: () => Promise<Style>;
}

interface RefreshStyleButtonProps {
  style: Style;
  onRefreshSuccess: (Style) => void;
  onRefreshError: () => void;
}

const STYLE_MESSAGES = defineMessages({
  refreshDataHeader: {
    id: "studio.styles.refresh-style-button.refresh-style-header",
    defaultMessage: "Refresh style {Title}",
  },
  whileRefreshData: {
    id: "studio.styles.refresh-style-button.while-refresh-style",
    defaultMessage: "Refreshing style...",
  },
  refreshButtonData: {
    id: "studio.styles.refresh-style-button.refresh-button-style",
    defaultMessage: "Refresh Style",
  },
  refreshDataSuccess: {
    id: "studio.styles.refresh-style-button.refresh-success",
    defaultMessage: "Style successfully refreshed",
  },
  buttonTitleData: {
    id: "studio.styles.refresh-style-button.refresh-style",
    defaultMessage: "Refresh",
  },
});

class RefreshStyleButtonComponent extends React.Component<RefreshStyleButtonProps & InjectedIntlProps & RefreshStyleButtonDispatchProps, {}> {

  render() {
    const {style, onRefresh, onRefreshSuccess, onRefreshError, intl} = this.props;
    return <RefreshButtonComponent
        intl={this.props.intl}
        onRefresh={onRefresh}
        onRefreshSuccess={onRefreshSuccess}
        onRefreshError={onRefreshError}
        dialogTitle={intl.formatMessage(STYLE_MESSAGES.refreshDataHeader, {Title: style.title})}
        buttonTitle={intl.formatMessage(STYLE_MESSAGES.buttonTitleData)}
        successMessage={intl.formatMessage(STYLE_MESSAGES.refreshDataSuccess)}
        executingMessage={intl.formatMessage(STYLE_MESSAGES.whileRefreshData)}
        confirmButtonText={intl.formatMessage(STYLE_MESSAGES.refreshButtonData)}
        renderConfirmation={() => {
          return (
              <div>
                <FormattedMessage id="studio.styles.refresh-style-button.confirmation-body" defaultMessage="Refreshing will update the style with any changes made to the source file."/>
              </div>
          );
        }}
    />;
  }

}

export const RefreshStyleButton = injectIntl(connect(null, mapDispatchToProps)(RefreshStyleButtonComponent));
