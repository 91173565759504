import {combineReducers} from "redux";
import {createPagedDataReducer, PagedDataState} from "../common/util/pagination/PaginationUtil";
import {Product} from "../products/model";
import {actionTypes, SERVICE_ACTION_TYPENAME} from "./actions";
import {filterReducer, FilterState} from "./filter/reducer";
import {Service, ServiceStatus, ServiceTypeDetails} from "./model";
import {moduleName} from "./selectors";

interface ServiceProductsById {
  [serviceId: number]: Product[];
}

export interface ServicesState {
  pages: PagedDataState<Service>;
  filter: FilterState;
  serviceProductsById: ServiceProductsById;
  allServiceTypes: ServiceTypeDetails[];
  enabledServiceTypes: ServiceTypeDetails[];
}

const serviceProductsById = (state: ServiceProductsById = {}, action): ServiceProductsById => {
  switch (action.type) {
  case actionTypes.LOAD_SERVICE_PRODUCTS_SUCCESS:
    return {[action.payload.serviceId]: [...action.payload.products]};
  default:
    return state;
  }
};

const allServiceTypeReducer = (state: ServiceTypeDetails[] = [], action) => {
  switch (action.type) {
  case actionTypes.LOAD_ALL_SERVICE_TYPES:
    const serviceConfigurations = action.payload.serviceConfigurations;
    serviceConfigurations.forEach((serviceConfiguration) => {
      serviceConfiguration["value"] = serviceConfiguration.serviceType;
      serviceConfiguration["label"] = serviceConfiguration.serviceType.toLocaleUpperCase();
    });
    return serviceConfigurations;
  default:
    return state;
  }
};

const enabledServiceTypeReducer = (state: ServiceTypeDetails[] = [], action) => {
  switch (action.type) {
  case actionTypes.LOAD_ENABLED_SERVICE_TYPES:
    const serviceConfigurations = action.payload.serviceConfigurations;
    serviceConfigurations.forEach((serviceConfiguration) => {
      serviceConfiguration["value"] = serviceConfiguration.serviceType;
      serviceConfiguration["label"] = serviceConfiguration.serviceType.toLocaleUpperCase();
    });
    return serviceConfigurations;
  default:
    return state;
  }
};

const basePagedServiceReducer = createPagedDataReducer<Service>(SERVICE_ACTION_TYPENAME);
const defaultState = basePagedServiceReducer(undefined, {type: "init"}) as PagedDataState<Service>;
const extendedPagedServiceReducer = (state: PagedDataState<Service> = defaultState, action) => {

  switch (action.type) {
  case actionTypes.START_SERVICE:
    return serviceStatusChange(action.payload.id, action.payload.service.status);
  case actionTypes.STOP_SERVICE:
    return serviceStatusChange(action.payload.id, ServiceStatus.STOPPED);
  case actionTypes.UPDATE_SERVICE:
    //don't update services that are not tracked in state
    if (!state.data[action.payload.service.id]) {
      return state;
    }
    const oldData = state.data;
    const newData = Object.assign({}, oldData, {[action.payload.service.id]: action.payload.service});
    return Object.assign({}, state, {data: newData});
  }
  return basePagedServiceReducer(state, action);

  function serviceStatusChange(statusId, status) {
    const oldData = state.data;
    const service = oldData[statusId];
    if (!service) {
      // service is not yet in the redux => do nothing (case for data quick publish)
      return state;
    }
    const updatedService = Object.assign({}, oldData[statusId], {status});
    const newData = Object.assign({}, oldData, {[statusId]: updatedService});
    return Object.assign({}, state, {data: newData});
  }
};

const reducer = combineReducers<ServicesState>({
  filter: filterReducer,
  pages: extendedPagedServiceReducer,
  serviceProductsById,
  allServiceTypes: allServiceTypeReducer,
  enabledServiceTypes: enabledServiceTypeReducer,
});

interface ServiceToProductMapEntry {
  serviceId: string;
  products: Product[];
}

const createTestState = (services: Service[] = [], productsByService: ServiceToProductMapEntry[] = []) => {
  let state = reducer(undefined, {type: "init"});

  //add one page with all services
  state = reducer(state, {
    type: actionTypes.SET_PAGE_SIZE,
    payload: services.length,
  });
  state = reducer(state, {
    type: actionTypes.REQUEST_PAGE,
    payload: 0,
  });
  state = reducer(state, {
    type: actionTypes.RECEIVE_PAGE_SUCCESS,
    payload: {data: services, pageNumber: 0},
  });

  productsByService.forEach((entry) => {
    state = reducer(state, {
      type: actionTypes.LOAD_SERVICE_PRODUCTS_SUCCESS,
      payload: {
        serviceId: entry.serviceId,
        products: entry.products,
      },
    });
  });
  return {[moduleName]: state};
};

export {
  moduleName,
  reducer,
  createTestState,
};
