import {StyleSearchFilter} from "./model";

export const actionTypes = {
  SET_STYLES_FILTER: "controlRoom/SET_STYLES_FILTER",
};

const setFilter = (newFilter: StyleSearchFilter) => {
  return {
    type: actionTypes.SET_STYLES_FILTER,
    payload: {
      filter: newFilter,
    },
  };
};

export const actions = {
  setFilter,
};
