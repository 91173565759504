import {combineReducers} from "redux";
import {createPagedDataReducer, PagedDataState} from "../common/util/pagination/PaginationUtil";
import {actionTypes} from "./actions";
import {StyleSearchFilter} from "./filter/model";
import {filterReducer} from "./filter/reducer";
import {Style} from "./model";
import {moduleName} from "./selectors";

interface StyleUpdateNotificationState {
  numberOfStylesAddedOnServer: number;
}
export interface StyleState {
  filter: StyleSearchFilter;
  pages: PagedDataState<Style>;
  styleUpdates: StyleUpdateNotificationState;
}

const initialUpdateState: StyleUpdateNotificationState = {
  numberOfStylesAddedOnServer: 0,
};

const styleUpdatesReducer = (state: StyleUpdateNotificationState = initialUpdateState, action) => {
  switch (action.type) {
  case actionTypes.STYLE_ADDED_ON_SERVER:
    return Object.assign({}, state, {numberOfStylesAddedOnServer: state.numberOfStylesAddedOnServer + 1});
  case actionTypes.CLEAR_DATA:
    return Object.assign({}, state, {numberOfStylesAddedOnServer: 0});
  }
  return state;
};

const pagedStyleReducer = createPagedDataReducer<Style>("STYLE");
const reducer = combineReducers({
  filter: filterReducer,
  pages: pagedStyleReducer,
  styleUpdates: styleUpdatesReducer,
});

const createTestState = () => {
  return {
    [moduleName]: reducer(undefined, {type: "init"}),
  };
};

export {
  moduleName,
  reducer,
  createTestState,
};
