import * as React from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {ColumnsDescription, ColumnWidth} from "../common/ui/table/ControlRoomColumns";
import {createBasicTableComponent} from "../common/ui/table/ControlRoomTable";
import {connectPagedTable, PagedTableConfig} from "../common/ui/table/PagedTable";
import {DateUtil} from "../common/util/DateUtil";
import {actions} from "./actions";
import {Style} from "./model";
import {selectors} from "./selectors";

const config: PagedTableConfig = {
  canMultiSelect: true,
  noItemsFoundMessage: <FormattedMessage id="studio.styles-list.none-found" defaultMessage="No styles found"/>,
};

const MESSAGES = defineMessages({
  createdByUnknown: {
    id: "studio.styles-list.created-by.unknown",
    defaultMessage: "Unknown",
  },
});

const columns: ColumnsDescription<Style> = {
  title: {
    header: <FormattedMessage id="studio.styles-list.title" defaultMessage="Title"/>,
    cellContent: (style) => <Link to={`/styles/${style.id}`}>{style.title}</Link>,
    cellTooltip: (style) => style.title,
    columnWidth: ColumnWidth.flex(1),
  },
  path: {
    header: <FormattedMessage id="studio.styles-list.path" defaultMessage="Path"/>,
    cellContent: (style) => style.filePath,
    cellTooltip: (style) => style.filePath,
    columnWidth: ColumnWidth.flex(3),
  },
  type: {
    header: <FormattedMessage id="studio.styles-list.type" defaultMessage="Type"/>,
    cellContent: (style) => style.type,
    cellTooltip: (style) => style.type,
    columnWidth: ColumnWidth.TYPE_COLUMN_WIDTH,
  },
  createdBy: {
    header: <FormattedMessage id="studio.styles-list.created-by" defaultMessage="Created By"/>,
    cellContent: (style, index, selected, data, selectedRowIndices) => style.createdBy ? style.createdBy.username :
      <FormattedMessage id="studio.styles-list.created-by.unknown" defaultMessage="Unknown"/>,
    cellTooltip: (style, intl) => style.createdBy ? style.createdBy.username : intl.formatMessage(MESSAGES.createdByUnknown),
    columnWidth: ColumnWidth.USERNAME_COLUMN_WIDTH,
  },
  creationTime: {
    header: <FormattedMessage id="studio.styles-list.date-created" defaultMessage="Date Created"/>,
    cellContent: (style) => DateUtil.formatDateTime(style.creationTime),
    cellTooltip: (style) => DateUtil.formatDateTime(style.creationTime),
    columnWidth: ColumnWidth.DATETIME_COLUMN_WIDTH,
  },
};

export const StylesListComponent = createBasicTableComponent(columns);
export const StylesList = connectPagedTable(StylesListComponent, selectors.getPagedState, actions.requestPage, config);
