import * as React from "react";
import {Col, Grid} from "react-bootstrap";

export interface StartupStatusProps {
  title?: string;
  message?: string;
}

export class StartupStatus extends React.Component<StartupStatusProps, {}> {

  render() {
    const emptyTitle = "...";
    const {title, message} = this.props;
    return title ?
      <Grid>
        <Col xs={12} md={10} mdOffset={1}>
          <h1>{title}</h1>
          <p>{message}</p>
        </Col>
      </Grid>
    :
      <Grid>
       <Col xs={2} xsOffset={5}>
         <h1>{emptyTitle}</h1>
       </Col>
     </Grid>;
  }
}
