import * as React from "react";
import {Button, Grid} from "react-bootstrap";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {ActionBar} from "../common/ui/actionbar/ActionBar";
import {DetailHeader} from "../common/ui/detailsection/DetailHeader";
import {DetailSection, DetailSectionProps} from "../common/ui/detailsection/DetailSection";
import {DetailView, Formats} from "../common/ui/detailsection/DetailView";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {MasterDetailLayout} from "../common/ui/layouts/MasterDetailLayout";
import {FileUploadAndInjectedProps, WithFileUpload} from "../common/ui/upload/WithFileUpload";
import {Logger} from "../common/util/Logger";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {AddToProductButton} from "../products/AddToProductButton";
import {CreateProductButton} from "../products/CreateProductButton";
import {PublishDataInServiceButton} from "../services/CreateServiceButton";
import {AddDataButton} from "./AddDataButton";
import {DataOutdatedWarning} from "./DataOutdatedWarning";
import {DeleteDataButton} from "./DeleteDataButton";
import {ImportedDataFilter} from "./ImportedDataFilter";
import {ImportedDataList} from "./ImportedDataList";
import {ImportedData} from "./model";

interface DataListState {
  selection: ImportedData[];
}

class DataListDetailSectionComponent extends DetailSection<DetailSectionProps<ImportedData> & WithApiProperties & InjectedIntlProps, ImportedData> {

  loadData(props: DetailSectionProps<ImportedData>): Promise<ImportedData> {
    if (props.selection.length === 1) {
      return this.props.api.getImportedDataById(props.selection[0].id);
    }
    return Promise.resolve(null);
  }

  renderContent() {
    const {isLoading, error} = this.state;
    const {selection} = this.props;

    if (selection.length === 0) {
      return (
          <div className="emptyDetailPlaceholder">
            <FormattedMessage id="studio.data.data-list-page.select-data"
                              defaultMessage="Select some data in the table."/>
          </div>
      );
    }
    if (selection.length > 1) {
      return <p><FormattedMessage id="studio.data.data-list-page.item-length" defaultMessage="Selected {length} items."
                                  values={{length: selection.length}}/></p>;
    }
    if (selection.length === 1 && this.state.data && !isLoading && !error) {
      const data = this.state.data;
      return (
          <div className="details">
            <DetailHeader title={data.title}/>
            <DetailView fields={[
              {
                key: "Path",
                name: <FormattedMessage id="studio.data.data-list-page.data-detail.path" defaultMessage="Path"/>,
                value: data.filePath,
              },
              {
                key: "Format",
                name: <FormattedMessage id="studio.data.data-list-page.data-detail.format" defaultMessage="Format"/>,
                value: data.type,
              },
              {
                key: "File Size",
                name: <FormattedMessage id="studio.data.data-list-page.data-detail.file-size" defaultMessage="File size"/>,
                value: data.fileMetadata.size,
                format: Formats.FILESIZE,
              },
            ]}/>
          </div>
      );
    }
    return super.renderContent();
  }
}

const DataListDetailSection = WithApi(injectIntl(DataListDetailSectionComponent));

class DataListPageComponent extends React.Component<FileUploadAndInjectedProps & WithApiProperties & InjectedIntlProps, DataListState> {
  _logger = Logger.getLogger("data.DataListPage");

  constructor(props) {
    super(props);
    this.state = {
      selection: [],
    };
  }

  handleRowSelect = (selection: ImportedData[]) => {
    this.setState(Object.assign({}, this.state, {selection}));
  }

  clearSelection = () => this.handleRowSelect([]);

  render() {
    const {selection} = this.state;
    const noSelection = selection.length === 0;

    const masterPane = (
        <Grid fluid={true}>
          <ActionBar>
            <div>
            <Button id={"dataFileUploadButton"}
                    bsStyle="primary"
                    onClick={this.props.openUpload}>
              <LcdIcon icon="upload"/>
              <FormattedMessage
                id="studio.data.data-list-page.upload"
                defaultMessage=" Upload"/>
            </Button>
            </div>
            <AddDataButton onAddSuccess={this.clearSelection}/>
          </ActionBar>

          <ImportedDataFilter notifyFilterChanged={this.clearSelection}/>

          <ActionBar>
            <CreateProductButton disabled={noSelection} initialData={selection}/>
            <AddToProductButton disabled={noSelection} initialData={selection}/>
            <PublishDataInServiceButton disabled={noSelection} inputData={selection}/>
            <DeleteDataButton disabled={noSelection} data={selection} onDeleteSuccess={this.clearSelection} onDeleteError={Function.prototype}
                              onDeleteWarnings={this.clearSelection} onCloseWarnings={Function.prototype}/>
          </ActionBar>

          <div> {/* This empty div is necessary! it's used by ControlRoomTable to calculate its dimensions.*/}
            <ImportedDataList className="user-tour-imported-data-list"
                              onRowSelect={this.handleRowSelect}
                              clearSelection={noSelection}/>
          </div>

          <DataOutdatedWarning/>
        </Grid>
    );
    return (
        <div>
          <MasterDetailLayout masterPane={masterPane} detailPane={<DataListDetailSection selection={selection}/>}/>
        </div>
    );
  }
}

export const DataListPage = WithFileUpload()(injectIntl(WithApi(DataListPageComponent)));
