import * as React from "react";
import {Button, Grid} from "react-bootstrap";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {ActionBar} from "../common/ui/actionbar/ActionBar";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {MasterDetailLayout} from "../common/ui/layouts/MasterDetailLayout";
import {FileUploadAndInjectedProps, WithFileUpload} from "../common/ui/upload/WithFileUpload";
import {Logger} from "../common/util/Logger";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {AddStyleButton} from "./AddStyleButton";
import {DeleteStyleButton} from "./DeleteStyleButton";
import {StylesFilter} from "./filter/StylesFilter";
import {Style} from "./model";
import {StylesList} from "./StylesList";
import {StylesListDetailSection} from "./StylesListDetailSection";
import {StylesOutdatedWarning} from "./StylesOutdatedWarning";

interface StylesListState {
  selection: Style[];
}

class StylesPageReactComponent extends React.Component<InjectedIntlProps & FileUploadAndInjectedProps & WithApiProperties, StylesListState> {
  _logger = Logger.getLogger("styles.StylesPage");

  constructor(props) {
    super(props);
    this.state = {
      selection: [],
    };
  }

  handleRowSelect = (selectedStyles: Style[]) => {
    this.setState({selection: selectedStyles});
  }

  clearSelection = () => this.handleRowSelect([]);
  render() {
    const {selection} = this.state;
    const noSelection = selection.length === 0;

    const MasterPane = (
        <Grid fluid={true}>
          <ActionBar>
            <div>
            <Button id={"styleFileUploadButton"}
                    bsStyle="primary"
                    className="style-data-tour-upload-button"
                    onClick={this.props.openUpload}>
              <LcdIcon icon="upload"/>
              <FormattedMessage id="studio.styles-page.upload-button"
                                defaultMessage="Upload"/>
            </Button>
            </div>
            <AddStyleButton onAddSuccess={this.clearSelection}/>
          </ActionBar>

          <StylesFilter notifyFilterChanged={this.clearSelection}/>

          <ActionBar>
            <DeleteStyleButton disabled={noSelection} styles={selection} onDeleteSuccess={this.clearSelection} onDeleteError={Function.prototype}
                               onDeleteWarnings={this.clearSelection} onCloseWarnings={Function.prototype}/>
          </ActionBar>

          <div> {/* This empty div is necessary! it's used by ControlRoomTable to calculate its dimensions.*/}
            <StylesList className="style-data-tour-styles-list"
                        onRowSelect={this.handleRowSelect}
                        clearSelection={noSelection}/>
          </div>
          <StylesOutdatedWarning />
        </Grid>
    );

    const detailPane = <StylesListDetailSection selection={selection}/>;

    return (
        <MasterDetailLayout masterPane={MasterPane} detailPane={detailPane}/>
    );
  }
}

export const StylesPageComponent = WithFileUpload()(StylesPageReactComponent);
export const StylesPage = WithApi(injectIntl(StylesPageComponent));
