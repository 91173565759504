import * as React from "react";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import {MetadataModel} from "../../../model";
import {DetailView, Formats} from "./DetailView";

const DETAIL_META_MESSAGES = defineMessages({
  title: {
    id: "studio.detail-meta.title",
    defaultMessage: "Title",
  },
  abstract: {
    id: "studio.detail-meta.abstract",
    defaultMessage: "Abstract",
  },
  keywords: {
    id: "studio.detail-meta.keywords",
    defaultMessage: "Keywords",
  },
});

interface DetailMetaProps {
  metadata: MetadataModel;
  updateEntity: (updatedEntity: MetadataModel) => any;

  // These are used when different behavior is needed for some specific metadata
  // e.g. When changing product abstract or keywords we don't need to check if the product is part of a service
  updateTitle?: (newTitle: string) => void;
  updateAbstract?: (newAbstract: string) => void;
  updateKeywords?: (newKeywords: string[]) => void;
}

export class DetailMetaComponent extends React.Component<InjectedIntlProps & DetailMetaProps, {}> {

  handleMeta = (pieceOfMetadata) => Object.assign({}, this.props.metadata, pieceOfMetadata);

  abstractOnChange = (newAbstract) => {
    if (this.props.updateAbstract) {
      return this.props.updateAbstract(this.handleMeta({abstractText: newAbstract}));
    }
    return this.props.updateEntity(this.handleMeta({abstractText: newAbstract}));
  }
  titleOnChange = (newTitle) => {
    if (this.props.updateTitle) {
      return this.props.updateTitle(this.handleMeta({title: newTitle}));
    }
    return this.props.updateEntity(this.handleMeta({title: newTitle}));
  }
  keywordsOnChange = (newKeywords) => {
    const keywordAsArray = newKeywords.split(",").map((str) => str.trim());
    if (this.props.updateKeywords) {
      return this.props.updateKeywords(this.handleMeta({keywords: keywordAsArray}));
    }
    return this.props.updateEntity(this.handleMeta({keywords: keywordAsArray}));
  }

  render() {
    const {metadata} = this.props;
    const keywordsValue = metadata && metadata.keywords ? metadata.keywords.join(", ") : "";

    return (
        <DetailView fields={[
          {
            key: "Title",
            name: this.props.intl.formatMessage(DETAIL_META_MESSAGES.title),
            value: metadata.title,
            format: Formats.TEXT_EDIT,
            onChange: this.titleOnChange,
          },
          {
            key: "Abstract",
            name: this.props.intl.formatMessage(DETAIL_META_MESSAGES.abstract),
            value: metadata.abstractText,
            format: Formats.TEXT_EDIT,
            onChange: this.abstractOnChange,
          },
          {
            key: "Keywords",
            name: this.props.intl.formatMessage(DETAIL_META_MESSAGES.keywords),
            value: keywordsValue,
            format: Formats.TEXT_EDIT,
            onChange: this.keywordsOnChange,
          },
        ]}/>
    );
  }
}

export const DetailMeta = injectIntl(DetailMetaComponent);
