import * as React from "react";
import {defineMessages, FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {createDeleteButtonComponent} from "../common/ui/DeleteButton";
import {actions} from "./actions";
import {Style} from "./model";
import {DeleteResponse} from "../common/model";
import InjectedIntlProps = ReactIntl.InjectedIntlProps;

const DeleteButtonComponent = createDeleteButtonComponent();

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDelete: () => {
    return dispatch(actions.deleteStyles(ownProps.styles.map((d) => d.id)));
  },
});

interface DeleteStyleButtonDispatchProps {
  onDelete: () => Promise<DeleteResponse>;
}

interface DeleteStyleButtonProps {
  styles: Style[];
  disabled: boolean;
  onDeleteSuccess: () => void;
  onDeleteError: () => void;
  onDeleteWarnings: () => void;
  onCloseWarnings: () => void;
  detail?: boolean;
}

const STYLE_MESSAGES = defineMessages({
  removeStylesHeader: {
    id: "studio.styles.delete-style-button.delete-styles-header",
    defaultMessage: "Remove {Amount} styles",
  },
  removeStyleHeader: {
    id: "studio.styles.delete-style-button.delete-style-header",
    defaultMessage: "Remove style {Title}",
  },
  removeStylesBody: {
    id: "studio.styles.delete-style-button.remove-styles-body",
    defaultMessage: "Are you sure you want to continue?",
  },
  whileRemoveStyles: {
    id: "studio.styles.delete-style-button.while-remove-styles",
    defaultMessage: "Removing styles...",
  },
  whileRemoveStyle: {
    id: "studio.styles.delete-style-button.while-remove-style",
    defaultMessage: "Removing style...",
  },
  deleteButtonStyles: {
    id: "studio.styles.delete-style-button.delete-button-styles",
    defaultMessage: "Remove Styles",
  },
  deleteButtonStyle: {
    id: "studio.styles.delete-style-button.delete-button-style",
    defaultMessage: "Remove Style",
  },
  removeStylesSuccess: {
    id: "studio.styles.delete-style-button.remove-success",
    defaultMessage: "Style(s) successfully removed",
  },
  tooltipDisabled: {
    id: "studio.styles.delete-style-button.no-selection-tooltip",
    defaultMessage: "Select some styles from the list below",
  },
  buttonTitleStyles: {
    id: "studio.styles.delete-style-button.delete-styles",
    defaultMessage: "Remove styles",
  },
  buttonTitleStyle: {
    id: "studio.styles.delete-style-button.delete-style",
    defaultMessage: "Remove style",
  },
  buttonTitleStyleDetail: {
    id: "studio.styles.delete-style-button.remove",
    defaultMessage: "Remove",
  },
});

class DeleteStyleButtonComponent extends React.Component<DeleteStyleButtonProps & InjectedIntlProps & DeleteStyleButtonDispatchProps, {}> {

  render() {
    const {styles, disabled, onDelete, onDeleteSuccess, onDeleteError, onDeleteWarnings, onCloseWarnings, intl, detail} = this.props;
    let headerText = intl.formatMessage(STYLE_MESSAGES.removeStylesHeader, {Amount: styles.length});
    let whileExecutingText = intl.formatMessage(STYLE_MESSAGES.whileRemoveStyles);
    let deleteButtonText = intl.formatMessage(STYLE_MESSAGES.deleteButtonStyles);
    let buttonTitle = intl.formatMessage(STYLE_MESSAGES.buttonTitleStyles);
    if (styles && styles.length === 1) {
      headerText = intl.formatMessage(STYLE_MESSAGES.removeStyleHeader, {Title: styles[0].title});
      whileExecutingText = intl.formatMessage(STYLE_MESSAGES.whileRemoveStyle);
      deleteButtonText = intl.formatMessage(STYLE_MESSAGES.deleteButtonStyle);
      if (detail) {
        buttonTitle = intl.formatMessage(STYLE_MESSAGES.buttonTitleStyleDetail);
      } else {
        buttonTitle = intl.formatMessage(STYLE_MESSAGES.buttonTitleStyle);
      }
    }
    return <DeleteButtonComponent
        intl={this.props.intl}
        disabled={disabled}
        onDelete={onDelete}
        onDeleteSuccess={onDeleteSuccess}
        onDeleteError={onDeleteError}
        onDeleteWarnings={onDeleteWarnings}
        onCloseWarnings={onCloseWarnings}
        dialogTitle={headerText}
        buttonTitle={buttonTitle}
        detail={detail}
        tooltipDisabled={intl.formatMessage(STYLE_MESSAGES.tooltipDisabled)}
        successMessage={intl.formatMessage(STYLE_MESSAGES.removeStylesSuccess)}
        executingMessage={whileExecutingText}
        confirmButtonText={deleteButtonText}
        renderConfirmation={() => {
          return (
              <div>
                <strong> <FormattedMessage id="studio.styles.delete-style-button.confirmation-header" defaultMessage="Warnings:"/></strong>
                <ul>
                  <li><FormattedMessage id="studio.styles.delete-style-button.warning-1" defaultMessage="The style(s) will be removed from existing products and services"/></li>
                  <li><FormattedMessage id="studio.styles.delete-style-button.warning-uploaded-styles" defaultMessage="For uploaded styles, all related uploaded files will be deleted"/></li>
                  <li><FormattedMessage id="studio.styles.delete-style-button.warning-crawled-styles" defaultMessage="Crawled styles will be removed until the next run of the crawl job, unless the corresponding files are deleted from disk"/></li>
                </ul>
                <FormattedMessage id="studio.styles.delete-style-button.confirmation-body" defaultMessage="Are you sure you want to continue?"/>
              </div>
          );
        }}
    />;
  }

}

export const DeleteStyleButton = injectIntl(connect(null, mapDispatchToProps)(DeleteStyleButtonComponent));
