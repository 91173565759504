import {LoadDataState} from "../common/util/asyncdata/reducerUtil";
import {PagedDataState} from "../common/util/pagination/PaginationUtil";
import {FileInfo} from "../data/model";
import {JobState} from "./import/reducer";
import {CompositeJob, FileInfoSearchFilter, JobStatus, JobType} from "./model";

const moduleName = "Jobs";

export const importJobSubmoduleName = JobType.IMPORT;
export const preprocessJobSubmoduleName = JobType.PREPROCESS;

export const importJobModuleName = importJobSubmoduleName + moduleName;
export const preprocessJobModuleName = preprocessJobSubmoduleName + moduleName;

const getJobsState = (jobType: JobType, state): JobState => {
  return state[jobType + moduleName];
};

const getAllJobs = (jobType: JobType, state): CompositeJob[] => {
  const jobState = getJobsState(jobType, state);
  return jobState.allIds.map((id) => jobState.byId[id]);
};

const getJobsWithStatus = (jobType: JobType, state, status: JobStatus): CompositeJob[] => {
  return getAllJobs(jobType, state).filter((job) => job.jobState === status);
};

const getJobById = (jobType: JobType, state, id: string): CompositeJob => {
  const jobState = getJobsState(jobType, state);
  return jobState.byId[id] || null;
};

const getJobRequestState = (jobType: JobType, state): LoadDataState<CompositeJob[]> => {
  const jobState = getJobsState(jobType, state);
  return jobState.loadJobsRequest;
};

const getJobDetailRequestState = (jobType: JobType, state): LoadDataState<CompositeJob> => {
  const jobState = getJobsState(jobType, state);
  return jobState.loadJobDetailRequest;
};

const getImportJobFilesPagedState = (state): PagedDataState<FileInfo> => {
  return getJobsState(JobType.IMPORT, state).jobFilePages;
};

const getJobFilesFilter = (jobType: JobType, state): FileInfoSearchFilter => {
  const jobsState = getJobsState(jobType, state);
  return jobsState.filter;
};

const getCurrentJob = (jobType: JobType, state): CompositeJob => {
  const jobsState = getJobsState(jobType, state);
  return jobsState.byId[jobsState.currentJobId];
};

const getCurrentJobId = (jobType: JobType, state): string => {
  const jobsState = getJobsState(jobType, state);
  return jobsState.currentJobId;
};

export const selectors = {
  getAllJobs,
  getJobsWithStatus,
  getJobById,
  getJobRequestState,
  getJobDetailRequestState,
  getImportJobFilesPagedState,
  getJobFilesFilter,
  getCurrentJob,
  getCurrentJobId,
};
