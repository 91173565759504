import * as React from "react";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {Previewer} from "../../common/ui/riamap/Previewer";
import * as paths from "../../paths";
import {BackgroundDataInfo} from "../../common/ui/riamap/model";
import {Logger} from "../../common/util/Logger";
import {Alert} from "react-bootstrap/lib";
import {ChangeBackgroundDataButton} from "./ChangeBackgroundDataButton";
import {RevertBackgroundDataButton} from "./RevertBackgroundDataButton";

interface PreviewerPageState {
  loading: boolean;
  loadingFailed: boolean;
  backgroundDataInfo: BackgroundDataInfo;
  backgroundDataSequenceNumber: number;
}

class PreviewerPageComponent extends React.Component<WithApiProperties & InjectedIntlProps, PreviewerPageState> {

  _logger: Logger = Logger.getLogger("settings.MapPreviewPage");

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingFailed: false,
      backgroundDataInfo: null,
      backgroundDataSequenceNumber: 1,
    }
  }

  componentDidMount() {
    this.updateBackgroundDataInfo();
  }

  updateBackgroundDataInfo = () => {
    this.props.api.getBackgroundDataInfo().then((backgroundDataInfo) => {
      this.setState(Object.assign({}, this.state, {loading: false, loadingFailed: false, backgroundDataInfo, backgroundDataSequenceNumber: (this.state.backgroundDataSequenceNumber+1)}));
    }).catch((error) => {
      this._logger.error("Error occurred while requesting background data info", error);
      this.setState(Object.assign({}, this.state, {loading: false, loadingFailed: true}));
    });
  }

  renderBackgroundDataInfo() {
    const {loadingFailed, backgroundDataInfo, backgroundDataSequenceNumber} = this.state;
    if (loadingFailed) {
      return <Alert bsStyle="danger">
        <FormattedMessage id="studio.settings.map-preview.background-data.loading-failed"
                          defaultMessage="Failed to load background data"/>
      </Alert>
    }
    return (
        <div>
          <div className="backgroundData">
            <div className="backgroundDataFilePath">
              {backgroundDataInfo.currentFilePath ?
               backgroundDataInfo.currentFilePath :
               <FormattedMessage id="studio.settings.map-preview.background-data.not-set"
                                 defaultMessage="Not set"/>}
            </div>
            <div className="backgroundButtons">
              <ChangeBackgroundDataButton onSuccess={this.updateBackgroundDataInfo}/>
              {backgroundDataInfo.defaultFilePath ? <RevertBackgroundDataButton defaultBackgroundData={backgroundDataInfo.defaultFilePath} onSuccess={this.updateBackgroundDataInfo}/> : null}
            </div>
          </div>
          {backgroundDataInfo.currentFilePath ?
           <div className="backgroundPreviewer">
             <Previewer wmsBaseUrl={paths.PREVIEW_DATA_PATH} dataToVisualize={[]} shouldFit={true} backgroundDataPreview={true} backgroundSequenceNumber={backgroundDataSequenceNumber}
                        hideFitDataButton={true} height="400px"/>
           </div> : null}
        </div>
    );
  }

  render() {
    const {loading} = this.state;
    return (
        <div>
          <h1><FormattedMessage id="studio.settings.map-preview.header" defaultMessage="Map preview"/></h1>
          <hr/>
          <h3><FormattedMessage id="studio.settings.map-preview.background-data.header" defaultMessage="Background data"/>
          </h3>
          <p>
            <FormattedMessage id="studio.settings.map-preview.background-data.description"
                              defaultMessage="Configure the data that is used as background imagery in the map preview."/>
          </p>
          {loading ? null : this.renderBackgroundDataInfo()}
        </div>
    );
  }
}

export const MapPreviewPage = injectIntl(WithApi(PreviewerPageComponent));
