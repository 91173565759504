import * as React from "react";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap/lib";
import {InjectedIntlProps} from "react-intl";
import {ConfirmationDialog} from "./ConfirmationDialog";
import {LcdIcon} from "./icon/LcdIcon";
import {DeleteResponse, Warning} from "../model";

export interface DeleteButtonProps {
  disabled?: boolean;
  onDelete: () => Promise<DeleteResponse>;
  onDeleteSuccess: () => void;
  onDeleteError: () => void;
  onDeleteWarnings: () => void;
  onCloseWarnings: () => void;
  dialogTitle: string;
  buttonTitle: string;
  tooltipDisabled: string;
  renderConfirmation: () => JSX.Element;
  confirmButtonText: string;
  executingMessage: string;
  successMessage: string;
  detail?: boolean;
}

interface ButtonState {
  isDialogOpen: boolean;
  isExecuting: boolean;
  isSuccess: boolean;
  error: Error;
  warnings: Warning[];
}

export function createDeleteButtonComponent() {
  return class DeleteButtonComponent extends React.Component<DeleteButtonProps & InjectedIntlProps, ButtonState> {

    constructor(props) {
      super(props);
      this.state = {
        isDialogOpen: false,
        isExecuting: false,
        error: null,
        isSuccess: false,
        warnings: null,
      };
    }

    openDialog = () => {
      this.setState({...this.state, isExecuting: false, error: null, warnings: null, isSuccess: false, isDialogOpen: true});
    }

    closeDialog = () => {
      if (this.state.warnings) {
        this.props.onCloseWarnings();
      }
      this.setState({...this.state, isDialogOpen: false});
    }

    performDeletion = () => {
      this.setState({...this.state, isExecuting: true, error: null, warnings: null, isSuccess: false});
      return this.props.onDelete().then((warnings) => {
        if (warnings) {
          this.setState({...this.state, isExecuting: false, error: null, warnings, isSuccess: false});
          this.props.onDeleteWarnings();
        } else {
          this.setState({...this.state, isExecuting: false, error: null, warnings: null, isSuccess: true});
          this.props.onDeleteSuccess();
        }
      }).catch((error) => {
        this.setState({...this.state, isExecuting: false, error, warnings: null, isSuccess: false});
        this.props.onDeleteError();
      });
    }

    renderButton() {
      const {disabled, tooltipDisabled, buttonTitle, detail} = this.props;
      const deleteIcon =  detail ? "delete-filled-circle" : "delete-circle";
      const style = undefined;
      if (disabled) {
        return (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{tooltipDisabled}</Tooltip>}>
              <div style={{cursor: "not-allowed"}}>
                <Button bsStyle={style} className="actionBarItem" style={{pointerEvents: "none"}} onClick={this.openDialog} disabled>
                  <LcdIcon icon={deleteIcon}/> {buttonTitle}
                </Button>
              </div>
            </OverlayTrigger>);
      } else {
        return <Button bsStyle={style} className="actionBarItem" onClick={this.openDialog}>
          <LcdIcon icon={deleteIcon}/> {buttonTitle}
        </Button>;
      }
    }

    render() {
      const {isDialogOpen} = this.state;
      return (
          <div>
            {this.renderButton()}
            <ConfirmationDialog
                show={isDialogOpen}
                intl={this.props.intl}
                renderConfirmation={this.props.renderConfirmation}
                confirmButtonIcon={"delete"}
                confirmButtonText={this.props.confirmButtonText}
                onConfirm={this.performDeletion}
                executing={this.state.isExecuting}
                executingMessage={this.props.executingMessage}
                error={this.state.error}
                warnings={this.state.warnings}
                success={this.state.isSuccess}
                successMessage={this.props.successMessage}
                title={this.props.dialogTitle}
                onHide={this.closeDialog}
            />
          </div>
      );
    }
  };
}
