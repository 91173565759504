import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {
  CONTROLROOM_TABLE_HEADER_HEIGHT,
  CONTROLROOM_TABLE_ROW_HEIGHT,
  ControlRoomTableDispatchProperties,
  ControlRoomTableStateProperties,
  createBasicTableComponent,
} from "../../common/ui/table/ControlRoomTable";
import {isFinished} from "../../common/util/asyncdata/reducerUtil";
import {CompositeJob} from "../model";
import {importJobSubmoduleName, selectors} from "../selectors";
import {actions} from "./actions";
import {ImportJobColumns} from "./ImportJobColumns";

export const jobContentListDimensions = (parent: HTMLElement) => {
  return {
    width: parent.clientWidth,
    height: 8 * CONTROLROOM_TABLE_ROW_HEIGHT + CONTROLROOM_TABLE_HEADER_HEIGHT,
  };
};

export const JobsListComponent = createBasicTableComponent(ImportJobColumns, jobContentListDimensions);

const mapStateToProps = (state, ownProps): ControlRoomTableStateProperties<CompositeJob> => {
  return {
    data: selectors.getAllJobs(importJobSubmoduleName, state),
    isPaged: false,
    isAllDataLoaded: isFinished(selectors.getJobRequestState(importJobSubmoduleName, state)),
    noItemsFoundMessage: <FormattedMessage id="studio.import-job-list.no-items" defaultMessage="No crawl jobs found."/>,
  };
};

const mapDispatchToProps = (dispatch): ControlRoomTableDispatchProperties<CompositeJob> => {
  return {
    loadData: () => {
      dispatch(actions.loadJobs());
    },
  };
};
export const JobsList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(JobsListComponent));
