import * as React from "react";
import {connect} from "react-redux";
import {actions} from "./actions";
import {selectors} from "./selectors";
import {UserTour} from "./UserTour";

interface StyleDataTourStateProperties {
  styleDataTourCompleted: boolean;

  allTourSteps: any[];
  appLayoutRef: React.ReactInstance;
}

interface StyleDataTourDispatchProperties {
  setStyleDataTourCompleted: (isStyleDataTourCompleted: boolean) => void;
}

export class StyleDataTourComponent extends React.Component<StyleDataTourStateProperties & StyleDataTourDispatchProperties, {}> {
  render() {
    const {styleDataTourCompleted, setStyleDataTourCompleted} = this.props;
    return <UserTour
        appLayoutRef={this.props.appLayoutRef}
        allTourSteps={this.props.allTourSteps}
        tourCompleted={styleDataTourCompleted}
        setTourCompleted={setStyleDataTourCompleted}
        initialTourLocation={"/styles"}
    />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    styleDataTourCompleted: selectors.getStyleDataTourCompleted(state),
  };
};

const mapDispatchToProps = (dispatch): StyleDataTourDispatchProperties => {
  return {
    setStyleDataTourCompleted: (isStyleDataTourCompleted: boolean) => dispatch(actions.setStyleDataTourCompleted(isStyleDataTourCompleted)),
  };
};

export const StyleDataTour = connect(mapStateToProps, mapDispatchToProps)(StyleDataTourComponent);
