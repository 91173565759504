import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {FIELD_NAME_MESSAGES, FieldFactory} from "../../common/ui/search/FieldFactory";
import {
  DateField,
  SearchForm,
  SearchFormField,
  SelectField,
  TypeaheadTextField,
} from "../../common/ui/search/SearchForm";
import {EntityType} from "../../model";
import {actions as serviceActions} from "../actions";
import {selectors} from "../selectors";
import {ServiceFilter as FilterObject} from "./model";

export interface ServiceFilterProperties {
  filter: FilterObject;
  searchQueryChanged: (newFilter: FilterObject) => Promise<void>;
  loadAllServiceTypes: () => void;
  allServiceTypesList: any[];
}

interface NotificationProps {
  notifyFilterChanged?: () => any;
}

export const ANY_TEXT_SEARCH_FIELD_NAME: string = "anyText"; //keep these in sync with the filter property names
export const SERVICE_TYPE_FIELD_NAME: string = "type";

export class ServiceFilterComponent extends React.Component<ServiceFilterProperties & NotificationProps & InjectedIntlProps, {}> {

  _fields: SearchFormField[];
  _typeaheadTextField: TypeaheadTextField;
  _serviceTypeField: SelectField;
  _dateStartField: DateField;
  _dateEndField: DateField;
  _anyFormatSearch: { label: string, value: string };

  constructor(props) {
    super(props);
    this._anyFormatSearch = {
      label: this.props.intl.formatMessage(FIELD_NAME_MESSAGES.typeLabelAny),
      value: ""
    };
    this._typeaheadTextField = FieldFactory.createTypeaheadTextField(this.props.intl);
    this._serviceTypeField = {
      type: "select",
      name: SERVICE_TYPE_FIELD_NAME,
      flexGrow: 1,
      options: this.props.allServiceTypesList,
    };
    this._dateStartField = FieldFactory.createStartDateField(this.props.intl);
    this._dateEndField = FieldFactory.createEndDateField(this.props.intl);
    this._fields = [this._typeaheadTextField, this._serviceTypeField, this._dateStartField, this._dateEndField];
  }

  componentDidMount() {
    this.props.loadAllServiceTypes();
  }

  handleSearch = (formValues) => {
    this.props.notifyFilterChanged && this.props.notifyFilterChanged();

    return this.props.searchQueryChanged({
      anyText: formValues[this._typeaheadTextField.name],
      type: formValues[this._serviceTypeField.name],
      startDate: formValues[this._dateStartField.name],
      endDate: formValues[this._dateEndField.name],
    });
  }

  render() {
    this._serviceTypeField.options = [this._anyFormatSearch, ...this.props.allServiceTypesList];
    const {filter} = this.props;
    return (
        <SearchForm fields={this._fields}
                    values={{
                      [this._typeaheadTextField.name]: filter.anyText || "",
                      [this._serviceTypeField.name]: filter.type || "",
                      [this._dateStartField.name]: filter.startDate || "",
                      [this._dateEndField.name]: filter.endDate || "",
                    }}
                    onSearch={this.handleSearch}
                    syncFieldsWithUrl={true}
                    entityType={EntityType.TYPE_SERVICE}/>
    );
  }
}

const mapStateToProps = (state) => ({
  allServiceTypesList: selectors.getAllServiceTypes(state),
  filter: selectors.getFilter(state),
});

const mapDispatchToProps = (dispatch) => ({
  searchQueryChanged: (newFilter: FilterObject) => {
    dispatch(serviceActions.setFilter(newFilter));
    dispatch(serviceActions.clearData());
    return Promise.resolve();
  },
  loadAllServiceTypes: () => dispatch(serviceActions.loadAllServiceTypes()),
});

export const ServiceFilter = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ServiceFilterComponent));
