import {Reducer} from "redux";
import {actionTypes, SetStyleDataTourCompletedAction} from "./actions";
import {moduleName} from "./selectors";

export interface ToursState {
  styleDataTourCompleted: boolean;
}

const defaultToursState = {
  styleDataTourCompleted: true,
};

const reducer: Reducer<ToursState> = (prevState: ToursState = defaultToursState, action) => {
  if (action.type === actionTypes.SET_STYLE_DATA_TOUR_COMPLETED) {
    const setStyleDataTourCompletedAction: SetStyleDataTourCompletedAction = action as SetStyleDataTourCompletedAction;
    return Object.assign({}, prevState, {styleDataTourCompleted: setStyleDataTourCompletedAction.payload});
  }
  return prevState;
};

export {
    moduleName,
    reducer,
};
