import * as React from "react";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {Button} from "react-bootstrap";
import {ChooseFolderDialog} from "../dataroots/ChooseFolderDialog";
import {DataRoot} from "../dataroots/model";

interface ChangeBackgroundDataButtonProps {
  onSuccess: () => void;
}

interface ChangeBackgroundDataButtonState {
  isChooseFileDialogOpen: boolean;
}

const CHANGE_BACKGROUND_MESSAGES = defineMessages({
  title: {
    id: "studio.settings.map-preview.background-data.change.title",
    defaultMessage: "Change background data",
  },
  chooseFile: {
    id: "studio.settings.map-preview.background-data.change.choose-file",
    defaultMessage: "Choose file",
  },
});

class ChangeBackgroundDataButtonComponent extends React.Component<WithApiProperties & InjectedIntlProps & ChangeBackgroundDataButtonProps, ChangeBackgroundDataButtonState> {

  constructor(props) {
    super(props);
    this.state = {
      isChooseFileDialogOpen: false,
    }
  }

  showChooseFileDialog = () => {
    this.setState({isChooseFileDialogOpen: true});
  }

  closeChooseFileDialog = () => {
    this.setState({isChooseFileDialogOpen: false});
  }

  handleChooseFile = (dataRoot: DataRoot) => {
    return this.props.api.setBackgroundDataFilePath(dataRoot.rootPath).then(() => this.props.onSuccess());
  }

  renderFileChooser() {
    const {intl} = this.props;
    const {isChooseFileDialogOpen} = this.state;
    return (
        <ChooseFolderDialog handleChoose={this.handleChooseFile}
                            show={isChooseFileDialogOpen}
                            onHide={this.closeChooseFileDialog}
                            onFolderChoosed={this.closeChooseFileDialog}
                            title={intl.formatMessage(CHANGE_BACKGROUND_MESSAGES.title)}
                            buttonText={intl.formatMessage(CHANGE_BACKGROUND_MESSAGES.chooseFile)}
        />
    );
  }

  renderButton() {
    return (
        <Button bsSize="small" bsStyle="primary" onClick={this.showChooseFileDialog}>
          <FormattedMessage
              id="studio.settings.map-preview.background-data.change"
              defaultMessage="Change"/>
        </Button>
    );
  }

  render() {
    return (
        <div className="backgroundButton">
          {this.renderButton()}
          {this.renderFileChooser()}
        </div>
    );
  }
}

export const ChangeBackgroundDataButton = injectIntl(WithApi(ChangeBackgroundDataButtonComponent));