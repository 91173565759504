import * as React from "react";
import {Alert} from "react-bootstrap";
import {connect} from "react-redux";
import {actions} from "./actions";
import {Notification} from "./model";
import {selectors} from "./selectors";

export interface NotificationBarStateProps {
  notifications: Notification[];
}

interface NotificationBarDispatchProperties {
  hideNotification: (id: number) => void;
}

type NotificationBarProperties = NotificationBarStateProps & NotificationBarDispatchProperties;

export class NotificationBarComponent extends React.Component<NotificationBarProperties, {}> {
  render() {
    const {notifications, hideNotification} = this.props;
    if (notifications && notifications.length > 0) {
      const notificationMessages = notifications.map((notification) => {
        return (
            <Alert bsStyle={notification.style} key={notification.id}
                   onDismiss={() => {hideNotification(notification.id); }}>
              <p key={notification.id}>{notification.message}</p>
            </Alert>
        );
      });
      return <div className="notificationArea">{notificationMessages}</div>;
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {notifications: selectors.getNotifications(state)};
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideNotification: (id) => {
      dispatch(actions.hideNotification(id));
    },
  };
};

export const NotificationBar = connect(mapStateToProps, mapDispatchToProps)(NotificationBarComponent);
