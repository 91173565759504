import {ToursState} from "./reducer";

export const moduleName = "tours";

const getToursState = (state) => state[moduleName] as ToursState;

const getStyleDataTourCompleted = (state) => {
  const toursState = getToursState(state);
  // By default user tour is not shown
  return toursState ? toursState.styleDataTourCompleted : true;
};

export const selectors = {
  getStyleDataTourCompleted,
};
