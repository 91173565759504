function formatNumber(numericValue) {
  let roundedValue = numericValue.toFixed(2);
  if (parseInt(roundedValue, 10) === 0) {
    roundedValue = "0.00";
  }
  const suffix = "00";
  if (roundedValue.indexOf(suffix, roundedValue.length - suffix.length) !== -1) {
    roundedValue = roundedValue.substr(0, roundedValue.length - (suffix.length + 1 ));
  }
  return roundedValue;
}

export const AxisFormat = {
  /**
   * Returns a formatter function. The formatter only shows decimals when needed, and limits the number
   * of decimals to 2. It appends the symbol of the unit of measure.
   * @param unitOfMeasure The unit of measure
   * @returns {Function} The formatter function
   * @private
   */
  createAxisFormatPostfixLabel(unitOfMeasure) {
    return (num) => {
       return formatNumber(num) + " " + unitOfMeasure.symbol;
    };
  },
  /**
   * Returns a formatter function. The formatter only shows decimals when needed, and limits the number
   * of decimals to 2. It prepends the symbol of the unit of measure.
   * @param unitOfMeasure The unit of measure
   * @returns {Function} The formatter function
   * @private
   */
  createAxisFormatPrefixLabel(unitOfMeasure) {
    return (num) => {
      return formatNumber(num) + " " + unitOfMeasure.symbol;
    };
  },
};
