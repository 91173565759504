import * as React from "react";
import {Badge, Button} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

interface OutdatedWarningProperties {
  numberOfNewItems: number;
  refreshLinkClicked: () => void;
  messageType: string;
}

export class OutdatedWarningPresentation extends React.Component<OutdatedWarningProperties, {}> {

  render() {
    const {numberOfNewItems, refreshLinkClicked, messageType} = this.props;
    if (numberOfNewItems === 0) {
      return null;
    }

    switch (messageType) {
    case "data":
      return (
        <div className="outdatedWarning">
          <span><FormattedMessage id="studio.outdated-warning.new-data-available"
                                  defaultMessage="New data available"/><Badge>{numberOfNewItems}</Badge></span>
          <Button
              bsSize="xsmall"
              bsStyle="warning"
              onClick={() => {
                refreshLinkClicked();
              }}>
            <FormattedMessage id="studio.outdated-warning.refresh-button" defaultMessage="Refresh Now"/>
          </Button>
        </div>
    );
    case "style":
      return (
          <div className="outdatedWarning">
          <span><FormattedMessage id="studio.outdated-warning.new-styles-available"
                                  defaultMessage="New styles available"/><Badge>{numberOfNewItems}</Badge></span>
            <Button bsSize="xsmall" onClick={() => {
              refreshLinkClicked();
              // No need to define a default message for this key because it's already defined above, we just want to use same message here.
            }}><FormattedMessage id="studio.outdated-warning.refresh-button" defaultMessage="Refresh Now"/></Button>
          </div>
      );
    }

  }
}
