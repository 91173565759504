import {Feature} from "@luciad/ria/model/feature/Feature";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {MemoryStore} from "@luciad/ria/model/store/MemoryStore";
import {WMSTileSetModel} from "@luciad/ria/model/tileset/WMSTileSetModel";
import {CoordinateReference} from "@luciad/ria/reference/CoordinateReference";
import {getReference} from "@luciad/ria/reference/ReferenceProvider";
import {Bounds} from "@luciad/ria/shape/Bounds";
import {LonLatPointFormat} from "@luciad/ria/shape/format/LonLatPointFormat";
import {createBounds as riaCreateBounds} from "@luciad/ria/shape/ShapeFactory";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {FeaturePainter} from "@luciad/ria/view/feature/FeaturePainter";
import {GridLayer} from "@luciad/ria/view/grid/GridLayer";
import {LonLatGrid} from "@luciad/ria/view/grid/LonLatGrid";
import {Layer} from "@luciad/ria/view/Layer";
import {Map} from "@luciad/ria/view/Map";
import {HatchedImageBuilder} from "@luciad/ria/view/style/HatchedImageBuilder";
import {WMSTileSetLayer} from "@luciad/ria/view/tileset/WMSTileSetLayer";
import {Bounds as ReactorBounds, SpatialModel} from "../../../model";
import {getAbsoluteBaseUrl, PREVIEW_BACKGROUND_DATA_PATH} from "../../../paths";

const LLH_REF = getReference("EPSG:4326");
const WEB_MERCATOR = getReference("EPSG:3857");
const WEB_MERCATOR_MAX_LAT = 85.06;

export function createMap(mapNode, shouldAddGridLayer, restrictNavigationBounds, reference) {
  const map = new Map(mapNode, {reference: reference || WEB_MERCATOR});
  map.layerTree.addChild(createBackgroundLayer(map));
  map.layerTree.addChild(createAreaOfInterestLayer());

  if (shouldAddGridLayer) {
    const gridLayer = createGridLayer();
    map.layerTree.addChild(gridLayer);
  }

  if (restrictNavigationBounds) {
    map.mapNavigator.constraints = {
      limitBounds: {
        bounds: createBounds(restrictNavigationBounds),
      },
    };
  }

  map.mapNavigator.defaults.snapToScaleLevels = false;

  // Prevent user from zooming in past the size of a normal residential house
  map.maxMapScale = [1 / 100, 1 / 100];

  return map;
}

export function destroyMap(map) {
  if (map) {
    map.destroy();
  }
}

function createGridLayer() {
  const settings = [];
  settings.push({scale: 2.0E-8 * 4, deltaLon: 1, deltaLat: 1});
  settings.push({scale: 2.0E-8, deltaLon: 5, deltaLat: 5});
  settings.push({scale: 9.0E-9, deltaLon: 10, deltaLat: 10});
  settings.push({scale: 5.0E-9, deltaLon: 20, deltaLat: 20});
  settings.push({scale: 0, deltaLon: 45, deltaLat: 45});

  const grid = new LonLatGrid(settings);
  //Set the default styling for grid lines and labels
  grid.fallbackStyle = {
    labelFormat: new LonLatPointFormat({pattern: "lat(+DM),lon(+DM)"}),
    originLabelFormat: new LonLatPointFormat({pattern: "lat(+D),lon(+D)"}),
    originLineStyle: {
      color: "rgba(230, 20, 20, 0.6)",
      width: 1,
    },
    lineStyle: {
      color: "rgba(210,210,210,0.4)",
      width: 0.5,
    },
    originLabelStyle: {
      fill: "rgba(210,210,210,0.8)",
      halo: "rgba(230, 20, 20, 0.8)",
      haloWidth: 3,
      font: "12px sans-serif",
    },
    labelStyle: {
      fill: "rgb(220,220,220)",
      halo: "rgb(102,102,102)",
      haloWidth: 3,
      font: "12px sans-serif",
    },
  };

  return new GridLayer(grid, {label: "Grid"});
}

/**
 * Creates a tiled WMS model.
 * V180-1067: To avoid caching on the client side, timestamp was added as a parameter to the requests.
 */
function createWMSModel(url: string, layers: string[], reference: CoordinateReference): WMSTileSetModel {
  return new WMSTileSetModel({
    getMapRoot: url,
    version: "1.3.0",
    reference,
    layers,
    transparent: true,
    requestParameters: {
      time: Date.now(),
    },
  });
}

const AREA_OF_INTEREST_LAYER = "area-of-interest";
const AREA_OF_INTEREST_ID = "aoi-id";

function createAreaOfInterestPainter() {
  const color = "rgba(255,40,40,0.8)";
  const width = 2;
  const style = {
    stroke: {color, width},
    fill: {
      image: new HatchedImageBuilder().patterns([HatchedImageBuilder.Pattern.SLASH])
          .patternSize(8, 8)
          .lineColor(color)
          .lineWidth(width)
          .backgroundColor("rgba(0,0,0,0)")
          .build(),
    },
  };
  const painter = new FeaturePainter();
  painter.paintBody = (geoCanvas, feature, shape/*, layer, map, paintState*/) => {
    geoCanvas.drawShape(shape, style);
  };
  return painter;
}

function createAreaOfInterestLayer() {
  const model = new FeatureModel(new MemoryStore());
  const painter = createAreaOfInterestPainter();

  return new FeatureLayer(model, {
    id: AREA_OF_INTEREST_LAYER,
    selectable: false,
    editable: false,
    painter,
  });
}

export function setAreaOfInterest(map, aoiBounds) {
  const aoiLayer = map.layerTree.findLayerById(AREA_OF_INTEREST_LAYER);
  aoiLayer.model.remove(AREA_OF_INTEREST_ID);

  if (aoiBounds) {
    aoiLayer.model.add(new Feature(aoiBounds, {}, AREA_OF_INTEREST_ID));
  }
}

export function addWMSLayerToMap(map, wmsUrl, layerName, layerTitle, riaBounds, layerReference) {
  const wmsLayer = createWMSLayer(wmsUrl, layerName, layerTitle, layerReference) as any;
  wmsLayer._internalBounds = riaBounds;
  map.layerTree.addChild(wmsLayer);
  return wmsLayer;
}

export function fitOnBounds(map, reactorBounds: ReactorBounds[], animatedFit) {
  if (reactorBounds) {
    const riaBounds = createUnionBounds(reactorBounds);
    const animationOptions = animatedFit ? {duration: 1500} : false;
    map.mapNavigator.fit({bounds: riaBounds, animate: animationOptions});
  }
}

function createWMSLayer(url: string, layerName: string, layerLabel: string,
                        reference: CoordinateReference): WMSTileSetLayer {
  const absoluteUrl = getAbsoluteBaseUrl() + url;
  return new WMSTileSetLayer(
      createWMSModel(absoluteUrl, [layerName], reference), {
        label: layerLabel || layerName,
        visible: true,
      },
  );
}

function createBackgroundLayer(map: Map): Layer {
  return createWMSLayer(PREVIEW_BACKGROUND_DATA_PATH, "preview_data_background", "Background",
      map.reference);
}

export function createBounds(reactorBounds: ReactorBounds): Bounds {
  return riaCreateBounds(LLH_REF,
      [reactorBounds.x, reactorBounds.width, reactorBounds.y, reactorBounds.height]);
}

export function createUnionBounds(reactorBoundsArray: ReactorBounds[]): Bounds {
  let unionBounds: Bounds = null;
  reactorBoundsArray.forEach((reactorBound) => {
    const riaBound = createBounds(reactorBound);
    if (unionBounds) {
      unionBounds.setTo2DUnion(riaBound);
    } else {
      unionBounds = riaBound;
    }
  });

  // normalize the lat to web mercator min/max
  if (unionBounds.y < -WEB_MERCATOR_MAX_LAT) {
    unionBounds.setTo2D(unionBounds.x, unionBounds.width, -WEB_MERCATOR_MAX_LAT, (unionBounds.y + unionBounds.height) + WEB_MERCATOR_MAX_LAT);
  }
  if ((unionBounds.y + unionBounds.height) > WEB_MERCATOR_MAX_LAT) {
    unionBounds.setTo2D(unionBounds.x, unionBounds.width, unionBounds.y, WEB_MERCATOR_MAX_LAT - unionBounds.y);
  }
  return unionBounds;
}

export function getBounds(dataElements: SpatialModel[]): Bounds {
  if (dataElements == null) {
    return null;
  }
  const reactorBounds: ReactorBounds[] = dataElements.map((element) => {
    return element.wgs84Bounds;
  });
  return reactorBounds.length ? createUnionBounds(reactorBounds) :
         createBounds({x: -180, y: -WEB_MERCATOR_MAX_LAT, width: 360, height: 2 * WEB_MERCATOR_MAX_LAT});
}
