import * as React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from "react-router-dom";
import {NotFoundPage} from "../common/ui/NotFoundPage";
import {DataRootsPage} from "./dataroots/DataRootsPage";
import {LogsPage} from "./logs/LogsPage";
import {SettingsNavigation} from "./SettingsNavigation";
import {StatusPage} from "./status/StatusPage";
import {MapPreviewPage} from "./mappreview/MapPreviewPage";

export class SettingsPageComponent extends React.Component<RouteComponentProps<{}>, {}> {

  render() {
    const {path} = this.props.match;
    return (
        <div>
          <Grid fluid={true}>
            <Row>
              <Col xs={2}>
                <SettingsNavigation />
              </Col>
              <Col xs={10}>
                <Switch>
                  <Route path={`${path}/dataroots`} component={DataRootsPage}/>
                  <Route path={`${path}/mappreview`} component={MapPreviewPage}/>
                  <Route path={`${path}/logs`} component={LogsPage}/>
                  <Route path={`${path}/status`} component={StatusPage}/>
                  <Redirect exact from={`${path}/`} to={`${path}/dataroots`}/>
                  <Route path="*" component={NotFoundPage}/>
                </Switch>
              </Col>
            </Row>
          </Grid>
        </div>
    );
  }
}

export const SettingsPage = withRouter(SettingsPageComponent);
