import {combineReducers, Reducer} from "redux";
import {Api} from "../../../api/InternalApi";

const ACTION_PREFIX = "CONTROLROOM/";
const REQUEST_PAGE_SUFFIX = "_REQUEST_PAGE";
const RECEIVE_PAGE_SUCCESS_SUFFIX = "_RECEIVE_PAGE_SUCCESS";
const RECEIVE_PAGE_ERROR_SUFFIX = "_RECEIVE_PAGE_ERROR";
const SET_PAGE_SIZE_SUFFIX = "_SET_PAGE_SIZE";
const CLEAR_DATA_SUFFIX = "_CLEAR_DATA";
const PUT_DATA_ITEM_SUFFIX = "_PUT_DATA_ITEM";

interface PaginationActionTypes {
  REQUEST_PAGE: string;
  RECEIVE_PAGE_SUCCESS: string;
  RECEIVE_PAGE_ERROR: string;
  SET_PAGE_SIZE: string;
  CLEAR_DATA: string;
  PUT_DATA_ITEM: string;
}

const requestPageActionType = (actionTypeName: string) => ACTION_PREFIX + actionTypeName + REQUEST_PAGE_SUFFIX;
const receivePageSuccessActionType = (actionTypeName: string) => ACTION_PREFIX + actionTypeName +
                                                                 RECEIVE_PAGE_SUCCESS_SUFFIX;
const receivePageErrorActionType = (actionTypeName: string) => ACTION_PREFIX + actionTypeName +
                                                               RECEIVE_PAGE_ERROR_SUFFIX;
const setPageSizeActionType = (actionTypeName: string) => ACTION_PREFIX + actionTypeName + SET_PAGE_SIZE_SUFFIX;
const clearDataActionType = (actionTypeName: string) => ACTION_PREFIX + actionTypeName + CLEAR_DATA_SUFFIX;
const putDataItemActionType = (actionTypeName: string) => ACTION_PREFIX + actionTypeName + PUT_DATA_ITEM_SUFFIX;

export const createPagedDataActionTypes = (actionTypeName: string): PaginationActionTypes => {
  return {
    REQUEST_PAGE: requestPageActionType(actionTypeName),
    RECEIVE_PAGE_SUCCESS: receivePageSuccessActionType(actionTypeName),
    RECEIVE_PAGE_ERROR: receivePageErrorActionType(actionTypeName),
    SET_PAGE_SIZE: setPageSizeActionType(actionTypeName),
    CLEAR_DATA: clearDataActionType(actionTypeName),
    PUT_DATA_ITEM: putDataItemActionType(actionTypeName),
  };
};

interface PagedDataActions<DataToLoad> {
  requestPage: (pageNumber: number) => Promise<DataToLoad[]>;
  setPageSize: (pageSize: number) => void;
  clearResults: () => void;
  requestItemById: (id: number) => Promise<DataToLoad>;
}

export type RequestPageAction<DataToLoad> = (pageNumber: number) => ((dispatch, getState,
                                                                      {getApi}) => Promise<Page<DataToLoad>>);

interface ApiPagedResponse<DataToLoad> {
  datas: DataToLoad[];
  pageValidationInfo: any;
}

export function createPagedDataActions<DataToLoad>(actionTypeName: string,
                                                   fetchPage: (pageNumber: number, state,
                                                               api: Api) => Promise<ApiPagedResponse<DataToLoad>>,
                                                   isPageStillValid: (pageValidationInfo: any, state) => boolean,
                                                   fetchById: (id: string, state, api: Api) => Promise<DataToLoad>,
                                                   subStateSelector: (state) => PagedDataState<DataToLoad>) {
  const requestPage: RequestPageAction<DataToLoad> = (pageNumber: number) => {
    return (dispatch, getState, {getApi}) => {
      const state = getState();
      const pagedDataState: PagedDataState<DataToLoad> = subStateSelector(state);
      const shouldFetchPage = getPageStatus(pagedDataState, pageNumber) === "NOT_PRESENT";
      const requestAction = {
        type: requestPageActionType(actionTypeName),
        payload: pageNumber,
      };
      dispatch(requestAction);
      if (shouldFetchPage) {
        const api = getApi(state);
        return fetchPage(pageNumber, state, api).then((result: { datas: DataToLoad[], pageValidationInfo: any }) => {
          if (!result.datas) {
            throw new Error("Page fetch returned null data");
          }
          //An "outdated" request (one from a different filter) might come in while the filter has already changed
          //make sure the page is still valid before adding the data to redux state
          if (!isPageStillValid(result.pageValidationInfo, getState())) {
            return Promise.resolve(null);
          }
          const receivedSuccesfullyAction = {
            type: receivePageSuccessActionType(actionTypeName),
            payload: {
              data: result.datas,
              pageNumber,
            },
          };
          dispatch(receivedSuccesfullyAction);
        }).catch((err) => {
          const receivedErrorAction = {
            type: receivePageErrorActionType(actionTypeName),
            payload: {
              error: err,
              pageNumber,
            },
          };
          dispatch(receivedErrorAction);
        });
      }
      return Promise.resolve(null);
    };
  };

  const setPageSize = (pageSize: number) => {
    return {
      type: setPageSizeActionType(actionTypeName),
      payload: pageSize,
    };
  };

  const clearData = () => {
    return {
      type: clearDataActionType(actionTypeName),
    };
  };

  const requestItemById = (id: string) => {
    return (dispatch, getState, {getApi}) => {
      const state = getState();
      const api = getApi(state);
      return fetchById(id, state, api).then((dataItem) => {
        const putAction = {
          type: putDataItemActionType(actionTypeName),
          payload: dataItem,
        };
        dispatch(putAction);
      });
    };
  };

  return {
    requestPage,
    setPageSize,
    clearData,
    requestItemById,
  };
}

export type PageStatus = "NOT_PRESENT" | "LOADING" | "SUCCESS" | "ERROR";

interface DataState<DataToLoad> {
  [id: number]: DataToLoad;
}

type PageSizeState = number;

interface PagesState {
  [pageNumber: number]: {
    dataIds: number[],
    status: PageStatus,
    error?: Error,
  };
}

interface MaxPageState {
  maxPageNumber: number;
}

export interface PagedDataState<DataToLoad> {
  data: DataState<DataToLoad>;
  pages: PagesState;
  pageSize: PageSizeState;
  maxPageInfo: MaxPageState;
}

export function createPagedDataReducer<DataToLoad>(actionTypeName: string): Reducer<PagedDataState<DataToLoad>> {
  function dataReducer(state: DataState<DataToLoad> = {}, action): DataState<DataToLoad> {
    switch (action.type) {
    case clearDataActionType(actionTypeName):
      return [];
    case receivePageSuccessActionType(actionTypeName):
      const newData = {};
      action.payload.data.map((data) => {
        newData[data.id] = data;
      });
      return Object.assign({}, state, newData);
    case putDataItemActionType(actionTypeName):
      return Object.assign({}, state, {[action.payload.id]: action.payload});
    }
    return state;
  }

  function pageSizeReducer(state: PageSizeState = 500, action) {
    switch (action.type) {
    case setPageSizeActionType(actionTypeName):
      return action.payload;
    }
    return state;
  }

  function pagesReducer(state: PagesState = {}, action) {
    switch (action.type) {
    case clearDataActionType(actionTypeName):
      return {};
    case requestPageActionType(actionTypeName):
      const newPage = {dataIds: [], status: "LOADING"};
      return Object.assign({}, state, {[action.payload]: newPage});
    case receivePageSuccessActionType(actionTypeName):
      const successPage = {dataIds: action.payload.data.map((data) => data.id), status: "SUCCESS"};
      return Object.assign({}, state, {[action.payload.pageNumber]: successPage});
    case receivePageErrorActionType(actionTypeName):
      const errorPage = {dataIds: [], status: "ERROR", error: action.payload.error};
      return Object.assign({}, state, {[action.payload.pageNumber]: errorPage});
    }
    return state;
  }

  function maxPageReducer(state: MaxPageState = {maxPageNumber: 0}, action) {
    switch (action.type) {
    case clearDataActionType(actionTypeName):
      return {maxPageNumber: 0};
    case receivePageSuccessActionType(actionTypeName):
      return {maxPageNumber: Math.max(state.maxPageNumber, action.payload.pageNumber)};
    }
    return state;
  }

  return combineReducers<PagedDataState<DataToLoad>>({
    data: dataReducer,
    pages: pagesReducer,
    pageSize: pageSizeReducer,
    maxPageInfo: maxPageReducer,
  });
}

export interface Page<DataToLoad> {
  data: DataToLoad[];
  status: PageStatus;
  number: number;
  error?: Error;
}

function getPage<DataToLoad>(state: PagedDataState<DataToLoad>, pageNumber: number): Page<DataToLoad> {
  if (!state.pages[pageNumber]) {
    return null;
  }
  const data = state.pages[pageNumber].dataIds.map((id) => state.data[id]);
  const status = state.pages[pageNumber].status;
  const error = state.pages[pageNumber].error;
  return {
    data,
    status,
    number: pageNumber,
    error,
  };
}

function getAllPages<DataToLoad>(state: PagedDataState<DataToLoad>): { [pageNumber: number]: Page<DataToLoad> } {
  const pages = {};
  for (const pageNumber in state.pages) {
    if (state.pages.hasOwnProperty(pageNumber)) {
      pages[pageNumber] = getPage(state, (pageNumber as any) as number);
    }
  }
  return pages;
}

function getPageStatus<DataToLoad>(state: PagedDataState<DataToLoad>, pageNumber: number): PageStatus {
  const page = state.pages[pageNumber];
  if (!page) {
    return "NOT_PRESENT";
  }
  return state.pages[pageNumber].status;
}

function getPageError<DataToLoad>(state: PagedDataState<DataToLoad>, pageNumber: number): Error {
  return state.pages[pageNumber].error;
}

function getPageSize<DataToLoad>(state: PagedDataState<DataToLoad>): number {
  return state.pageSize;
}

function getAllLoadedData<DataToLoad>(state: PagedDataState<DataToLoad>): DataToLoad[] {
  const allData = [];
  for (let i = 0; i <= state.maxPageInfo.maxPageNumber; i++) {
    const page = state.pages[i];
    if (page) {
      page.dataIds.forEach((dataId) => {
        allData.push(state.data[dataId]);
      });
    }
  }
  return allData;
}

function isAllPagesLoaded<DataToLoad>(state: PagedDataState<DataToLoad>): boolean {
  const maxPageNumber = state.maxPageInfo.maxPageNumber;
  if (state.pages[maxPageNumber]) {
    return state.pages[maxPageNumber].status === "SUCCESS" &&
           state.pages[maxPageNumber].dataIds.length < getPageSize(state);
  }
  return false;
}

function getDataById<DataToLoad>(state: PagedDataState<DataToLoad>, id: string): DataToLoad {
  return state.data[id] || null;
}

export const selectors = {
  getPageStatus,
  getPageSize,
  getAllLoadedData,
  getAllPages,
  isAllPagesLoaded,
  getDataById,
};

export interface PaginationRequestParams {
  offset?: number;
  maxResults?: number;
}

export type PagedDataSelector<DataToLoad> = (state: any) => PagedDataState<DataToLoad>;
