import {Warning} from "../model";
import {FormattedMessage} from "react-intl";
import * as React from "react";

export const mapWarningToMessage = (warning: Warning) => {
  if (warning.type === "/api/documentation#delete-file-failed" && (warning as any).filePath) {
    return <FormattedMessage id="studio.ui.warning.warning-delete-upload-failed"
                             defaultMessage="Failed to delete the uploaded file {filePath} from disk"
                             values={{filePath: (warning as any).filePath}}/>
  }
  return null;
}