import * as React from "react";
import {Button, FormControl, FormGroup} from "react-bootstrap";
import Alert = require("react-bootstrap/lib/Alert");
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {ActionBar} from "../common/ui/actionbar/ActionBar";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {DefaultReorderControl} from "../common/ui/reorder/DefaultReorderControl";
import {InjectedReorderableListProps, ReorderableList} from "../common/ui/reorder/ReorderableList";
import {ColumnFactory, ColumnWidth, ControlRoomColumn} from "../common/ui/table/ControlRoomColumns";
import {
  CalculateDimensionsFunction,
  CONTROLROOM_TABLE_HEADER_HEIGHT,
  ControlRoomTable,
  ControlRoomTableProperties,
} from "../common/ui/table/ControlRoomTable";
import {ValidationResult, ValidationState} from "../common/ui/validation/ProductValidation";
import {moveItem} from "../common/util/ArrayUtil";
import {getConcatenatedValidationMessages} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {ImportedDataTypeahead} from "../data/DataTypeahead";
import {ImportedData} from "../data/model";
import {Identifiable} from "../model";
import {SetStyleButton} from "../styles/SetStyleButton";
import {actions} from "./actions";
import {StyledData} from "./model";
import {selectors} from "./selectors";

interface ProductContentListComponentState {
  dataToAdd: ImportedData;
  styledDataToAdd: StyledData;
  selectedIndices: number[];
  isBulkRemovingEntries: boolean;
  validation: ValidationResult;
}

type ColumnEntry = ControlRoomColumn<StyledData>;

class ProductContentColumnFactory implements ColumnFactory<StyledData> {

  _removeItem: (item: StyledData, index: number) => void;
  _moveSelection: (toIdx: number) => void;
  _changeItem: (changedItem: StyledData, index: number) => void;
  _withVisibilityColumn: boolean;

  columns: { [columnID: string]: ColumnEntry } = {
    data: {
      header: <FormattedMessage id="studio.products.product-content-list.data" defaultMessage="Data"/>,
      cellContent: (styledData) => {
        return <Link to={`/data/${encodeURIComponent(styledData.data)}`}>{this.getDataTitle(styledData)}</Link>;
      },
      cellTooltip: (styledData) => this.getDataTitle(styledData),
      columnWidth: ColumnWidth.flex(2),
    },
    style: {
      header: <FormattedMessage id="studio.products.product-content-list.style" defaultMessage="Style"/>,
      cellContent: (styledData, index, selected) => {
        const style = styledData.style;
        if (style) {
          return (<Link to={`/styles/${styledData.style}`}>{styledData.styleTitle}</Link>);
        }
        return <FormattedMessage id="studio.products.product-content-list.no-style" defaultMessage="No style set"/>;
      },
      cellTooltip: (styledData, intl) => styledData.style ? styledData.styleTitle : intl.formatMessage(PRODUCT_CONTENT_MESSAGES.noStyle),
      columnWidth: ColumnWidth.flex(2),
    },
    move: {
      header: <FormattedMessage id="studio.products.product-content-list.move" defaultMessage="Move"/>,
      cellContent: (styledData, index, selected, allData, selectedIndices) => {
        if (selected && selectedIndices.length === 1) {
          return (
              <DefaultReorderControl
                  moveSelection={this._moveSelection}
                  selectedIndex={index}
                  maxIndex={allData.length - 1}
              />
          );
        }
        return null;
      },
      cellTooltip: (styledData, intl) => intl.formatMessage(PRODUCT_CONTENT_MESSAGES.moveTooltip),
      columnWidth: ColumnWidth.pixels(70),
    },
    visible: {
      header: <FormattedMessage id="studio.products.product-content-list.visible" defaultMessage="Visible"/>,
      cellContent: (styledData, index) => {
        return (<Button className="btn-icon" style={{backgroundColor: "transparent", color: "currentColor"}} onClick={(event) => {
          const changedStyledData = Object.assign({}, styledData, {visible: !styledData.visible});
          this._changeItem(changedStyledData, index);
          event.stopPropagation(); //avoid that ControlRoomTable's onRowClick gets called
        }}><LcdIcon icon={styledData.visible ? "eye" : "eye-close"}/></Button>);
      },
      cellTooltip: (styledData, intl) => intl.formatMessage(PRODUCT_CONTENT_MESSAGES.visibleTooltip),
      columnWidth: ColumnWidth.pixels(65),
    },
  };

  constructor(removeItem: ((item: StyledData, index: number) => void),
              moveSelection: (toIdx: number) => void,
              changeItem: (changedItem: StyledData, index: number) => void,
              withVisibilityColumn: boolean) {
    this._removeItem = removeItem;
    this._moveSelection = moveSelection;
    this._changeItem = changeItem;
    this._withVisibilityColumn = withVisibilityColumn;
  }

  getDataTitle(styledData: StyledData) {
    return (styledData.dataTitle) ? styledData.dataTitle : "UNKNOWN_DATA_TITLE";
  }

  getInitialColumnIDs(): string[] {
    return this.getAvailableColumnIDs();
  }

  getInitialColumnWidth(columnID: string): ColumnWidth {
    return this.columns[columnID].columnWidth || ColumnWidth.pixels(200);
  }

  getAvailableColumnIDs(): string[] {
    const availableColumnIDs = [];
    for (const prop in this.columns) {
      if (this.columns.hasOwnProperty(prop) && !(prop === "visible" && !(this._withVisibilityColumn))) {
        availableColumnIDs.push(prop);
      }
    }
    return availableColumnIDs;
  }

  createColumn(columnID: string): ControlRoomColumn<StyledData> {
    return this.columns[columnID];
  }

}

interface OwnProductContentListProps {
  productId?: string;
  initialLoad?: () => void;
  onBulkChange: (changedItems: StyledData[], changedIndices: number[]) => Promise<void>;
  onBulkRemove: (removeItems: StyledData[], items: StyledData[]) => Promise<void>;
  addDataPlaceHolder?: string;
  withVisibilityToggle: boolean;
  calculateDimensions: (parent: HTMLElement) => {};
}

type OwnProductContentListPropsWithApi = OwnProductContentListProps & WithApiProperties;

type ProductContentListComponentProps =
    InjectedReorderableListProps<StyledData>
    & InjectedIntlProps
    & OwnProductContentListPropsWithApi;

type StyledDataFactoryFunction = (oldStyledData: StyledData, ...args) => StyledData;

const PRODUCT_CONTENT_MESSAGES = defineMessages({
  dataPlaceholder: {
    id: "studio.products.product-content-list.data-placeholder",
    defaultMessage: "Search for data to add",
  },
  toggleVisibilityDescription: {
    id: "studio.products.product-content-list.toggle-visibility-description",
    defaultMessage: "Toggle visibility of selected items",
  },
  selectAllItemsDescription: {
    id: "studio.products.product-content-list.select-all-items-description",
    defaultMessage: "Select all items",
  },
  clearSelectionDescription: {
    id: "studio.products.product-content-list.clear-selection-description",
    defaultMessage: "Clear selection",
  },
  removeSelectedItems: {
    id: "studio.products.product-content-list.remove-selected-items",
    defaultMessage: "Remove selected items",
  },
  noStyle: {
    id: "studio.products.product-content-list.no-style",
    defaultMessage: "No style set",
  },
  moveTooltip: {
    id: "studio.products.product-content-list.move",
    defaultMessage: "Move",
  },
  visibleTooltip: {
    id: "studio.products.product-content-list.visible-tooltip",
    defaultMessage: "Toggle visibility",
  },
});

export class ProductContentListComponent extends React.Component<ProductContentListComponentProps, ProductContentListComponentState> {

  ProductContentListTable: React.ComponentClass<ControlRoomTableProperties<StyledData>>;

  constructor(props) {
    super(props);
    this.state = {
      dataToAdd: null,
      styledDataToAdd: null,
      selectedIndices: [],
      isBulkRemovingEntries: false,
      validation: null,
    };
    const moveSelection = (toIdx) => {
      props.moveSelection(toIdx);
      this.setState(Object.assign({}, this.state, {selectedIndices: [toIdx]}));
    };
    this.ProductContentListTable = ControlRoomTable(
        new ProductContentColumnFactory(
            props.removeItem, moveSelection, props.changeItem,
            props.withVisibilityToggle,
        ),
        props.calculateDimensions,
    );
  }

  componentDidMount() {
    if (this.props.initialLoad) {
      this.props.initialLoad();
    }
  }

  handleDataChange = (newData) => {
    if (newData) {
      this.setState({validation: null});
      const {api} = this.props;

      const styledDataToAdd = {
        id: newData.id,
        productId: this.props.productId,
        data: newData.id,
        dataTitle: newData.title,
        style: newData.defaultStyleId,
        visible: true,
      };

      // Validate only if the product is present
      if (this.props.productId) {
        api.validateStyledData(this.props.productId, [styledDataToAdd]).then(
            (apiValidationResult: ValidationResult) => {
              const hasWarnings = apiValidationResult.errorMessages.length > 0 ||
                                  apiValidationResult.warningMessages.length > 0;

              if (hasWarnings) {
                this.setState({validation: apiValidationResult});
              } else {
                this.setState({dataToAdd: newData, styledDataToAdd}, this.addData);
              }
            });
      } else {
        this.setState({dataToAdd: newData, styledDataToAdd}, this.addData); // data picked and automatically added to backend
      }
    }
  }

  addData = () => {
    const _data = this.state.styledDataToAdd;
    if (_data) {
      this.props.addItem(_data);
      this.setState({dataToAdd: null, styledDataToAdd: null});
    }
  }

  validateDataTypeHead = (data: Identifiable) => {
    const {items, validateItemToAdd} = this.props;
    if (items.some((styledData) => (styledData.data === data.id))) {
      return false;
    }
    if (validateItemToAdd) {
      return validateItemToAdd(data);
    }
    return true;
  }

  _handleBulkStyledDataChange(styledDataFactoryFunction: StyledDataFactoryFunction) {
    const {items} = this.props;
    const changedStyledDatas: StyledData[] = [];
    const changedIndices: number[] = [];
    //save selected indices so the selection can be restored post-update
    for (let i = 0; i < items.length; i++) {
      const styledData = items[i];
      const isSelectedEntry = this.state.selectedIndices.indexOf(i) >= 0;
      if (isSelectedEntry) {
        const newStyledData = styledDataFactoryFunction(styledData);
        changedStyledDatas.push(newStyledData);
        changedIndices.push(i);
      }
    }
    return this.props.onBulkChange(changedStyledDatas, changedIndices);
  }

  updateBulkVisibilityHandler = () => {
    return this._handleBulkStyledDataChange((styledData) => {
      return Object.assign({}, styledData, {visible: !styledData.visible});
    });
  }

  updateBulkStyleHandler = (styleToSet) => {
    return this._handleBulkStyledDataChange((styledData) => {
      return Object.assign({}, styledData, {
        style: styleToSet ? styleToSet.id : null,
        styleTitle: styleToSet ? styleToSet.title : null,
      });
    });
  }

  getSelectedStyledDatas = () => {
    return this.state.selectedIndices.map((index) => this.props.items[index]);
  }

  renderErrors = () => {
    const {validation} = this.state;
    if (validation && validation.errorMessages.length > 0) {
      const errorMessage = getConcatenatedValidationMessages(validation);
      return <Alert bsStyle="danger">{errorMessage}</Alert>;
    }
  }

  render() {
    const {items, readOnly, intl} = this.props;
    const {dataToAdd, selectedIndices, isBulkRemovingEntries} = this.state;
    const validationResult: ValidationState = !!dataToAdd ? "success" : null;
    const hasSelectedEntries = this.state.selectedIndices.length > 0;
    const toggleVisibilityButton = (
        <Button bsSize="xs"
                disabled={!hasSelectedEntries}
                alt={intl.formatMessage(PRODUCT_CONTENT_MESSAGES.toggleVisibilityDescription)}
                onClick={this.updateBulkVisibilityHandler}>
          <LcdIcon icon="eye"/>
          <FormattedMessage id="studio.products.product-content-list.toggle-visibility"
                            defaultMessage="Toggle visibility"/>
        </Button>
    );
    return (
        <div className="productContentList">
          {!readOnly && (
              <FormGroup validationState={validationResult}>
                <ImportedDataTypeahead value={dataToAdd}
                                       onChange={this.handleDataChange}
                                       validate={this.validateDataTypeHead}
                                       placeholder={this.props.addDataPlaceHolder || this.props.intl.formatMessage(
                                           PRODUCT_CONTENT_MESSAGES.dataPlaceholder)}
                />
                <FormControl.Feedback/>
              </FormGroup>)
          }
          <div>
            <ActionBar>
              <Button bsSize="xs" alt={intl.formatMessage(PRODUCT_CONTENT_MESSAGES.selectAllItemsDescription)}
                      onClick={() => {
                        this.setState(Object.assign({}, this.state, {selectedIndices: items.map((item, idx) => idx)}));
                      }}>
                <LcdIcon icon="check"/><FormattedMessage
                  id="studio.products.product-content-list.select-all"
                  defaultMessage="Select all"/>
              </Button>
              <Button bsSize="xs" disabled={!hasSelectedEntries}
                      alt={intl.formatMessage(PRODUCT_CONTENT_MESSAGES.clearSelectionDescription)} onClick={() => {
                this.setState(Object.assign({}, this.state, {selectedIndices: []}));
              }}>
                <LcdIcon icon="unchecked"/> <FormattedMessage
                  id="studio.products.product-content-list.clear-selection"
                  defaultMessage="Clear selection"/>
              </Button>
              <SetStyleButton bsSize="xs" disabled={!hasSelectedEntries} onStyleSet={this.updateBulkStyleHandler}/>
              <Button bsSize="xs" disabled={!hasSelectedEntries || isBulkRemovingEntries}
                      alt={intl.formatMessage(PRODUCT_CONTENT_MESSAGES.removeSelectedItems)}
                      onClick={() => {
                        this.setState(Object.assign({}, this.state, {isBulkRemovingEntries: true}));
                        const removePromise = this.props.onBulkRemove(this.getSelectedStyledDatas(), this.props.items);
                        const onDoneRemoving = () => {
                          this.setState(
                              Object.assign({}, this.state, {isBulkRemovingEntries: false, selectedIndices: []}));
                        };
                        removePromise.then(onDoneRemoving).catch(onDoneRemoving);
                        return removePromise;
                      }}>
                {!isBulkRemovingEntries && <LcdIcon icon="delete"/>}
                {isBulkRemovingEntries ?
                 <FormattedMessage id="studio.products.product-content-list.removing" defaultMessage="Removing..."/> :
                 <FormattedMessage id="studio.products.product-content-list.remove" defaultMessage="Remove"/>}
              </Button>
              {this.props.withVisibilityToggle && toggleVisibilityButton}
            </ActionBar>

            {this.renderErrors()}

            <this.ProductContentListTable data={items}
                                          intl={intl}
                                          isPaged={false}
                                          canMultiSelect={true}
                                          isAllDataLoaded={true}
                                          selectedRowIndices={selectedIndices}
                                          onRowSelect={(newSelectedEntries: StyledData[],
                                                        selectedRowIndices: number[]) => {
                                            this.setState(
                                                Object.assign({}, this.state, {selectedIndices: selectedRowIndices}));
                                            if (newSelectedEntries.length === 1) {
                                              this.props.selectItem(
                                                  newSelectedEntries[0] ? newSelectedEntries[0].id : null, true);
                                            } else {
                                              this.props.selectItem(null, true);
                                            }
                                          }}
                                          rowHeight={PRODUCT_DATA_LIST_ROW_HEIGHT}
            />
          </div>
          <div className="numberReadout"><FormattedMessage id="studio.products.product-content-list.items-listed"
                                                           defaultMessage="Number of data items listed: {length}"
                                                           values={{length: items.length}}/></div>
        </div>
    );
  }
}

export const PRODUCT_DATA_LIST_ROW_HEIGHT = 38;

export const ProductContentListPresentation = WithApi(
    ReorderableList<StyledData, OwnProductContentListPropsWithApi>(injectIntl(ProductContentListComponent), null));

const mapStateToProps = (state, ownProps) => {
  return {
    productId: ownProps.productId,
    items: selectors.getProductContentsOfProduct(state, ownProps.productId),
    withVisibilityToggle: ownProps.withVisibilityToggle,
    calculateDimensions: ownProps.calculateDimensions,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    productId: ownProps.productId,
    initialLoad: () => dispatch(actions.loadProductContents(ownProps.productId)),
    onReorder: (oldIndex, newIndex, items) => {
      const newContents = moveItem<StyledData>(items, oldIndex, newIndex);
      dispatch(actions.setStyledData(ownProps.productId, newContents));
    },
    onAdd: (newItem) => {
      newItem.productId = ownProps.productId;
      dispatch(actions.createOrUpdateStyledData(ownProps.productId, [newItem]));
    },
    onChange: (changedItem) => {
      dispatch(actions.createOrUpdateStyledData(ownProps.productId, [changedItem]));
    },
    onBulkChange: (newStyledDatas) => {
      return dispatch(actions.createOrUpdateStyledData(ownProps.productId, newStyledDatas));
    },
    onBulkRemove: (removedStyledDatas, items) => {
      const newItems = items.filter((i) => !removedStyledDatas.includes(i));
      return dispatch(actions.setStyledData(ownProps.productId, newItems));
    },
  };
};

export const ProductContentList = connect(mapStateToProps, mapDispatchToProps)(ProductContentListPresentation);

/*
 * Some functions for calculating dimensions of this component, to be used in different places in ControlRoom
 */

export const productContentListDimensionsOnDetailPage = (parent: HTMLElement) => {
  const paddingLeft = parseFloat(getComputedStyle(parent).paddingLeft) || 0;
  const paddingRight = parseFloat(getComputedStyle(parent).paddingRight) || 0;
  const parentRect = parent.getBoundingClientRect();
  const parentTop = parentRect.top;
  const viewPortHeight = document.documentElement.clientHeight;
  const remainingHeightOnScreen = viewPortHeight - parentTop;
  const numberReadOutHeight = 25;
  const minHeight = 5 * PRODUCT_DATA_LIST_ROW_HEIGHT + CONTROLROOM_TABLE_HEADER_HEIGHT;
  return {
    width: parent.clientWidth - paddingLeft - paddingRight,
    height: Math.max(minHeight, remainingHeightOnScreen - numberReadOutHeight),
  };
};
