import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {FIELD_NAME_MESSAGES, FieldFactory} from "../common/ui/search/FieldFactory";
import {DateField, SearchForm, SearchFormField, SelectField, TypeaheadTextField} from "../common/ui/search/SearchForm";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {EntityType} from "../model";
import {actions} from "./actions";
import {ImportedDataSearchFilter} from "./model";
import {selectors} from "./selectors";

interface ImportedDataFilterStateProperties {
  dataTypeList: any[];
  filter: ImportedDataSearchFilter;
}

interface ImportedDataFilterDispatchProperties {
  filterChanged: (newFilter: ImportedDataSearchFilter) => Promise<void>;
  loadDataTypes: () => void;
}

interface NotificationProps {
  notifyFilterChanged?: () => any;
}

type ImportedDataFilterComponentType = NotificationProps &
                                       ImportedDataFilterDispatchProperties &
                                       ImportedDataFilterStateProperties &
                                       WithApiProperties;

export const DATA_TYPE_SEARCH_FIELD = "type";

export class ImportedDataFilterComponent extends React.Component<ImportedDataFilterComponentType & InjectedIntlProps, {}> {

  _typeaheadTextField: TypeaheadTextField;
  _dateStartField: DateField;
  _dateEndField: DateField;
  _typeField: SelectField;
  _searchFields: SearchFormField[];
  _anyFormatSearch: { label: string, value: string };

  constructor(props) {
    super(props);
    this._anyFormatSearch = {
      label: this.props.intl.formatMessage(FIELD_NAME_MESSAGES.typeLabelAny),
      value: ""
    };
    this._typeaheadTextField = FieldFactory.createTypeaheadTextField(this.props.intl);
    this._dateStartField = FieldFactory.createStartDateField(this.props.intl);
    this._dateEndField = FieldFactory.createEndDateField(this.props.intl);
    this._typeField = {
      type: "select",
      name: DATA_TYPE_SEARCH_FIELD,
      flexGrow: 1,
      options: this.props.dataTypeList,
    };
    this._searchFields = [this._typeaheadTextField, this._typeField, this._dateStartField, this._dateEndField];
  }

  componentDidMount() {
    this.props.loadDataTypes();
  }

  handleSearch = (formValues) => {
    if (this.props.notifyFilterChanged) {
      this.props.notifyFilterChanged();
    }
    return this.props.filterChanged({
      anyText: formValues[this._typeaheadTextField.name],
      type: formValues[this._typeField.name],
      startDate: formValues[this._dateStartField.name],
      endDate: formValues[this._dateEndField.name],
    });
  }

  render() {
    this._typeField.options = [this._anyFormatSearch, ...this.props.dataTypeList];
    const {filter} = this.props;
    return (
        <SearchForm fields={this._searchFields}
                    values={{
                      [this._typeaheadTextField.name]: filter.anyText || "",
                      [this._typeField.name]: filter.type || "",
                      [this._dateStartField.name]: filter.startDate || "",
                      [this._dateEndField.name]: filter.endDate || "",
                    }}
                    onSearch={this.handleSearch}
                    syncFieldsWithUrl={true}
                    entityType={EntityType.TYPE_DATA}
        />
    );
  }
}



const mapStateToProps = (state) => ({
  dataTypeList: selectors.getDataTypes(state),
  filter: selectors.getFilter(state),
});

const mapDispatchToProps = (dispatch) => ({
  filterChanged: (newFilter: ImportedDataSearchFilter) => {
    dispatch(actions.setDataFilter(newFilter));
    dispatch(actions.clearData());
    return Promise.resolve();
  },
  loadDataTypes: () => dispatch(actions.loadDataTypes()),
});

export const ImportedDataFilter = connect(mapStateToProps, mapDispatchToProps)(WithApi(injectIntl(ImportedDataFilterComponent)));
