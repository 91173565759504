import * as React from "react";
import {Button} from "react-bootstrap/lib";
import {InjectedIntlProps} from "react-intl";
import {ConfirmationDialog} from "./ConfirmationDialog";
import {LcdIcon} from "./icon/LcdIcon";

export interface RefreshButtonProps<T> {
  onRefresh: () => Promise<T>;
  onRefreshSuccess: (T) => void;
  onRefreshError: () => void;
  dialogTitle: string;
  buttonTitle: string;
  renderConfirmation: () => JSX.Element;
  confirmButtonText: string;
  executingMessage: string;
  successMessage: string;
}

interface ButtonState {
  isDialogOpen: boolean;
  isExecuting: boolean;
  isSuccess: boolean;
  error: Error;
}

export function createRefreshButtonComponent<T>() {
  return class RefreshButtonComponent extends React.Component<RefreshButtonProps<T> & InjectedIntlProps, ButtonState> {

    constructor(props) {
      super(props);
      this.state = {
        isDialogOpen: false,
        isExecuting: false,
        error: null,
        isSuccess: false,
      };
    }

    openDialog = () => {
      this.setState({...this.state, isExecuting: false, error: null, isSuccess: false, isDialogOpen: true});
    }

    closeDialog = () => {
      this.setState({...this.state, isDialogOpen: false});
    }

    performRefresh = () => {
      this.setState({...this.state, isExecuting: true, error: null, isSuccess: false});
      return this.props.onRefresh().then((data) => {
        this.setState({...this.state, isExecuting: false, error: null, isSuccess: true});
        this.closeDialog();
        this.props.onRefreshSuccess(data);
      }).catch((error) => {
        this.setState({...this.state, isExecuting: false, error, isSuccess: false});
        this.props.onRefreshError();
      });
    }

    renderButton() {
      const {buttonTitle} = this.props;
      return <Button className="actionBarItem" onClick={this.openDialog}>
        <LcdIcon icon="refresh-filled-circle"/> {buttonTitle}
      </Button>;
    }

    render() {
      const {isDialogOpen} = this.state;
      return (
          <div>
            {this.renderButton()}
            <ConfirmationDialog
                show={isDialogOpen}
                intl={this.props.intl}
                renderConfirmation={this.props.renderConfirmation}
                confirmButtonIcon={"refresh"}
                confirmButtonText={this.props.confirmButtonText}
                bsStyleConfirm={"primary"}
                onConfirm={this.performRefresh}
                executing={this.state.isExecuting}
                executingMessage={this.props.executingMessage}
                error={this.state.error}
                warnings={null}
                success={this.state.isSuccess}
                successMessage={this.props.successMessage}
                title={this.props.dialogTitle}
                onHide={this.closeDialog}
            />
          </div>
      );
    }
  };
}
