import {actionTypes} from "./actions";
import {ServiceFilter} from "./model";

export type FilterState = ServiceFilter;

export const filterReducer = (state: FilterState = {},
                              action): FilterState => {
  switch (action.type) {
  case actionTypes.SET_SERVICE_FILTER:
    return Object.assign({}, state, action.payload);
  }
  return state;
};
