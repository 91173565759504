import * as React from "react";
import {Typeahead} from "../common/ui/typeahead/Typeahead";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {Service} from "./model";
import {InjectedIntlProps, injectIntl} from "react-intl";

interface ServiceTypeaheadProps {
  value: Service;
  onChange: (newValue: Service) => void;
  validate?: (suggestion: Service) => boolean;
}

export const MAX_RESULTS = 10;

export const AbstractServiceTypeahead = Typeahead<Service>();

export class ServiceTypeaheadComponent extends React.Component<ServiceTypeaheadProps & WithApiProperties & InjectedIntlProps, {}> {

  fetchSuggestions = (value: string) => this.props.api.listServices({anyText: value + "*"})
      .then((services) => {
            const {validate} = this.props;
            const filteredItems = validate ? services.filter(validate) : services;
            return filteredItems.slice(0, MAX_RESULTS);
          },
      )

  renderSuggestion = (service: Service, shortForm = false) => {
    return service.title;
  }

  render() {
    return (
        <AbstractServiceTypeahead value={this.props.value}
                                  fetchSuggestions={this.fetchSuggestions}
                                  renderSuggestion={this.renderSuggestion}
                                  onChange={this.props.onChange}
                                  intl={this.props.intl}
        />
    );
  }
}

export const ServiceTypeahead = injectIntl(WithApi(ServiceTypeaheadComponent));
