import * as React from "react";
import {EntityType} from "../../../model";
import {WithApi, WithApiProperties} from "../../util/WithApi";
import {Typeahead} from "../typeahead/Typeahead";
import {InjectedIntlProps, injectIntl} from "react-intl";

export interface TextTypeaheadProps {
  entityType: EntityType;
  name: string;
  value: string;
  placeholder: string;
  onChange: (text: string) => void;
}

export const AbstractStringTypeahead = Typeahead<string>();

export class TextTypeaheadComponent extends React.Component<TextTypeaheadProps & WithApiProperties & InjectedIntlProps, {}> {

  handleChange = (text: string) => {
    if (text !== null) {
      this.props.onChange(text); //avoid double onChange calls (both for null and empty string)
    }
  }

  fetchSuggestions = (suggestionQuery: string) => {
    this.handleChange(suggestionQuery); //trigger an immediate onChange event, can do this because this is a string Typeahead
    return this.props.api.getSuggestionText(this.props.entityType, suggestionQuery);
  }

  render() {
    return (
        <AbstractStringTypeahead value={this.props.value}
                                 name={this.props.name}
                                 placeholder={this.props.placeholder}
                                 fetchSuggestions={this.fetchSuggestions}
                                 renderSuggestion={(text) => text}
                                 onChange={this.handleChange}
                                 intl={this.props.intl}
        />
    );
  }
}

export const TextTypeahead = injectIntl(WithApi(TextTypeaheadComponent));
