import * as React from "react";
import {Button, Sizes} from "react-bootstrap";
import {LcdIcon} from "./icon/LcdIcon";

interface StartStopButtonProps {
  showStartButton: boolean;
  label?: any;
  onClick?: (startButtonClicked: boolean) => void;
  bsSize?: Sizes;
}

export class StartStopButton extends React.Component<StartStopButtonProps, {}> {

  render() {
    const {showStartButton, label, bsSize, onClick} = this.props;
    const icon = showStartButton ? "play" : "pause";
    return (
        <Button
            bsSize={bsSize || null}
            onClick={(event) => {
              event.stopPropagation();
              onClick(showStartButton);
            }}>
          <LcdIcon icon={icon}/> {label}
        </Button>
    );
  }
}
