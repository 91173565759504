export const moduleName = "notifications";

const getNotificationState = (state) => state[moduleName];
const getNotifications = (state) => {
  return getNotificationState(state);
  /* notificationState === array of notifications */
};

export const selectors = {
  getNotifications,
};
