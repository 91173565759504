export const STYLE_DATA_TOUR_METADATA = {
  description: "In this tour you will learn how to apply different styles to your data.",
};

export const STYLE_DATA_TOUR_STEPS = [
  {
    title: "List of styles",
    text: "Here you can see the list of styles",
    selector: ".style-data-tour-styles-list",
    position: "bottom-left",
    isFixed: true,
  },
  {
    title: "Upload styles",
    text: "Upload a style file that is OGC SLD/SE-compliant",
    selector: ".style-data-tour-upload-button",
    position: "bottom-left",
    isFixed: true,
  }];
