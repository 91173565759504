import * as React from "react";
import {Button, Table} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {LcdIcon} from "../icon/LcdIcon";
import {FileUploadFormState} from "./FileUploadFormState";
import {humanFileSize} from "./FileUploadUtil";

interface UploadFilesProperties {
  fileUploadFormState: FileUploadFormState;
  removeFile: (file) => void;
}

export class UploadFiles extends React.Component<UploadFilesProperties, {}> {

  render() {
    const state = this.props.fileUploadFormState;
    if (state.uploadFiles.length === 0) {
      return null;
    }

    let tableHeader = (<tr />);
    let tableData = null;

    if (!state.finished) {
      tableHeader = (
          <thead>
          <tr>
            <th className="col-sm-9"><FormattedMessage id="studio.file-upload.file-name" defaultMessage="File name" /></th>
            <th className="col-sm-2"><FormattedMessage id="studio.file-upload.file-size" defaultMessage="File size" /></th>
            <th className="col-sm-1 text-center"><FormattedMessage id="studio.file-upload.remove" defaultMessage="Remove" /></th>
          </tr>
          </thead>
      );

      const files = state.uploadFiles;
      if (files?.length > 0) {
        tableData = files.map((uploadFile, idx) => {
          const removeButton = !this.props.fileUploadFormState.isUploading ? (
              <Button bsSize="xs" bsStyle="danger" onClick={() => this.props.removeFile(uploadFile)}>
                <LcdIcon className="file-upload-remove-LcdIcon" icon="delete"/>
              </Button>
          ) : null;

          const size = humanFileSize(uploadFile.size);

          return (
              <tr key={idx}>
                <td>{uploadFile.name}</td>
                <td>{size}</td>
                <td className="text-center">{removeButton}</td>
              </tr>
          );
        });
      }
    } else {
      tableHeader = (
          <thead>
          <tr>
            <th className="col-sm-1 text-center"><FormattedMessage id="studio.file-upload.upload-status" defaultMessage="Status" /></th>
            <th className="col-sm-5"><FormattedMessage id="studio.file-upload.upload-file-name" defaultMessage="File name" /></th>
            <th className="col-sm-6"><FormattedMessage id="studio.file-upload.upload-status-message" defaultMessage="Status message" /></th>
          </tr>
          </thead>
      );

      const status = state.status;
      if (status?.length > 0) {
        tableData = status.map((uploadStatus, idx) => {
          let statusIcon = <LcdIcon icon={"ok"}/>;
          if (!uploadStatus.success) {
            statusIcon = <LcdIcon icon={"delete"}/>;
          }

          return (
              <tr key={idx}>
                <td className="text-center">
                  {statusIcon}
                </td>
                <td>
                  {uploadStatus.fileName}
                </td>
                <td>
                  {uploadStatus.message}
                </td>
              </tr>
          );
        });
      }
    }

    return (
        <div style={{height: "300px", overflowY: "auto"}}>
          <Table striped hover responsive>
            {tableHeader}
            <tbody>
            {tableData}
            </tbody>
          </Table>
        </div>
    );
  }
}
