import * as React from "react";
import {Col, Grid, Row} from "react-bootstrap";

interface MasterDetailLayoutProps {
  masterPane: JSX.Element;
  detailPane: JSX.Element;
  evenSplit?: boolean;
}

export class MasterDetailLayout extends React.Component<MasterDetailLayoutProps, {}> {

  render() {
    const {masterPane, detailPane, evenSplit} = this.props;
    const masterPaneWidthUnits = evenSplit ? {xs: 6, md: 6} : {xs: 12, md: 8};
    const detailPaneWidthUnits = evenSplit ? {xs: 6, md: 6} : {xs: 12, md: 4};

    return (
        <Grid fluid={true} className="masterDetailLayout">
          <Row>
            <Col {...detailPaneWidthUnits} className="detailPane">
              {detailPane}
            </Col>
            <Col {...masterPaneWidthUnits} className="masterPane">
              {masterPane}
            </Col>
          </Row>
        </Grid>
    );
  }
}
