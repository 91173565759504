import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {FIELD_NAME_MESSAGES, FieldFactory} from "../../common/ui/search/FieldFactory";
import {
  DateField,
  SearchForm,
  SearchFormField,
  SelectField,
  TypeaheadTextField,
} from "../../common/ui/search/SearchForm";
import {EntityType} from "../../model";
import {actions as stylesActions} from "../actions";
import {StyleType} from "../model";
import {selectors} from "../selectors";
import {StyleSearchFilter} from "./model";

interface StylesFilterStateProperties {
  filter: StyleSearchFilter;
}

interface StylesFilterDispatchProperties {
  filterChanged: (newFilter: StyleSearchFilter) => Promise<void>;
}

interface NotificationProps {
  notifyFilterChanged?: () => any;
}

type StylesFilterProps = StylesFilterStateProperties & StylesFilterDispatchProperties & NotificationProps;

export const ANY_TEXT_SEARCH_FIELD_NAME = "anyText";

export class StylesFilterComponent extends React.Component<StylesFilterProps & InjectedIntlProps, {}> {

  _fields: SearchFormField[];

  _typeaheadTextField: TypeaheadTextField;
  _typeField: SelectField;
  _dateStartField: DateField;
  _dateEndField: DateField;

  constructor(props) {
    super(props);
    this._typeaheadTextField = FieldFactory.createTypeaheadTextField(this.props.intl);
    this._typeField = FieldFactory.createTypeField([
      {label: this.props.intl.formatMessage(FIELD_NAME_MESSAGES.typeLabelAny), value: ""},
      {label: this.props.intl.formatMessage(FIELD_NAME_MESSAGES.typeLabelRaster), value: StyleType.RASTER},
      {label: this.props.intl.formatMessage(FIELD_NAME_MESSAGES.typeLabelVector), value: StyleType.VECTOR},
      {label: this.props.intl.formatMessage(FIELD_NAME_MESSAGES.typeLabelMixed), value: StyleType.MIXED},
      {label: this.props.intl.formatMessage(FIELD_NAME_MESSAGES.typeLabelUnknown), value: StyleType.UNKNOWN},
    ]);
    this._dateStartField = FieldFactory.createStartDateField(this.props.intl);
    this._dateEndField = FieldFactory.createEndDateField(this.props.intl);
    this._fields = [
      this._typeaheadTextField,
      this._typeField,
      this._dateStartField,
      this._dateEndField,
    ];
  }

  handleSearch = (formValues) => {
    this.props.notifyFilterChanged && this.props.notifyFilterChanged();

    return this.props.filterChanged({
      anyText: formValues[this._typeaheadTextField.name],
      type: formValues[this._typeField.name],
      startDate: formValues[this._dateStartField.name],
      endDate: formValues[this._dateEndField.name],
    });
  }

  render() {
    const {filter} = this.props;
    return (
        <SearchForm fields={this._fields}
                    values={{
                      [this._typeaheadTextField.name]: filter.anyText || "",
                      [this._typeField.name]: filter.type || "",
                      [this._dateStartField.name]: filter.startDate || "",
                      [this._dateEndField.name]: filter.endDate || "",
                    }}
                    onSearch={this.handleSearch}
                    syncFieldsWithUrl={true}
                    entityType={EntityType.TYPE_STYLE}
        />
    );
  }
}

const mapStateToProps = (state) => ({
  filter: selectors.getFilter(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    filterChanged: (newFilter: StyleSearchFilter) => {
      //order matters here!
      dispatch(stylesActions.setFilter(newFilter));
      dispatch(stylesActions.clearData());
      return Promise.resolve();
    },
  };
};

export const StylesFilter = connect(mapStateToProps, mapDispatchToProps)(injectIntl(StylesFilterComponent));
