export const moduleName = "warnings";

const getWarningsState = (state) => state[moduleName];
const getWarnings = (state) => {
  return getWarningsState(state);
  /* type == WarningsModalMeta? */
};

export const selectors = {
  getWarnings,
};
