import * as React from "react";
import {defineMessages, FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {createDeleteButtonComponent} from "../common/ui/DeleteButton";
import {actions} from "./actions";
import {ImportedData} from "./model";
import {DeleteResponse} from "../common/model";
import InjectedIntlProps = ReactIntl.InjectedIntlProps;

const DeleteButtonComponent = createDeleteButtonComponent();

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDelete: () => {
    return dispatch(actions.deleteData(ownProps.data.map((d) => d.id)));
  },
});

interface DeleteDataButtonDispatchProps {
  onDelete: () => Promise<DeleteResponse>;
}

interface DeleteDataButtonProps {
  data: ImportedData[];
  disabled: boolean;
  onDeleteSuccess: () => void;
  onDeleteError: () => void;
  onDeleteWarnings: () => void;
  onCloseWarnings: () => void;
  detail?: boolean;
}

const DATA_MESSAGES = defineMessages({
  removeDataHeader: {
    id: "studio.data.delete-data-button.delete-data-header",
    defaultMessage: "Remove {Amount} data",
  },
  removeSingleDataHeader: {
    id: "studio.data.delete-data-button.delete-single-data-header",
    defaultMessage: "Remove data {Title}",
  },
  removeDataBody: {
    id: "studio.data.delete-data-button.remove-data-body",
    defaultMessage: "Are you sure you want to continue?",
  },
  whileRemoveData: {
    id: "studio.data.delete-data-button.while-remove-data",
    defaultMessage: "Removing data...",
  },
  deleteButton: {
    id: "studio.data.delete-data-button.delete-button",
    defaultMessage: "Remove Data",
  },
  removeDataSuccess: {
    id: "studio.data.delete-data-button.remove-success",
    defaultMessage: "Data successfully removed",
  },
  tooltipDisabled: {
    id: "studio.data.delete-data-button.no-selection-tooltip",
    defaultMessage: "Select some data from the list below",
  },
  buttonTitle: {
    id: "studio.data.delete-data-button.delete-data",
    defaultMessage: "Remove Data",
  },
  buttonTitleDetail: {
    id: "studio.data.delete-data-button.remove",
    defaultMessage: "Remove",
  },
});

class DeleteDataButtonComponent extends React.Component<DeleteDataButtonProps & InjectedIntlProps & DeleteDataButtonDispatchProps, {}> {

  render() {
    const {data, onDelete, onDeleteSuccess, onDeleteError, onDeleteWarnings, onCloseWarnings, intl, disabled, detail} = this.props;
    let headerText = intl.formatMessage(DATA_MESSAGES.removeDataHeader, {Amount: data.length});
    let buttonTitle = intl.formatMessage(DATA_MESSAGES.buttonTitle);
    if (data && data.length === 1) {
      if (detail) {
        buttonTitle = intl.formatMessage(DATA_MESSAGES.buttonTitleDetail);
      }
      headerText = intl.formatMessage(DATA_MESSAGES.removeSingleDataHeader, {Title: data[0].title});
    }
    return <DeleteButtonComponent
        intl={this.props.intl}
        disabled={disabled}
        onDelete={onDelete}
        onDeleteSuccess={onDeleteSuccess}
        onDeleteError={onDeleteError}
        onDeleteWarnings={onDeleteWarnings}
        onCloseWarnings={onCloseWarnings}
        dialogTitle={headerText}
        buttonTitle={buttonTitle}
        detail={detail}
        tooltipDisabled={intl.formatMessage(DATA_MESSAGES.tooltipDisabled)}
        successMessage={intl.formatMessage(DATA_MESSAGES.removeDataSuccess)}
        executingMessage={intl.formatMessage(DATA_MESSAGES.whileRemoveData)}
        confirmButtonText={intl.formatMessage(DATA_MESSAGES.deleteButton)}
        renderConfirmation={() => {
          return (
              <div>
                <strong> <FormattedMessage id="studio.data.delete-data-button.confirmation-header" defaultMessage="Warnings:"/></strong>
                <ul>
                  <li><FormattedMessage id="studio.data.delete-data-button.warning-1" defaultMessage="The data will be removed from existing products and services"/></li>
                  <li><FormattedMessage id="studio.data.delete-data-button.warning-uploaded-data" defaultMessage="For uploaded data, all related uploaded files will be deleted"/></li>
                  <li><FormattedMessage id="studio.data.delete-data-button.warning-crawled-data" defaultMessage="Crawled data will be removed until the next run of the crawl job, unless the corresponding files are deleted from disk"/></li>
                </ul>
                <FormattedMessage id="studio.data.delete-data-button.confirmation-body" defaultMessage="Are you sure you want to continue?"/>
              </div>
          );
        }}
    />;
  }

}

export const DeleteDataButton = injectIntl(connect(null, mapDispatchToProps)(DeleteDataButtonComponent));
