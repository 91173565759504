import * as React from "react";
import {FormattedMessage} from "react-intl";
import {LcdIcon} from "../../common/ui/icon/LcdIcon";
import {BasicColumnFactory, ColumnWidth} from "../../common/ui/table/ControlRoomColumns";
import {DirectoryItem} from "../../model";

export class DirectoryItemsColumnFactory extends BasicColumnFactory<DirectoryItem> {

  constructor() {
    super({
      fileType: {
        header: "",
        cellContent: (directoryItem) => (
            directoryItem.directory ? <LcdIcon icon="folder-open"/> : <LcdIcon icon="file"/>
        ),
        columnWidth: ColumnWidth.flex(1),
      },
      fileName: {
        header: <FormattedMessage id="studio.settings.data-roots-directory-column.path-header" defaultMessage="Path"/>,
        cellContent: (directoryItem) => directoryItem.fileName,
        columnWidth: ColumnWidth.flex(3),
      },
    });
  }
}
