//See https://intranet.luciad.com/display/PROD/Reactor+brainstorm for job states and transitions

import {defineMessages} from "react-intl";
import {PaginationRequestParams} from "../common/util/pagination/PaginationUtil";

const MODEL_MESSAGES = defineMessages({
  idle: {id: "studio.preprocessing.model.idle", defaultMessage: "IDLE"},
});

export class JobStatus {
  static IDLE = "Idle"; //this.props.intl.formatMessage(MODEL_MESSAGES.idle);
  static SCHEDULED = "Scheduled";
  static QUEUED = "Queued";
  static RUNNING = "Running";
}

export class JobExecutionResult {
  static DID_NOT_RUN = "DID_NOT_RUN";
  static SUCCESS = "Success";
  static FAILED = "Failed";
  static STOPPED = "Stopped";
}

export class JobType {
  static IMPORT = "import";
  static PREPROCESS = "preprocess";
}

export class DecodeFailureResolution {
  static KEEP = "Keep";
  static DELETE = "Delete";
}

export interface Job {
  id: string;
  jobProgress?: number;
  jobState: JobStatus;
  lastExecutionResult: JobExecutionResult;
  lastExecutionTime?: string;
  createdBy?: {
    username: string;
  };
  creationTime: string;
}

export interface ImportJob extends Job {
  dataRoot: string;
  dataRootPath: string;
  crawlStats?: {
    crawledFilesCount: number;
    skippedFilesCount: number;
    failedFilesCount: number;
  };
  ignoreDeletedFiles: boolean;
  forceRecrawlFiles: boolean;
  decodeFailureResolution: DecodeFailureResolution;
  jobSchedule?: any;
}

export interface PreprocessJob extends Job {
  service: string;
  serviceTitle: string;
  product: string;
  productTitle: string;
}

export type CompositeJob = ImportJob | PreprocessJob;

export interface FileInfoSearchFilter extends PaginationRequestParams {
  contentRootID?: string;
  crawlJob?: string;
  dateFilter?: string;
  status?: string;
}

export const filtersEqual = (filterA: FileInfoSearchFilter, filterB: FileInfoSearchFilter) => {
  return (!filterA && !filterB) ||
         (filterA && filterB
          && filterA.contentRootID === filterB.contentRootID
          && filterA.status === filterB.status
          && filterA.crawlJob === filterB.crawlJob
          && filterA.dateFilter === filterB.dateFilter);
};
