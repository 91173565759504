import {Action} from "redux";

type RESET_AFFIX = "RESET";
const RESET_AFFIX: RESET_AFFIX = "RESET";
type STARTED_AFFIX = "STARTED";
const STARTED_AFFIX: STARTED_AFFIX = "STARTED";
type SUCCESS_AFFIX = "SUCCESS";
const SUCCESS_AFFIX: SUCCESS_AFFIX = "SUCCESS";
type ERROR_AFFIX = "ERROR";
const ERROR_AFFIX: ERROR_AFFIX = "ERROR";
export const asyncActionTypeAffixes = {
  RESET_AFFIX,
  STARTED_AFFIX,
  SUCCESS_AFFIX,
  ERROR_AFFIX,
};

export interface LoadDataState<DataToLoad> {
  isLoading: boolean;
  isFinished: boolean;
  payload: DataToLoad | Error | null;
  error: boolean;
}

export interface LoadDataSuccessAction<DataToLoad> extends LoadDataState<DataToLoad> {
  payload: DataToLoad;
}

export interface LoadDataErrorAction<DataToLoad> extends LoadDataState<DataToLoad> {
  payload: Error;
}

type LoadDataReducer<DataToLoad> = (prevState: LoadDataState<DataToLoad>, action: Action) => LoadDataState<DataToLoad>;

export function createLoadDataReducer<DataToLoad>(asyncActionType: string,
                                                  initialPayload): LoadDataReducer<DataToLoad> {
  return (prevState: LoadDataState<DataToLoad> = {
    isLoading: false,
    isFinished: false,
    payload: initialPayload,
    error: false,
  },      action: Action & any) => {
    const reset = asyncActionType + "_" + asyncActionTypeAffixes.RESET_AFFIX;
    const started = asyncActionType + "_" + asyncActionTypeAffixes.STARTED_AFFIX;
    const success = asyncActionType + "_" + asyncActionTypeAffixes.SUCCESS_AFFIX;
    const error = asyncActionType + "_" + asyncActionTypeAffixes.ERROR_AFFIX;

    switch (action.type) {
    case reset:
      return {isLoading: false, isFinished: false, payload: initialPayload, error: false};
    case started:
      return {isLoading: true, isFinished: false, payload: null, error: false};
    case success:
      return {isLoading: false, isFinished: true, payload: action.payload, error: false};
    case error:
      return {isLoading: false, isFinished: true, payload: action.payload, error: true};
    default:
      return prevState;
    }
  };
}

//selectors
export const getLoadedData = (state) => {
  if (isFinished(state) && !isError(state)) {
    return state.payload;
  }
  return [];
};

export const isLoading = (state) => state.isLoading;
export const isFinished = (state) => state.isFinished;
export const getError = (state) => {
  if (isError(state)) {
    return state.payload;
  }
  return null;
};
export const isError = (state) => {
  return state.error;
};

export const asyncDataSelectors = {
  getLoadedData,
  isLoading,
  isFinished,
  getError,
  isError,
};
