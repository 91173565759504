//selectors are separate here, in order to break cyclic dependency cycle between actions and reducer
import {selectors as pagedDataSelectors} from "../common/util/pagination/PaginationUtil";
import {ServiceFilter} from "./filter/model";
import {Service} from "./model";

export const moduleName = "services";

const getServiceState = (state) => state[moduleName];

const getServiceById = (state, id: string): Service => {
  const pagedServiceState = getPagedState(state);
  return pagedDataSelectors.getDataById<Service>(pagedServiceState, id);
};

const getServiceProducts = (state, id: string) => {
  const serviceState = getServiceState(state);
  const products = serviceState.serviceProductsById[id];
  return products === undefined ? products : products.slice(0).reverse();
};

const getFilter = (state): ServiceFilter => {
  const serviceState = getServiceState(state);
  return serviceState.filter;
};

const getPagedState = (state) => {
  const servicesState = getServiceState(state);
  return servicesState.pages;
};

const getAllServiceTypes = (state) => getServiceState(state).allServiceTypes;
const getEnabledServiceTypes = (state) => getServiceState(state).enabledServiceTypes;

export const selectors = {
  getServiceById,
  getServiceProducts,
  getPagedState,
  getAllServiceTypes,
  getEnabledServiceTypes,
  getFilter,
};
