import {FileUploadStatus} from "../../../model";

export class FileUploadFormState {
  uploadFiles: File[];
  isUploading: boolean;
  finished: boolean;
  progress: number;
  status: FileUploadStatus[];
  maxUploadSize: number;

  constructor(state?: FileUploadFormState) {
    this.uploadFiles = state ? state.uploadFiles : [];
    this.isUploading = state ? state.isUploading : false;
    this.finished = state ? state.finished : false;
    this.progress = state ? state.progress : 0;
    this.status = state ? state.status : null;
    this.maxUploadSize = state ? state.maxUploadSize : null;
  }
}
