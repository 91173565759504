import {History} from "history";
import {parse, stringify} from "query-string";
import * as React from "react";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {ConfigurationInfo} from "../model";
import {actions} from "./actions";
import {selectors} from "./selectors";
import {StartupStatus} from "./StartupStatus";

interface StartupState {
  missingMappEntInfo?: boolean;
  invalidMappEntInfo?: boolean;
}

interface StartupProps {
  loadConfiguration: (refreshToken: string, tenant: string) => Promise<void>;
  history: History;
  configurationInfo: ConfigurationInfo;
}

const STARTUP_ERROR_MESSAGES = defineMessages({
  missingMappEnterpriseInfoTitle: {
    id: "studio.startup-error.missing-mappent-info.title",
    defaultMessage: "Launch Fusion Studio from M.App Enterprise Studio",
  },
  missingMappEnterpriseInfoMessage: {
    id: "studio.startup-error.missing-mappent-info.message",
    defaultMessage: "The Fusion server is configured for M.App Enterprise integration, Fusion Studio must be launched from M.App Enterprise Studio.",
  },
  invalidMappEnterpriseInfoTitle: {
    id: "studio.startup-error.invalid-mappent-info.title",
    defaultMessage: "Invalid M.App Enterprise Authentication Information",
  },
  invalidMappEnterpriseInfoMessage: {
    id: "studio.startup-error.invalid-mappent-info.message",
    defaultMessage: "The M.App Enterprise authentication information provided to Fusion Studio is invalid or expired, launch Fusion Studio from M.App Enterprise Studio again.",
  },
});

class Startup extends React.Component<WithApiProperties & InjectedIntlProps & StartupProps & StartupState, StartupState> {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {history} = this.props;
    const query = parse(history.location.search);
    const refreshToken = query.refresh_token ? (Array.isArray(query.refresh_token) ? query.refresh_token[0] : query.refresh_token) : null;
    const tenant = query.tenant ? (Array.isArray(query.tenant) ? query.tenant[0] : query.tenant): null;
    this.props.loadConfiguration(refreshToken, tenant).then(() => {
      const {configurationInfo} = this.props;
      if (configurationInfo.securityInfo.isMappEnterprise) {
        if (configurationInfo.missingMappEnterpriseInfo) {
          this.setState({
            missingMappEntInfo: true,
          });
        } else if (configurationInfo.invalidMappEnterpriseInfo) {
          this.setState({
            invalidMappEntInfo: true,
          });
        } else {
          // remove the M.App Enterprise auth related parameters from the querystring and update the location, this
          // will retain everything else so that deep linking still works.
          delete query.refresh_token;
          delete query.tenant;
          delete query.me_host;
          history.push({
            pathname: history.location.pathname,
            search: stringify(query),
          });
        }
      }
    });
  }

  render() {
    const {children, configurationInfo, intl} = this.props;
    if (configurationInfo) {
      const {invalidMappEntInfo, missingMappEntInfo} = this.state;
      if (invalidMappEntInfo) {
        return <StartupStatus
            title={intl.formatMessage(STARTUP_ERROR_MESSAGES.invalidMappEnterpriseInfoTitle)}
            message={intl.formatMessage(STARTUP_ERROR_MESSAGES.invalidMappEnterpriseInfoMessage)}
        />;
      } else if (missingMappEntInfo) {
        return <StartupStatus
            title={intl.formatMessage(STARTUP_ERROR_MESSAGES.missingMappEnterpriseInfoTitle)}
            message={intl.formatMessage(STARTUP_ERROR_MESSAGES.missingMappEnterpriseInfoMessage)}
        />;
      } else {
        return <div>{children}</div>;
      }
    } else {
      return <StartupStatus/>;
    }
  }
}

function mapStateToProps(state) {
  return {
    configurationInfo: selectors.getConfigurationInfo(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loadConfiguration: (refreshToken: string, tenant: string): Promise<void> => {
      return dispatch(actions.loadConfiguration(refreshToken, tenant));
    },
  };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(WithApi(Startup)));
