import {Notification} from "./model";

type SHOW_NOTIFICATION = "notification/SHOW_NOTIFICATION";
const SHOW_NOTIFICATION = "notification/SHOW_NOTIFICATION";

type HIDE_NOTIFICATION = "notification/HIDE_NOTIFICATION";
const HIDE_NOTIFICATION = "notification/HIDE_NOTIFICATION";

let notificationNextId = 0;

export interface ShowNotificationAction {
  type: SHOW_NOTIFICATION;
  payload: Notification;
}

export interface HideNotificationAction {
  type: HIDE_NOTIFICATION;
  payload: Notification;
}

const showNotification = (notification: Notification) => {
  return (dispatch, getState) => {
    dispatch({type: SHOW_NOTIFICATION, payload: notification});

    // Toast effect: Make notification disappear after 5 seconds
    setTimeout(() => {
      dispatch({type: HIDE_NOTIFICATION, payload: {id: notification.id}});
    }, 5000 );
  };
};

const showErrorNotification = (notificationMessage: string) => {
  const notification = {id: notificationNextId++, message: notificationMessage, style: "danger"};
  return showNotification(notification);
};

const showWarningNotification = (notificationMessage: string) => {
  const notification = {id: notificationNextId++, message: notificationMessage, style: "warning"};
  return showNotification(notification);
};

const showInfoNotification = (notificationMessage: string) => {
  const notification = {id: notificationNextId++, message: notificationMessage, style: "info"};
  return showNotification(notification);
};
const showSuccessNotification = (notificationMessage: string) => {
  const notification = {id: notificationNextId++, message: notificationMessage, style: "success"};
  return showNotification(notification);
};

const hideNotification = (notificationId: number) => {
  return (dispatch, getState) => {
    dispatch({type: HIDE_NOTIFICATION, payload: {id: notificationId}});
  };
};

const actionTypes = {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
};

const actions = {
  showErrorNotification,
  showWarningNotification,
  showInfoNotification,
  showSuccessNotification,

  hideNotification,
};

export {actions, actionTypes};
