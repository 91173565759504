import * as React from "react";

interface FileDropProps {
  onDrop?: (files: File[], e: React.DragEvent<any>) => any;
  baseClassName?: string;
  disabled?: boolean;
}

interface FileDropState {
  draggingOverWindow: boolean;
  draggingOverDropZone: boolean;
}

const isFileDragEvent = (event: React.DragEvent<any>): boolean => {
  if (!event.dataTransfer) {
    return false;
  }
  //event.dataTransfer.types is one of the few properties that is present across all major browsers
  if (event.dataTransfer.types) {
    let dataTransferContainsFiles = false;
    //event.dataTransfer.types is sometimes a DOMStringList and sometimes an Array..
    for (const dataTransferType of event.dataTransfer.types) {
      if (dataTransferType.indexOf("Files") >= 0) {
        dataTransferContainsFiles = true;
        break;
      }
    }
    return dataTransferContainsFiles;
  }
};

export class FileDrop extends React.Component<FileDropProps, FileDropState> {

  static defaultState() {
    return {
      draggingOverWindow: false,
      draggingOverDropZone: false,
    };
  }

  _windowCounter: number;
  _dropZoneCounter: number;

  constructor(props: FileDropProps) {
    super(props);

    this.state = FileDrop.defaultState();
    this._windowCounter = 0;
    this._dropZoneCounter = 0;
  }

  componentDidMount() {
    this.setState(FileDrop.defaultState());
    this.addWindowListeners();
    this.addDropZoneListeners();
  }

  componentWillUnmount() {
    this.removeDropZoneListeners();
    this.removeWindowListeners();
  }

  removeWindowListeners() {
    window.document.removeEventListener("dragenter", this._handleWindowDragEnter, false);
    window.document.removeEventListener("dragover", this._handleWindowDragOver, false);
    window.document.removeEventListener("dragleave", this._handleWindowDragLeave, false);
    window.document.removeEventListener("drop", this._handleWindowDrop, false);
  }

  addWindowListeners() {
    window.document.addEventListener("dragenter", this._handleWindowDragEnter, false);
    window.document.addEventListener("dragover", this._handleWindowDragOver, false);
    window.document.addEventListener("dragleave", this._handleWindowDragLeave, false);
    window.document.addEventListener("drop", this._handleWindowDrop, false);
  }

  addDropZoneListeners() {
    const dropZoneElement = this.refs.dropZone as HTMLElement;
    if (dropZoneElement) {
      dropZoneElement.addEventListener("dragenter", this._handleDropZoneDragEnter, false);
      dropZoneElement.addEventListener("dragover", this._handleDropZoneDragOver, false);
      dropZoneElement.addEventListener("dragleave", this._handleDropZoneDragLeave, false);
      dropZoneElement.addEventListener("drop", this._handleDropZoneDrop, false);
    }
  }

  removeDropZoneListeners() {
    const dropZoneElement = this.refs.dropZone as HTMLElement;
    if (dropZoneElement) {
      dropZoneElement.removeEventListener("dragenter", this._handleDropZoneDragEnter, false);
      dropZoneElement.removeEventListener("dragover", this._handleDropZoneDragOver, false);
      dropZoneElement.removeEventListener("dragleave", this._handleDropZoneDragLeave, false);
      dropZoneElement.removeEventListener("drop", this._handleDropZoneDrop, false);
    }
  }

  _handleWindowDragEnter = (event) => {
    event.preventDefault();
    if (isFileDragEvent(event)) {
      this._windowCounter++;
      if (this._windowCounter === 1) {
        const newState = Object.assign({}, this.state, {draggingOverWindow: true});
        this.setState(newState);
      }
    }
  }

  _handleWindowDragOver = (event) => {
    event.preventDefault();
  }

  _handleWindowDragLeave = (event) => {
    if (isFileDragEvent(event)) {
      this._windowCounter--;
      if (this._windowCounter === 0) {
        const newState = Object.assign({}, this.state, {draggingOverWindow: false});
        this.setState(newState);
      }
    }
  }

  _handleWindowDrop = (event) => {
    event.preventDefault();
    this._dropZoneCounter = 0;
    this._windowCounter = 0;
    this.setState(FileDrop.defaultState());
  }

  _handleDropZoneDragEnter = (event) => {
    event.preventDefault(); //for unknown reasons this is necessary. Feel free to replace this comment if you know why.
    if (isFileDragEvent(event)) {
      // event.stopPropagation(); //stop event from bubbling up
      this._dropZoneCounter++;
      if (this._dropZoneCounter === 1) {
        const newState = Object.assign({}, this.state, {draggingOverDropZone: true});
        this.setState(newState);
      }
    }
  }

  _handleDropZoneDragOver = (event) => {
    event.preventDefault();
  }

  _handleDropZoneDragLeave = (event) => {
    if (isFileDragEvent(event)) {
      // event.stopPropagation();
      this._dropZoneCounter--;
      if (this._dropZoneCounter === 0) {
        const newState = Object.assign({}, this.state, {draggingOverDropZone: false});
        this.setState(newState);
      }
    }
  }

  _handleDropZoneDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this._dropZoneCounter = 0;
    this._windowCounter = 0;
    if (isFileDragEvent(event)) {

      if (!this.props.disabled) {
        const itemList = event.dataTransfer.files;
        const files: File[] = [];
        for (let i = 0; i < itemList.length; i++) {
          files.push(itemList.item(i));
        }
        if (this.props.onDrop) {
          this.props.onDrop(files, event);
        }
      }

      this.setState(FileDrop.defaultState());
    }
  }

  render() {
    let className = this.props.baseClassName || "controlroom-dnd";

    if (this.props.disabled) {
      className += " disabled";
    }

    if (this.state.draggingOverWindow) {
      className += " dragging";
    }

    if (this.state.draggingOverDropZone) {
      className += " dragHover";
    }

    return (
        <div className={className} ref="dropZone">
          {this.props.children}
        </div>
    );
  }

}
