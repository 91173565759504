import * as React from "react";
import {Button, Modal} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {ErrorDisplay} from "../../common/ui/errordisplay/ErrorDisplay";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {actions} from "./actions";
import {ChooseFolderForm} from "./ChooseFolderForm";
import {DataRoot} from "./model";
import {mapErrorToMessage} from "../../common/util/ErrorMapper";
import {Alert} from "react-bootstrap/lib";

interface ChooseFolderDialogOwnProps {
  show: boolean;
  onHide: () => void;
  handleChoose: (dataRootPath: DataRoot) => Promise<void>;
  initialPath?: string;
  title: string;
  buttonText: string;
}

interface ChooseFolderDialogDispatchProps {
  onFolderChoosed: () => void;
}

type ChooseFolderDialogProps = ChooseFolderDialogDispatchProps & ChooseFolderDialogOwnProps & WithApiProperties;

interface ChooseDataRootDialogState {
  isLoading: boolean;
  success: boolean;
  error: Error;
}

class ChooseFolderDialogComponent extends React.Component<ChooseFolderDialogProps, ChooseDataRootDialogState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      success: false,
      error: null,
    };
  }

  addContentRoot(contentRoot: DataRoot) {
    this.setState(Object.assign({}, this.state, {isLoading: true}));
    this.props.handleChoose(contentRoot).then((data) => {
      this.setState(Object.assign({}, this.state, {isLoading: false, success: true}));
      this.props.onFolderChoosed();
    }).catch(
        (error) => {
          this.setState(Object.assign({}, this.state, {isLoading: false, error}));
        },
    );
  }

  resetState = () => {
    this.setState({isLoading: false, success: false, error: null});
  }

  render() {
    const {show, onHide, initialPath, title, buttonText} = this.props;
    const {isLoading, success, error} = this.state;
    const dataHandler = (contentRootPath) => {
      const contentRoot = {rootPath: contentRootPath};
      this.addContentRoot(contentRoot);
    };
    let pageMainContent, buttons = null;
    if (!isLoading && error) {
      const errorMessage = mapErrorToMessage(error);
      if (errorMessage) {
        pageMainContent = <Alert bsStyle="danger">{errorMessage}</Alert>;
      } else {
        pageMainContent = (
            <div>
              <ErrorDisplay error={error}/>
            </div>
        );
      }
      buttons = <Button onClick={this.resetState}> <FormattedMessage id="studio.add-data-root-dialog.try-again"
                                                                     defaultMessage="Try again"/> </Button>;
    } else if (!isLoading && success) {
      pageMainContent = (
          <div>
            <p><FormattedMessage id="studio.add-data-root-dialog.add-success"
                                 defaultMessage="Successfully added selected folder."/></p>
          </div>
      );
      buttons = (
          <Button onClick={onHide}> <FormattedMessage id="studio.add-data-root-dialog.close"
                                                      defaultMessage="Close"/></Button>
      );
    } else if (isLoading) {
      pageMainContent = <p><FormattedMessage id="studio.add-data-root-dialog.adding"
                                             defaultMessage="Loading..."/></p>;
    } else {
      pageMainContent = <ChooseFolderForm handleChoose={dataHandler}
                                            initialPath={initialPath}
                                            buttonText={buttonText}/>;
    }

    const footer = buttons ? (<Modal.Footer>{buttons}</Modal.Footer>) : null;

    return (
        <div>
          <Modal show={show} onHide={onHide} onExited={this.resetState} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {pageMainContent}
            </Modal.Body>
            {footer}
          </Modal>
        </div>
    );
  }

}

//a container around AddDataRootDialog that reloads content root data after the dialog has created a new content root
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onFolderChoosed: () => {
      dispatch(actions.loadDataRoots());
    },
  };
};

export const AddFolderDialog = connect(null, mapDispatchToProps)(WithApi(ChooseFolderDialogComponent));

export const ChooseFolderDialog = WithApi(ChooseFolderDialogComponent);
