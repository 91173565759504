import {createAsyncActionTypes, createLoadDataActionCreator} from "../../common/util/asyncdata/actionUtil";
import {PreprocessJob} from "../model";
import {preprocessJobSubmoduleName, selectors} from "../selectors";

export const LOAD_PREPROCESS_JOBS_PREFIX = "controlRoom/LOAD_PREPROCESS_JOBS";
const loadPreprocessJobActionTypes = createAsyncActionTypes(LOAD_PREPROCESS_JOBS_PREFIX);

export const LOAD_PREPROCESS_JOB_DETAIL_PREFIX = "controlRoom/LOAD_PREPROCESS_JOB_DETAIL";
const loadPreprocessJobDetailActionTypes = createAsyncActionTypes(LOAD_PREPROCESS_JOB_DETAIL_PREFIX);

export const preprocessActionTypes = {
  ADD_PREPROCESS_JOB: "controlRoom/ADD_PREPROCESS_JOB",
  REMOVE_PREPROCESS_JOB: "controlRoom/REMOVE_PREPROCESS_JOB",
  QUEUE_PREPROCESS_JOB: "controlRoom/QUEUE_PREPROCESS_JOB",
  RUN_PREPROCESS_JOB: "controlRoom/RUN_PREPROCESS_JOB",
  PROGRESS_PREPROCESS_JOB: "controlRoom/PROGRESS_PREPROCESS_JOB",
  STOP_PREPROCESS_JOB: "controlRoom/STOP_PREPROCESS_JOB",
  FINISH_PREPROCESS_JOB: "controlRoom/FINISH_PREPROCESS_JOB",
  FAIL_PREPROCESS_JOB: "controlRoom/FAIL_PREPROCESS_JOB",
  UPDATE_PREPROCESS_JOB: "controlRoom/UPDATE_PREPROCESS_JOB",
  LOAD_PREPROCESS_JOBS_STARTED: loadPreprocessJobActionTypes.STARTED,
  LOAD_PREPROCESS_JOBS_SUCCESS: loadPreprocessJobActionTypes.SUCCESS,
  LOAD_PREPROCESS_JOBS_ERROR: loadPreprocessJobActionTypes.ERROR,
  LOAD_PREPROCESS_JOBS_RESET: loadPreprocessJobActionTypes.RESET,
  LOAD_PREPROCESS_JOB_DETAIL_STARTED: loadPreprocessJobDetailActionTypes.STARTED,
  LOAD_PREPROCESS_JOB_DETAIL_SUCCESS: loadPreprocessJobDetailActionTypes.SUCCESS,
  LOAD_PREPROCESS_JOB_DETAIL_ERROR: loadPreprocessJobDetailActionTypes.ERROR,
  LOAD_PREPROCESS_JOB_DETAIL_RESET: loadPreprocessJobDetailActionTypes.RESET,
};

const addJob = (job: PreprocessJob) => {
  return {
    type: preprocessActionTypes.ADD_PREPROCESS_JOB,
    payload: {
      job,
    },
  };
};

const removeJob = (jobId: string) => {
  return {
    type: preprocessActionTypes.REMOVE_PREPROCESS_JOB,
    payload: {
      id: jobId,
    },
  };
};

const queueJob = (jobId: string) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.queuePreprocessJob(jobId);
  };
};

const progressJob = (jobId: string, progress: number) => {
  return {
    type: preprocessActionTypes.PROGRESS_PREPROCESS_JOB,
    payload: {
      id: jobId,
      progress,
    },
  };
};

const finishJob = (jobId: string) => {
  return {
    type: preprocessActionTypes.FINISH_PREPROCESS_JOB,
    payload: {
      id: jobId,
    },
  };
};

const failJob = (jobId: string) => {
  return {
    type: preprocessActionTypes.FAIL_PREPROCESS_JOB,
    payload: {
      id: jobId,
    },
  };
};

const stopJob = (jobId: string) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).stopPreprocessJob(jobId);
  };
};

const updateJobOnClient = (job: PreprocessJob) => {
  return {
    type: preprocessActionTypes.UPDATE_PREPROCESS_JOB,
    payload: {
      job,
    },
  };
};

const jobRequestState = selectors.getJobRequestState.bind(null, preprocessJobSubmoduleName);
const jobDetailRequestState = selectors.getJobDetailRequestState.bind(null, preprocessJobSubmoduleName);

const loadJobs = createLoadDataActionCreator(LOAD_PREPROCESS_JOBS_PREFIX, (api) => api.loadPreprocessJobs,
    jobRequestState);
const loadJobById = createLoadDataActionCreator(LOAD_PREPROCESS_JOB_DETAIL_PREFIX, (api) => api.getPreprocessJobById,
    jobDetailRequestState);

export const actions = {
  addJob,
  removeJob,
  queueJob,
  progressJob,
  finishJob,
  failJob,
  stopJob,
  updateJobOnClient,
  loadJobs,
  loadJobById,
};
