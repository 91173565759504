export function getTotalUploadSize(uploadFiles: File[]) {
  let size = 0;
  for (const uploadFile of uploadFiles) {
    size += uploadFile.size;
  }
  return size;
}

export function humanFileSize(bytes) {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return bytes.toFixed(1) + " " + units[u];
}
