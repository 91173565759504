import * as React from "react";
import {defineMessages, injectIntl} from "react-intl";
import {CronUtil, CronValidationResult} from "./CronUtil";
import InjectedIntlProps = ReactIntl.InjectedIntlProps;

interface CronPickerProperties {
  value: string;
  onChange: (newValue: string) => void;
}

export class Period {

  static MINUTE = new Period("MIN", /^0\s(\*\s){4}\*$/);
  static HOUR = new Period("HOUR", /^0\s\d{1,2}\s(\*\s){3}\*$/);
  static DAY = new Period("DAY", /^0\s(\d{1,2}\s){2}(\*\s){2}\*$/);
  static WEEK = new Period("WEEK", /^0\s(\d{1,2}\s){2}(\*\s){2}\d{1,2}$/);
  static MONTH = new Period("MONTH", /^0\s(\d{1,2}\s){3}\*\s\*$/);
  static YEAR = new Period("YEAR", /^0\s(\d{1,2}\s){4}\*$/);

  static ALL_PERIODS = [
    Period.MINUTE,
    Period.HOUR,
    Period.DAY,
    Period.WEEK,
    Period.MONTH,
    Period.YEAR,
  ];

  static fromString = (value: string): Period =>  {
    return Period.ALL_PERIODS.find((period) => period.value === value);
  }

  private constructor(public value: string, public regex: RegExp) {
  }
}

interface CronPickerState {
  period: Period;
  minuteVal: string;
  hourVal: string;
  dayOfMonthVal: string;
  monthVal: string;
  dayOfWeekVal: string;
}

const PERIOD_FOR_INVALID_VALUES = Period.MINUTE;

const CRON_PICKER_MESSAGES = defineMessages({
  periodMIN: {
    id: "studio.UI.cron.simple-cron-picker.period.min",
    defaultMessage: "minute",
  },
  periodHOUR: {
    id: "studio.UI.cron.simple-cron-picker.period.hour",
    defaultMessage: "hour",
  },
  periodDAY: {
    id: "studio.UI.cron.simple-cron-picker.period.day",
    defaultMessage: "day",
  },
  periodWEEK: {
    id: "studio.UI.cron.simple-cron-picker.period.week",
    defaultMessage: "week",
  },
  periodMONTH: {
    id: "studio.UI.cron.simple-cron-picker.period.month",
    defaultMessage: "month",
  },
  periodYEAR: {
    id: "studio.UI.cron.simple-cron-picker.period.year",
    defaultMessage: "year",
  },
  january: {
    id: "studio.UI.cron.simple-cron-picker.months.january",
    defaultMessage: "January",
  },
  february: {
    id: "studio.UI.cron.simple-cron-picker.months.february",
    defaultMessage: "February",
  },
  march: {
    id: "studio.UI.cron.simple-cron-picker.months.march",
    defaultMessage: "March",
  },
  april: {
    id: "studio.UI.cron.simple-cron-picker.months.april",
    defaultMessage: "April",
  },
  may: {
    id: "studio.UI.cron.simple-cron-picker.months.may",
    defaultMessage: "May",
  },
  june: {
    id: "studio.UI.cron.simple-cron-picker.months.june",
    defaultMessage: "June",
  },
  july: {
    id: "studio.UI.cron.simple-cron-picker.months.july",
    defaultMessage: "July",
  },
  august: {
    id: "studio.UI.cron.simple-cron-picker.months.august",
    defaultMessage: "August",
  },
  september: {
    id: "studio.UI.cron.simple-cron-picker.months.september",
    defaultMessage: "September",
  },
  october: {
    id: "studio.UI.cron.simple-cron-picker.months.october",
    defaultMessage: "October",
  },
  november: {
    id: "studio.UI.cron.simple-cron-picker.months.november",
    defaultMessage: "November",
  },
  december: {
    id: "studio.UI.cron.simple-cron-picker.months.december",
    defaultMessage: "December",
  },
  sunday: {
    id: "studio.UI.cron.simple-cron-picker.days.sunday",
    defaultMessage: "Sunday",
  },
  monday: {
    id: "studio.UI.cron.simple-cron-picker.days.monday",
    defaultMessage: "Monday",
  },
  tuesday: {
    id: "studio.UI.cron.simple-cron-picker.days.tuesday",
    defaultMessage: "Tuesday",
  },
  wednesday: {
    id: "studio.UI.cron.simple-cron-picker.days.wednesday",
    defaultMessage: "Wednesday",
  },
  thursday: {
    id: "studio.UI.cron.simple-cron-picker.days.thursday",
    defaultMessage: "Thursday",
  },
  friday: {
    id: "studio.UI.cron.simple-cron-picker.days.friday",
    defaultMessage: "Friday",
  },
  saturday: {
    id: "studio.UI.cron.simple-cron-picker.days.saturday",
    defaultMessage: "Saturday",
  },
  at: {
    id: "studio.UI.cron.simple-cron-picker.at",
    defaultMessage: "at",
  },
  on: {
    id: "studio.UI.cron.simple-cron-picker.on",
    defaultMessage: "on",
  },
  of: {
    id: "studio.UI.cron.simple-cron-picker.of",
    defaultMessage: "of",
  },
  onDay: {
    id: "studio.UI.cron.simple-cron-picker.on-day",
    defaultMessage: "on day",
  },
  every: {
    id: "studio.UI.cron.simple-cron-picker.every",
    defaultMessage: "Every",
  },
  minuteInHour: {
    id: "studio.UI.cron.simple-cron-picker.minutes-in-hour",
    defaultMessage: "minutes past the hour",
  },
});

export class SimpleCronPickerComponent extends React.Component<CronPickerProperties & InjectedIntlProps, CronPickerState> {

  //checks if the simple cron picker can handle the string. If it can handle the string, it will
  //also return the period the cron string is for.
  static canHandleCronString(cronString: string): CronValidationResult & {supported: boolean, period?: Period} {
    const validation = CronUtil.validateCronString(cronString);
    if (!validation.valid) {
      return Object.assign({}, validation, {supported: false});
    }

    // determine period for string using the Period regexes
    for (const period of Period.ALL_PERIODS) {
      if (period.regex.test(cronString)) {
        return Object.assign({}, validation, {period, supported: true});
      }
    }

    return Object.assign({}, validation, {
      supported: false,
      warning: "Valid Cron string, but unsupported by simple cron picker.",
    });
  }

  constructor(props) {
    super(props);
    this.state = this.createStateFromCronString(props.value);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props !== nextProps) {
      if (this.props.value !== nextProps.value) {
        this.setState(this.createStateFromCronString(nextProps.value));
      }
    }
  }

  createCronString = (state: CronPickerState): string => {
    const sec = "0";
    let min = "*";
    let hour = "*";
    let day = "*";
    let month = "*";
    let dow = "*";
    switch (state.period.value) {
    case Period.MINUTE.value:
      break;
    case Period.HOUR.value:
      min = state.minuteVal;
      break;
    case Period.DAY.value:
      min = state.minuteVal;
      hour = state.hourVal;
      break;
    case Period.WEEK.value:
      min = state.minuteVal;
      hour = state.hourVal;
      dow = state.dayOfWeekVal;
      break;
    case Period.MONTH.value:
      min = state.minuteVal;
      hour = state.hourVal;
      day = state.dayOfMonthVal;
      break;
    case Period.YEAR.value:
      min = state.minuteVal;
      hour = state.hourVal;
      day = state.dayOfMonthVal;
      month = state.monthVal;
      break;
    default:
      break;
    }
    return [sec, min, hour, day, month, dow].join(" ");
  }

  createStateFromCronString = (cron: string): CronPickerState => {
    const period = SimpleCronPickerComponent.canHandleCronString(cron).period || PERIOD_FOR_INVALID_VALUES;
    if (!cron) {
      return {
        period,
        minuteVal: "0",
        hourVal: "0",
        dayOfMonthVal: "1",
        monthVal: "1",
        dayOfWeekVal: "1",
      };
    }
    const cols = cron.split(" "); //sec, min, hour, day, month, weekday
    return {
      period,
      minuteVal: cols[1] === "*" ? "0" : cols[1],
      hourVal: cols[2] === "*" ? "0" : cols[2],
      dayOfMonthVal: cols[3] === "*" ? "1" : cols[3],
      monthVal: cols[4] === "*" ? "1" : cols[4],
      dayOfWeekVal: cols[5] === "*" ? "1" : cols[5],
    };
  }

  triggerChange = () => {
    this.props.onChange(this.createCronString(this.state));
  }

  renderPeriodSelect() {
    const {period} = this.state;
    const {intl} = this.props;
    const handleChange = (event) => {
      const changedPeriod = Period.fromString((event.target as any).value);
      this.setState(Object.assign({}, this.state, {period: changedPeriod}), this.triggerChange);
    };
    return (
        <select className="form-control periodSelect" value={period.value} onChange={handleChange}>
          {
            Period.ALL_PERIODS.map((aPeriod) => {
              return (<option key={aPeriod.value} value={aPeriod.value}>{intl.formatMessage(CRON_PICKER_MESSAGES["period" + aPeriod.value])}</option>);
            })
          }
        </select>
    );
  }

  renderMinuteInHourSelect() {
    const options = [];
    for (let i = 0; i < 60; i++) {
      const j = (i < 10) ? "0" : "";
      options.push(<option value={i} key={i}>{j}{i}</option>);
    }
    const handleChange = (event) => {
      this.setState(Object.assign({}, this.state, {minuteVal: (event.target as any).value}), this.triggerChange);
    };
    return (
       <select className="form-control minuteInHourSelect" value={this.state.minuteVal} onChange={handleChange}>
         {options}
       </select>
    );
  }

  renderHourSelect() {
    const options = [];
    for (let i = 0; i < 24; i++) {
      const j = (i < 10) ? "0" : "";
      options.push(<option value={i} key={i}>{j}{i}</option>);
    }
    const handleChange = (event) => {
      this.setState(Object.assign({}, this.state, {hourVal: (event.target as any).value}), this.triggerChange);
    };
    return (
        <select className="form-control hourSelect" value={this.state.hourVal} onChange={handleChange}>
          {options}
        </select>
    );
  }

  renderDayOfWeekSelect() {
    const {intl} = this.props;
    const days = [
      intl.formatMessage(CRON_PICKER_MESSAGES.sunday),
      intl.formatMessage(CRON_PICKER_MESSAGES.monday),
      intl.formatMessage(CRON_PICKER_MESSAGES.tuesday),
      intl.formatMessage(CRON_PICKER_MESSAGES.wednesday),
      intl.formatMessage(CRON_PICKER_MESSAGES.thursday),
      intl.formatMessage(CRON_PICKER_MESSAGES.friday),
      intl.formatMessage(CRON_PICKER_MESSAGES.saturday),
    ];
    const handleChange = (event) => {
      this.setState(Object.assign({}, this.state, {dayOfWeekVal: (event.target as any).value}), this.triggerChange);
    };
    return (
        <select className="form-control dayOfWeekSelect" value={this.state.dayOfWeekVal} onChange={handleChange}>
          {days.map((day, i) => <option value={i} key={i}>{day}</option>)}
        </select>
    );
  }

  renderDayOfMonthSelect() {
    const options = [];
    for (let i = 1; i < 32; i++) {
      options.push(<option value={i} key={i}>{i}</option>);
    }
    const handleChange = (event) => {
      this.setState(Object.assign({}, this.state, {dayOfMonthVal: (event.target as any).value}), this.triggerChange);
    };
    return (
        <select className="form-control dayOfMonthSelect" value={this.state.dayOfMonthVal} onChange={handleChange}>
          {options}
        </select>
    );
  }

  renderMonthSelect() {
    const {intl} = this.props;
    const months = [
      intl.formatMessage(CRON_PICKER_MESSAGES.january),
      intl.formatMessage(CRON_PICKER_MESSAGES.february),
      intl.formatMessage(CRON_PICKER_MESSAGES.march),
      intl.formatMessage(CRON_PICKER_MESSAGES.april),
      intl.formatMessage(CRON_PICKER_MESSAGES.may),
      intl.formatMessage(CRON_PICKER_MESSAGES.june),
      intl.formatMessage(CRON_PICKER_MESSAGES.july),
      intl.formatMessage(CRON_PICKER_MESSAGES.august),
      intl.formatMessage(CRON_PICKER_MESSAGES.september),
      intl.formatMessage(CRON_PICKER_MESSAGES.october),
      intl.formatMessage(CRON_PICKER_MESSAGES.november),
      intl.formatMessage(CRON_PICKER_MESSAGES.december),
    ];
    const handleChange = (event) => {
      this.setState(Object.assign({}, this.state, {monthVal: (event.target as any).value}), this.triggerChange);
    };
    return (
        <select className="form-control monthSelect" value={this.state.monthVal} onChange={handleChange}>
          {months.map((month, i) => <option value={i + 1} key={i + 1}>{month}</option>)}
        </select>
    );
  }

  renderPeriodBlock() {
    const {intl} = this.props;
    return (<span>
      {intl.formatMessage(CRON_PICKER_MESSAGES.every)}
      &nbsp;
      {this.renderPeriodSelect()}
      </span>);
  }

  renderMinsBlock() {
    const {intl} = this.props;
    return (<span>
      {intl.formatMessage(CRON_PICKER_MESSAGES.at)}
      &nbsp;
      {intl.formatMessage(CRON_PICKER_MESSAGES.minuteInHour)}
      &nbsp;
      {this.renderMinuteInHourSelect()}
      </span>);
  }

  renderTimeBlock() {
    const {intl} = this.props;
    return (<span>
      {intl.formatMessage(CRON_PICKER_MESSAGES.at)}
      &nbsp;
      {this.renderHourSelect()}
      &nbsp;&#58;&nbsp;
      {this.renderMinuteInHourSelect()}
      </span>);
  }

  renderDayOfWeekBlock() {
    const {intl} = this.props;
    return (<span>
      {intl.formatMessage(CRON_PICKER_MESSAGES.on)}
      &nbsp;
      {this.renderDayOfWeekSelect()}
      </span>);
  }

  renderDayOfMonthBlock() {
    const {intl} = this.props;
    return (<span>
      {intl.formatMessage(CRON_PICKER_MESSAGES.onDay)}
      &nbsp;
      {this.renderDayOfMonthSelect()}
      </span>);
  }

  renderMonthBlock() {
    const {intl} = this.props;
    return (<span>
      {intl.formatMessage(CRON_PICKER_MESSAGES.of)}
      &nbsp;
      {this.renderMonthSelect()}
      </span>);
  }

  renderDynamicBlock() {
    const {period} = this.state;
    switch (period.value) {
    case Period.MINUTE.value:
      return null;
    case Period.HOUR.value:
      return this.renderMinsBlock();
    case Period.DAY.value:
      return this.renderTimeBlock();
    case Period.WEEK.value:
      return (<span>{this.renderDayOfWeekBlock()} {this.renderTimeBlock()}</span>);
    case Period.MONTH.value:
      return (<span>{this.renderDayOfMonthBlock()} {this.renderTimeBlock()}</span>);
    case Period.YEAR.value:
      return (<span>{this.renderDayOfMonthBlock()} {this.renderMonthBlock()} {this.renderTimeBlock()}</span>);
    default:
      return null;
    }
  }

  render() {
    return (
        <span className="form-inline">{this.renderPeriodBlock()} {this.renderDynamicBlock()}</span>
    );
  }
}

export const SimpleCronPicker = injectIntl(SimpleCronPickerComponent);
