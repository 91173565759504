import {Action} from "redux";
import {Api} from "../../../api/InternalApi";
import {asyncActionTypeAffixes, isLoading, LoadDataState} from "./reducerUtil";

/**
 * FSA (Flux Standard Action, https://github.com/acdlite/flux-standard-action).
 * It has the following fields:
 *   - type (mandatory): indicates the type of the action, convention is that this is a string.
 *   - payload (optional): holds the value, or, in case that the error field is true, the Error object
 *   - error (optional): boolean indicating that an error occurred
 *   - meta (optional): extra information that is not part of the payload. Can be of any type.
 */
export interface LoadDataAction<DataToLoad> extends Action {
  type: string;
  payload?: DataToLoad | Error | null;
  error?: boolean;
  meta?: any;
}

function createResetDataActionCreator<DataToLoad>(asyncActionType: string): (() => LoadDataAction<DataToLoad>) {
  return () => {
    return {
      type: asyncActionType + "_" + asyncActionTypeAffixes.RESET_AFFIX,
      error: false,
    };
  };
}

type LoadDataActionCreator<DataToLoad> = (...args: any[]) => ((dispatch, getState, {getApi}) => Promise<DataToLoad>);

/**
 * Makes a request to the REST controller and dispatches proper actions based on the outcome of the request.
 * @param asyncActionType   The type of action required. For example: Get content roots.
 *                          This is needed so that items describing the redux state are not the same for all types of data.
 * @param getLoadDataFunc   A function that given an API returns the appropriate function to call on that API.
 * @param subStateSelector  A function that returns the proper subState from the redux state for this data type.
 * @returns {(dispatch:any, getState:any, api: any)=>(Promise<DataToLoad>|Promise<void>)}
 */
function createLoadDataActionCreator<DataToLoad>(asyncActionType,
                                                 getLoadDataFunc: (api: Api) => ((...args: any[]) => Promise<DataToLoad>),
                                                 subStateSelector: (state) => LoadDataState<DataToLoad>,
                                                ): LoadDataActionCreator<DataToLoad> {
  return function(...args) {
    const self = this;
    return (dispatch, getState, {getApi}) => {
      const state = getState();
      const loadDataFunc = getLoadDataFunc(getApi(state));
      if (!isLoading(subStateSelector(state))) {
        dispatch({type: asyncActionType + "_" + asyncActionTypeAffixes.STARTED_AFFIX});
        return loadDataFunc.apply(self, args).then(
            (payload) => {
              dispatch(
                  {type: asyncActionType + "_" + asyncActionTypeAffixes.SUCCESS_AFFIX, payload, error: false});
              return payload;
            },
        ).catch(
            (error) => {
              dispatch({type: asyncActionType + "_" + asyncActionTypeAffixes.ERROR_AFFIX, payload: error, error: true});
              throw error;
            },
        ) as Promise<DataToLoad>;
      } else {
        return Promise.resolve(undefined);
      }
    };
  };
}

function createAsyncActionTypes(asyncActionTypePrefix: string) {
  return {
    RESET: asyncActionTypePrefix + "_" + asyncActionTypeAffixes.RESET_AFFIX,
    STARTED: asyncActionTypePrefix + "_" + asyncActionTypeAffixes.STARTED_AFFIX,
    SUCCESS: asyncActionTypePrefix + "_" + asyncActionTypeAffixes.SUCCESS_AFFIX,
    ERROR: asyncActionTypePrefix + "_" + asyncActionTypeAffixes.ERROR_AFFIX,
  };
}

export {
  createLoadDataActionCreator,
  createResetDataActionCreator,
  createAsyncActionTypes,
};
