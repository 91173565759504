export interface CronValidationResult {
  valid: boolean;
  warning?: string;
}

export const CronUtil = {

  validateCronString: (cronString: string): CronValidationResult => {

    const validCronPattern = /(((((\d{1,2})(,)?)*)|\*)\s){5}((((\d{1,2})(,)?)*)|\*)$/;
    if (!validCronPattern.test(cronString)) {
      return {
        valid: false,
        warning: `Cron string is not in a valid Cron string format`,
      };
    }
    // check actual cron values
    const cols = cronString.split(" ");
    //            ss, mm, hh, DD, MM, DOW
    const minval = [0,  0,  0,  1,  1,  0];
    const maxval = [59, 59, 23, 31, 12,  6];
    for (let i = 0; i < cols.length; i++) {
      if (cols[i] === "*") {
        continue;
      }
      const value = parseInt(cols[i], 10);
      if (typeof value !== "undefined" && value <= maxval[i] && value >= minval[i]) {
        continue;
      }
      return {
        valid: false,
        warning: `Invalid value "${value}" found at column ${i + 1}. Must be between ${minval[i]} and ${maxval[i]}`,
      };
    }
    return {valid: true};
  },

};
