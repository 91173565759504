import {AxiosError} from "axios";
import {stringify} from "query-string";
import * as React from "react";
import {FormattedMessage} from "react-intl";
import {connect, ConnectedProps} from "react-redux";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {actions} from "./actions";
import {User} from "./model";
import {selectors} from "./selectors";

interface WithAuthenticationStateProperties {
  user: User;
}

interface WithAuthenticationDispatchProperties {
  onUserFound: (user: User) => void;
}

type WithAuthenticationProperties = WithAuthenticationStateProperties & WithAuthenticationDispatchProperties & RouteComponentProps<{}>;

interface WithAuthenticationCheckState {
  authenticationCheckCompleted: boolean;
  backendOffline: boolean;
}

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, {
    user: selectors.getUser(state),
  }, ownProps);
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUserFound: (user) => dispatch(actions.setUser(user)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export function WithAuthenticationCheck<WrappedComponentProps>(
    WrappedComponent: React.ComponentClass<WrappedComponentProps> | React.FunctionComponent<WrappedComponentProps>,
): React.ComponentClass<WrappedComponentProps & PropsFromRedux> {

  type WithAuthenticationCheckHOCProps = WithAuthenticationProperties & WithApiProperties & WrappedComponentProps & PropsFromRedux;

  class WithAuthenticationCheckHOC extends React.Component<WithAuthenticationCheckHOCProps, WithAuthenticationCheckState> {

    constructor(props) {
      super(props);
      this.state = {
        authenticationCheckCompleted: false,
        backendOffline: false,
      };
    }

    componentDidMount() {
      this.checkAuthenticated(this.props.user);
    }

    componentDidUpdate(prevProps) {
      if (prevProps.user !== this.props.user) {
        this.checkAuthenticated(this.props.user);
      }
    }

    checkAuthenticated = (user: User) => {
      if (!user) { //if ControlRoom doesn't know who you are based on what's in redux
        const triggerRedirect = (error?: AxiosError) => {
          //redirect to the login page if nobody knows
          const noResponse = error && !error.response && error.message.includes("Network");
          const badGateway = error && error.response && error.response.status === 504; //when behind a proxy you get a bad gateway
          const backendOffline = noResponse || badGateway;
          this.setState({
            authenticationCheckCompleted: true,
            backendOffline,
          });
        };
        //check if the backend knows who you are and store that result locally
        this.props.api.getCurrentUser().then((currentUser) => {
          if (currentUser == null) {
            triggerRedirect();
          } else {
            this.props.onUserFound(currentUser);
          }
        }).catch(triggerRedirect);
      } else {
        this.setState({
          authenticationCheckCompleted: true,
          backendOffline: false,
        });
      }
    }

    render() {
      const {authenticationCheckCompleted, backendOffline} = this.state;
      const {user, location} = this.props;

      if (!authenticationCheckCompleted) {
        return <h1><FormattedMessage
            id="studio.authentication-check.header"
            defaultMessage="Authenticating..."
        /></h1>;
      }
      //authentication check completed...
      if (backendOffline) {
        return <Redirect to={{
              pathname: "/backendoffline",
              search: stringify({
                redirect: location.pathname + location.search,
              }),
            }}/>;
      }

      if (user === null) {
        return <Redirect to={{
              pathname: "/login",
              search: stringify({
                redirect: location.pathname + location.search,
              }),
            }}/>;
      }

      return <WrappedComponent {...this.props as WrappedComponentProps} />;
    }
  }


  return withRouter(connector(WithApi(WithAuthenticationCheckHOC)));

}
