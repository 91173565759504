import {FILE_STATUS_MESSAGES, FileStatus} from "./model";

const getFileStatusTranslation = (fileStatus: FileStatus, intl) => {
  if (fileStatus === FileStatus.SUCCESS) {
    return intl.formatMessage(FILE_STATUS_MESSAGES.success);
  } else if (fileStatus === FileStatus.SKIPPED) {
    return intl.formatMessage(FILE_STATUS_MESSAGES.skipped);
  } else if (fileStatus === FileStatus.FAILED) {
    return intl.formatMessage(FILE_STATUS_MESSAGES.failed);
  }

  return fileStatus;
};

export {
  getFileStatusTranslation,
};
