import * as React from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {
  PagedDataSelector,
  PagedDataState,
  RequestPageAction,
  selectors as pagedDataSelectors,
} from "../../util/pagination/PaginationUtil";
import {
  ControlRoomTableDispatchProperties,
  ControlRoomTableProperties,
  ControlRoomTableStateProperties,
} from "./ControlRoomTable";

function createMapStateToPropsFunction<T>(pagedDataSelector: (state: any) => PagedDataState<T>,
                                          config: PagedTableConfig) {
  return (state) => {
    const pagedDataState = pagedDataSelector(state);
    return {
      isPaged: true,
      canMultiSelect: config.canMultiSelect,
      noItemsFoundMessage: config.noItemsFoundMessage,
      data: pagedDataSelectors.getAllLoadedData<T>(pagedDataState),
      pageSize: pagedDataSelectors.getPageSize(pagedDataState),
      pages: pagedDataSelectors.getAllPages<T>(pagedDataState),
      isAllDataLoaded: pagedDataSelectors.isAllPagesLoaded(pagedDataState),
    } as ControlRoomTableStateProperties<T>;
  };
}

function createMapDispatchToPropsFunction<T>(requestPageAction: RequestPageAction<T>) {
  return (dispatch, ownProps) => {
    return {
      loadPage: (pageNumber: number) => {
        dispatch(requestPageAction(pageNumber));
      },
    } as ControlRoomTableDispatchProperties<T>;
  };
}

export interface PagedTableConfig {
  canMultiSelect: boolean;
  noItemsFoundMessage: string | JSX.Element;
}

/**
 * Connects a ControlRoomTable so that it works with paged data in redux state
 */
export function connectPagedTable<T>(tableComponent: React.ComponentClass<ControlRoomTableProperties<T>>,
                                     pagedDataSelector: PagedDataSelector<T>,
                                     requestPageAction: RequestPageAction<T>,
                                     config: PagedTableConfig) {
  const mapStateToProps = createMapStateToPropsFunction(pagedDataSelector, config);
  const mapDispatchToProps = createMapDispatchToPropsFunction(requestPageAction);
  return connect(mapStateToProps, mapDispatchToProps)(injectIntl(tableComponent));
}
