import * as React from "react";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {ErrorDisplay} from "../../common/ui/errordisplay/ErrorDisplay";
import {getParameterFromOwnProps} from "../../common/util/Util";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {FileInfo} from "../../data/model";
import {Product} from "../../products/model";
import {Service, ServiceType} from "../model";
import {DataSourceFileList} from "./DataSourceFileList";

interface DataSourceFilesPageState {
  importedDataFiles: FileInfo[];
  product: Product;
  service: Service;
  isLoading: boolean;
  success: boolean;
  error: Error;
}

interface DataSourceFilesPageProps {
  dataId: string;
  productId: string;
  serviceName: string;
}

const DATA_SOURCE_MESSAGES = defineMessages({
  wrongData: {
    id: "studio.services.fileserver.data-source-files.wrong-data",
    defaultMessage: "Incorrect data id: {dataID}",
  },
  wrongProduct: {
    id: "studio.services.fileserver.data-source-files.wrong-product",
    defaultMessage: "Incorrect product id: {productID}",
  },
  wrongService: {
    id: "studio.services.fileserver.data-source-files.wrong-service",
    defaultMessage: "Incorrect service id: {serviceID}",
  },
  wrongServiceType: {
    id: "studio.services.fileserver.data-source-files.wrong-service-type",
    defaultMessage: "Incorrect service type: {serviceType}",
  },
  noDataFound: {
    id: "studio.services.fileserver.data-source-files.no-data-found",
    defaultMessage: "No data found.",
  },
});

export class DataSourceFilesPageComponent extends React.Component<InjectedIntlProps & DataSourceFilesPageProps & WithApiProperties, DataSourceFilesPageState> {

  constructor(props) {
    super(props);
    this.state = {
      importedDataFiles: null,
      product: null,
      service: null,
      isLoading: true,
      success: false,
      error: null,
    };
  }

  componentDidMount() {
    const {dataId, productId, serviceName} = this.props;
    this.getItemsById(dataId, productId, serviceName);
  }

  getItemsById(importedDataId: string, productId: string, serviceName: string) {
    if (importedDataId == null) {
      this.updateState({
        isLoading: false,
        error: {message: this.props.intl.formatMessage(DATA_SOURCE_MESSAGES.wrongData, {dataID: importedDataId})},
      });
    } else if (productId == null) {
      this.updateState({
        isLoading: false,
        error: {message: this.props.intl.formatMessage(DATA_SOURCE_MESSAGES.wrongProduct, {productID: productId})},
      });
    } else if (serviceName == null) {
      this.updateState({
        isLoading: false,
        error: {message: this.props.intl.formatMessage(DATA_SOURCE_MESSAGES.wrongService, {serviceID: serviceName})},
      });
    } else {
      this.updateState({isLoading: true});

      this.props.api.serviceByName(serviceName).then((service) => {
            if (service && service.type !== ServiceType.FILE_SERVER) {

              this.updateState({
                isLoading: false,
                error: {
                  message: this.props.intl.formatMessage(
                      DATA_SOURCE_MESSAGES.wrongService,
                      {
                        serviceType: service.type,
                      }),
                },
              });

            } else {
              const importedDataFilePromise = this.props.api.getAllImportedDataFilesByImportedDataId(importedDataId);
              const productPromise = this.props.api.getProductById(productId);

              Promise.all([importedDataFilePromise, productPromise]).then(
                  (datas) => this.updateState(
                      {importedDataFiles: datas[0], product: datas[1], service, isLoading: false, success: true}),
              ).catch(
                  (error) => this.updateState(
                      {importedDataFiles: null, product: null, service: null, isLoading: false, error}),
              );
            }
          },
      ).catch(
          (error) => this.updateState({importedData: null, product: null, service: null, isLoading: false, error}),
      );

    }
  }

  updateState = (updatedPart) => this.setState(Object.assign({}, this.state, updatedPart));

  render() {
    const {intl} = this.props;
    const {importedDataFiles, product, service, isLoading, success, error} = this.state;

    if (!isLoading && error) {
      return <ErrorDisplay error={error}/>;
    } else if (!isLoading && success) {
      const isDataExist = importedDataFiles && importedDataFiles[0] && importedDataFiles[0].fileName;
      return (
          <div>
            <h3>
              <b>
                <FormattedMessage id="studio.services.fileserver.data-source-file.header"
                                  defaultMessage="File Server Service :"/>
              </b>
              &nbsp;{service.name}&nbsp;&#45;&nbsp;
              {isDataExist &&
               <b>
                 <FormattedMessage id="studio.services.fileserver.data-source-file.data-source"
                                   defaultMessage="Data Source:"/>
               </b>
              }
              &nbsp;
              {isDataExist && importedDataFiles[0].fileName}
            </h3>
            <hr/>
            <div> {/* This empty div is necessary! it's used by ControlRoomTable to calculate its dimensions.*/}
              <DataSourceFileList data={importedDataFiles}
                                  product={product}
                                  service={service}
                                  className="user-tour-data-roots-list"
                                  noItemsFoundMessage={intl.formatMessage(DATA_SOURCE_MESSAGES.noDataFound)}
              />
            </div>
          </div>
      );
    } else if (isLoading) {
      return <p><FormattedMessage id="studio.services.fileserver.data-source-file.loading" defaultMessage="Loading..."/>
      </p>;
    }
    return <p><FormattedMessage id="studio.services.fileserver.data-source-file.unknown-state"
                                defaultMessage="Unknown data file page state!"/></p>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const dataId = getParameterFromOwnProps(ownProps, "dataId");
  const productId = getParameterFromOwnProps(ownProps, "productId");
  const serviceName = getParameterFromOwnProps(ownProps, "serviceName");
  return {
    dataId,
    productId,
    serviceName,
  };
};

export const DataSourceFilesPage = connect(mapStateToProps)(injectIntl(WithApi(DataSourceFilesPageComponent)));
