import {Reducer} from "redux";
import {actionTypes, SetUserAction, SetUserTourCompletedAction} from "./actions";
import {User} from "./model";
import {moduleName} from "./selectors";

export type UserState = User;

const defaultUserState = null;

const reducer: Reducer<UserState> = (prevState: UserState = defaultUserState, action) => {
  if (action.type === actionTypes.SET_USER) {
    const setUserAction: SetUserAction = action as SetUserAction;
    if (setUserAction.payload === null) {
      return null; //Object assign won't work when trying to assign null
    }
    return Object.assign({}, setUserAction.payload);
  } else if (action.type === actionTypes.SET_USER_TOUR_COMPLETED) {
    const setUserTourCompletedAction: SetUserTourCompletedAction = action as SetUserTourCompletedAction;
    return Object.assign({}, prevState, {userTourCompleted: setUserTourCompletedAction.payload});
  }
  return prevState;
};

const createTestState = (user: User = null) => {
  return {
    [moduleName]: user,
  };
};

export {
  moduleName,
  reducer,
  createTestState,
};
