import * as React from "react";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {SearchForm, SearchFormField, SelectField} from "../../common/ui/search/SearchForm";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {FILE_STATUS_MESSAGES} from "../../data/model";
import {EntityType} from "../../model";
import {CompositeJob, FileInfoSearchFilter, JobType} from "../model";
import {selectors} from "../selectors";
import {actions} from "./actions";

interface ImportJobFilesFilterStateProperties {
  filter: FileInfoSearchFilter;
  currentJob: CompositeJob;
}

interface ImportJobFilesFilterDispatchProperties {
  filterChanged: (newFilter: FileInfoSearchFilter) => Promise<void>;
}

interface NotificationProps {
  notifyFilterChanged?: () => any;
}

type ImportJobFilesFilterComponentType = NotificationProps &
    ImportJobFilesFilterDispatchProperties &
    ImportJobFilesFilterStateProperties &
    WithApiProperties;

export const STATUS_SEARCH_FIELD = "status";
export const DATE_SEARCH_FIELD = "date";

const JOB_FILES_FILTER_MESSAGES = defineMessages({
  anyStatus: {
    id: "studio.jobs.import-job-files-filter.status.any",
    defaultMessage: "(Any Status)",
  },
  allFiles: {
    id: "studio.jobs.import-job-files-filter.date.all-files",
    defaultMessage: "All Files",
  },
  lastRunOnly: {
    id: "studio.jobs.import-job-files-filter.date.last-run-only",
    defaultMessage: "Last Run Only",
  },
});

export class ImportJobFilesFilterComponent extends React.Component<ImportJobFilesFilterComponentType & InjectedIntlProps, {}> {

  _statusField: SelectField;
  _searchFields: SearchFormField[];
  _dateField: SelectField;

  constructor(props) {
    super(props);
    const {intl} = this.props;
    this._statusField = {
      type: "select",
      name: STATUS_SEARCH_FIELD,
      flexGrow: 1,
      options: [{
        label: intl.formatMessage(JOB_FILES_FILTER_MESSAGES.anyStatus),
        value: "",
      },
      intl.formatMessage(FILE_STATUS_MESSAGES.success),
      intl.formatMessage(FILE_STATUS_MESSAGES.skipped),
      intl.formatMessage(FILE_STATUS_MESSAGES.failed),
      ],
    };
    const lastExecutionTime = props.currentJob.lastExecutionTime || props.currentJob.creationTime;
    this._dateField = {
      type: "select",
      name: DATE_SEARCH_FIELD,
      flexGrow: 1,
      options: [{
        label: intl.formatMessage(JOB_FILES_FILTER_MESSAGES.allFiles),
        value: "",
      }, {
        label: intl.formatMessage(JOB_FILES_FILTER_MESSAGES.lastRunOnly),
        value: lastExecutionTime,
      }],
    };
    this._searchFields = [this._statusField, this._dateField];
  }

  handleSearch = (formValues) => {
    if (this.props.notifyFilterChanged) {
      this.props.notifyFilterChanged();
    }
    return this.props.filterChanged({
      status: formValues[this._statusField.name],
      dateFilter: formValues[this._dateField.name],
    });
  }

  render() {
    const {filter} = this.props;
    return (
        <SearchForm fields={this._searchFields}
                    values={{
                      [this._statusField.name]: filter.status || "",
                      [this._dateField.name]: filter.dateFilter || "",
                    }}
                    onSearch={this.handleSearch}
                    syncFieldsWithUrl={true}
                    entityType={EntityType.TYPE_FILE_INFO}
        />
    );
  }
}

const mapStateToProps = (state) => ({
  filter: selectors.getJobFilesFilter(JobType.IMPORT, state),
  currentJob: selectors.getCurrentJob(JobType.IMPORT, state),
});

const mapDispatchToProps = (dispatch) => ({
  filterChanged: (newFilter: FileInfoSearchFilter) => {
    dispatch(actions.setJobFilesFilter(newFilter));
    dispatch(actions.clearData());
    return Promise.resolve();
  },
});

export const ImportJobFilesFilter = connect(mapStateToProps, mapDispatchToProps)(WithApi(injectIntl(ImportJobFilesFilterComponent)));
