import * as React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";

const NotFoundPageComponent = (props: RouteComponentProps<{}>) => {
  return (
      <Grid>
        <Row>
          <Col xsOffset={3} xs={6} className="text-center">
            <h4>
              <FormattedMessage
                  id="studio.not-found-page.404-message"
                  defaultMessage="404 Page Not Found"
              />
            </h4>
            <div>
              <a role="button" onClick={() => {
                props.history.goBack();
              }}><FormattedMessage
                  id="studio.not-found-page.go-back-link"
                  defaultMessage="Go back"
              /></a>
            </div>
            <Link to="/"><FormattedMessage
                id="studio.not-found-page.homepage-link"
                defaultMessage="Go to homepage"
            /></Link>
          </Col>
        </Row>
      </Grid>
  );
};

export const NotFoundPage = withRouter(NotFoundPageComponent);
