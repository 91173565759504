import * as React from "react";
import {Button, Modal} from "react-bootstrap";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Action, Dispatch} from "redux";
import {LcdIcon} from "../icon/LcdIcon";
import {Spinner} from "../spinner/Spinner";
import {clearWarnings} from "./actions";
import {selectors} from "./selectors";
import {executeById} from "./Warnings";

/**
 * @interface
 * Describes an object needed to parameterize the WarningModal popup.
 * @param headerText - title text
 * @param forceActionCreatorId - string id that defines the action creator to use when the force button is clicked.
 * @param forceActionParams - an array of parameters passed to action creator when the force button is clicked.
 * @param forceButtonText - force button text.
 * @param forceButtonGlyph - force button glyph.
 */
export interface WarningsModalMeta {
  headerText: string;
  bodyText: string;
  whileExecutingText?: string;
  forceActionCreatorId: string;
  forceActionParams: any[];
  forceButtonText?: string;
  forceButtonIcon?: string;
}

interface MessagesDisplayProps {
  messages: string[];
}
type WarningsType = WarningsModalMeta & MessagesDisplayProps;

interface WarningsComponentProps {
  warnings: WarningsType;
  dispatch: Dispatch<Action>;
}

interface WarningsComponentState {
  executing: boolean;
}

export class MessagesDisplay extends React.Component<MessagesDisplayProps, {}> {
  render() {
    const {messages} = this.props;
    const warningsUI = (messages && messages.length > 0) ?
                       messages.map((message, index) => <li key={index}>{message}</li>) : "";
    return (
        <div>
          <strong> <FormattedMessage id="studio.ui.warnings.modal-warnings.header" defaultMessage="Warnings:"/></strong>
          <ul>{warningsUI}</ul>
        </div>
    );
  }
}

export class ModalWarningsComponent extends React.Component<InjectedIntlProps & WarningsComponentProps, WarningsComponentState> {

  constructor(props) {
    super(props);
    this.state = {executing: false};
  }

  render() {
    const {dispatch, warnings} = this.props;
    const {executing} = this.state;
    const uiActive = (executing || (warnings.messages && warnings.messages.length > 0));

    const closeWarnings = () => dispatch(clearWarnings());

    const forceAction = () => {
      this.setState({executing: true});
      dispatch(executeById(warnings.forceActionCreatorId, warnings.forceActionParams)).then(() => {
        this.setState({executing: false});
        closeWarnings();
      }).catch(closeWarnings);
    };

    const btnIcon = warnings.forceButtonIcon ?
                    (<LcdIcon icon={warnings.forceButtonIcon}/>) : "";

    const textBody = this.props.warnings.bodyText ? <div><p> {this.props.warnings.bodyText} </p></div> : null;

    const modalBody = executing ? (
        <div style={{textAlign: "center"}}>
          <h2>{this.props.warnings.whileExecutingText || "Executing..."}</h2>
          <Spinner bigStyle/>
        </div>) : (
        <div>
           <MessagesDisplay messages={warnings.messages}/>
           {textBody}
        </div>
        );

    const buttons = !executing ? (
        <div>
          <Button onClick={forceAction}>{btnIcon} {warnings.forceButtonText}</Button>
          <Button onClick={closeWarnings}><FormattedMessage id="studio.ui.warnings.modal-warnings.cancel"
                                                            defaultMessage="Cancel"/></Button>
        </div>
    ) : null;

    return (
        <Modal show={uiActive} onHide={closeWarnings} backdrop="static">
          <Modal.Header closeButton={!executing}>
            <Modal.Title>{warnings.headerText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalBody}
          </Modal.Body>
          <Modal.Footer>
            {buttons}
          </Modal.Footer>
        </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  warnings: selectors.getWarnings(state),
});
export const ModalWarnings = connect(mapStateToProps)(injectIntl(ModalWarningsComponent));
