import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {
  ControlRoomTable,
  ControlRoomTableDispatchProperties,
  ControlRoomTableStateProperties,
} from "../../common/ui/table/ControlRoomTable";
import {isFinished} from "../../common/util/asyncdata/reducerUtil";
import {actions} from "./actions";
import {DataRootsColumnFactory} from "./DataRootsColumnFactory";
import {DataRoot} from "./model";
import {selectors} from "./selectors";

export const DataRootListComponent = ControlRoomTable(new DataRootsColumnFactory());

const mapStateToProps = (state, ownProps) => {
  return {
    data: selectors.getContentRoots(state),
    isPaged: false,
    isAllDataLoaded: isFinished(selectors.getRequestState(state)),
    noItemsFoundMessage: <FormattedMessage id="studio.settings.data-root-list.none-found"
                                           defaultMessage="No data roots found."/>,
  } as ControlRoomTableStateProperties<DataRoot>;
};
// important to keep ownProps - see redux API!
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadData: () => {
      dispatch(actions.loadDataRoots());
    },
  } as ControlRoomTableDispatchProperties<DataRoot>;
};

export const DataRootList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(DataRootListComponent));
