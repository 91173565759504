import {StyleSearchFilter} from "./model";

export const filterReducer = (state: StyleSearchFilter = {}, action) => {
  switch (action.type) {
  case "controlRoom/SET_STYLES_FILTER":
    const newFilter = action.payload.filter;
    return Object.assign({}, state, newFilter);
  }
  return state;
};
