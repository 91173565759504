import * as React from "react";
import {Col, ControlLabel, FormControl, FormGroup, HelpBlock} from "react-bootstrap";
import {Alert, Button, Form, Modal} from "react-bootstrap/lib";
import {FormattedMessage, InjectedIntlProps} from "react-intl";
import {ChooseFolderDialog} from "../../settings/dataroots/ChooseFolderDialog";
import {DataRoot} from "../../settings/dataroots/model";
import {ErrorDisplay} from "./errordisplay/ErrorDisplay";
import {LcdIcon} from "./icon/LcdIcon";
import {Spinner} from "./spinner/Spinner";
import InputGroup = require("react-bootstrap/lib/InputGroup");
import InputGroupAddon = require("react-bootstrap/lib/InputGroupAddon");
import InputGroupButton = require("react-bootstrap/lib/InputGroupButton");
import {mapErrorToMessage} from "../util/ErrorMapper";

const LABEL_COL_WIDTH = 2;

export interface AddButtonProps<T> {
  buttonTitle: string;
  confirmButtonTitle: string;
  modalTitle: string;
  addHelperText: string;
  chooseFileTitle: string;
  chooseFileButtonTitle: string;
  executingMessage: string;
  onAdd: (filePath: string) => Promise<T>;
  onAddSuccess: () => void;
}

interface ButtonState {
  isDialogOpen: boolean;
  isExecuting: boolean;
  isSuccess: boolean;
  error: Error;
  filePath: string;
  isChooseFileDialogOpen: boolean;
}

export function createAddButtonComponent<T>() {
  return class DeleteButtonComponent extends React.Component<AddButtonProps<T> & InjectedIntlProps, ButtonState> {

    constructor(props) {
      super(props);
      this.state = {
        isDialogOpen: false,
        isExecuting: false,
        error: null,
        isSuccess: false,
        filePath: "",
        isChooseFileDialogOpen: false,
      };
    }

    openDialog = () => {
      this.setState({
        ...this.state,
        isExecuting: false,
        error: null,
        isSuccess: false,
        isDialogOpen: true,
        isChooseFileDialogOpen: false,
      });
    }

    closeDialog = () => {
      this.setState({...this.state, isDialogOpen: false});
    }

    showChooseFileDialog = () => {
      this.setState({isChooseFileDialogOpen: true});
    }

    closeChooseFileDialog = () => {
      this.setState({isChooseFileDialogOpen: false});
    }

    handleChooseFile = (dataRoot: DataRoot) => {
      this.setState({filePath: dataRoot.rootPath});
      return Promise.resolve();
    }

    performAdd = () => {
      this.setState({...this.state, isExecuting: true, error: null, isSuccess: false});
      return this.props.onAdd(this.state.filePath).then((data) => {
        this.props.onAddSuccess();
        this.setState({...this.state, isExecuting: false, error: null, isSuccess: true});
        this.closeDialog();
      }).catch((error) => {
        this.setState({...this.state, isExecuting: false, error, isSuccess: false});
      });
    }

    renderButton() {
      const {buttonTitle} = this.props;
      return (<Button bsStyle="primary" className="actionBarItem" onClick={this.openDialog}>
        <LcdIcon icon="plus"/> {buttonTitle}
      </Button>);
    }

    renderTitle() {
      const {isExecuting, isSuccess} = this.state;
      const {modalTitle} = this.props;

      if (isExecuting || isSuccess) {
        return null;
      }
      return <Modal.Title>{modalTitle}</Modal.Title>;
    }

    renderButtons() {
      const {isExecuting, isSuccess, filePath} = this.state;
      const {confirmButtonTitle} = this.props;

      if (isExecuting || isSuccess) {
        return null;
      }

      return (
          <div>
            <Button disabled={!filePath || filePath === ""} onClick={this.performAdd}> <LcdIcon icon="add"/> {confirmButtonTitle}
            </Button>
            <Button onClick={this.closeDialog}><FormattedMessage id="studio.ui.add-dialog.cancel"
                                                                 defaultMessage="Cancel"/></Button>
          </div>
      );
    }

    renderExecuting() {
      const {isExecuting} = this.state;
      const {executingMessage} = this.props;
      if (isExecuting) {
        return (
            <div style={{textAlign: "center"}}>
              <h2>{executingMessage}</h2>
              <Spinner bigStyle/>
            </div>
        );
      }
      return null;
    }

    renderError() {
      const {error} = this.state;

      if (error) {
        const errorMessage = mapErrorToMessage(error);
        if (errorMessage) {
          return <Alert bsStyle="danger">{errorMessage}</Alert>;
        }
        return <ErrorDisplay error={error}/>;
      }
      return null;
    }

    renderBody() {
      const {isExecuting, error} = this.state;

      if (isExecuting) {
        return this.renderExecuting();
      }

      const {addHelperText} = this.props;
      const {filePath} = this.state;
      return (
          <div>
            {this.renderError()}
            <Form horizontal>
              <FormGroup>
                <Col sm={LABEL_COL_WIDTH}>
                  <ControlLabel>
                    <FormattedMessage
                        id="studio.add-modal.filePath"
                        defaultMessage="File path"
                    />
                  </ControlLabel>
                </Col>
                <Col sm={12 - LABEL_COL_WIDTH}>
                  <InputGroup>
                    <FormControl className={"browse-data-input"} type="text"
                                 value={filePath} onChange={(event) => {
                      const newValue = (event.target as any).value;
                      this.setState(Object.assign({}, this.state, {filePath: newValue}));
                    }}/>
                    <InputGroupAddon className={"browse-data-button"} onClick={this.showChooseFileDialog}>
                      <InputGroupButton>
                        <LcdIcon icon="folder-open"/>
                      </InputGroupButton>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col smOffset={LABEL_COL_WIDTH} sm={12 - LABEL_COL_WIDTH}>
                  <HelpBlock>
                    {addHelperText}
                  </HelpBlock>
                </Col>
              </FormGroup>
            </Form>
          </div>
      );
    }

    renderModal() {
      const {isDialogOpen, isExecuting} = this.state;
      return (
          <Modal show={isDialogOpen} onHide={this.closeDialog} backdrop="static">
            <Modal.Header closeButton={!isExecuting}>
              {this.renderTitle()}
            </Modal.Header>
            <Modal.Body id={"add-modal-body"}>
              {this.renderBody()}
            </Modal.Body>
            <Modal.Footer>
              {this.renderButtons()}
            </Modal.Footer>
          </Modal>
      );
    }

    renderFileChooser() {
      const {chooseFileTitle, chooseFileButtonTitle} = this.props;
      const {isChooseFileDialogOpen, filePath} = this.state;
      return (
          <ChooseFolderDialog handleChoose={this.handleChooseFile}
                              show={isChooseFileDialogOpen}
                              onHide={this.closeChooseFileDialog}
                              onFolderChoosed={this.closeChooseFileDialog}
                              title={chooseFileTitle}
                              buttonText={chooseFileButtonTitle}
          />
      );
    }

    render() {
      return (
          <div>
            {this.renderButton()}
            {this.renderModal()}
            {this.renderFileChooser()}
          </div>
      );
    }
  };
}
