import * as React from "react";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {DetailHeader} from "../common/ui/detailsection/DetailHeader";
import {DetailSection, DetailSectionProps} from "../common/ui/detailsection/DetailSection";
import {DetailView} from "../common/ui/detailsection/DetailView";
import {PreviewMetadata} from "../common/ui/riamap/model";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {SpatialModel} from "../model";
import * as paths from "../paths";
import {Product} from "../products/model";
import {hasEndPointURL, Service} from "./model";

const SERVICE_LIST_DETAIL_MESSAGES = defineMessages({
  type: {
    id: "studio.services.list-detail-section.type", defaultMessage: "Type",
  },
  status: {
    id: "studio.services.list-detail-section.status", defaultMessage: "Status",
  },
  url: {
    id: "studio.services.list-detail-section.url", defaultMessage: "URL",
  },
});

class ServiceListDetailSectionComponent extends DetailSection<DetailSectionProps<Service> & WithApiProperties & InjectedIntlProps, Service> {

  loadData(nextProps: DetailSectionProps<Service>): Promise<Service> {
    if (nextProps.selection.length === 1) {
      const serviceId = nextProps.selection[0].id;
      return this.props.api.getFullServiceById(serviceId);
    }
    return Promise.resolve(null);
  }

  getDataToVisualize(): Product[] {
    if (this.props.selection.length === 1 && this.state.data) {
      const service = this.state.data;
      if (service && service.products && service.products.length > 0) {
        return service.products;
      }
    }
    return [];
  }

  getPreviewBaseUrl(): string {
    return paths.PREVIEW_PRODUCT_PATH;
  }

  getPreviewMetadataFunction(): (item: SpatialModel) => Promise<PreviewMetadata> {
    return (item) => this.props.api.getProductPreviewMetadata(item.id);
  }

  renderContent() {
    const {isLoading, error} = this.state;
    const {selection} = this.props;

    if (selection.length === 0) {
      return (
          <div className="emptyDetailPlaceholder">
            <FormattedMessage id="studio.services.list-detail-section.select-data"
                              defaultMessage="Select some data in the table."/>
          </div>
      );
    }
    if (selection.length > 1) {
      const length = selection.length;
      return <p><FormattedMessage id="studio.services.list-detail-section.selected-length"
                                  defaultMessage="Selected {length} items." values={{length}}/></p>;
    }
    if (selection.length === 1 && this.state.data && !isLoading && !error) {
      const service = this.state.data;
      const endPointUrl = service && paths.getAbsoluteBaseUrl() + service.endpointPath;
      const showEndPointURL = endPointUrl && hasEndPointURL(service);

      const fields = [
        {
          key: "Type",
          name: this.props.intl.formatMessage(SERVICE_LIST_DETAIL_MESSAGES.type),
          value: service.type.toUpperCase(),
        },
        {
          key: "Status",
          name: this.props.intl.formatMessage(SERVICE_LIST_DETAIL_MESSAGES.status),
          value: service.status,
        },
      ];

      if (showEndPointURL) {
        fields.push(
            {key: "URL", name: this.props.intl.formatMessage(SERVICE_LIST_DETAIL_MESSAGES.url), value: endPointUrl});
      }

      return (
          <div className="details">
            <DetailHeader title={service.title}/>
            <DetailView fields={fields}/>
          </div>
      );
    }
    return super.renderContent();
  }
}

export const ServiceListDetailSection = injectIntl(WithApi(ServiceListDetailSectionComponent));
