import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {ColumnsDescription, ColumnWidth} from "../../common/ui/table/ControlRoomColumns";
import {createBasicTableComponent} from "../../common/ui/table/ControlRoomTable";
import {connectPagedTable, PagedTableConfig} from "../../common/ui/table/PagedTable";
import {FileInfo} from "../../data/model";
import {selectors} from "../selectors";
import {actions} from "./actions";

const config: PagedTableConfig = {
  canMultiSelect: false,
  noItemsFoundMessage: <FormattedMessage id="studio.jobs.import-job-files.no-items" defaultMessage="No files found."/>,
};

const statusCell = (file: FileInfo): React.ReactElement<any> => {
  const lowerStatus = file.status.toString().toLowerCase();
  const className = "status-" + lowerStatus;
  const messageId = "studio.data.file-status." + lowerStatus;
  return <Link to={`/jobs/file/${file.id}`} className={className}><span><FormattedMessage id={messageId}/></span></Link>;
};

const columns: ColumnsDescription<FileInfo> = {
  name: {
    header: <FormattedMessage id="studio.jobs.import-job-files.columns.name" defaultMessage="Name"/>,
    cellContent: (file) => <Link to={`/jobs/file/${file.id}`}>{file.fileName}</Link>,
    columnWidth: ColumnWidth.flex(2),
  },
  path: {
    header: <FormattedMessage id="studio.jobs.import-job-files.columns.path" defaultMessage="Path"/>,
    cellContent: (file) => file.filePath,
    cellTooltip: (file) => file.filePath,
    columnWidth: ColumnWidth.flex(4),
  },
  status: {
    header: <FormattedMessage id="studio.jobs.import-job-files.columns.status" defaultMessage="Status"/>,
    cellContent: (file) => statusCell(file),
    columnWidth: ColumnWidth.flex(1),
  },
};

export const JobFilesListComponent = createBasicTableComponent(columns);
export const JobFilesList = connectPagedTable(JobFilesListComponent, selectors.getImportJobFilesPagedState,
    actions.requestPage,
    config);
