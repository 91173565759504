import * as React from "react";
import {connect} from "react-redux";
import {actions} from "../../../user/actions";
import {selectors} from "../../../user/selectors";
import {UserTour} from "./UserTour";

interface MainUserTourStateProperties {
  userTourCompleted: boolean;

  allTourSteps: any[];
  appLayoutRef: React.ReactInstance;
}

interface MainUserTourDispatchProperties {
  setUserTourCompleted: (isUserTourCompleted: boolean) => void;
}

export class MainUserTourComponent extends React.Component<MainUserTourStateProperties & MainUserTourDispatchProperties, {}> {
  render() {
    const {userTourCompleted, setUserTourCompleted} = this.props;
    return <UserTour
        appLayoutRef={this.props.appLayoutRef}
        allTourSteps={this.props.allTourSteps}
        tourCompleted={userTourCompleted}
        setTourCompleted={setUserTourCompleted}
        initialTourLocation={"/"}
        finalTourLocation={"/"}
    />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userTourCompleted: selectors.getUserTourCompleted(state),
  };
};

const mapDispatchToProps = (dispatch): MainUserTourDispatchProperties => {
  return {
    setUserTourCompleted: (isUserTourCompleted: boolean) => dispatch(actions.setUserTourCompleted(isUserTourCompleted)),
  };
};

export const MainUserTour = connect(mapStateToProps, mapDispatchToProps)(MainUserTourComponent);
