import {defineMessages, InjectedIntl} from "react-intl";
import {Api} from "../api/InternalApi";
import {actions as notificationActions} from "../common/ui/notification/actions";
import {executeIfNoWarnings, registerWarningAction} from "../common/ui/warnings/Warnings";
import {createAsyncActionTypes, createLoadDataActionCreator} from "../common/util/asyncdata/actionUtil";
import {
  createPagedDataActions,
  createPagedDataActionTypes,
  selectors as pagedDataSelectors,
} from "../common/util/pagination/PaginationUtil";
import {Service} from "../services/model";
import {filtersEqual, Product, ProductFilter, StyledData} from "./model";
import {selectors} from "./selectors";

export const LOAD_PRODUCT_CONTENTS_PREFIX = "controlRoom/LOAD_PRODUCT_CONTENTS_PREFIX";
const productContentsAsyncActionTypes = createAsyncActionTypes(LOAD_PRODUCT_CONTENTS_PREFIX);

export const PRODUCT_ACTION_TYPENAME = "PRODUCT";

const pagedProductActionTypes = createPagedDataActionTypes(PRODUCT_ACTION_TYPENAME);

const fetchPage = (page: number, state, api: Api) => {
  const filter = selectors.getFilter(state);
  const pagedDataState = selectors.getPagedState(state);
  const pageSize = pagedDataSelectors.getPageSize(pagedDataState);
  const offset = page * pageSize;
  const filterWithPagination: ProductFilter = Object.assign({}, filter, {offset, maxResults: pageSize});
  return api.loadProducts(filterWithPagination).then((datas) => ({
    datas,
    pageValidationInfo: {
      filter,
    },
  }));
};

const isPageStillValid = (pageValidationInfo, state) => {
  return filtersEqual(pageValidationInfo.filter, selectors.getFilter(state));
};

const fetchById = (id: string, state, api) => api.getProductById(id);

const pagedProductActions = createPagedDataActions(
    PRODUCT_ACTION_TYPENAME,
    fetchPage,
    isPageStillValid,
    fetchById,
    selectors.getPagedState);

export const actionTypes = Object.assign({}, {
  SET_FILTER: "controlRoom/SET_PRODUCT_FILTER",
  UPDATE_PRODUCT: "controlRoom/UPDATE_PRODUCT",
  ADD_STYLED_DATA: "controlRoom/ADD_STYLED_DATA",
  REMOVE_STYLED_DATA: "controlRoom/REMOVE_STYLED_DATA",
  UPDATE_ALL_STYLED_DATAS: "controlRoom/UPDATE_STYLED_DATA",
  LOAD_PRODUCT_CONTENTS_STARTED: productContentsAsyncActionTypes.STARTED,
  LOAD_PRODUCT_CONTENTS_SUCCESS: productContentsAsyncActionTypes.SUCCESS,
  LOAD_PRODUCT_CONTENTS_ERROR: productContentsAsyncActionTypes.ERROR,
  LOAD_PRODUCT_CONTENTS_RESET: productContentsAsyncActionTypes.RESET,
}, pagedProductActionTypes);

const loadProductContents = createLoadDataActionCreator(
    LOAD_PRODUCT_CONTENTS_PREFIX,
    (api) => api.loadProductContents,
    selectors.getProductContentsRequestState);

const getProduct = (productId: string) => {
  return (dispatch, getState, {getApi}): Promise<Product> => {
    return getApi(getState()).getProductById(productId).then((product) => {
      dispatch({
        type: actionTypes.UPDATE_PRODUCT,
        payload: {product},
      });
      return product;
    });
  };
};

const createProduct = (product: Product) => {
  return (dispatch, getState, {getApi}): Promise<Product> => {
    const api = getApi(getState());
    return api.createProduct(product)
    // bit of a hack - the product returned from the API createProduct method does not include the
    // validationByServiceType info because it uses the public API, but we need that info for various workflows that
    // call this action to create the product, such as creating a service directly from data. So, we immediately call
    // getProductById to fill out the product with this info.
        .then((p) => api.getProductById(p.id))
        .then((p) => {
          dispatch(pagedProductActions.clearData());
          return p;
        });
  };
};

const deleteProduct = (productId: string) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).deleteProduct(productId).then(() => {
      dispatch(pagedProductActions.clearData());
    });
  };
};

const REMOVE_PRODUCT_WARNING_ID = "removeProduct";
registerWarningAction(REMOVE_PRODUCT_WARNING_ID, deleteProduct);

const MESSAGES = defineMessages({
  serviceUsesProduct: {
    id: "studio.products.actions.productUsage",
    defaultMessage: "Product is used by {status} service {title}",
  },
});

const formatServiceUsageWarnings = (services: Service[], intl: InjectedIntl): string[] => {
  if (services && services.length > 0) {
    return services.map((service, index) => intl.formatMessage(MESSAGES.serviceUsesProduct, {status: service.status, title: service.title}));
  } else {
    return [];
  }
};

export const deleteProductIfNoWarnings = (productId: string, intl: InjectedIntl) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return dispatch(executeIfNoWarnings(
        api.getProductServices(productId).then((services: Service[]) => formatServiceUsageWarnings(services, intl)),
        actions.deleteProduct(productId),
        {
          headerText: `Removing product id ${productId}`,
          bodyText: "Users currently consuming the above services will be disconnected due to this change. " +
                    "Are you sure you want to continue?",
          forceActionCreatorId: REMOVE_PRODUCT_WARNING_ID,
          forceActionParams: [productId],
          forceButtonText: "Remove Product",
          forceButtonIcon: "delete",
        },
    ));
  };
};

const updateProduct = (product: Product) => {
  return (dispatch, getState, {getApi}): Promise<Product> => {
    return getApi(getState()).updateProduct(product).then((updatedProduct) => {
      const updateProductAction = {
        type: actionTypes.UPDATE_PRODUCT,
        payload: {
          product: updatedProduct,
        },
      };
      dispatch(updateProductAction);
      return updatedProduct;
    }).catch((error) => {
      dispatch(notificationActions.showErrorNotification(
          error.response.data.message));
    });
  };
};

const UPDATE_PRODUCT_WARNING_ID = "updateProduct";
registerWarningAction(UPDATE_PRODUCT_WARNING_ID, updateProduct);

const updateProductIfNoWarnings = (product: Product, intl: InjectedIntl) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return dispatch(executeIfNoWarnings(
        api.getProductServices(product.id).then((services: Service[]) => formatServiceUsageWarnings(services, intl)),
        actions.updateProduct(product), {
          headerText: `Changing product id ${product.id}`,
          bodyText: "Users currently consuming the above services will be disconnected due to this change. " +
                    "Are you sure you want to continue?",
          forceActionCreatorId: UPDATE_PRODUCT_WARNING_ID,
          forceActionParams: [product],
          forceButtonText: "Update Product",
        }));
  };
};

const createOrUpdateStyledData = (productId: string, styledDatas: StyledData[]) => {
  return (dispatch, getState, {getApi}): Promise<StyledData> => {
    return getApi(getState()).createOrUpdateStyledData(productId, styledDatas).then((addedStyledDatas) => {
      const addStyledDataToProductAction = {
        type: actionTypes.ADD_STYLED_DATA,
        payload: {
          styledDatas: addedStyledDatas,
        },
      };
      dispatch(addStyledDataToProductAction);

      // Update product so that we get proper sequence number and bounds
      dispatch(getProduct(productId));

      return addedStyledDatas;
    });
  };
};

const setStyledData = (productId: string, styledData: StyledData[]) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).setStyledData(productId, styledData).then(() => {
      const updateStyledDataAction = {
        type: actionTypes.UPDATE_ALL_STYLED_DATAS,
        payload: {
          styledDatas: styledData,
        },
      };
      dispatch(updateStyledDataAction);

      // Update product so that we get proper sequence number and bounds
      dispatch(getProduct(productId));
    });
  };
};

const validateStyledData = (productId: string, styledData: StyledData[]) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).validateStyledData(productId, styledData);
  };
};

const setFilter = (newFilter: ProductFilter) => {
  return {
    type: actionTypes.SET_FILTER,
    payload: newFilter,
  };
};

export const actions = Object.assign({}, {
  setFilter,
  getProduct,
  createProduct,
  deleteProduct,
  deleteProductIfNoWarnings,
  updateProduct,
  updateProductIfNoWarnings,
  createOrUpdateStyledData,
  setStyledData,
  validateStyledData,
  loadProductContents,
}, pagedProductActions);
