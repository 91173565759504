import * as React from "react";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {Chart, DataSerie} from "../../common/ui/chart/Chart";
import {ChartReferences} from "../../common/ui/chart/ChartReferences";
import {Spinner} from "../../common/ui/spinner/Spinner";
import {Logger} from "../../common/util/Logger";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";

interface StatusPageState {
  charts: {
    mem: { [metricName: string]: DataSerie },
    heap: { [metricName: string]: DataSerie },
    nonheap: { [metricName: string]: DataSerie },
  };
  busyLoading: boolean;
}

const COLOR1 = "rgb(216, 41, 47)"; // red (danger)
const COLOR2 = "rgb(80, 158, 47)"; // green (success)
const COLOR3 = "rgb(0, 151, 186)"; // blue (info)
const COLOR4 = "rgb(241, 196, 0)"; // yellow (warning)

class StatusPageComponent extends React.Component<WithApiProperties & InjectedIntlProps, StatusPageState> {

  _logger: Logger = Logger.getLogger("settings.StatusPage");

  updateTimeoutHandle;
  startTime;

  constructor(props) {
    super(props);
    const MEM_MESSAGES = defineMessages({
      label: {id: "studio.settings.status.memory-label", defaultMessage: "Memory"},
      freeLabel: {id: "studio.settings.status.free-memory-label", defaultMessage: "Free memory"},
    });
    const HEAP_MESSAGES = defineMessages({
      label: {id: "studio.settings.status.heap-label", defaultMessage: "Heap"},
      committedLabel: {id: "studio.settings.status.committed-heap-label", defaultMessage: "Heap committed"},
      initializedLabel: {id: "studio.settings.status.initialized-heap-label", defaultMessage: "Heap initialized"},
      usedLabel: {id: "studio.settings.status.used-heap-label", defaultMessage: "Heap used"},
    });
    const NONHEAP_MESSAGES = defineMessages({
      label: {id: "studio.settings.status.nonheap-label", defaultMessage: "Nonheap"},
      committedLabel: {id: "studio.settings.status.committed-nonheap-label", defaultMessage: "Nonheap committed"},
      initializedLabel: {id: "studio.settings.status.initialized-nonheap-label", defaultMessage: "Nonheap initialized"},
      usedLabel: {id: "studio.settings.status.used-nonheap-label", defaultMessage: "Nonheap used"},
    });
    this.startTime = Date.now();
    this.state = {
      charts: {
        mem: {
          "mem": {
            serie: [],
            label: props.intl.formatMessage(MEM_MESSAGES.label),
            color: COLOR1,
            reference: ChartReferences.MILLISECONDS_KILOBYTES_REFERENCE,
          },
          "mem.free": {
            serie: [],
            label: props.intl.formatMessage(MEM_MESSAGES.freeLabel),
            color: COLOR2,
            reference: ChartReferences.MILLISECONDS_KILOBYTES_REFERENCE,
          },
        },
        heap: {
          "heap": {
            serie: [],
            label: props.intl.formatMessage(HEAP_MESSAGES.label),
            color: COLOR1,
            reference: ChartReferences.MILLISECONDS_KILOBYTES_REFERENCE,
          },
          "heap.committed": {
            serie: [],
            label: props.intl.formatMessage(HEAP_MESSAGES.committedLabel),
            color: COLOR2,
            reference: ChartReferences.MILLISECONDS_KILOBYTES_REFERENCE,
          },
          "heap.init": {
            serie: [],
            label: props.intl.formatMessage(HEAP_MESSAGES.initializedLabel),
            color: COLOR3,
            reference: ChartReferences.MILLISECONDS_KILOBYTES_REFERENCE,
          },
          "heap.used": {
            serie: [],
            label: props.intl.formatMessage(HEAP_MESSAGES.usedLabel),
            color: COLOR4,
            reference: ChartReferences.MILLISECONDS_KILOBYTES_REFERENCE,
          },
        },
        nonheap: {
          "nonheap": {
            serie: [],
            label: props.intl.formatMessage(NONHEAP_MESSAGES.label),
            color: COLOR1,
            reference: ChartReferences.MILLISECONDS_KILOBYTES_REFERENCE,
          },
          "nonheap.committed": {
            serie: [],
            label: props.intl.formatMessage(NONHEAP_MESSAGES.committedLabel),
            color: COLOR2,
            reference: ChartReferences.MILLISECONDS_KILOBYTES_REFERENCE,
          },
          "nonheap.init": {
            serie: [],
            label: props.intl.formatMessage(NONHEAP_MESSAGES.initializedLabel),
            color: COLOR3,
            reference: ChartReferences.MILLISECONDS_KILOBYTES_REFERENCE,
          },
          "nonheap.used": {
            serie: [],
            label: props.intl.formatMessage(NONHEAP_MESSAGES.usedLabel),
            color: COLOR4,
            reference: ChartReferences.MILLISECONDS_KILOBYTES_REFERENCE,
          },
        },
      },
      busyLoading : true,
    };
  }

  updateChart = () => {
    this.props.api.getActuator("systemStatusInfo").then((metrics: any) => {
      const {charts} = this.state;
      const now = Date.now();
      const timestamp = now - this.startTime;
      for (const chartName in charts) {
        if (charts.hasOwnProperty(chartName)) {
          const chart = charts[chartName];
          for (const metricName in chart) {
            if (chart.hasOwnProperty(metricName) && metrics.hasOwnProperty(metricName)) {
              chart[metricName].serie.push({time: timestamp, value: metrics[metricName]});
            }
          }
        }
      }
      this.setState({
        busyLoading: false,
        charts,
      });
      this.updateTimeoutHandle = setTimeout(this.updateChart, 1000);
    }).catch((error) => {
      this._logger.error("Error occurred while loading metrics", error);
    });
  }

  componentDidMount() {
    this.updateChart();
  }

  componentWillUnmount() {
    clearTimeout(this.updateTimeoutHandle);
  }

  render() {
    const {busyLoading, charts} = this.state;

    let content = null;
    if (busyLoading) {
      content = (<div style={{width: "100%", textAlign: "center"}}><Spinner bigStyle={true}/></div>);
    } else {
      content = (
          <div>
            <h2><FormattedMessage id="studio.settings.status.memory-header" defaultMessage="Memory"/></h2>
            <Chart dataSeries={charts.mem} style={{height: "250px", width: "100%"}} reference={ChartReferences.SECONDS_MEGABYTES_REFERENCE}/>
            <h2><FormattedMessage id="studio.settings.status.heap-header" defaultMessage="Heap"/></h2>
            <Chart dataSeries={charts.heap} style={{height: "250px", width: "100%"}} reference={ChartReferences.SECONDS_MEGABYTES_REFERENCE}/>
            <h2><FormattedMessage id="studio.settings.status.nonheap-header" defaultMessage="Nonheap"/></h2>
            <Chart dataSeries={charts.nonheap} style={{height: "250px", width: "100%"}} reference={ChartReferences.SECONDS_MEGABYTES_REFERENCE}/>
          </div>
      );
    }
    return (
        <div>
          <h1><FormattedMessage id="studio.settings.status.status-header" defaultMessage="Status"/></h1>
          <hr/>
          {content}
        </div>

    );
  }
}

export const StatusPage = injectIntl(WithApi(StatusPageComponent));
