import * as React from "react";
import {defineMessages, FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {createRefreshButtonComponent} from "../common/ui/RefreshButton";
import {actions} from "./actions";
import {ImportedData} from "./model";
import InjectedIntlProps = ReactIntl.InjectedIntlProps;

const RefreshButtonComponent = createRefreshButtonComponent<ImportedData>();

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRefresh: () => {
    return dispatch(actions.refreshData(ownProps.data.id));
  },
});

interface RefreshDataButtonDispatchProps {
  onRefresh: () => Promise<ImportedData>;
}

interface RefreshDataButtonProps {
  data: ImportedData;
  onRefreshSuccess: (data: ImportedData) => void;
  onRefreshError: () => void;
}

const DATA_MESSAGES = defineMessages({
  refreshDataHeader: {
    id: "studio.data.refresh-data-button.refresh-data-header",
    defaultMessage: "Refresh Data {Title}",
  },
  whileRefreshData: {
    id: "studio.data.refresh-data-button.while-refresh-data",
    defaultMessage: "Refreshing data...",
  },
  refreshButtonData: {
    id: "studio.data.refresh-data-button.refresh-button-data",
    defaultMessage: "Refresh Data",
  },
  refreshDataSuccess: {
    id: "studio.data.refresh-data-button.refresh-success",
    defaultMessage: "Data successfully refreshed",
  },
  buttonTitleData: {
    id: "studio.data.refresh-data-button.refresh-data",
    defaultMessage: "Refresh",
  },
});

class RefreshDataButtonComponent extends React.Component<RefreshDataButtonProps & InjectedIntlProps & RefreshDataButtonDispatchProps, {}> {

  render() {
    const {data, onRefresh, onRefreshSuccess, onRefreshError, intl} = this.props;
    return <RefreshButtonComponent
        intl={this.props.intl}
        onRefresh={onRefresh}
        onRefreshSuccess={onRefreshSuccess}
        onRefreshError={onRefreshError}
        dialogTitle={intl.formatMessage(DATA_MESSAGES.refreshDataHeader, {Title: data.title})}
        buttonTitle={intl.formatMessage(DATA_MESSAGES.buttonTitleData)}
        successMessage={intl.formatMessage(DATA_MESSAGES.refreshDataSuccess)}
        executingMessage={intl.formatMessage(DATA_MESSAGES.whileRefreshData)}
        confirmButtonText={intl.formatMessage(DATA_MESSAGES.refreshButtonData)}
        renderConfirmation={() => {
          return (
              <div>
                <FormattedMessage id="studio.data.refresh-data-button.confirmation-body" defaultMessage="Refreshing will update the data with any changes made to the source file(s)."/>
              </div>
          );
        }}
    />;
  }

}

export const RefreshDataButton = injectIntl(connect(null, mapDispatchToProps)(RefreshDataButtonComponent));
