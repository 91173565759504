import * as React from "react";
import {Button, Grid} from "react-bootstrap";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {ActionBar} from "../common/ui/actionbar/ActionBar";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {MasterDetailLayout} from "../common/ui/layouts/MasterDetailLayout";
import {FileUploadAndInjectedProps, WithFileUpload} from "../common/ui/upload/WithFileUpload";
import {AddToServiceButton} from "../services/AddToServiceButton";
import {PublishProductInServiceButton} from "../services/CreateServiceButton";
import {CreateProductButton} from "./CreateProductButton";
import {Product} from "./model";
import {ProductList} from "./ProductList";
import {ProductListDetailSection} from "./ProductListDetailSection";
import {ProductsFilter} from "./ProductsFilter";

interface ProductsPageState {
  selection: Product[];
}

class ProductsPageReactComponent extends React.Component<FileUploadAndInjectedProps & InjectedIntlProps, ProductsPageState> {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
    };
  }
  handleRowSelect = (selection: Product[]) => {
    this.setState(Object.assign({}, this.state, {selection}));
  }
  clearSelection = () => this.handleRowSelect([]);
  render() {
    const {selection} = this.state;
    const noSelection = selection.length === 0;

    const masterPane = (
        <Grid fluid={true}>
          <ActionBar>
            <div>
            <Button id={"productFileUploadButton"} bsStyle="primary" onClick={this.props.openUpload}>
              <LcdIcon icon="upload"/>
              <FormattedMessage id="studio.products.product-page.upload" defaultMessage="Upload"/>
            </Button>
            </div>
            <CreateProductButton/>
          </ActionBar>

          <ProductsFilter notifyFilterChanged={this.clearSelection}/>

          <ActionBar>
            <PublishProductInServiceButton disabled={noSelection} inputProducts={selection}/>
            <AddToServiceButton disabled={noSelection} inputProducts={selection}/>
          </ActionBar>

          <div> {/* This empty div is necessary! it's used by ControlRoomTable to calculate its dimensions.*/}
            <ProductList onRowSelect={this.handleRowSelect}
                         clearSelection={noSelection}/>
          </div>
        </Grid>
    );

    const detailPane = <ProductListDetailSection selection={selection}/>;

    return (
        <MasterDetailLayout masterPane={masterPane} detailPane={detailPane}/>
    );
  }
}

export const ProductsPageComponent = WithFileUpload()(ProductsPageReactComponent);
export const ProductsPage = injectIntl(ProductsPageComponent);
