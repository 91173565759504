import * as React from "react";
import {MenuItem, Navbar, NavDropdown} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {selectors as startupSelectors} from "../startup/selectors";
import {User} from "./model";
import {selectors} from "./selectors";

export interface UserDisplayProps {
  user: User;
  isMappEnterprise: boolean;
}

export class UserDisplayComponent extends React.Component<UserDisplayProps & RouteComponentProps<{}>, any> {
  render() {
    const {user, isMappEnterprise} = this.props;
    return isMappEnterprise ? <Navbar.Text>{user.username}</Navbar.Text> : (
        <NavDropdown className="userDisplay" eventKey="user" title={user.username} id="userDisplay">
          <MenuItem eventKey="user-role" header={true}>
            {user.username}
          </MenuItem>
          <MenuItem divider/>
          <MenuItem eventKey="user-logout" onClick={() => {
            //cannot use react-router-dom/Link as it renders an "a" element inside the MenuItem, which also
            //renders a "a" element.
            this.props.history.push("/logout");
          }}><FormattedMessage
              id="studio.user-display.logout"
              defaultMessage="Logout"
          />
          </MenuItem>
        </NavDropdown>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: selectors.getUser(state),
    isMappEnterprise: startupSelectors.isMappEnterprise(state),
  };
};

export const UserDisplay = connect(mapStateToProps)(withRouter(UserDisplayComponent));
