import {CONTROLROOM_TABLE_HEADER_HEIGHT} from "../ui/table/ControlRoomTable";
import {ValidationResult} from "../ui/validation/ProductValidation";

/**
 * Returns a property value as a number or null if property does not exist
 * @param aObj an objects that holds a property, e.g. ownProps.params
 * @param propertyName the property name
 */
const getNumberProperty = (aObj: any, propertyName: string): number => {
  const value = getProperty(aObj, propertyName);
  return value != null ? Number.parseInt(value, 10) : null;
};

/**
 * Returns a property value or null if property does not exist
 * @param aObj an objects that holds a property, e.g. ownProps.params
 * @param propertyName the property name
 */
const getProperty = (aObj: any, propertyName: string): any => {
  if (aObj != null && aObj[propertyName] != null) {
    return aObj[propertyName];
  }
  return null;
};

// either passed as own properties or as a router parameter
/**
 * Returns a property value from ownProps that may be passes as a react-router-dom parameter.
 * @param ownProps
 * @param propertyName
 */
const getNumberFromOwnProps = (ownProps: any, propertyName: string): number =>
    ownProps != null && ownProps[propertyName] != null ?
    getNumberProperty(ownProps, propertyName) :
    getNumberProperty(ownProps.match.params, propertyName);

// either passed as own properties or as a router parameter
/**
 * Returns a property value from ownProps that may be passes as a react-router-dom parameter.
 * @param ownProps
 * @param propertyName
 */
const getParameterFromOwnProps = (ownProps: any, propertyName: string): string =>
    ownProps != null && ownProps[propertyName] != null ? getProperty(ownProps, propertyName) : getProperty(
        ownProps.match.params, propertyName);

/**
 * Returns concatenated error and warning messages of the given validation.
 * @param {ValidationResult} API validation
 * @returns {string} concatenated error message
 */
const getConcatenatedValidationMessages = (validation: ValidationResult): string => {
  let message = "";
  validation.errorMessages.forEach((aMessage) => message += aMessage + " ");
  validation.warningMessages.forEach((aMessage) => message += aMessage);
  return message;
};

export function calculateDimensionBasedOnRowsBeforeScroll(rowsBeforeScroll: number, rowHeight: number): (parent: HTMLElement) => {} {
  return (parent: HTMLElement) => {
    return {
      width: getFirstParentWithWidth(parent).clientWidth,
      height: rowsBeforeScroll * rowHeight + CONTROLROOM_TABLE_HEADER_HEIGHT + 2,
    };
  };
}

// When a ControlRoomTable component is used within a Tab and that Tab is not the default active tab, it won't have
// a clientWidth (activating a tab gives the active one width and hides the others by setting width to 0 for previously
// active.  If we walk up the parent tree, we will get the TabContainer which will have a usable width.
export function getFirstParentWithWidth(child: HTMLElement): HTMLElement {
  let newParent: HTMLElement = child;
  while (newParent !== undefined && newParent != null &&
         newParent.clientWidth !== undefined &&
         newParent.clientWidth !== null && newParent.clientWidth === 0) {
    newParent = newParent.parentElement;
  }
  if (newParent === undefined || newParent === null) {
    newParent = child;
  }
  return newParent;
}

function isEmpty(obj) {
  for(const key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export {
  isEmpty,
  getNumberProperty,
  getNumberFromOwnProps,
  getParameterFromOwnProps,
  getConcatenatedValidationMessages,
};
