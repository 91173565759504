import * as React from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {ColumnsDescription, ColumnWidth} from "../common/ui/table/ControlRoomColumns";
import {createBasicTableComponent} from "../common/ui/table/ControlRoomTable";
import {connectPagedTable, PagedTableConfig} from "../common/ui/table/PagedTable";
import {DateUtil} from "../common/util/DateUtil";
import {actions} from "./actions";
import {Product} from "./model";
import {ProductRemoveButton} from "./ProductRemoveButton";
import {selectors} from "./selectors";

const config: PagedTableConfig = {
  canMultiSelect: true,
  noItemsFoundMessage: <FormattedMessage id="studio.products.product-list.no-products-found"
                                         defaultMessage="No products found."/>,
};

const MESSAGES = defineMessages({
  removeTooltip: {
    id: "studio.products.product-list.remove-tooltip",
    defaultMessage: "Remove {title}",
  },
});
const columns: ColumnsDescription<Product> = {
  title: {
    header: <FormattedMessage id="studio.products.product-list.title" defaultMessage="Title"/>,
    cellContent: (product) => <Link to={`/products/${product.id}`}>{product.title}</Link>,
    cellTooltip: (product) => product.title,
    columnWidth: ColumnWidth.flex(2),
  },
  keywords: {
    header: <FormattedMessage id="studio.products.product-list.keywords" defaultMessage="Keywords"/>,
    cellContent: (product) => product.keywords.join(", "),
    cellTooltip: (product) => product.keywords.join(", "),
    columnWidth: ColumnWidth.flex(1),
  },
  creationTime: {
    header: <FormattedMessage id="studio.products.product-list.date-created" defaultMessage="Date created"/>,
    cellContent: (product) => DateUtil.formatDateTime(product.creationTime),
    cellTooltip: (product) => DateUtil.formatDateTime(product.creationTime),
    columnWidth: ColumnWidth.DATETIME_COLUMN_WIDTH,
  },
  remove: {
    header: <FormattedMessage id="studio.products.product-list.remove" defaultMessage="Remove"/>,
    cellContent: (product) => <ProductRemoveButton productId={product.id}/>,
    cellTooltip: (product, intl) => intl.formatMessage(MESSAGES.removeTooltip, {title: product.title}),
    columnWidth: ColumnWidth.REMOVE_COLUMN_WIDTH,
  },
};

export const ProductListComponent = createBasicTableComponent(columns);
export const ProductList = connectPagedTable(ProductListComponent, selectors.getPagedState, actions.requestPage,
    config);
