import {combineReducers, Reducer} from "redux";
import {reducer as formReducer} from "redux-form";
import {moduleName as notificationModuleName, reducer as notificationsReducer} from "./common/ui/notification/reducer";
import {actionTypes as toursActionTypes} from "./common/ui/tour/actions";
import {moduleName as toursModuleName, reducer as toursReducer} from "./common/ui/tour/reducer";
import {moduleName as warningsModuleName, reducer as warningsReducer} from "./common/ui/warnings/reducer";
import {moduleName as importedDataModuleName, reducer as importedDataReducer} from "./data/reducer";
import {importJobModuleName, reducer as jobsReducer} from "./jobs/import/reducer";
import {moduleName as preprocessJobModuleName, reducer as preprocessJobsReducer} from "./jobs/preprocessing/reducer";
import {moduleName as productModuleName, reducer as productReducer} from "./products/reducer";
import {moduleName as serviceModuleName, reducer as servicesReducer} from "./services/reducer";
import {moduleName as contentRootsModuleName, reducer as getContentRootsReducer} from "./settings/dataroots/reducer";
import {moduleName as startupModuleName, reducer as startupReducer} from "./startup/reducer";
import {moduleName as stylesModuleName, reducer as stylesReducer} from "./styles/reducer";
import {actionTypes as userActionTypes} from "./user/actions";
import {moduleName as userModuleName, reducer as userReducer} from "./user/reducer";
import {
  moduleName as defaultMetadataModuleName,
  reducer as defaultMetadataReducer
} from "./common/defaultMetadata/reducer";

export const rootReducer = (state, action) => {
  if (action.type === userActionTypes.SET_USER_TOUR_COMPLETED || action.type === toursActionTypes.SET_STYLE_DATA_TOUR_COMPLETED) {
    state = {
      [userModuleName]: state.user,
      // don't lose the startup state
      [startupModuleName]: state[startupModuleName],
    };
  }

  return appReducer(state, action);
};

// Add the reducer to our application store on the `routing` key
const appReducer: Reducer<any> = combineReducers({
  form: formReducer,
  [startupModuleName]: startupReducer,
  [contentRootsModuleName]: getContentRootsReducer,
  [importedDataModuleName]: importedDataReducer,
  [userModuleName]: userReducer,
  [serviceModuleName]: servicesReducer,
  [importJobModuleName]: jobsReducer,
  [preprocessJobModuleName]: preprocessJobsReducer,
  [notificationModuleName]: notificationsReducer,
  [productModuleName]: productReducer,
  [stylesModuleName]: stylesReducer,
  [warningsModuleName]: warningsReducer,
  [toursModuleName]: toursReducer,
  [defaultMetadataModuleName]: defaultMetadataReducer,
});

export const createTestState = () => {
  return rootReducer(undefined, {type: "init"});
};
