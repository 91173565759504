import * as React from "react";
import {Typeahead} from "../common/ui/typeahead/Typeahead";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {ImportedData} from "./model";
import {InjectedIntlProps, injectIntl} from "react-intl";

interface ImportedDataTypeaheadProps {
  value: ImportedData;
  onChange: (newValue: ImportedData) => void;
  validate?: (suggestion: ImportedData) => boolean;
  placeholder?: string;
}

const AbstractDataTypeahead = Typeahead<ImportedData>();

class ImportedDataTypeaheadComponent extends React.Component<ImportedDataTypeaheadProps & WithApiProperties & InjectedIntlProps, {}> {

  static MAX_RESULTS = 10;

  fetchSuggestions = (value: string) => this.props.api.getImportedData({anyText: value + "*"})
      .then((data) => {
            const {validate} = this.props;
            const filteredData = validate ? data.filter(validate) : data;
            return filteredData.slice(0, ImportedDataTypeaheadComponent.MAX_RESULTS);
          },
      )

  renderSuggestion = (importedData) => {
    return (importedData.title || importedData.name || "NO_NAME");
  }

  render() {
    return (
        <AbstractDataTypeahead value={this.props.value}
                               fetchSuggestions={this.fetchSuggestions}
                               renderSuggestion={this.renderSuggestion}
                               onChange={this.props.onChange}
                               placeholder={this.props.placeholder}
                               intl={this.props.intl}
        />
    );
  }
}

export const ImportedDataTypeahead = injectIntl(WithApi(ImportedDataTypeaheadComponent));
