export interface StyleSearchFilter {
  anyText?: string;
  type?: string;
  startDate?: string;
  endDate?: string;
}

export const filtersEqual = (filterA: StyleSearchFilter, filterB: StyleSearchFilter) => {
  return filterA.anyText === filterB.anyText
         &&  filterA.type === filterB.type
         && filterA.startDate === filterB.startDate
         && filterA.endDate === filterB.endDate;
};
