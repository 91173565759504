import * as React from "react";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {LcdIcon} from "../icon/LcdIcon";
import {FileUploadFormState} from "./FileUploadFormState";
import {getTotalUploadSize} from "./FileUploadUtil";

interface FileUploadStartCancelButtonProperties {
  fileUploadFormState: FileUploadFormState;

  startUpload: () => void;
  cancelUpload: () => void;
  resetUpload: (files?: any[]) => void;
}

const FILE_UPLOAD_MESSAGES = defineMessages({
  startUploading: {
    id: "studio.file-upload.start-uploading",
    defaultMessage: "Start Uploading",
  },
  addSomeFilesFirst: {
    id: "studio.file-upload.add-some-files-first",
    defaultMessage: "Add some files first.",
  },
  maximumFileSizeExceeded: {
    id: "studio.file-upload.maximum-file-size-exceeded",
    defaultMessage: "Maximum file size exceeded",
  },
});

class FileUploadStartCancelButtonComponent extends React.Component<FileUploadStartCancelButtonProperties & InjectedIntlProps, {}> {

  render() {
    const {fileUploadFormState, cancelUpload, intl} = this.props;
    if (fileUploadFormState.finished) {
      return (
          <Button bsStyle="primary" type="button" onClick={() => this.props.resetUpload()} block>
            <FormattedMessage id="studio.file-upload.start-new-upload"
                              defaultMessage="Start a new upload"/>
          </Button>
      );
    }
    if (fileUploadFormState.isUploading) {
      return (
          <Button onClick={cancelUpload} bsStyle="danger" block>
            <FormattedMessage id="studio.file-upload.cancel-upload"
                              defaultMessage="Cancel upload"/>
          </Button>
      );
    }
    const noFilesAdded = fileUploadFormState.uploadFiles.length === 0;
    const maximumExceeded = (fileUploadFormState.maxUploadSize <
                             getTotalUploadSize(fileUploadFormState.uploadFiles));

    if (noFilesAdded || maximumExceeded) {
      let reason;
      if (noFilesAdded) {
        reason = intl.formatMessage(FILE_UPLOAD_MESSAGES.addSomeFilesFirst);
      } else {
        reason = intl.formatMessage(FILE_UPLOAD_MESSAGES.maximumFileSizeExceeded);
      }
      //adding an overlay (tooltip) to a disabled element requires some witchcraft, as disabled elements
      //do not respond to mouse events
      //see remark in bootstrap docs: http://getbootstrap.com/javascript/#tooltips
      //and https://github.com/react-bootstrap/react-bootstrap/issues/1588
      return (
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{reason}</Tooltip>}>
            <div style={{display: "inline-block", cursor: "not-allowed", width: "100%"}}>
              <Button style={{pointerEvents: "none"}} bsStyle="primary" onClick={this.props.startUpload} disabled block>
                <LcdIcon icon="upload"/>&nbsp;&nbsp;
                {intl.formatMessage(FILE_UPLOAD_MESSAGES.startUploading)}
              </Button>
            </div>
          </OverlayTrigger>
      );
    }
    return (
        <Button bsStyle="primary" onClick={this.props.startUpload} block>
          <LcdIcon icon="upload"/>&nbsp;&nbsp;
          {intl.formatMessage(FILE_UPLOAD_MESSAGES.startUploading)}
        </Button>
    );
  }
}

export const FileUploadStartCancelButton = injectIntl(FileUploadStartCancelButtonComponent);
