import * as React from "react";
import {Modal} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {FileDrop} from "../filedrop/FileDrop";
import {FileUploadForm} from "./FileUploadForm";
import {UploadOptions} from "./UploadOptions";

export interface FileUploadAndDndState {
  showModal: boolean;
  droppedFiles: File[];
  uploadOptions: UploadOptions;
}

export interface FileUploadAndInjectedProps {
  isFileUploading: boolean;
  openUpload: () => void;
}

interface WithFileUploadExtraProps {
  createFormData?: (uploadFile: File[]) => FormData;
}

export type WithFileUploadExternalProps<WrappedComponentProps> = WrappedComponentProps & WithFileUploadExtraProps;

type WithFileUploadHOCType<WrappedComponentProps> = React.ComponentClass<WithFileUploadExternalProps<WrappedComponentProps>>;

export const DEFAULT_UPLOAD_OPTIONS: UploadOptions = {
  allowedFileExtension: null,
  allowMultipleFiles: true,
  uploadUri: "/upload/files",
};

export const defaultCreateFormData = (uploadFiles: File[]) => {
  const data = new FormData();
  uploadFiles.forEach((uploadFile) => data.append("files", uploadFile));
  return data;
};

export const WithFileUpload = (uploadOptions: UploadOptions = DEFAULT_UPLOAD_OPTIONS) => {

  // tslint:disable-next-line:only-arrow-functions
  return function <WrappedProps>(ComponentToWrap: React.ComponentClass<WrappedProps & FileUploadAndInjectedProps>):
      WithFileUploadHOCType<WrappedProps> {

    return class extends React.Component<WithFileUploadExternalProps<WrappedProps>, FileUploadAndDndState> {

      constructor(wrappedCompProps) {
        super(wrappedCompProps);
        this.state = {
          showModal: false,
          droppedFiles: [],
          uploadOptions,
        };
      }

      mergeState = (partial) => this.setState(Object.assign({}, this.state, partial));

      closeUpload = () => this.mergeState({showModal: false, droppedFiles: []});

      openUpload = () => this.mergeState({showModal: true});

      openUploadWithDroppedFiles = (droppedFiles: File[]) => this.mergeState(
          {showModal: true, droppedFiles})

      render() {
        // Drag and Drop area for entire page should not be available when the uploading form is displayed
        const dragAndDropArea = !this.state.showModal &&
                                <FileDrop onDrop={this.openUploadWithDroppedFiles}
                                          baseClassName="controlroom-dnd-full-page">
                                  <div className="controlroom-dnd-hint text-center">
                                    <h1>
                                      <FormattedMessage id="studio.file-upload.drop-files-here"
                                                        defaultMessage="Drop files here"/>
                                    </h1>
                                  </div>
                                </FileDrop>;

        const fileUploadFormModal = (
            <Modal show={this.state.showModal}
                   onHide={this.closeUpload}
                   bsSize="large"
                   backdrop="static"
                   className="file-upload-modal">
              <Modal.Header closeButton />
              <Modal.Body>
                <FileUploadForm initialFiles={this.state.droppedFiles}
                                shouldCancel={!this.state.showModal}
                                uploadOptions={this.state.uploadOptions}
                                createFormData={this.props.createFormData || defaultCreateFormData}/>
              </Modal.Body>
            </Modal>
        );

        //TODO: find a way to properly type this. Currently, the TS struggles with this (TS 2.3.4) if you remove the any type.
        const propsToPassDown: any = Object.assign({}, {
          isFileUploading: this.state.showModal,
          openUpload: this.openUpload,
        }, this.props);
        return (
            <div>
              {dragAndDropArea}
              {fileUploadFormModal}
              <ComponentToWrap {...propsToPassDown}/>
            </div>
        );
      }
    };
  };
};
