import {combineReducers, Reducer} from "redux";
import {createLoadDataReducer} from "../../common/util/asyncdata/reducerUtil";
import {AllIdState, ByIdState, JobState} from "../import/reducer";
import {CompositeJob} from "../model";
import {jobReducerUtil} from "../reducerUtil";
import {preprocessJobModuleName as moduleName} from "../selectors";
import {LOAD_PREPROCESS_JOB_DETAIL_PREFIX, LOAD_PREPROCESS_JOBS_PREFIX, preprocessActionTypes} from "./actions";

const jobByIdReducer = (state: ByIdState = {}, action): ByIdState => {
  switch (action.type) {
  case preprocessActionTypes.LOAD_PREPROCESS_JOB_DETAIL_SUCCESS:
    return jobReducerUtil.loadJobDetailForJobByIdReducer(state, action);
  case preprocessActionTypes.LOAD_PREPROCESS_JOBS_SUCCESS:
    return jobReducerUtil.loadJobsSuccessForJobByIdReducer(state, action);
  case preprocessActionTypes.ADD_PREPROCESS_JOB:
  case preprocessActionTypes.UPDATE_PREPROCESS_JOB:
    return jobReducerUtil.updateJobActionForJobByIdReducer(state, action);
  case preprocessActionTypes.REMOVE_PREPROCESS_JOB:
  case preprocessActionTypes.FINISH_PREPROCESS_JOB:
    return jobReducerUtil.deleteJobForJobByIdReducer(state, action);
  case preprocessActionTypes.QUEUE_PREPROCESS_JOB:
  case preprocessActionTypes.RUN_PREPROCESS_JOB:
  case preprocessActionTypes.PROGRESS_PREPROCESS_JOB:
  case preprocessActionTypes.FAIL_PREPROCESS_JOB:
  case preprocessActionTypes.STOP_PREPROCESS_JOB:
    return jobReducerUtil.updateJobForJobByIdReducer(state, action);
  default:
    return state;
  }
};

const allIdReducer = (state: AllIdState = [], action): AllIdState => {
  switch (action.type) {
  case preprocessActionTypes.LOAD_PREPROCESS_JOBS_SUCCESS:
    return jobReducerUtil.loadJobsSuccessForAllIdReducer(state, action);
  case preprocessActionTypes.ADD_PREPROCESS_JOB:
    return jobReducerUtil.addJobForAllIdReducer(state, action);
  case preprocessActionTypes.REMOVE_PREPROCESS_JOB:
    return jobReducerUtil.removeJobForAllIdReducer(state, action);
  default:
    return state;
  }
};

const loadJobRequestReducer = createLoadDataReducer<CompositeJob[]>(LOAD_PREPROCESS_JOBS_PREFIX, []);
const loadJobDetailRequestReducer = createLoadDataReducer<CompositeJob>(LOAD_PREPROCESS_JOB_DETAIL_PREFIX, null);

const reducer: Reducer<JobState> = combineReducers<JobState>({
  byId: jobByIdReducer,
  allIds: allIdReducer,
  loadJobsRequest: loadJobRequestReducer,
  loadJobDetailRequest: loadJobDetailRequestReducer,
  jobFilePages: null,
  filter: null,
});

export {
  reducer,
  moduleName,
};
