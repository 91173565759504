import * as React from "react";
import {Button, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {ImportedData} from "../data/model";
import {CloseCreateProductFormButton, CreateProductForm, CreateProductFormSubmitButton} from "./CreateProductForm";

interface ButtonProps {
  disabled?: boolean;
  initialData?: ImportedData[];
}
interface ButtonState {
  showModal: boolean;
}

export class CreateProductButtonComponent extends React.Component<InjectedIntlProps & ButtonProps, ButtonState> {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  closeCreationModal = () => {
    this.setState({showModal: false});
  }
  openCreationModal = () => {
    this.setState({showModal: true});
  }
  render() {
    const {disabled, initialData} = this.props;
    const {showModal} = this.state;

    const dataForNewProduct = (showModal) ? initialData : [];

    const buttonIsDisabled = disabled || showModal;

    let createProductButton = null;

    if (buttonIsDisabled) {
      const reason = <FormattedMessage
          id="studio.create-product-button.overlay"
          defaultMessage="Select some data from the list below"
      />;
      //adding an overlay (tooltip) to a disabled element requires some witchcraft, as disabled elements
      //do not respond to mouse events
      //see remark in bootstrap docs: http://getbootstrap.com/javascript/#tooltips
      //and https://github.com/react-bootstrap/react-bootstrap/issues/1588
      createProductButton = (
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{reason}</Tooltip>}>
            <div style={{cursor: "not-allowed"}}>
              <Button style={{pointerEvents : "none"}} onClick={this.openCreationModal} disabled>
                <LcdIcon icon="plus"/> <FormattedMessage
                  id="studio.create-product-button.label.disabled"
                  defaultMessage="Create Product"/>
              </Button>
            </div>
          </OverlayTrigger>
      );
    } else {
      createProductButton = (
          <Button onClick={this.openCreationModal}>
            <LcdIcon icon="plus"/> <FormattedMessage
              id="studio.create-product-button.label.enabled"
              defaultMessage="Create Product"/>
          </Button>
      );
    }

    return (
        <div>
          {createProductButton}

          <Modal show={showModal} onHide={this.closeCreationModal} bsSize="large" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title><FormattedMessage
                  id="studio.create-product-button.modal"
                  defaultMessage="Create Product"
              /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateProductForm initialData={dataForNewProduct}/>
            </Modal.Body>
            <Modal.Footer>
              <CreateProductFormSubmitButton />
              <CloseCreateProductFormButton onClick={this.closeCreationModal} />
            </Modal.Footer>
          </Modal>
        </div>
    );
  }
}

export const CreateProductButton = injectIntl(CreateProductButtonComponent);
