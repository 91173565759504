import * as React from "react";
import {InputGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {LcdIcon} from "../icon/LcdIcon";
import {Spinner} from "../spinner/Spinner";

interface TextEditProps {
  name?: string;
  value: string;
  defaultValue?: string;
  type?: string;
  onChange: (newValue: string) => void | Promise<void>;
  onBlur?: React.FocusEventHandler<any>;
  onFocus?: React.FocusEventHandler<any>;
  onDrop?: React.DragEventHandler<any>;
  onDragStart?: React.DragEventHandler<any>;
  readOnly?: boolean;
}

interface TextEditState {
  isMouseOver: boolean;
  isEditing: boolean;
  editingValue: string;
  submitState: "NOT_STARTED" | "SUBMITTING" | "ERROR"; //no explicit FINISHED state, the edit field just goes back to 'display' mode
  submitError: Error;
  lastMouseOut: number;
}

/**
 * A text edit field that can be edited upon mouseover, just like a JIRA text field.
 */
export class TextEdit extends React.Component<TextEditProps, TextEditState> {

  _textInput: HTMLInputElement;

  constructor(props) {
    super(props);
    this.state = {
      isMouseOver: false,
      isEditing: false,
      editingValue: null,
      submitState: "NOT_STARTED",
      submitError: null,
      lastMouseOut: 0,
    };
  }

  reset = () => {
    this.setState(Object.assign({}, this.state, {
      isEditing: false,
      editingValue: null,
      submitState: "NOT_STARTED",
      submitError: null,
    }));
  }

  handleMouseOver = () => {
    // Remove this time hack when implementing a real solution for LF-2229
    if(Date.now() - this.state.lastMouseOut > 20) {
      this.setState(Object.assign({}, this.state, {isMouseOver: true}));
    }
  }

  handleMouseOut = () => {
    this.setState(Object.assign({}, this.state, {isMouseOver: false, lastMouseOut: Date.now()}));
  }

  handleEditButtonClick = () => {
    if (this._textInput) {
      this._textInput.focus();
    }
  }

  handleChange = () => {
    const newValue = this._textInput.value;
    //remove the error state when changing the input after an error
    const nextSubmitState = this.state.submitState === "ERROR" ? "NOT_STARTED" : this.state.submitState;
    this.setState(Object.assign({}, this.state, {editingValue: newValue, submitState: nextSubmitState}));
  }

  handleInputFocus = () => {
    if (this._textInput) {
      this._textInput.select();
    }
    const newEditingValue = this.state.submitState === "ERROR" ? this.state.editingValue : this.props.value;
    this.setState(Object.assign({}, this.state, {isEditing: true, editingValue: newEditingValue}));
  }

  handleInputBlur = (blurEvent) => {
    if (this.state.editingValue !== this.props.value && !(this.state.submitState === "ERROR")) {
      this.valueChanged(this.state.editingValue).then(this.reset).catch(this.reset);
    } else {
      this.reset();
    }
    if (this.props.onBlur) {
      this.props.onBlur(blurEvent);
    }
  }

  valueChanged = (newValue: string): Promise<void> => {
    const onChangeResult = this.props.onChange(newValue);
    if (onChangeResult instanceof Promise) {
      this.setState(Object.assign({}, this.state, {submitState: "SUBMITTING"}));
      const onChangeResultPromise = onChangeResult as Promise<void>;
      return onChangeResultPromise.catch((error) => {
        this.setState(Object.assign({}, this.state, {submitState: "ERROR", submitError: error}));
        this._textInput.focus();
        throw error;
      });
    } else {
      return Promise.resolve();
    }
  }

  handleKeyPress = (event: React.KeyboardEvent<any>) => {
    //submit on ENTER
    if (event.which === 13 && this._textInput) {
      this._textInput.blur();
    }
  }

  shouldShowInput = () => {
    const {isMouseOver, isEditing} = this.state;
    return isMouseOver || isEditing;
  }

  escapeKeyPressedListener = (event: KeyboardEvent) => {
    //cancel on ESC, unfortunately ESC keys are only dispatched on document
    if (event.which === 27 && this._textInput) {
      //revert editingValue to this.props.value -> no onChange fired
      this.setState(Object.assign({}, this.state, {editingValue: this.props.value}));
      this._textInput.blur();
    }
  }

  renderInput = () => {
    if (this.shouldShowInput() && !this.props.readOnly) {
      const inputValue = this.state.isEditing ? this.state.editingValue : this.props.value;
      document.addEventListener("keyup", this.escapeKeyPressedListener);
      //use a 'vanilla' input iso a FormControl to allow more low-level handling of events (select/focus/blur methods)
      return <input type={this.props.type || "text"}
                    className="form-control textEditInput"
                    ref={(ref) => {this._textInput = ref; }}
                    onBlur={this.handleInputBlur}
                    onFocus={this.handleInputFocus}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    name={this.props.name}
                    value={inputValue || ""}
      />;
    }
    this._textInput = null;
    document.removeEventListener("keyup", this.escapeKeyPressedListener);
    return (<div className="textEditDisplay">
      {this.props.children || this.props.value || this.props.defaultValue || "None"}
    </div>);
  }

  renderAddon = () => {
    if (this.props.readOnly) {
      return null;
    }
    const {submitState, submitError} = this.state;
    switch (submitState) {
    case "NOT_STARTED":
      return this.shouldShowInput() ?
             <InputGroup.Addon onClick={this.handleEditButtonClick}>
               <LcdIcon icon="pencil"/>
             </InputGroup.Addon>
          : null;
    case "SUBMITTING":
      return <InputGroup.Addon><Spinner /></InputGroup.Addon>;
    case "ERROR":
      const errorMessage = submitError.message;
      const tooltip = <Tooltip id={(this.props.name || "textedit") + "-error-tooltip"}>{errorMessage}</Tooltip>;
      return (
          <InputGroup.Addon>
            <OverlayTrigger placement="top" overlay={tooltip} defaultOverlayShown={true}>
              <LcdIcon icon="alert"/>
            </OverlayTrigger>
          </InputGroup.Addon>
      );
    }
  }

  render() {

    return (
        <InputGroup className="textEditContainer"
                    onMouseOver={this.handleMouseOver}
                    onMouseOut={this.handleMouseOut}>
          {this.renderInput()}{this.renderAddon()}
        </InputGroup>
    );

  }

}
