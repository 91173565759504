import {User} from "./model";

type SET_USER = "user/SET_USER";
const SET_USER: SET_USER = "user/SET_USER";
type SET_USER_TOUR_COMPLETED = "user/SET_USER_TOUR_COMPLETED";
const SET_USER_TOUR_COMPLETED: SET_USER_TOUR_COMPLETED = "user/SET_USER_TOUR_COMPLETED";

export interface SetUserAction {
  type: SET_USER;
  payload: User;
}

export interface SetUserTourCompletedAction {
  type: SET_USER_TOUR_COMPLETED;
  payload: boolean;
}

const actionTypes = {
  SET_USER,
  SET_USER_TOUR_COMPLETED,
};

const setUser = (user: User): SetUserAction => {
  return {
    type: SET_USER,
    payload: user,
  };
};

const login = ({username, password, redirect}: { username: string; password: string; redirect?: string }) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.login({username, password, redirect})
        .then(() => api.getCurrentUser())
        .then((user) => {
          dispatch(setUser(user));
        });
  };
};

const logout = () => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.logout().then(() => {
      dispatch(setUser(null));
    });
  };
};

const setUserTourCompleted = (isUserTourCompleted: boolean) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.setUserTourCompleted(isUserTourCompleted).then(() => {
      dispatch({type: SET_USER_TOUR_COMPLETED, payload: isUserTourCompleted});
    }).catch((error) => {
      dispatch({type: SET_USER_TOUR_COMPLETED, payload: true});
      throw error;
    });
  };
};

const actions = {
  login,
  logout,
  setUser,
  setUserTourCompleted,
};

export {actions, actionTypes};
