const currentTime = Date.now || (() => new Date().getTime());

/**
 * Returns a function, that, when invoked, will only be triggered at most once during the given window of time.
 * Normally, the throttled function will run as much as it can, without ever going more than once per `wait` duration;
 * but if you'd like to disable the execution on the leading edge, pass `{leading: false}`.
 * To disable execution on the trailing edge, ditto.
 * @param func {F} The function to throttle.
 * @param wait {number} The minimum time between invocations, in milliseconds.
 * @param options {{leading:boolean, trailing:boolean}}
 * @returns {F} The throttled func.
 */
// tslint:disable-next-line:ban-types
function throttle<F extends Function>(func: F,
                                      wait: number,
                                      options?: {leading?: boolean, trailing?: boolean}): F {
  //source: http://stackoverflow.com/questions/27078285/simple-throttle-in-js
  let context, result;
  let timeout = null;
  let previous = 0;
  if (!options) {
    options = {};
  }
  const later = (...args) => {
    previous = options.leading === false ? 0 : currentTime();
    timeout = null;
    result = func(...args);
    if (!timeout) {
      context = args = null;
    }
  };
  const throttled = (...args) => {
    const now = currentTime();
    if (!previous && options.leading === false) {
      previous = now;
    }
    const remaining = wait - (now - previous);
    context = this;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func(...args);
      if (!timeout) {
        context = args = null;
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
  return (throttled as any) as F;
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 */
function debounce(func, wait: number, immediate: boolean = false) {
  let timeout;
  return function() {
    const context = this, args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

export {throttle, debounce};
