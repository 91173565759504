import {Api} from "../api/InternalApi";
import {
  createPagedDataActions,
  createPagedDataActionTypes,
  selectors as pagedDataSelectors,
} from "../common/util/pagination/PaginationUtil";
import {actions as styleFilterActions} from "./filter/actions";
import {filtersEqual, StyleSearchFilter} from "./filter/model";
import {Style} from "./model";
import {selectors} from "./selectors";

export const STYLE_ACTION_TYPE_NAME = "STYLE";

const pagedStyleActionTypes = createPagedDataActionTypes(STYLE_ACTION_TYPE_NAME);

const fetchPage = (page: number, state, api: Api) => {
  const filter = selectors.getFilter(state);
  const pagedDataState = selectors.getPagedState(state);
  const pageSize = pagedDataSelectors.getPageSize(pagedDataState);
  const offset = page * pageSize;
  const filterWithPagination: StyleSearchFilter = Object.assign({}, filter, {offset, maxResults: pageSize});
  return api.getStyles(filterWithPagination).then((datas) => ({
    datas,
    pageValidationInfo: {
      filter,
    },
  }));
};

const isPageStillValid = (pageValidationInfo, state) => {
  return filtersEqual(pageValidationInfo.filter, selectors.getFilter(state));
};

const fetchById = (id: string, state, api) => api.getStyleById(id);

const pagedStyleActions = createPagedDataActions(
    STYLE_ACTION_TYPE_NAME,
    fetchPage,
    isPageStillValid,
    fetchById,
    selectors.getPagedState,
);

export const actionTypes = Object.assign({}, {
  STYLE_ADDED_ON_SERVER: "controlRoom/STYLE_ADDED_ON_SERVER",
}, pagedStyleActionTypes);

const styleAddedOnServer = () => {
  return {
    type: actionTypes.STYLE_ADDED_ON_SERVER,
  };
};

const deleteStyles = (ids: string[]) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).deleteStyles(ids).then(() => {
      dispatch(pagedStyleActions.clearData());
    });
  };
};

const refreshStyle = (id: string) => {
  return (dispatch, getState, {getApi}): Promise<Style> => {
    return getApi(getState()).refreshStyle(id)
        .then((style) =>
            getApi(getState()).getStyleBoundsByType(style.type)
                .then((bounds) => {
                  dispatch(pagedStyleActions.clearData());
                  return Object.assign({}, style, {wgs84Bounds: bounds});
                }));
  };
};

const addStyle = (filePath: string) => {
  return (dispatch, getState, {getApi}): Promise<Style> => {
    return getApi(getState()).addStyle(filePath)
        .then((style) =>
            getApi(getState()).getStyleBoundsByType(style.type)
                .then((bounds) => {
                  dispatch(pagedStyleActions.clearData());
                  return Object.assign({}, style, {wgs84Bounds: bounds});
                }));
  };
};

export const actions = Object.assign({}, {
  setFilter: styleFilterActions.setFilter,
  styleAddedOnServer,
  deleteStyles,
  refreshStyle,
  addStyle,
}, pagedStyleActions);
