import {LineType} from "@luciad/ria/geodesy/LineType";
import {Feature} from "@luciad/ria/model/feature/Feature";
import {Shape} from "@luciad/ria/shape/Shape";
import {FeaturePainter, PaintState} from "@luciad/ria/view/feature/FeaturePainter";
import {Layer} from "@luciad/ria/view/Layer";
import {Map} from "@luciad/ria/view/Map";
import {GeoCanvas} from "@luciad/ria/view/style/GeoCanvas";
import {ShapeStyle} from "@luciad/ria/view/style/ShapeStyle";

const shapeStyleBase: ShapeStyle = {
  lineType: LineType.SHORTEST_DISTANCE,
  stroke: {
    color: "rgba(255,0,0,1.0)",
    width: 2,
  },
};

export class DataSeriePainter extends FeaturePainter {

  colorMap: {[dataSerieName: string]: string} = {};

  paintBody(geoCanvas: GeoCanvas, feature: Feature, shape: Shape,
            layer: Layer, map: Map, paintState: PaintState): void {
    const color = this.colorMap[feature.id] || "rgba(0, 0, 0, 255)";
    const lineStyle = Object.assign({}, shapeStyleBase.stroke, {color});
    const shapeStyle = Object.assign({}, shapeStyleBase, {stroke: lineStyle});
    geoCanvas.drawShape(feature.shape, shapeStyle);
  }

  assignColor(dataSerieName: string, color: string) {
    this.colorMap[dataSerieName] = color;
  }

  getSupportedPaintRepresentations(paintRep: string) {
    //the original implementation of getSupportedPaintRepresentations does a for(var ... in this), but
    //the paintBody function is not enumerable!
    return ["BODY"];
  }

}
