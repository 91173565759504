import * as React from "react";
import {Button} from "react-bootstrap";
import {LcdIcon} from "../icon/LcdIcon";

interface LayerProps {
  layer: any;
  onFit: () => void;
}

export class Layer extends React.Component<LayerProps, {}> {

  constructor(props) {
    super(props);

    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  toggleVisibility() {
    const {layer} = this.props;
    layer.visible = !layer.visible;
    this.forceUpdate(); // needed to re-render UI after visibility change
  }

  render() {
    const {layer, onFit} = this.props;
    return (
        <tr>
          <td>{layer.label}</td>
          <td style={{width: "10%"}}>
            <Button bsSize="xs" bsStyle="primary-outline" onClick={this.toggleVisibility}>
              <LcdIcon icon={layer.visible ? "eye" : "eye-close"}/>
            </Button>
          </td>
          <td style={{width: "10%"}}>
            <Button bsSize="xs" bsStyle="primary-outline" onClick={onFit}>
              <LcdIcon icon="spot"/>
            </Button>
          </td>
        </tr>
    );
  }
}
