import {combineReducers} from "redux";
import {createLoadDataReducer, LoadDataState, LoadDataSuccessAction} from "../common/util/asyncdata/reducerUtil";
import {createPagedDataReducer, PagedDataState} from "../common/util/pagination/PaginationUtil";
import {actionTypes, LOAD_PRODUCT_CONTENTS_PREFIX, PRODUCT_ACTION_TYPENAME} from "./actions";
import {Product, ProductFilter, ProductType, StyledData} from "./model";
import {moduleName} from "./selectors";

interface ProductsState {
  pages: PagedDataState<Product>;
  filter: ProductFilter;
  allProductContents: StyledData[];
  loadProductContentsRequest: LoadDataState<StyledData[]>;
}

//used when product is undefined in productReducer
const defaultProduct: Product = {
  id: null,
  title: "",
  abstractText: "",
  keywords: [],
  contents: [],
  createdBy: {
    username: "Unknown",
  },
  type: ProductType.EMPTY,
};

const allProductContentsReducer = (state: StyledData[] = [], action) => {
  switch (action.type) {
  case actionTypes.LOAD_PRODUCT_CONTENTS_SUCCESS:
    const loadProductContentsAction = action as LoadDataSuccessAction<StyledData[]>;
    return loadProductContentsAction.payload;
  case actionTypes.ADD_STYLED_DATA:
    const idsFromAction = action.payload.styledDatas.map((styledData) => styledData.id);
    const newStyledDatas = action.payload.styledDatas.slice();
    const replaceIfInAction = (styledDataInState) => {
      const styledDataToUpdateId = idsFromAction.find((idFromAction) => styledDataInState.id === idFromAction);
      if (typeof styledDataToUpdateId !== "undefined") {
        //If already exists, don't add again. This is dirty, but necessary, and efficient
        newStyledDatas.splice(newStyledDatas.findIndex((styledData) => styledData.id === styledDataToUpdateId), 1);
        return action.payload.styledDatas.find((styledData) => styledData.id === styledDataToUpdateId);
      }
      return styledDataInState;
    };
    const updatedStyledDatas = state.map(replaceIfInAction);
    return [...newStyledDatas, ...updatedStyledDatas];
  case actionTypes.REMOVE_STYLED_DATA:
    const removedId = action.payload.id;
    return state.filter((currentStyledData) => currentStyledData.id !== removedId);
  case actionTypes.UPDATE_ALL_STYLED_DATAS:
    return action.payload.styledDatas.slice();
  default:
    return state;
  }
};
const basePagedProductReducer = createPagedDataReducer<Product>(PRODUCT_ACTION_TYPENAME);
const defaultState = basePagedProductReducer(undefined, {type: "init"}) as PagedDataState<Product>;
//extend the existing pagedDataReducer so that it also handles UPDATE_PRODUCT actions
const extendedPagedProductReducer = (state: PagedDataState<Product> = defaultState, action) => {
  switch (action.type) {
  case actionTypes.UPDATE_PRODUCT:
    const oldData = state.data;
    const newData = Object.assign({}, oldData, {[action.payload.product.id]: action.payload.product});
    return Object.assign({}, state, {data: newData});
  }
  return basePagedProductReducer(state, action);
};

const filterReducer = (state: ProductFilter = {}, action) => {
  switch (action.type) {
  case actionTypes.SET_FILTER:
    const newFilter = action.payload;
    return Object.assign({}, state, newFilter);
  }
  return state;
};

const reducer = combineReducers<ProductsState>({
  pages: extendedPagedProductReducer,
  filter: filterReducer,
  allProductContents: allProductContentsReducer,
  loadProductContentsRequest: createLoadDataReducer(LOAD_PRODUCT_CONTENTS_PREFIX, []),
});

const createTestState = (products: Product[] = [], productContents: StyledData[] = []) => {
  let state = reducer(undefined, {type: "init"});
  const requestPageAction = {
    type: actionTypes.REQUEST_PAGE,
    payload: 0,
  };
  state = reducer(state, requestPageAction);
  const pageSuccessAction = {
    type: actionTypes.RECEIVE_PAGE_SUCCESS,
    payload: {
      data: products,
      pageNumber: 0,
    },
  };
  state = reducer(state, pageSuccessAction);
  const contentsLoadingStartAction = {
    type: actionTypes.LOAD_PRODUCT_CONTENTS_STARTED,
  };
  state = reducer(state, contentsLoadingStartAction);
  const contentsSuccessAction = {
    type: actionTypes.LOAD_PRODUCT_CONTENTS_SUCCESS,
    payload: productContents,
    error: false,
  };
  state = reducer(state, contentsSuccessAction);
  return {[moduleName]: state};
};

export {
  moduleName,
  reducer,
  createTestState,
};
