import * as React from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Action, Dispatch} from "redux";
import {
  Field,
  Fields,
  formValueSelector,
  hasSubmitFailed,
  hasSubmitSucceeded,
  InjectedFormProps,
  isSubmitting,
  reduxForm,
  submit,
} from "redux-form";
import {DetailView, DetailViewRow} from "../common/ui/detailsection/DetailView";
import {ErrorDisplay} from "../common/ui/errordisplay/ErrorDisplay";
import {createCloseButton} from "../common/ui/FormButtons";
import {ValidationResult} from "../common/ui/validation/ProductValidation";
import {diffArray, moveItem, removeItem} from "../common/util/ArrayUtil";
import {Logger} from "../common/util/Logger";
import {calculateDimensionBasedOnRowsBeforeScroll, getConcatenatedValidationMessages} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {ImportedData} from "../data/model";
import {Identifiable} from "../model";
import {actions} from "./actions";
import {Product, StyledData, toProductLink} from "./model";
import {PRODUCT_DATA_LIST_ROW_HEIGHT, ProductContentListPresentation} from "./ProductContentList";
import {ProductTypeahead} from "./ProductTypeahead";

const FIELD_PRODUCT = "selectedProduct";
const FIELD_DATA_TO_ADD = "toAddStyledData";
const FIELD_PRODUCT_DATA = "selectedProductStyledData";
const FIELD_USED_DATA = "blackList";
const FIELD_IS_LTS_SERVICE = "isLtsService";

interface AddToProductFormData {
  selectedProduct: Product;
  selectedProductStyledData: StyledData[];
  toAddStyledData: StyledData[];
  blackList: ImportedData[];
  isLtsService: boolean;
}

interface AddToProductFormProps {
  initialData: ImportedData[];
}

const PRODUCT_FORM_MESSAGES = defineMessages({
  product: {id: "studio.products.add-to-product-form.product", defaultMessage: "Product"},
  productSelect: {id: "studio.products.add-to-product-form.product-select", defaultMessage: "Select Product"},
  productSearch: {id: "studio.products.add-to-product-form.product-search", defaultMessage: "Search for a Product"},
  dataSelect: {id: "studio.products.add-to-product-form.data-select", defaultMessage: "Select Data"},
});

type AddToProductFormComponentProps = AddToProductFormProps & WithApiProperties & InjectedIntlProps;

class AddToProductFormComponent extends React.Component<AddToProductFormComponentProps & InjectedFormProps<AddToProductFormData, AddToProductFormComponentProps>> {

  _logger: Logger = Logger.getLogger("products.AddToProductForm");

  // temporary ID's counter - when submitted, server will assign global ID's to saved styled-products.
  tempId = -1;

  componentDidMount() {
    this.props.initialize({
      selectedProduct: null,
      selectedProductStyledData: [],
      toAddStyledData: this.props.initialData.map(this.convertToStyledData),
      blackList: [],
      isLtsService: false,
    });
  }

  convertToStyledData = (inputData) => {
    return {
      id: inputData.id,
      productId: null,
      data: inputData.id,
      dataTitle: inputData.title,
      style: inputData.defaultStyleId,
      visible: true,
    };
  }

  saveWhiteList = (rawProductContent: StyledData[]) => {
    const promiseForStyledProducts = rawProductContent.map((aProduct) => {
      return !aProduct.style ?
             Promise.resolve(aProduct) :
             this.fetchStyleTitle(aProduct.style).then((title) => {
               aProduct.styleTitle = title;
               return aProduct;
             }).catch((error) => {
               this._logger.error("Error occurred while retrieving name of style with id " + aProduct.style, error);
             });
    });

    Promise.all(promiseForStyledProducts).then(
        (whiteList) => this.props.change(FIELD_DATA_TO_ADD, [...whiteList]),
    ).catch((error) => {
      this._logger.error("Error occurred while retrieving names of styles", error);
    });
  }

  fetchSelectedProductContents = (prod) => {
    if (prod) {
      this.props.api.loadProductContents(prod.id).then((styledData) => {
        const productDataIds = styledData.map((obj) => obj.data);

        // initial data can be added only if the data does not yet exist in the product
        const whiteList = [];
        const blackList = [];
        this.props.initialData.forEach((inputData) => {
          if (productDataIds.indexOf(inputData.id) === -1) {
            whiteList.push(this.convertToStyledData(inputData));
          } else {
            blackList.push(inputData);
          }
        });

        this.saveWhiteList(whiteList);
        this.props.change(FIELD_USED_DATA, [...blackList]);
        this.props.change(FIELD_PRODUCT_DATA, [...styledData]);
      }).catch((error) => {
        this._logger.error("Error fetching product contents", error);
      });

      this.props.api.getProductServices(prod.id).then((services) => {
        const isLtsService = services && services.filter && services.filter((service) => service.type === "lts").length > 0;
        this.props.change(FIELD_IS_LTS_SERVICE, isLtsService);
      }).catch((error) => {
        this._logger.error("Error fetching product services", error);
      });

      this.props.change(FIELD_PRODUCT, prod);
    }
  }

  validateDataItemToAdd = (selectedProductStyledData: StyledData[]) => (item: Identifiable) => {
    return !selectedProductStyledData.some((styleData) => styleData.data === item.id);
  }

  doAddNewItem = (fieldInput, newItem: StyledData) => {
    if (newItem.style) {
      this.fetchStyleTitle(newItem.style).then((title) => {
        newItem.styleTitle = title;
        this.addToProductField(fieldInput, newItem);
      }).catch((error) => {
        this._logger.error("Error occurred while retrieving name of style with id " + newItem.style, error);
      });
    } else {
      this.addToProductField(fieldInput, newItem);
    }
  }

  addToProductField = (fieldInput, newItem) => fieldInput.onChange([newItem, ...fieldInput.value]);

  fetchStyleTitle = (styleId) => this.props.api.getStyleById(styleId).then((style) => style.title);

  renderFormFields = (fields) => {
    const selectedProductInput = fields.selectedProduct.input;
    const selectedProductStyledDataInput = fields.selectedProductStyledData.input;
    const toAddStyledDataInput = fields.toAddStyledData.input;
    const blackListInput = fields.blackList.input;
    const isLtsService = fields.isLtsService.input.value;

    let productElement;
    if (selectedProductInput.value) {
      productElement = <DetailView
          fields={
            [
              {
                key: "Product",
                name: this.props.intl.formatMessage(PRODUCT_FORM_MESSAGES.product),
                value: selectedProductInput.value.title,
              },
            ]
          }
      />;
    } else {
      productElement = <DetailViewRow name={this.props.intl.formatMessage(PRODUCT_FORM_MESSAGES.productSelect)}>
        <ProductTypeahead
            value={selectedProductInput.value}
            onChange={this.fetchSelectedProductContents}
            productFilter={{}}
            placeholder={this.props.intl.formatMessage(PRODUCT_FORM_MESSAGES.productSearch)}
        />
      </DetailViewRow>;
    }

    const contentsElement =
        (
            <div>
              <DetailViewRow name={this.props.intl.formatMessage(PRODUCT_FORM_MESSAGES.dataSelect)}>
                <div>
                  <ProductContentListPresentation
                      productId={selectedProductInput.value.id}
                      items={toAddStyledDataInput.value}
                      onReorder={(oldIndex, newIndex, items) => toAddStyledDataInput.onChange(
                          moveItem(items, oldIndex, newIndex))}
                      onAdd={(newItem) => this.doAddNewItem(toAddStyledDataInput, newItem)}
                      onRemove={(item, index) => {
                        const newContent = removeItem(toAddStyledDataInput.value, index);
                        toAddStyledDataInput.onChange(newContent);
                      }}
                      onChange={(changedItem, index) => {
                        const newContent = toAddStyledDataInput.value.slice(0);
                        newContent[index] = changedItem;
                        toAddStyledDataInput.onChange(newContent);
                      }}
                      onBulkChange={(changedContent, changedIndices) => {
                        const newContent = [...toAddStyledDataInput.value];
                        for (let i = 0; i < changedIndices.length; i++) {
                          newContent[changedIndices[i]] = changedContent[i];
                        }
                        toAddStyledDataInput.onChange(newContent);
                        return Promise.resolve();
                      }}
                      onBulkRemove={(removedContent) => {
                        const newContent = diffArray(toAddStyledDataInput.value, removedContent);
                        toAddStyledDataInput.onChange(newContent);
                        return Promise.resolve();
                      }}
                      validateItemToAdd={this.validateDataItemToAdd(selectedProductStyledDataInput.value)}
                      noLinks
                      withVisibilityToggle={false}
                      calculateDimensions={calculateDimensionBasedOnRowsBeforeScroll(5, PRODUCT_DATA_LIST_ROW_HEIGHT)}
                  />
                </div>
              </DetailViewRow>
            </div>);

    const blackListElement = blackListInput && blackListInput.value.length > 0 && (
        <DetailViewRow name="">
          <Alert bsStyle="info"><FormattedMessage id="studio.products.add-to-product-form.duplicates-removed"
                                                  defaultMessage="Automatically removed {length} entries that are already present in the product."
                                                  values={{length: blackListInput.value.length}}/></Alert>
          {/*{ blackListInput.value.map(item => item.id)*/}
          {/*.sort()*/}
          {/*.map(itemId => <Label bsSize='xs' key={itemId} style={{margin:'0px 3px 0px 3px'}}> { itemId }</Label>)*/}
          {/*}*/}
        </DetailViewRow>);

    return (
        <div>
          {productElement}
          {contentsElement}
          {blackListElement}
          {isLtsService ?
           <small><strong><FormattedMessage id="studio.products.add-to-product-form.note"
                                            defaultMessage="Note"/>&#58;&nbsp;</strong>
             <FormattedMessage id="studio.products.add-to-product-form.lts-note"
                               defaultMessage="LTS services will only start if all products contain either a Fusion coverage or elevation data"/>
           </small> : null}
        </div>
    );
  }

  confirmationComponent = (field) => {
    const product = field.input.value;
    return (
        <div>
          <h2><FormattedMessage id="studio.products.add-to-product-form.product-updated"
                                defaultMessage="Product successfully updated!"/></h2>
          <Link to={toProductLink(product)}>
            <Button bsStyle="info"><FormattedMessage id="studio.products.add-to-product-form.go-to-product"
                                                     defaultMessage="Go To Product {product}"
                                                     values={{product: product.title}}/></Button>
          </Link>
        </div>
    );
  }

  render() {
    if (this.props.submitting) {
      return (
          <div className="form-message">
            <h1><FormattedMessage id="studio.products.add-to-product-form.product-updating"
                                  defaultMessage="Updating Product..."/></h1>
          </div>
      );
    }
    if (this.props.submitFailed) {
      return (
          <div className="form-message">
            <h1><FormattedMessage id="studio.products.add-to-product-form.product-update-failed"
                                  defaultMessage="Product update failed"/></h1>
            {_error ? <ErrorDisplay error={_error}/> :
             <p><FormattedMessage id="studio.products.add-to-product-form.error-unknown"
                                  defaultMessage="Reason: unknown"/></p>}
          </div>
      );
    }
    if (this.props.submitSucceeded) {
      return <Field name={FIELD_PRODUCT} component={this.confirmationComponent}/>;
    }
    return (

        <Form horizontal onSubmit={this.props.handleSubmit}>
          <Fields names={[FIELD_PRODUCT, FIELD_DATA_TO_ADD, FIELD_PRODUCT_DATA, FIELD_USED_DATA, FIELD_IS_LTS_SERVICE]}
                  component={this.renderFormFields}/>
        </Form>
    );
  }
}

const submitAddToProduct = (values: AddToProductFormData, dispatch) => {
  _error = null;
  const {selectedProduct, toAddStyledData, selectedProductStyledData} = values;
  if (!selectedProduct || !selectedProduct.id) {
    return;
  }

  const itemsToAddInReverseOrder = toAddStyledData.reverse();

  let orderCounter = selectedProductStyledData.length;

  const itemsToAdd = itemsToAddInReverseOrder.map((styledData) => {
    return Object.assign({}, styledData, {order: ++orderCounter, productId: selectedProduct.id});
  });

  const validatePromise = dispatch(actions.validateStyledData(selectedProduct.id, itemsToAdd));

  let resolveFunc, rejectFunc;
  const promise = new Promise((resolve, reject) => {
    resolveFunc = resolve;
    rejectFunc = reject;
  });

  validatePromise.then((apiValidationResult: ValidationResult) => {
    const hasWarnings = apiValidationResult.errorMessages.length > 0 || apiValidationResult.warningMessages.length > 0;

    if (hasWarnings) {
      const errorMessage = getConcatenatedValidationMessages(apiValidationResult);
      _error = new Error(errorMessage);
      rejectFunc(_error);
    } else {
      dispatch(actions.createOrUpdateStyledData(selectedProduct.id, itemsToAdd)).then(resolveFunc);
    }
  }).catch((error) => {
    _error = error;
    rejectFunc(error);
  });
  return promise;
};

let _error: Error = null;

export const FORM_NAME = "addToProductForm";
const formConfig = {
  form: FORM_NAME,
  onSubmit: submitAddToProduct,
};

export const AddToProductForm = injectIntl(WithApi(reduxForm<AddToProductFormData, AddToProductFormComponentProps>(formConfig)(AddToProductFormComponent)));

interface ExecuteButtonProps {
  dispatch: Dispatch<Action>;
  submitting: boolean;
  submitSucceeded: boolean;
  submitFailed: boolean;
  product: Product;
  toAddStyledData: StyledData[];
}

/**
 * The button that submits the form changes.
 * Any changes that user performs are not persisted to the backend before the submit button is pushed.
 */
class ExecuteButtonComponent extends React.Component<ExecuteButtonProps, {}> {
  render() {
    const {dispatch, submitting, submitSucceeded, submitFailed, product, toAddStyledData} = this.props;
    if (submitting || submitSucceeded || submitFailed) {
      return null;
    }

    const disabled = (!toAddStyledData || toAddStyledData.length === 0 || !product);
    return (
        <Button disabled={disabled} onClick={() => dispatch(submit(FORM_NAME))}>
          <FormattedMessage id="studio.products.add-to-product-form.button-disabled" defaultMessage="Add to product"/>
        </Button>
    );
  }
}

export const ExecuteButton = connect((state) => {
  return {
    submitting: isSubmitting(FORM_NAME)(state),
    submitSucceeded: hasSubmitSucceeded(FORM_NAME)(state),
    submitFailed: hasSubmitFailed(FORM_NAME)(state),
    product: formValueSelector(FORM_NAME)(state, FIELD_PRODUCT),
    toAddStyledData: formValueSelector(FORM_NAME)(state, FIELD_DATA_TO_ADD),
  };
})(ExecuteButtonComponent);

export const CloseAddToProductFormButton = createCloseButton(FORM_NAME);
