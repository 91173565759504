import {ImportedData} from "../../../data/model";
import {DataRoot} from "../../../settings/dataroots/model";
import {Style, StyleType} from "../../../styles/model";

const systemUser = {
  id: 1,
  creationTime: "2017-04-10T10:18:07.902",
  username: "system",
  userTourCompleted: false,
};

export const TOUR_DATA: {importedData: ImportedData[], contentRoots: DataRoot[], styles: Style[], actuators: any} = {
  importedData: [
    {
      id: "test id 1",
      creationTime: "2017-04-10T10:39:47.511",
      title: "US States",
      type: "SHP",
      wgs84Bounds: {
        x: -178.21502796792052,
        y: 18.924781274497757,
        width: 111.24518044755983,
        height: 52.481865978836524,
      },
      filePath: "/server_data/data/shp/us_states.shp",
    }, {
      id: "test id 2",
      creationTime: "2010-01-01T00:00:00",
      title: "World boundaries",
      type: "SHP",
      wgs84Bounds: {
          x: 180.0,
          y: 90.0,
          width: 360.0,
          height: 180.0,
      },
      filePath: "/server_data/data/shp/world.shp",
    },
  ],

  contentRoots: [
    {
      id: "test id 1",
      creationTime: "2017-04-10T10:18:21.414",
      rootPath: "./datastore/uploads",
      createdBy: systemUser,
      updatedBy: systemUser,
      updateTime: "2017-04-10T10:18:21.414",
    }, {
      id: "test id 2",
      creationTime: "2016-02-10T10:18:21.414",
      rootPath: "/server_data/data/shp",
      createdBy: systemUser,
      updatedBy: systemUser,
      updateTime: "2016-02-10T10:18:21.414",
    },
  ],

  styles: [
    {
      id: "2001",
      name: "World boundaries red style",
      title: "worldBoundariesRed",
      abstractText: "Red style for World boundaries",
      keywords: [],
      createdBy: systemUser,
      type: StyleType.VECTOR,
    }, {
      id: "2002",
      name: "World boundaries blue style",
      title: "worldBoundariesBlue",
      abstractText: "Blue style for World boundaries",
      keywords: [],
      createdBy: systemUser,
      type: StyleType.VECTOR,
    },
  ],

  actuators: {
    health: {
      status: "UP",
      diskSpace: {
        status: "UP",
        total: 256000000000,
        free: 56000000000,
        threshold: 10485760,
      },
      db: {
        status: "UP",
        database: "USER_TOUR",
        hello: 1,
      },
    },
  },
};
