import * as React from "react";
import {Alert, Button} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Logger} from "../common/util/Logger";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {actions} from "../user/actions";
import {selectors} from "../user/selectors";

interface WelcomePageState {
  usesH2: boolean;
  warningExpanded: boolean;
  finishedLoading: boolean;
}

interface WelcomePageProperties {
  userTourCompleted: boolean;
}

interface WelcomePageDispatchProperties {
  setUserTourCompleted: (isUserTourCompleted: boolean) => void;
}

class WelcomePageComponent extends React.Component<WelcomePageProperties & WelcomePageDispatchProperties & WithApiProperties, WelcomePageState> {

  _logger: Logger = Logger.getLogger("WelcomePage");

  constructor(props) {
    super(props);
    this.state = {
      usesH2: true,
      warningExpanded: false,
      finishedLoading: false,
    };
  }

  componentDidMount() {
    this.setState(Object.assign({}, this.state, {isLoading: true}));
    this.props.api.getActuator("health").then(( input ) => {
      this.setState({
        usesH2: input && input.details && input.details.db && input.details.db.details && input.details.db.details.database && input.details.db.details.database === "H2",
        warningExpanded: false,
        finishedLoading: true,
      });
    }).catch((error) => {
      this.setState(Object.assign({}, this.state, {isLoading: false}));
      this._logger.error("Error occurred while checking backend database type", error);
    });
  }

  renderDevelopmentModeWarning() {
    const {usesH2, warningExpanded, finishedLoading} = this.state;
    if (!finishedLoading  || (finishedLoading && !usesH2)) {
      return null;
    }
    const extraWarningText = <p className="extraText"><FormattedMessage
        id="studio.welcome-page.extra-warning"
        // V181-825: Translation messages should be in one line, otherwise react-intl can't handle them.
        defaultMessage={"The current Studio configuration is fine for single-user development or for demonstrations with small data sets. To optimize performance for multiple users, large data sets, or production mode, it is {strongly} recommended to use a Postgres, Microsoft SQL Server, or Oracle database with Studio. See the LuciadFusion Platform Deployment Guide for instructions. Note that all the data in the current H2 database will be lost when you make the switch to a different database."}
        values={{
          strongly: (
              <b>
                <FormattedMessage
                    id="studio.welcome-page.extra-warning.strongly"
                    defaultMessage="strongly"
                />
              </b>
          ),
        }}
    />
    </p>;
    const showMoreLink = <Button bsStyle="warning" className="pull-right" onClick={() => {
            this.setState(Object.assign({}, this.state, {warningExpanded: true}));
            event.preventDefault();
    }}>
      <FormattedMessage
          id="studio.welcome-page.learn-more-button"
          defaultMessage="Learn More"
      />
    </Button>;
    return <Alert bsStyle="warning" className="devModeWarning">
      <span>
        <FormattedMessage
          id="studio.welcome.performancewarning"
          defaultMessage="LuciadFusion Studio is currently not configured for optimal performance."
        />
      </span>
      {warningExpanded ? extraWarningText : showMoreLink}
    </Alert>;
  }

  render() {
    return (
        <div className="welcomePage">
          {this.renderDevelopmentModeWarning()}
          <div className="splash"/>
          <div className="text-center welcomeText">
            <p>
              <FormattedMessage
                  id="studio.welcomepage.welcomemessage"
                  defaultMessage="To get started, tell LuciadFusion Studio where to find files by configuring {dataRootsLink} or {dataUploadLink}."
                  values={{
                    dataRootsLink: (
                     <span>
                         <br/>
                         <Link className="user-tour-data-roots-link" to="/settings/dataroots">
                         <strong>
                             <FormattedMessage
                                 id="studio.welcomepage.welcomemessage.datarootlinktext"
                                 defaultMessage="data roots"
                             />
                         </strong>
                         </Link>
                      </span>
                    ),
                    dataUploadLink: (
                        <Link className="data-upload-link" to="/data">
                          <strong>
                            <FormattedMessage
                                id="studio.welcomepage.welcomemessage.uploadlinktext"
                                defaultMessage="upload some data"
                            />
                          </strong>
                        </Link>
                    ),
                  }}
              />
            </p>
            <Button bsStyle="primary" bsSize="large" onClick={() => {
              this.props.setUserTourCompleted(false);
            }}>
              <FormattedMessage
                  id="studio.welcome-page.user-tour-button"
                  defaultMessage="Start User Tour"
              />
            </Button>
          </div>
        </div>
    );
  }

}

// Unused, just here so that component is mounted when userTourCompleted has changed, i.e. to start the tour when clicking the button
const mapStateToProps = (state, ownProps) => {
  return {
    userTourCompleted: selectors.getUserTourCompleted(state),
  };
};

const mapDispatchToProps = (dispatch): WelcomePageDispatchProperties => {
  return {
    setUserTourCompleted: (isUserTourCompleted: boolean) => dispatch(actions.setUserTourCompleted(isUserTourCompleted)),
  };
};

export const WelcomePage = connect(mapStateToProps, mapDispatchToProps)(WithApi(WelcomePageComponent));
