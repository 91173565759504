import * as React from "react";
import {connect} from "react-redux";
import {OutdatedWarningPresentation} from "../common/ui/outdatedwarning/OutdatedWarning";
import {actions} from "./actions";
import {selectors} from "./selectors";

const mapStateToProps = (state) => {
  return {
    numberOfNewItems: selectors.getNumberOfNewStylesOnServer(state),
    messageType: "style",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refreshLinkClicked: () => {
      dispatch(actions.clearData());
    },
  };
};

export const StylesOutdatedWarning = connect(mapStateToProps, mapDispatchToProps)(OutdatedWarningPresentation);
