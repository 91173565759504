import * as React from "react";
import {Button} from "react-bootstrap";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {actions} from "./actions";

interface ProductRemoveButtonStateProperties {
  productId: number;
  bsSize?: string;
}

interface ProductRemoveButtonDispatchProperties {
  onProductRemove: () => any;
}

type ProductRemoveButtonProperties = ProductRemoveButtonStateProperties & ProductRemoveButtonDispatchProperties;

export class ProductRemoveButtonComponent extends React.Component<InjectedIntlProps & ProductRemoveButtonProperties, {}> {

  render() {
    return (
        <Button className="btn-icon"
                style={{backgroundColor: "transparent", color: "currentColor"}}
                bsSize={this.props.bsSize as any || null}
                onClick={() => {this.props.onProductRemove(); }}>
          <LcdIcon icon="delete"/>
        </Button>
    );
  }
}

const mapStateToPros = (state, ownProps) => {
  return {
    productId: ownProps.productId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onProductRemove: () => dispatch(actions.deleteProductIfNoWarnings(ownProps.productId, ownProps.intl)),
  };
};

export const ProductRemoveButton = injectIntl(connect(mapStateToPros, mapDispatchToProps)(ProductRemoveButtonComponent));
