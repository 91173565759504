import * as React from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {ColumnsDescription, ColumnWidth} from "../common/ui/table/ControlRoomColumns";
import {createBasicTableComponent} from "../common/ui/table/ControlRoomTable";
import {connectPagedTable, PagedTableConfig} from "../common/ui/table/PagedTable";
import {DateUtil} from "../common/util/DateUtil";
import {actions} from "./actions";
import {canRemoveService, Service, ServiceStatus} from "./model";
import {selectors} from "./selectors";
import {ServiceDeleteButton} from "./ServiceDeleteButton";
import {ServiceStartStopButton} from "./ServiceStartStopButton";

const config: PagedTableConfig = {
  canMultiSelect: false,
  noItemsFoundMessage: <FormattedMessage id="studio.services.service-list.no-services-found"
                                         defaultMessage="No services found"/>,
};

const MESSAGES = defineMessages({
  statusPendingTooltip: {
    id: "studio.services.service-list.status.tooltip-pending",
    defaultMessage: "Preprocessing job is running for the service {name}",
  },
  createdByUnknown: {
    id: "studio.services.service-list.created-by.unknown",
    defaultMessage: "Unknown",
  },
  startStopTooltip: {
    id: "studio.services.service-list.start-stop.tooltip",
    defaultMessage: "Start/Stop {name}",
  },
  canRemoveTooltip: {
    id: "studio.services.service-list.remove.can-remove-tooltip",
    defaultMessage: "Remove {title}",
  },
  cannotRemoveTooltip: {
    id: "studio.services.service-list.remove.cannot-remove-tooltip",
    defaultMessage: "Cannot remove {title}",
  },
});

const columns: ColumnsDescription<Service> = {
  title: {
    header: <FormattedMessage id="studio.services.service-list.title" defaultMessage="Title"/>,
    cellContent: (service) => <Link to={`/services/${service.id}`}>{service.title}</Link>,
    cellTooltip: (service) => service.title,
    columnWidth: ColumnWidth.flex(1),
  },
  type: {
    header: <FormattedMessage id="studio.services.service-list.type" defaultMessage="Type"/>,
    cellContent: (service) => service.type.toString().toUpperCase(),
    cellTooltip: (service) => service.type.toString().toUpperCase(),
    columnWidth: ColumnWidth.pixels(70),
  },
  status: {
    header: <FormattedMessage id="studio.services.service-list.status" defaultMessage="Status"/>,
    cellContent: (service) => {
      if (service.status === ServiceStatus.PENDING) {
        return <Link to={`/jobs/`} style={{fontWeight: "inherit"}}>{service.status}</Link>;
      }
      return service.status.toString();
    },
    cellTooltip: (service, intl) => {
      if (service.status === ServiceStatus.PENDING) {
        return intl.formatMessage(MESSAGES.statusPendingTooltip, {name: service.name});
      }
      return service.status.toString();
    },
    columnWidth: ColumnWidth.pixels(80),
  },
  createdBy: {
    header: <FormattedMessage id="studio.services.service-list.created-by" defaultMessage="Created by"/>,
    cellContent: (service) => service.createdBy ? service.createdBy.username :
      <FormattedMessage id="studio.services.service-list.created-by.unknown" defaultMessage="Unknown"/>,
    cellTooltip: (service, intl) => service.createdBy ? service.createdBy.username : intl.formatMessage(MESSAGES.createdByUnknown),
    columnWidth: ColumnWidth.USERNAME_COLUMN_WIDTH,
  },
  creationTime: {
    header: <FormattedMessage id="studio.services.service-list.date-created" defaultMessage="Date created"/>,
    cellContent: (service) => DateUtil.formatDateTime(service.creationTime),
    cellTooltip: (service) => DateUtil.formatDateTime(service.creationTime),
    columnWidth: ColumnWidth.DATETIME_COLUMN_WIDTH,
  },
  startStop: {
    header: <FormattedMessage id="studio.services.service-list.start-stop" defaultMessage="Start/Stop"/>,
    cellContent: (service) => <ServiceStartStopButton service={service} compact/>,
    cellTooltip: (service, intl) => intl.formatMessage(MESSAGES.startStopTooltip, {name: service.name}),
    columnWidth: ColumnWidth.STARTSTOP_BUTTON_COLUMN_WIDTH,
  },
  remove: {
    header: <FormattedMessage id="studio.services.service-list.remove" defaultMessage="Remove"/>,
    cellContent: (service) => <ServiceDeleteButton service={service} disabled={!canRemoveService(service)}/>,
    cellTooltip: (service, intl) => {
      if (canRemoveService(service)) {
        return intl.formatMessage(MESSAGES.canRemoveTooltip, {title: service.title});
      }
      return intl.formatMessage(MESSAGES.cannotRemoveTooltip, {title: service.title});
    },
    columnWidth: ColumnWidth.REMOVE_COLUMN_WIDTH,
  },
};

export const ServiceListComponent = createBasicTableComponent<Service>(columns);
export const ServiceList = connectPagedTable(ServiceListComponent, selectors.getPagedState, actions.requestPage,
    config);
