import * as React from "react";
import {connect} from "react-redux";
import {JobStartStopButtonComponent} from "../JobStartStopButton";
import {actions} from "./actions";

interface JobStartStopButtonDispatchProperties {
  startJob: (id: string) => void;
  stopJob: (id: string) => void;
}

const mapStateToProps = (state, ownProps) => {
  return {
    job: ownProps.job,
  };
};

const mapDispatchToProps = (dispatch): JobStartStopButtonDispatchProperties => {
  return {
    startJob: (id) => {
      dispatch(actions.queueJob(id));
    },
    stopJob: (id) => {
      dispatch(actions.stopJob(id));
    },
  };
};

export const PreprocessingJobStartStopButton = connect(mapStateToProps, mapDispatchToProps)(
    JobStartStopButtonComponent);
